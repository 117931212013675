<script setup>
import { ref, onMounted, watch } from "vue";
import SimpleTable from "@/components/table/SimpleTable.vue";
import ArgonTd from "@/components/table/ArgonTd.vue";
import ArgonDetailLayout from "@/components/detail/ArgonDetailLayout.vue";
import { pricingPricesListStore } from "@/_store/pricingPricesStore";
import { useRoute } from "vue-router";
import ArgonSnackbar from "@/components/ArgonSnackbar.vue";
import ArgonInput from "@/components/ArgonInput.vue";
import { getCurrentInstance } from "vue";

const { proxy } = getCurrentInstance();
const pricingPricesStore = pricingPricesListStore();
const snackbar = ref(null);
const route = useRoute();
const model = ref(null);

const listTable = ref({ data: [] });

const headers = ref([
  {
    header: "Superior a (kg)",
    field: "from_kg",
   
    position: "start",
  },
  {
    header: "Inferior o igual a (kg)",
    field: "to_kg",
   
    position: "start",
  },
  {
    header: "Precio",
    field: "amound",
     
    position: "start",
  },
]);

onMounted(() => {
  getFarePrice();
});
function getFarePrice() {
  pricingPricesStore.getPricingPriceById(route.params.id);
}
function getPosition(field) {
  const column = headers.value.find((col) => col.field === field);
  return column && column.position ? column.position : "start";
}
watch(
  () => pricingPricesStore.pricingPrice,
  (value) => {
    if (value) {
      model.value = value;
      listTable.value.data = value.prices;
    }
  }
);

watch(
  () => pricingPricesStore.error,
  (value) => {
    if (value) {
      snackbar.value = {
        title: "Error",
        message: value.message,
        type: "danger",
        color: "white",
      };
    }
  }
);
const closeSnackbar = () => {
  snackbar.value = null;
};
</script>
<template>
  <div class="card">
    <div class="card-body">
      <argon-detail-layout>
        <template #title>
          <argon-input
            v-if="model"
            id="name"
            placeholder="Tarifa"
            v-model="model.description"
            :max-length="40"
            type="text"
            size="lg"
            font-style="h5 fw-bold"
            disabled
          />
        </template>
        <template #body>
          <div class="row spacing">
            <div class="col-12">
              <div class="card">
                <div class="card-body" v-if="model">
                  <div class="row">
                    <div class="col">
                      <label class="col-form-label"
                        >Precio cliente (kg adicional): 
                        {{ proxy.$formatPrice(model.extra_amount.customer) }}</label
                      >
                    </div>
                  </div>
                  <div class="row">
                    <div class="col">
                      <label class="col-form-label"
                        >Precio coste (kg adicional): 
                        {{ proxy.$formatPrice(model.extra_amount.cost) }}</label
                      >
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </div>
          <div class="row spacing">
            <div class="col-12">
              <div class="card">
                <div class="card-body">
                  <simple-table :headers="headers" :items="listTable">
                    <template #default="{ item }">
                      <argon-td
                        :position="getPosition('from_kg')"
                        :text-secondary="item.from_kg"
                      ></argon-td>
                      <argon-td
                        :position="getPosition('to_kg')"
                        :text-secondary="item.to_kg"
                      ></argon-td>
                      <argon-td
                        :position="getPosition('amound')"
                        :text-primary="`Cliente: ${proxy.$formatPrice(
                          item.amound.customer
                        )}`"
                        :text-secondary="`Coste: ${proxy.$formatPrice(
                          item.amound.cost
                        )}`"
                      ></argon-td>
                    </template>
                  </simple-table>
                </div>
              </div>
            </div>
          </div>
        </template>
      </argon-detail-layout>
    </div>
  </div>
  <div class="position-fixed top-1 end-1 z-index-2">
    <argon-snackbar
      v-if="snackbar"
      :title="snackbar.title"
      :description="snackbar.message"
      :icon="{ component: 'ni ni-notification-70', color: snackbar.type }"
      :color="snackbar.color"
      :close-handler="closeSnackbar"
    />
  </div>
</template>