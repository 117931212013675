<script setup>
import ImportModal from "../ImportModal.vue";
const emit = defineEmits(["import", "export", "eventAction"]);

function importData() {
  emit("import");
}

function exportData() {
  emit("export");
}

function eventMoreAction(action){
  emit('eventAction', action);
}

defineProps({
  importOptions: {
    type: Object,
    default() {
      return {
        title: "Importar Fichero",
        options: [],
        url: "",
      };
    },
  },
  showConfigOption: {
    type: Boolean,
    default: true,
  },
  showImportar: {
    type: Boolean,
    default: true,
  },
  moreActions: {
    type: Array,
    default() {
      return [];
    },
  },
});
</script>
<template>
  <div>
    <div v-if="showConfigOption" class="btn-table-settings dropdown d-inline">
      <a
        id="navbarDropdownSettingsTable"
        class="me-2"
        data-bs-toggle="dropdown"
        aria-expanded="false"
        ><i class="fa fa-gear me-sm-2"></i
      ></a>
      <ul
        class="dropdown-menu dropdown-menu-lg-start px-2 py-3"
        aria-labelledby="navbarDropdownSettingsTable"
        style="z-index: 1030 !important"
      >
        <li v-if="showImportar">
          <a
            class="dropdown-item border-radius-md"
            data-bs-toggle="modal"
            data-bs-target="#modalImport"
            @click="importData()"
            >Importar</a
          >
        </li>
        <li>
          <a class="dropdown-item border-radius-md" @click="exportData()"
            >Exportar</a
          >
        </li>
        <template v-if="moreActions.length > 0">
          <li>
            <hr class="dropdown-divider" />
          </li>
          <li v-for="action in moreActions" :key="action">
            <a class="dropdown-item border-radius-md" @click="eventMoreAction(action.event)">
              {{ action.label }}
            </a>
          </li>
        </template>
      </ul>
    </div>
    <import-modal
      id="modalImport"
      :title="importOptions.title"
      :options="importOptions.options"
      :url="importOptions.url"
    ></import-modal>
    <!-- <expedite-modal
      id="modalExpedite"
    ></expedite-modal> -->
  </div>
</template>
