<script setup>
import { computed, ref, watch } from "vue";
const emit = defineEmits([
  "filterChanged",
  "pageChanged",
  "import",
  "export",
  "newItem",
  "onReload",
  "onSync",
  "expedite",
  "eventAction"
]);
import { Shops } from "@/globalVars";
import { argonTableStore } from "@/_store/argonTableStore";
import ArgonButton from "@/components/ArgonButton.vue";
import ArgonTableSettings from "./ArgonTableSettings.vue";
import ArgonSearcher from "../searcher/ArgonSearcher.vue";

const tableStore = argonTableStore();
var selectedCount = ref(0);

const props = defineProps({
  moreActions: {
    type: Array,
    default() {
      return [];
    },
  },
  showNewButton: {
    type: Boolean,
    default: true,
  },
  items: {
    type: Array,
    default() {
      return [];
    },
  },
  total: {
    type: Number,
    default: 80,
  },
  fieldFilters: {
    type: Array,
    default() {
      return [];
    },
  },
  defaultFilters: {
    type: Array,
    default() {
      return [];
    },
  },
  importOptions: {
    type: Object,
    default() {
      return {
        title: "Importar Fichero",
        options: [],
        url: "",
      };
    },
  },
  showConfigOption: {
    type: Boolean,
    default: true,
  },
  showImportar: {
    type: Boolean,
    default: true,
  },
  showSyncButton: {
    type: Boolean,
    default: false,
  },
  labelButton: {
    type: String,
    default: "Nuevo",
  },
  showFavorites: {
    type: Boolean,
    default: true,
  },
});

watch(
  () => tableStore.checkedList.length,
  () => {
    selectedCount.value = tableStore.selectedCount;
  }
);

watch(
  () => tableStore.selectedAllItems,
  () => {
    selectedCount.value = tableStore.selectedCount;
  }
);

function importData() {
  emit("import");
}

function exportData() {
  emit("export");
}
function expedite() {
  emit("expedite");
}
const previousPage = computed(() => {
  if (tableStore.pagination.page == 1) {
    return Math.ceil(props.total / tableStore.pagination.limit);
  }
  return tableStore.pagination.page - 1;
});

const nextPage = computed(() => {
  const maxPages = Math.ceil(props.total / tableStore.pagination.limit);
  if (tableStore.pagination.page == maxPages) {
    return 1;
  }
  return tableStore.pagination.page + 1;
});

const pagination_description = computed(() => {
  var startPage =
    (tableStore.pagination.page - 1) * tableStore.pagination.limit + 1;
  var endPage = startPage + tableStore.pagination.limit - 1;
  return `${startPage}-${endPage > props.total ? props.total : endPage}`;
});

function filterChanged(changes) {
  emit("filterChanged", changes);
}

function changePage(newPage) {
  tableStore.pagination.page = newPage;
  emit("pageChanged", newPage);
}

function newItem() {
  emit("newItem");
}

function onReload() {
  emit("onReload");
}

function onSync(shopType) {
  emit("onSync", shopType);
}

function onEventAction(action){
  emit('eventAction', action)
}
</script>

<template>
  <div class="row m-0 pt-1">
    <div class="col-md-auto d-flex ps-3 align-items-center">
      <argon-button
        v-if="showNewButton"
        class="me-2 btn-sm"
        color="primary"
        @click="newItem"
        ><i v-if="labelButton === 'Sincronizar'" class="fa fa-sync me-sm-2"></i>
        <i v-else class="fa fa-plus me-sm-2"></i>
        {{ labelButton }}</argon-button
      >
      <slot name="button-custom"></slot>
      <div v-if="showSyncButton" class="dropdown d-inline">
        <argon-button
          id="navbarDropdownPrinter"
          color="primary"
          class="dropdown-toggle me-2 btn-sm"
          data-bs-toggle="dropdown"
          aria-expanded="false"
          >
          <i v-if="labelButton === 'Sincronizar'" class="fa fa-sync me-sm-2"></i>
          Sincronizar
        </argon-button>
        <ul
          class="dropdown-menu z-index-1030 dropdown-menu-lg-start px-2 py-3"
          aria-labelledby="navbarDropdownPrinter"
          style
        >
          <li>
            <a
              class="dropdown-item border-radius-md"
              href="javascript:;"
              @click="onSync(Shops.SHOPIFY)"
              >Shopify</a
            >
          </li>
          <li>
            <a
              class="dropdown-item border-radius-md"
              href="javascript:;"
              @click="onSync(Shops.PRESTASHOP)"
              >Prestashop</a
            >
          </li>
        </ul>
      </div>
      <ArgonTableSettings
        v-if="!tableStore.hasSelectedItems"
        :show-config-option="props.showConfigOption"
        :import-options="importOptions"
        :show-importar="showImportar"
        @import="importData"
        @export="exportData"
        @expedite="expedite"
        @event-action="onEventAction"
        :moreActions="moreActions"
      ></ArgonTableSettings>
    </div>
    <div
      v-if="tableStore.hasSelectedItems"
      class="col d-flex justify-content-center"
    >
      <div
        class="btn-selection btn-selection-primary pt-0 pb-0 ps-2 pe-2 d-flex me-2 align-items-center"
      >
        <span v-if="tableStore.selectedAllItems" class="me-1">Todos</span>
        <span>{{ selectedCount }} Seleccionado(s)</span>
        <a
          v-if="selectedCount == tableStore.pagination.rows"
          class="btn-select-all ms-2 p-1"
          @click="tableStore.selectedAll(total)"
          ><i class="fas fa-arrow-right me-1"></i>Seleccionar Todo
          {{ total }}</a
        >
        <a class="ms-2" @click="tableStore.clearSelection()"
          ><i class="fas fa-times text-primary"></i
        ></a>
      </div>
      <slot name="buttons"></slot>
      <div class="dropdown d-inline">
      </div>
    </div>
    <div v-else class="col d-flex justify-content-center pe-5 ps-5">
      <argon-searcher
        size="sm"
        icon="fa fa-search"
        class="min-size"
        placeholder="Buscar..."
        :field-filters="fieldFilters"
        :default-filters="defaultFilters"
        @filter-changed="filterChanged"
        :show-favorites="showFavorites"
      ></argon-searcher>
    </div>
    <div class="col-md-auto">
      <nav
        aria-label="paginacion"
        class="d-flex justify-content-end align-items-center"
      >
        <ul class="pagination m-0 pe-1">
          <li class="page-item">
            <a
              v-tooltip
              data-bs-title="Refrescar"
              class="page-link"
              @click="onReload"
              >&#8634;</a
            >
          </li>
        </ul>
        <ul class="m-0 ps-1 pe-1 text-xs">
          <span class="page">{{ pagination_description }} / {{ total }}</span>
        </ul>
        <ul v-tooltip class="pagination justify-content-end m-0">
          <li class="page-item">
            <a class="page-link" @click="changePage(previousPage)">&lt;</a>
          </li>
          <li class="page-item">
            <a class="page-link" @click="changePage(nextPage)">&gt;</a>
          </li>
        </ul>
      </nav>
    </div>
  </div>
</template>
<style scoped>
.min-size{
  min-height: 34px !important;
}
</style>
<style lang="scss" scoped>
.z-index-1030 {
  z-index: 1030 !important;
}
</style>
