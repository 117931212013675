<script setup>
import { ref, onMounted, watch } from "vue";
import ArgonTable from "@/components/table/ArgonTable.vue";
import { pricingPricesListStore } from "@/_store/pricingPricesStore";
import { argonTableStore } from "@/_store/argonTableStore";
import { useRouter } from "vue-router";
import ArgonTd from "@/components/table/ArgonTd.vue";
import moment from "moment";
import { userStore } from "@/_store/userStore";
import PartnerAvatar from "@/components/PartnerAvatar.vue";
import ArgonSnackbar from "@/components/ArgonSnackbar.vue";
import ModalNewPricingFares from "./ModalNewPricingFares.vue";
const pricingStore = pricingPricesListStore();
const tableStore = argonTableStore();
const userStores = userStore();
const router = useRouter();
const snackbar = ref(null);
const showModalImport = ref(false);
const actions = ref([]);
const actionEvents = ref({'importPricingFare': importPricingFare});
actions.value.push({label: 'Importar', event: 'importPricingFare' })

const fieldFilters = ref([
  {
    field: "fare.code",
    description: "Código tarifa",
  },
  {
    field: "geographic",
    description: "Geográfico",
  },
  {
    field: "service",
    description: "Servicio",
  },
]);

const defaultFilters = [
  {
    field: "courier",
    label: "Courier",
    multi: true,
    options: [
      { label: "Correos Express", value: 'CEX'},
      { label: "Ctt Express", value: 'CTT' },
      { label: "Ups", value: 'UPS' },
    ],
  },
];

const columns = ref([
  {
    header: "Tarifa",
    field: "fare.code",
    position: "start",
  }, 
  {
    header: "Servicio",
    field: "service",
    position: "start",
  },
  {
    header: "Geográfico",
    field: "geographic",
    position: "start",
  }, 
  {
    header: "Inicio vigencia",
    field: "from_date",
    position: "start",
    sort: true
  },
  {
    header: "Fin vigencia",
    field: "to_date",
    position: "start",
    sort: true
  },
  {
    header: "Creado",
    field: "created_at",
    sort: true,
    position: "start",
  },  
  {
    header: "Partner",
    field: "courier",
    position: "center",
  }
]);

const importOptions = {
  title: "Importar Tarifas",
  type: "SHIPMENT",
  url: `/shipment/batch`,
};

onMounted(() => {
  tableStore.clear();
  const shipmentFilters = userStores.filtersPage.pricingFares?.filters || [];
  tableStore.changeFilters(shipmentFilters);
  reloadPage();
});

function reloadPage() {
  userStores.filtersPage.pricingFares = {
    filters: tableStore.filters,
    filtersFavorite: tableStore.filtersFavorite,
  };
  pricingStore.getPricingPrice(
    tableStore.pagination,
    tableStore.query,
    tableStore.sort
  );
};
function getPosition(field) {
  const column = columns.value.find((col) => col.field === field);
  return column && column.position ? column.position : "start";
};
function onClick(val) {
  const url = `/pricing/prices/${val._id}`;
  router.push(url);
}
function importPricingFare(){
  showModalImport.value = true;
}
watch(
  () => pricingStore.error,
  (value) => {
    if (value) {
      snackbar.value = {
        title: "Error",
        message: value.message,
        type: "danger",
        color: "white",
      };
    }
  }
);
function exportData() {
  pricingStore.getPricingPriceAsExcel(tableStore.query, tableStore.sort);
}
const closeSnackbar = () => {
  snackbar.value = null;
};
function viewPDF(blob) {
  const URI = URL.createObjectURL(blob);
  window.open(URI, "_blank");
}
function closeModal(){
  showModalImport.value=false;
}
function onEventAction(action){
  actionEvents.value[action]()
}
function successModalPricing(){
  snackbar.value = {
    title: "Enviado",
    message: "Fichero enviado para procesar",
    type: "info",
    color: "white",
  };
}
watch(
  () => pricingStore.pricingPricesAsExcel,
  (newVal) => {
    if (newVal) {
      viewPDF(newVal);
    }
  }
);
</script>
<template>
  <div class="card">
    <div class="card-body">
      <argon-table
        v-if="pricingStore.pricingPricesTable"
        :columns="columns"
        :items="pricingStore.pricingPricesTable"
        :field-filters="fieldFilters"
        :default-filters="defaultFilters"
        @filter-changed="reloadPage"
        @on-reload="reloadPage"
        @sort-changed="reloadPage"
        :show-check-input="false"
        @page-changed="reloadPage"
        :show-new-button="false"
        @on-click="onClick"
        @export-data="exportData"
        :show-importar="false"
        :import-options="importOptions"
        :more-actions="actions"
        @event-action="onEventAction"
        >
        <template #default="{ item }">
          <argon-td :position="getPosition('fare.code')" :text-primary="item.fare.code" :text-secondary="item.fare.name" />         
          <argon-td :position="getPosition('service')" :text-secondary="item.service" />
          <argon-td :position="getPosition('geographic')" :text-secondary="item.geographic" />

        
          <argon-td
            :position="getPosition('from_date')"
            :text-secondary="item.from_date != null ? moment(item.from_date).format('DD/MM/YYYY') : ''"
          ></argon-td>
          <argon-td
            :position="getPosition('to_date')"
            :text-secondary="item.to_date != null ? moment(item.to_date).format('DD/MM/YYYY') : ''"
          ></argon-td>
          <argon-td
            :position="getPosition('created_at')"
            :text-primary="moment(item.created_at).format('DD/MM/YYYY HH:mm')"
            :text-secondary="moment(item.created_at).fromNow()"
          ></argon-td>
          
        
          <argon-td :position="getPosition('courier')">
            <partner-avatar
              :item="item.courier"
            ></partner-avatar>
          </argon-td>
        </template>
      </argon-table>
    </div>
  </div>
  <modal-new-pricing-fares v-if="showModalImport" @close="closeModal" @success="successModalPricing"/>
  <div class="position-fixed top-1 end-1 z-index-2">
    <argon-snackbar
      v-if="snackbar"
      :title="snackbar.title"
      :description="snackbar.message"
      :icon="{ component: 'ni ni-notification-70', color: snackbar.type }"
      :color="snackbar.color"
      :close-handler="closeSnackbar"
    />
  </div>
</template>