<script setup>
import { ref, onMounted, watch, nextTick, onBeforeUnmount } from "vue";
import ArgonInput from "@/components/ArgonInput.vue";
const emit = defineEmits([
  "update:modelValue",
  "textChanged",
  "selectedChange",
  "pageChanged",
  "closeMenu"
]);
const page=ref(1);
const props = defineProps({
  label: {
    type: String,
    default: "",
  },
  menuWidth:{
    type: String,
    default: "200",
  },
  size: {
    type: String,
    default: "default",
  },
  error: {
    type: Boolean,
    default: false,
  },
  success: {
    type: Boolean,
    default: false,
  },
  name: {
    type: String,
    default: "",
  },
  id: {
    type: String,
    default: "",
  },
  placeholder: {
    type: String,
    default: "",
  },
  itemText: {
    type: Array,
    default() {
      return [];
    },
  },
  separator: {
    type: String,
    default: "-",
  },
  dataSuggestions: {
    type: Array,
    default() {
      return [];
    },
  },
  modelValue: {
    type: String,
    default: "",
  },
  init: {
    type: Number,
    default: 0,
  },
  disabled: {
    type: Boolean,
    default: false,
  },
  maxLength: {
    type: Number,
    default: 500,
  },
  iconSelected: {
    type: String,
    default: "",
  },
  withTooltip: {
    type: Boolean,
    default: false,
  },
});

var model = ref(props.modelValue);
var isVisible = ref(false);
const suggestionRefs = ref([]);
const index = ref(0);
const menuRef = ref(null);
var listSuggestions =ref(props.dataSuggestions);

function isOutOfViewport(elem) {
  const bounding = elem.getBoundingClientRect();
  const out = {};

  out.right =
    bounding.right >
    (window.innerWidth || document.documentElement.clientWidth);

  return out;
}

function adjustAutocompletePosition() {
  setTimeout(() => {
    if (menuRef.value) {
      const out = isOutOfViewport(menuRef.value);
      if (out.right) {
        menuRef.value.style.right = "0";
        menuRef.value.style.left = "auto";
      } else {
        menuRef.value.style.right = "auto";
        menuRef.value.style.left = "0";
      }
    }
  }, 500);
}
const uniqueId = Math.random().toString(36).substr(2, 9);
const dynamicId=ref("");
onMounted(() => {
  dynamicId.value = `divRef-${uniqueId}`;
  model.value = props.modelValue;
  listSuggestions.value=props.dataSuggestions;
  window.addEventListener('resize', adjustAutocompletePosition);
  nextTick(() => {
    setIndent();
    const element = document.getElementById(dynamicId.value);
    if (element) {
      element.style.setProperty('--width', `${props.menuWidth}px`);
    }
  });
});
onBeforeUnmount(() => {
  window.removeEventListener('resize', adjustAutocompletePosition);
  if (menuRef.value) {
    menuRef.value.removeEventListener('scroll', handleScroll);
  }
});

function handleScroll() {
  const element = menuRef.value;
  if (!element) return;
  const isAtBottom = Math.abs(element.scrollHeight - element.scrollTop - element.clientHeight) <= 1;
  if (isAtBottom) {
    page.value++;
    emit('pageChanged',model.value,page.value);
  }
}

function setIndent(withIndent){
  let inputPoint=document.getElementById(props.id);
  if(withIndent){
    if(inputPoint)
      inputPoint.setAttribute("style", "text-indent: 25px;");
  }else{
    if(inputPoint)
      inputPoint.setAttribute("style", "text-indent: 0px;");
  }
}
function textView(item, itemText, separator) {
  var text = "";
  for (var i = 0; i < itemText.length; i++) {
    var value = getValueFromPath(item, itemText[i]) || "";
    if (value) {
      text = text + value;
      if (i < itemText.length - 1 && itemText.slice(i + 1).some(path => getValueFromPath(item, path))) {
        text = text + separator;
      }
    }
  }
  return text;
}
function getValueFromPath(obj, path) {
  const keys = path.split(".");
  let value = obj;

  for (let key of keys) {
    value = value[key];
    if (value === undefined) return undefined;
  }

  return value;
}
function resetKeyDownUpActions(){
  index.value= 0;
  isFirstKeydown= true;
}
function onFocus(){
  isVisible.value = true;
  resetKeyDownUpActions();
  emit("textChanged", model.value, 1);
}
function textChanged(value) {
  model.value = value;
  if(model.value=="")
    setIndent(false);
  if (!value || value.length < props.init) {
    listSuggestions.value = [];
  }
  isVisible.value = true;
  emit("update:modelValue", value);
  page.value=1;
  emit("textChanged", model.value, page.value);
}
function selectedChange(item) {
  setIndent(true)
  isVisible.value = false;
  model.value = textView(item, props.itemText, props.separator);
  emit("update:modelValue", model.value);
  emit("selectedChange", item);
}
function scrollIntoViewIfNeeded(element) {
  if(!element) return;
  const parent = element.parentNode;
  const parentRect = parent.getBoundingClientRect();
  const elementRect = element.getBoundingClientRect();
  if (elementRect.bottom > parentRect.bottom) {
    parent.scrollTop += elementRect.bottom - parentRect.bottom;
  } else if (elementRect.top < parentRect.top) {
    parent.scrollTop -= parentRect.top - elementRect.top;
  }
}
let isFirstKeydown = true;
function activateKeyScroll(index, container){
  if (!container){
    return
  }
  const itemHeight = container.querySelector(".item").offsetHeight;
  const  itemsShowed=5
  if (index >= itemsShowed) {
    container.scrollTop = (index - itemsShowed) * itemHeight;
  }
}
function handleKeydown(event) {
  const container = menuRef.value;
  const keyActions = {
    ArrowDown: () => {
      if(isFirstKeydown) isFirstKeydown= false
      else if (index.value < suggestionRefs.value.length - 1){
        index.value++;
        activateKeyScroll(index.value, container);
      } 
    },
    ArrowUp: () => {
      if (index.value > 0){
        index.value--;
        activateKeyScroll(index.value, container);
      }
    },
    Enter: () => {
      if (index.value >= 0) suggestionRefs.value[index.value]?.click();
    },
    Escape: () => {
      isVisible.value=false;
    },
    Tab: () => {
      isVisible.value=false;
    }
  };
  const action = keyActions[event.key];
  if (action) {
    if (event.key !== "Backspace" && event.key !== "Tab") {
      event.preventDefault();
    }
    if (event.key === "ArrowDown" || event.key === "ArrowUp") {
      suggestionRefs.value[index.value]?.classList.remove("focused");
    }
    action();
    if (event.key === "ArrowDown" || event.key === "ArrowUp") {
      suggestionRefs.value[index.value]?.classList.add("focused");
      scrollIntoViewIfNeeded(suggestionRefs.value[index.value]);
    }
  }
}
function handleClickOutside(){
  listSuggestions.value=[];
  emit("closeMenu")
  isVisible.value=false;
}
watch(
  () => props.modelValue,
  (val) => {
    model.value = val;
  }
);
watch(model, (newVal) => {
  if (newVal && suggestionRefs.value.length > 0) {
    index.value = 0;
    suggestionRefs.value[index.value].classList.add("focused");
    scrollIntoViewIfNeeded(suggestionRefs.value[index.value]);
  }
});
watch(() => props.iconSelected, (value) => {
  if(value!==""){
    setIndent(true);
  }else setIndent(false);
});
watch(() => props.dataSuggestions, (value) => {
  listSuggestions.value=value;
});

watch(() => isVisible.value, (value) => {
  if(!value){
    page.value=1;
  }
});

watch(() => menuRef.value, (value) => {
  if(value!==null){
    nextTick(() => {
      if (menuRef.value) {
        menuRef.value.addEventListener('scroll', handleScroll);
      }
      adjustAutocompletePosition();
    });
  }
});
</script>
<template>
  <div :id="dynamicId" class="col mb-3 p-0 border-0 dropdown" v-click-outside="handleClickOutside">
    <img v-if="iconSelected!='' && model!=''" :src="iconSelected" class="input-icon" />
    <argon-input
      :id="id"
      v-model="model"
      :label="label"
      :max-length="maxLength"
      :is-required="true"
      :placeholder="placeholder"
      :name="name"
      :error="error"
      :size="size"
      :disabled="disabled"
      :class="{ visible: isVisible && listSuggestions.length > 0 }"
      class="mb-0 input-dropdown-menu"
      @input="textChanged($event.target.value)"
      @keydown="handleKeydown"
      @focus="onFocus"
    />
    <div
      :id="`menu-${uniqueId}`"
      ref="menuRef"
      v-if="isVisible && listSuggestions.length > 0"
      class="searcher-menu-auto-complete searcher-menu-config shadow rounded mt-3 p-1"
      aria-labelledby="searcherTextDropdown"
    >
      <div v-for="(item, key) in listSuggestions" :key="key" class="d-flex align-items-center item">
        <slot name="icon" :item="item"></slot>
        <a
          v-tooltip
          :data-bs-title="withTooltip?textView(item, itemText, separator):null"
          ref="suggestionRefs"
          class="dropdown-item border-radius-md flex-grow-1 text-truncate"
          @click="selectedChange(item)"
          ><b class="ellipsis">{{ textView(item, itemText, separator) }}</b></a
        >
      </div>
    </div>
  </div>
</template>
<style scoped lang="scss">
.input-icon {
  position: absolute;
  left: 0px;
  top: 50%;
  transform: translateY(-50%);
  width: 16px;
  height: 16px;
  border-radius: 50%;
  object-fit: cover;
}
.searcher-menu-config {
  max-height: 200px;
  overflow-y: auto;
  opacity: 1;
  min-width: var(--width);
  max-width: var(--width);
}
.auto-complete {
  margin: 0;
  width: 100%;
  border-radius: 0.5rem;
  margin-bottom: 1rem;

  input {
    border: 0;
    width: 100%;
    min-height: unset;
    font-size: 0.75rem;

    &:hover,
    &:focus,
    &:hover:focus,
    &:active,
    &:hover:focus:active {
      outline: none;
    }
  }
}
.focused {
  color: #495057;
  background-color: #e9ecef;
}
b, strong {
  font-weight: normal !important;
}

.ellipsis {
  display: block;
  white-space: nowrap;
  overflow: hidden;
  text-overflow: ellipsis;
}

.text-truncate {
  max-width: 100%;
}
.input-dropdown-menu {
  &::after {
    font: normal normal normal 14px/1 FontAwesome;
    content: "\f0d8";
    color: #fff;
    position: absolute;
    top: 25px;
    left: 20px;
    font-size: 22px;
    width: 0;
    height: 0;
    text-shadow:
      1px 1px 0px #0000001f,
      -1px -1px 0px #0000001f,
      -1px 1px 0px #0000001f,
      1px -1px 0px #0000001f;
    visibility: hidden;
    opacity: 0;
    transition: visibility 0s, opacity 0.3s ease-in-out;
  }
  
  &.visible::after {
    visibility: visible;
    opacity: 1;
  }
}


</style>