<script setup>
import { ref, onMounted } from "vue";
import { shipmentDetailStore } from "@/_store";
import { useStore } from "vuex";
import ModalBase from "@/components/modal/ModalBase.vue";
import ValuationsSubTable from "@/components/ValuationsSubTable.vue";
const emits=defineEmits(['close'])
const detailStore = shipmentDetailStore();
const modalRevaluation = ref(null);
const store = useStore();
const bootstrap = store.state.bootstrap;
onMounted(() => {
  let statusModal = new bootstrap.Modal(
    document.getElementById("modalValuation"),
    {
      keyboard: true,
    }
  );
  statusModal.show();
});

function closeModal() {
  emits('close')
}
</script>

<template>
  <modal-base
    id="modalValuation"
    ref="modalRevaluation"
    @hide="closeModal()"
    width="60rem"
  >
    <template #header>Valoración</template>
    <template #body> 
      <valuations-sub-table
        v-if="detailStore.valuation"
        v-model="detailStore.valuation"
        :sorter="false"
      />
    </template>
  </modal-base>
</template>