<script setup>
import {  ref, inject } from "vue";
import ArgonSnackbar from "@/components/ArgonSnackbar.vue";
import { ArgonLoadingButton } from "@/components";
import { useVuelidate } from "@vuelidate/core";
import { required, helpers, email } from "@vuelidate/validators";
import ArgonInput from "@/components/ArgonInput.vue";
const emits=defineEmits(['accept','cancel'])
let dataEmail = ref({
  email: ""
});
const rules = {
  email: {
    required: helpers.withMessage("El campo es obligatorio", required),
    email: helpers.withMessage("Ingrese un email válido", email),
  },
};
const v$ = ref(useVuelidate(rules, dataEmail));
const snackbar = ref(null);
const closeSnackbar = () => {
  snackbar.value = null;
};
const fileStore = inject("fileStore");
async function validate() {
  await v$.value.$validate();
  return v$.value.$errors;
}
function acceptedSend(){
  const promiseEmail = validate();
  promiseEmail.then((values) => {
    let message = "";
    for (const value of values) {
      message = `${message}<li>${value.$message}</li>`;
    }
    if (message != "") {
      snackbar.value = {
        title: "Error",
        message: `<ul>${message}</ul>`,
        type: "danger",
        color: "white",
      };
    } else {
      fileStore.sendLoading=true;
      emits('accept',dataEmail.value.email)
    }
  })
}
</script>
<template>
  <div
    class="card mb-2 pe-3 card-design"
    style="z-index: 99999"
  >
    <div class="card-body">
      <div class="row">
        <div class="col-3">
          <label
            class="col-form-label required"
            style="font-size: 14px; color: #495057"
            >Email</label
          >
        </div>
        <div class="col-9 pe-0">
          <argon-input
            class="transparent"
            id="email"
            type="email"
            v-model="dataEmail.email"
            :is-required="true"
            :error="v$.email.$error"
          />
        </div>
      </div>
    </div>
    <div class="card-footer p-0">
      <div class="d-flex justify-content-end">
        <argon-loading-button
          class="btn btn-sm bg-gradient-primary me-1"
          size="xs"
          variant="gradient"
          color="primary"
          :label="'Cancelar'"
          style="
            font-size: 11px;
            padding: 2px 8px;
            width: 65px;
            border-radius: 5px;
          "
          @click="emits('cancel')"
        >
        </argon-loading-button>
        <argon-loading-button
          class="btn btn-sm bg-gradient-primary"
          size="xs"
          variant="gradient"
          color="primary"
          :label="disableEmailButton ? '' : 'Enviar'"
          style="
            font-size: 11px;
            padding: 2px 8px;
            width: 65px;
            border-radius: 5px;
          "
          @click="acceptedSend"
          :loading="disableEmailButton"
          :disable-button="disableEmailButton"
        >
        </argon-loading-button>
      </div>
    </div>
  </div>
  <div class="position-fixed top-1 end-1 z-index-2">
    <argon-snackbar
      v-if="snackbar"
      :title="snackbar.title"
      :description="snackbar.message"
      :icon="{ component: 'ni ni-notification-70', color: snackbar.type }"
      :color="snackbar.color"
      :close-handler="closeSnackbar"
    />
  </div>
</template>