import createAxios from "./axiosHttp";

function getAllIssues(pagination, query, sort) {
  const axios = createAxios();
  let urlPath = `issue/ql`;
  const ql = {
    query,
    pagination,
    sort,
  };
  return axios.post(urlPath, ql);
}

function getIssuesAsExcel(query, sort) {
  const axios = createAxios();
  let urlPath = `/issue/report/ql`;
  const ql = {
    query,
    sort,
  };
  return axios.post(urlPath, ql, {
    responseType: "blob",
  }).catch(async (error) => {
    const data = await error.text();
    throw JSON.parse(data);
  });
}

function putIssuesStatus(body) {
  const axios = createAxios();
  let urlPath = `/issue`;
  return axios.put(urlPath, body);
}

function getIssueById(idCustomer) {
  const axios = createAxios();
  let urlPath = `issue/${idCustomer}`;
  return axios.get(urlPath);
}

function updateIssue(id, body) {
  const axios = createAxios();
  let urlPath = `issue/${id}`;
  return axios.put(urlPath, body);
}

function insertIssue(body) {
  const axios = createAxios();
  let urlPath = `issue`;
  return axios.post(urlPath, body);
}

function getChats(id) {
  const axios = createAxios();
  let urlPath = `issue/${id}/chat`;
  return axios.get(urlPath);
}

function sendChat(body) {
  const axios = createAxios();
  let urlPath = `issue/${body.issue_id}/chat`;
  return axios.post(urlPath,body);
}

function sendChatSolution(body) {
  const axios = createAxios();
  let urlPath = `issue/${body.issue_id}/solution`;
  return axios.put(urlPath,body);
}

function deleteChat(idIssue,idChat) {
  const axios = createAxios();
  let urlPath = `issue/${idIssue}/chat/${idChat}`;
  return axios.delete(urlPath);
}

export const issueService = {
  getAllIssues,
  getIssuesAsExcel,
  putIssuesStatus,
  getIssueById,
  updateIssue,
  insertIssue,
  getChats,
  sendChat,
  deleteChat,
  sendChatSolution
};
