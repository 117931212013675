<script setup>
import { onMounted, watch, ref } from "vue";
import SimpleTable from "@/components/table/SimpleTable.vue";
import StatusBadge from "@/components/badges/ShipmentStatus.vue";
import ArgonTd from "@/components/table/ArgonTd.vue";
import { batchFileShipmentStore } from "@/_store/batchFileShipmentStore";
import { argonTableStore } from "@/_store/argonTableStore";
import moment from "moment";

const shipmentStore = batchFileShipmentStore();
const tableStore = argonTableStore();
const listTable = ref([])
const props = defineProps({
  idFile: {
    type: String,
    default: "",
  },
});

const headers = [
  {
    header: "Creado",
    field: "created_at",
    sort: false,
  },
  {
    header: "Fecha",
    field: "pickup.date",
    sort: false,
  },
  {
    header: "Localizador",
    field: "locator",
    sort: false
  },
  {
    header: "Referencia",
    field: "reference",
    sort: false,
  },

  {
    header: "Estado",
    field: "status",
    sort: false,
    width: "w-5",
    position: "center",
  },
];
onMounted(() => {
  reloadBatchsShipments();
});
function getPosition(field) {
  const column = headers.find((col) => col.field === field);
  return column && column.position ? column.position : "start";
}

function reloadBatchsShipments(){
  shipmentStore.getListBatchShipments(props.idFile, tableStore.pagination);;
}
watch(
  () => shipmentStore.batchListShipment,
  (newVal) => {
    if (newVal) {
      listTable.value = newVal;
    }
  }
);
</script>
<template>
  <simple-table
    v-if="listTable"
    :headers="headers"
    :items="listTable"
    @on-reload="reloadBatchsShipments"
    @page-changed="reloadBatchsShipments"
    :show-pagination="true"
  >
    <template #default="{ item }"> 
      <argon-td
        :position="getPosition('created_at')"
        :text-primary="moment(item.created_at).format('DD/MM/YYYY HH:mm')"
        :text-secondary="moment(item.created_at).fromNow()"
      ></argon-td>
      <argon-td
        :position="getPosition('date')"
        :text-secondary="moment(item.date).format('DD/MM/YYYY')"
      ></argon-td>    
      <argon-td
        :text-secondary="item.locator"
        :position="getPosition('locator')"
      />
      <argon-td
        :text-secondary="item.reference"
        :position="getPosition('reference')"
      />   
      <argon-td :position="getPosition('status')">
        <status-badge :item="item.status"></status-badge>
      </argon-td>
    </template>
  </simple-table>
</template>