<script setup>
import { onMounted, ref, watch } from "vue";
import ArgonInput from "@/components/ArgonInput.vue";
import { required, email, helpers } from "@vuelidate/validators";
import { useVuelidate } from "@vuelidate/core";
import { contactListStore } from "@/_store/contactStore";
import { contactDetailStore } from "@/_store/contactDetailStore";
import AutoComplete from "@/components/auto-complete/AutoComplete.vue";

const emit = defineEmits(["selectedContact"]);
let model = ref({
  name: "",
  vat_number: "",
  email: "",
  attention_of: "",
  phone: "",
});

let props = defineProps({
  modelValue: {
    type: Object,
    default: () => ({
      name: "",
      vat_number: "",
      email: "",
      attention_of: "",
      phone: "",
    }),
  },
});

let key = ref(1);
const rules = {
  name: {
    required: helpers.withMessage("Nombre de contacto es requerido", required),
  },
  email: { email: helpers.withMessage("Ingrese un email valido", email) },
};

const contactStore = contactListStore();
const contactSummaries = contactDetailStore();
const dataSuggestions = ref([]);
const v$ = ref(useVuelidate(rules, model));

onMounted(() => {
  model.value = props.modelValue;

  document
    .querySelector("#fulfillmentModal")
    .addEventListener("show.bs.modal", () => {
      model.value = props.modelValue;
      key.value += 1;
    });
});

let pagination=false;
function getContact(text,page) {
  if(page==1){
    pagination=true;
  }else pagination =false;
  const user = JSON.parse(localStorage.getItem("user"));
  let req =
    text != ""
      ? { type: "RECOGIDA", "contact.name": { $regex: text, $options: "i" } }
      : { type: "RECOGIDA" };
  contactStore.getAllContactsByCustomer(user.extraData.customerId, req,{ page: page, limit: 80 });
}
function selectedAutocomplete(item) {
  if (item._id) {
    contactSummaries.getContactById(item._id);
  }
}
async function validate() {
  await v$.value.$validate();
  return v$.value.$errors;
}

defineExpose({
  validate,
});

watch(
  () => contactSummaries.contact,
  (value) => {
    if (!value) return;
    emit("selectedContact", value);
  }
);
watch(
  () => contactStore.customerContacts,
  (value) => {
    if(value && value.data.length > 0){
      if(pagination)
        dataSuggestions.value =[];
      dataSuggestions.value = [...dataSuggestions.value, ...value.data];
    }
  },
);
</script>
<template>
  <div class="h-100">
    <div class="row">
      <div class="col-6">
        <div class="row">
          <div class="col-4">
            <label class="col-form-label required">Nombre</label>
          </div>
          <div class="col-8">
            <auto-complete
              v-model="model.name"
              :max-length="40"
              :is-required="true"
              :error="v$.name.$error"
              type="text"              
              :data-suggestions="dataSuggestions"
              :item-text="['contact.name']"
              :separator="' '"
              size="default"
              @text-changed="getContact"
              @page-changed="getContact"
              @selected-change="selectedAutocomplete"
              menu-width="500"
            ></auto-complete>
          </div>
        </div>
      </div>
      <div class="col-lg-6">
        <div class="row">
          <div class="col-3">
            <label class="col-form-label">NIF</label>
          </div>
          <div class="col-9">
            <argon-input
              id="text"
              :key="key"
              v-model="model.vat_number"
              :max-length="25" 
              type="vat_number"
            />
          </div>
        </div>
      </div>
    </div>
    <div class="row">
      <div class="col-6">
        <div class="row">
          <div class="col-4">
            <label class="col-form-label">Email</label>
          </div>
          <div class="col-8">
            <argon-input
              id="email"
              :key="key"
              v-model="model.email"
              :max-length="75"
              type="email" 
              :error="v$.email.$error"
            />
          </div>
        </div>
      </div>
      <div class="col-6">
        <div class="row">
          <div class="col-3">
            <label class="col-form-label">Teléfono</label>
          </div>
          <div class="col-9">
            <argon-input
              id="phone"
              :key="key"
              v-model="model.phone"
              :max-length="15"              
              type="text"
            />
          </div>
        </div>
      </div>
    </div>
  </div>
</template>
