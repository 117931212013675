import { defineStore } from 'pinia';
import { notificacionService } from '@/_services';


export const notificationListStore = defineStore('notificationList', {
  state: () => ({
    notification: null,
    error: null,
  }),
  actions: {
    sendNotifications(body) {
      notificacionService.sendNotifications(body)
          .then((results) => this.notification = results)
          .catch((reason) => this.error = reason);
    },
  },
})
