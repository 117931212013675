import { defineStore } from 'pinia';
import { stopService } from '@/_services';


export const ownPickupListStore = defineStore('ownPickupList', {
  state: () => ({ 
    reload: false,
    ownPickups: null,
    error: null,
    ownPickupsAsExcel: null,
  }),
  actions: {
    getOwnPickups(pagination, filters, sort) {
      this.reload = false
      stopService.getAllOwnPickups(pagination, filters, sort)
        .then((results) => this.ownPickups = results)
        .catch((reason) => this.error = reason);
    },
    getOwnPickupsAsExcel(query, sort){
      query.courier='PROPIO'
      query.type='RECOGIDA'
      stopService.getStopAsExcel(query, sort)
      .then((results) => this.ownPickupsAsExcel = results)
      .catch((reason) => this.error = reason);
    }
  },
})
