import { defineStore } from "pinia";
import { marketplaceService } from "@/_services";

export const prestashopStore = defineStore("prestashopStore", {
  state: () => ({
    shop: null,
    error: null,
  }),
  actions: {
    getShop() {
      marketplaceService
        .getPrestaShop()
        .then((result) => (this.shop = result))
        .catch((reason) => (this.error = reason));
    },
  },
});
