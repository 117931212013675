<script setup>
import { onMounted, watch, ref, inject, computed } from "vue";

import { ArgonLoadingButton } from "@/components"
import Direction from "@/components/forms/Direction.vue";
import ArgonSnackbar from "@/components/ArgonSnackbar.vue";
import ArgonInput from "../../../components/ArgonInput.vue";
import ArgonDetailLayout from "@/components/detail/ArgonDetailLayout";
import { useRoute, useRouter } from "vue-router";
import { customerDetailStore } from "@/_store/customerDetailStore";
import ArgonSelect from "@/components/ArgonSelect.vue";
import { useVuelidate } from "@vuelidate/core";
import { required, email, helpers } from "@vuelidate/validators";
import ArgonCheckbox from "@/components/ArgonCheckbox";
import ArgonSelectMulti from "@/components/multi/ArgonSelectMulti.vue";
import { shipmentDetailStore, customerListStore } from "@/_store";
import cex from "@/assets/img/logos/partners/cex.jpeg";
import ctt from "@/assets/img/logos/partners/ctt.png";
import ups from "@/assets/img/logos/partners/ups.svg";
import propio from "@/assets/img/logos/logo.svg";
import propio_agency from "@/assets/img/logos/logo-agency.svg";
import { userStore } from "@/_store/userStore";
import TabsBootstrap from "@/components/TabsBootstrap.vue";
import TarifasTabs from "./TarifasTabs.vue";
import DocumentsButton from "@/components/gallery-files/DocumentsButton.vue"

const route = useRoute();
const router = useRouter();
const sweetAlert = inject("$swal");
const customerStoreRequest = customerDetailStore();
const userStores = userStore();
const customersStore = customerListStore();
const shipmentStore = shipmentDetailStore();
const rules = {
  code: { required: helpers.withMessage("Código es requerido", required) },
  contact:{
    name: { required: helpers.withMessage("Nombre es requerido", required) },
    vat_number: { required: helpers.withMessage("Id fiscal es requerido", required) },
    email: { email: helpers.withMessage("Ingrese un email valido", email) },
  },
  pricing_config:{
    pct_fuel: { required: helpers.withMessage("Aplicación combustible es requerido", required) }
  }
};
let tabSelected = ref("valoraciones");
const tabs = ref([
  { label: "Configuración valoración", value: "valoraciones" },
]);

const isAgency = computed(() => userStores.userSigned?.portalType === 'AGENCY');

const disableButton = ref(false);
let customer = ref(null);
let localValue = ref(null);
let directionForm = ref(null);
const v$ = ref(useVuelidate(rules, localValue));
const actions = ref([]);
const prioridades = [
  { name: "Alta", value: 3 },
  { name: "Media", value: 2 },
  { name: "Baja", value: 1 },
];
let key = ref(1);
onMounted(() => {
  loadCustomer();
  shipmentStore.getServices();
  key.value += 1;
});


function loadCustomer() {
  if (route.params.id) {
    customerStoreRequest.getCustomerById(route.params.id);
    customerStoreRequest.isNew = false;
  } else {
    customerStoreRequest.isNew = true;
    localValue.value = {
      contact: {
        name: "",
        vat_number: "",
        email: "",
        attention_of: "",
        phone: "",
      },
      pricing_config:{
        fare: "",
        fare_secondary: "",
        fare_inter: "",
        fare_secondary_inter: "",
        pct_fuel: 100,
      },
      address: {
        postal_code: "",
        street: "",
        street2: "",
        city: "",
        country: "ES",
        location: {
          lat: 0,
          lng: 0,
        },
        text: "",
        place_id: "",
        normalized: true,
        province: "",
      },
      code: "",
      priority: null,
      services: [],
      departments: [],
      active: true,
    };
    customer.value = localValue.value.contact;
  }
}
function saveItem() {
  const promiseCustomer = validateCustomer();
  const promiseDirection = validateDirection();
  const promiseTarifa = validate();
  Promise.all([promiseCustomer, promiseDirection, promiseTarifa]).then(
    (values) => {
      let message = "";
      for (const value of values) {
        for (const error of value) {
          message = `${message}<li>${error.$message}</li>`;
        }
      }
      if (message != "") {
        snackbar.value = {
          title: "Error",
          message: `<ul>${message}</ul>`,
          type: "danger",
          color: "white",
        };
      } else {
        disableButton.value = true
        localValue.value.priority = parseInt(localValue.value.priority)
        if (!customerStoreRequest.isNew){
          customerStoreRequest.updateCustomer(route.params.id, localValue.value);
        }
        else {
          customerStoreRequest.insertCustomer(localValue.value);
        }
      }
    }
  );
}
async function validateDirection() {
  return await directionForm.value.validate();
}
async function validate() {
  await v$.value.$validate();
  return v$.value.$errors;
}
const beeImage = computed(() => isAgency.value ? propio_agency : propio);

let servicios = ref([]);
watch(
  () => shipmentStore.services,
  (val) => {
    if (val && val.length > 0) {
      servicios.value = val.map((servicio) => {
        let image = "";
        switch (servicio.courier) {
          case "CEX":
            image = cex;
            break;
          case "CTT":
            image = ctt;
            break;
          case "UPS":
            image = ups;
            break;
          default:
            image = beeImage;
            break;
        }
        return {
          ...servicio,
          image,
        };
      });
    } else {
      servicios.value = [];
    }
  },
  { immediate: true }
);

watch(
  () => customerStoreRequest.deletedId,
  (value) => {
    if (value) {
      sweetAlert({
        title: "Borrado!",
        text: "Tu cliente ha sido borrado.",
        icon: "success",
        customClass: {
          confirmButton: "btn bg-gradient-success",
        },
        buttonsStyling: false,
      }).then((result) => {
        if (result.isConfirmed) {
          router.push("/customers");
        }
      });
    }
  }
);

watch(
  () => customerStoreRequest.createdCustomer,
  (value) => {
    if (value) { 
      customersStore.reload = true
      router.push("/customers");
    }
  }
);

watch(
  () => customerStoreRequest.updatedCustomer,
  (value) => {
    if (value) { 
      customersStore.reload = true
      router.push("/customers");
    }
  }
);
watch(
  () => customerStoreRequest.error,
  (value) => {
    if (value) {
      disableButton.value = true
      snackbar.value = {
        title: "Error",
        message: value.message,
        type: "danger",
        color: "white",
      };
    }
  }
);

watch(
  () => customerStoreRequest.customer,
  (value) => {
    if (value) {
      localValue.value = value;
      customer.value = localValue.value.contact;
    }
  }
);

async function validateCustomer() {
  await v$.value.$validate();
  return v$.value.$errors;
}

const snackbar = ref(null);
const closeSnackbar = () => {
  snackbar.value = null;
};
</script>
<template>
  <div class="card">
    <div v-if="localValue" class="card-body">
      <argon-detail-layout :group-actions="actions" @click="triggerEvent">
        <template #buttons>
          <argon-loading-button class="btn-sm bg-gradient-primary mb-0" color="primary" variant="gradient" icon="fa fa-save"
            :label="disableButton ? 'Guardando...' : 'Guardar'" :disable-button="disableButton" :loading="disableButton"
            @click="saveItem">
          </argon-loading-button>
        </template>
        <template #buttons-right>
          <documents-button
            v-if="isAgency"
            label="Documentos" :id-entity="route.params.id" container="documents"
            entity="customers" :origin="localValue.code"/>
        </template>
        <template #body>
          <div class="row">
            <div class="col-md-6 col-lg-6-col-lg-6 col-sm-12">
              <div class="row spacing">
                <div class="col-12">
                  <div class="card">
                    <div class="card-header">
                      Datos Básicos
                    </div>
                    <div class="card-body">
                      <div class="row">
                        <div class="col-12">
                          <div class="row">
                            <div class="col-1">
                              <label for="inputPassword6" class="col-form-label">Activo</label>
                            </div>
                            <div class="col-11">
                              <argon-checkbox id="active" :key="key"
                                v-model.number="localValue.active" name="active"></argon-checkbox> 
                            </div>
                          </div>
                        </div>
                      </div>
                      <div class="row">
                        <div class="col-5">
                          <div class="row">
                            <div class="col-3">
                              <label for="inputPassword6" class="col-form-label required">Código</label>
                            </div>
                            <div class="col-9">
                              <argon-input v-if="localValue" id="code" v-model="localValue.code" :max-length="10"
                                type="text" :error="v$.code.$error" />
                            </div>
                          </div>
                        </div>
                        <div class="col-7">
                          <div class="row">
                            <div class="col-3">
                              <label class="col-form-label">Prioridad</label>
                            </div>
                            <div class="col-9">
                              <argon-select id="priority" v-model="localValue.priority" name-label="name"
                                name-value="value" name="priority" :is-required="true" :options="prioridades">
                              </argon-select>
                            </div>
                          </div>
                        </div>
                      </div>
                    </div>
                  </div>
                </div>
              </div>
              <div class="row spacing">
                <div class="col-12">
                  <div class="card">
                    <div class="card-header">
                      Contacto
                    </div>
                    <div class="card-body">
                      <div class="row">
                        <div class="col-3">
                          <label for="inputPassword6" class="col-form-label">Atención de</label>
                        </div>
                        <div class="col-9">
                          <argon-input v-if="localValue.contact" id="attention_of"
                            v-model="localValue.contact.attention_of" :max-length="40" type="text"
                              />
                        </div>
                      </div>
                      <div class="row">
                        <div class="col-3">
                          <label for="inputPassword6" class="col-form-label required">Id fiscal</label>
                        </div>
                        <div class="col-9">
                          <argon-input v-if="localValue.contact" :error="v$.contact.vat_number.$error" id="text" v-model="localValue.contact.vat_number"
                            :max-length="25" type="vat_number"   />
                        </div>
                      </div>
                      <div class="row">
                        <div class="col-3">
                          <label for="inputPassword6" class="col-form-label">Email</label>
                        </div>
                        <div class="col-9">
                          <argon-input v-if="localValue.contact" id="email" v-model="localValue.contact.email"
                            :max-length="75" type="email" :error="v$.contact.email.$error" />
                        </div>
                      </div>
                      <div class="row">
                        <div class="col-3">
                          <label for="inputPassword6" class="col-form-label">Teléfono</label>
                        </div>
                        <div class="col-9">
                          <argon-input v-if="localValue.contact" id="phone" v-model="localValue.contact.phone"
                            :max-length="15" type="text" 
                             />
                        </div>
                      </div>

                    </div>
                  </div>
                </div>
              </div>
            </div>
            <div class="col-md-6 col-lg-6-col-lg-6 col-sm-12">
              <div class="row spacing">
                <div class="col">
                  <div class="card">
                    <div class="card-header">Dirección</div>
                    <div class="card-body">
                      <div class="row">
                        <div class="col-12">
                          <direction ref="directionForm" v-model="localValue.address" />
                        </div>
                      </div>
                    </div>
                  </div>
                </div>
              </div>
              <div class="row spacing">
                <div class="col">
                  <div class="card">
                    <div class="card-header">Departamentos</div>
                    <div class="card-body">
                      <div class="row">
                        <div class="col-12">
                          <argon-select-multi v-model="localValue.departments" />
                        </div>
                      </div>
                    </div>
                  </div>
                </div>
              </div>
              <div class="row spacing">
                <div class="col">
                  <div class="card">
                    <div class="card-header">Servicios</div>
                    <div class="card-body">
                      <div class="row">
                        <div class="col-12">
                          <argon-select v-if="shipmentStore.services" id="contact-type" v-model="localValue.services"
                            name="contact-type" :is-required="true" :options="servicios" name-value="name"
                            name-label="name" filterable :disabled="false" :multiple="true">
                          </argon-select>
                        </div>
                      </div>
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </template>
        <template #tabs>
          <div class="card mt-3">
            <div class="card-body">
              <tabs-bootstrap v-model="tabSelected" :tabs="tabs">
              </tabs-bootstrap>
            <tarifas-tabs v-show="tabSelected == 'valoraciones'" v-model="localValue.pricing_config" />
            </div>
          </div>
        </template>
        <template #title>
          <argon-input v-if="localValue.contact" id="name" placeholder="Nombre" v-model="localValue.contact.name" :max-length="40"
            :is-required="true" :error="v$.contact.name.$error" type="text"
             size="lg" :allow-create="true"
            font-style="h5 fw-bold" />
        </template>
      </argon-detail-layout>
    </div>
  </div>
  <div class="position-fixed top-1 end-1 z-index-2">
    <argon-snackbar v-if="snackbar" :title="snackbar.title" :description="snackbar.message"
      :icon="{ component: 'ni ni-notification-70', color: snackbar.type }" :color="snackbar.color"
      :close-handler="closeSnackbar" />
  </div>
</template>
