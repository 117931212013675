<script setup>
import ArgonGroupActions from "./ArgonGroupActions.vue";
const emit = defineEmits(["click"]);

defineProps({
  groupActions: {
    type: Array,
    default() {
      return [];
    },
  },
  showConfig: {
    type: Boolean,
    default: false
  }
});

function click(value) {
  emit("click", value);
}
</script>
<template>
  <div class="btn-table-settings dropdown d-inline mb-3 not-cursor" :class="{ noCursor: showConfig }">
    <a
      id="navbarDropdownSettingsTable"
      class="me-2"
      data-bs-toggle="dropdown"
      aria-expanded="false"
      :class="{ disabled: showConfig }"
      ><i class="fa fa-gear me-sm-2"></i
    ></a>
    <ul
      class="dropdown-menu dropdown-menu-lg-start px-2 py-3"
      aria-labelledby="navbarDropdownSettingsTable"
      style="z-index: 1030 !important"
    >
      <argon-group-actions
        v-for="(actions, i) in groupActions"
        :key="i"
        :actions="actions"
        :divider="i != groupActions.length - 1"
        @click="click"
      />
    </ul>
  </div>
</template>
<style lang="css" scoped>
.disabled {
  pointer-events: none;
  opacity: 0.5; 
}
.noCursor {
  cursor: not-allowed !important;
}
</style>
