import createAxios from "./axiosHttp";

function getFiles(url, pagination, query, sort){
  const axios = createAxios();
  const ql = {
    query,
    pagination,
    sort,
  };
  return axios.post(url,ql)
}
function putFile(url, body){
  const axios = createAxios();
  return axios.put(url, body)
}
function sendDocumentationEmail(url, body){
  const axios = createAxios();
  return axios.post(url, body)
}
function deleteFile(url){
  const axios = createAxios();
  return axios.delete(url)
}

function getTotalDocuments(url){
  const axios = createAxios();
  return axios.get(url)
}

export const fileService = {
  getFiles,
  deleteFile,
  putFile,
  sendDocumentationEmail,
  getTotalDocuments
};
