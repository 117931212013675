import createAxios from './axiosHttp';

function getAllCountries() {
  const axios = createAxios();
  let urlPath = `geoname/country/ql`;
  const body = {
    query: make_query({}),
    pagination:  {
      limit: 500,
      page: 1
    },
    sort: { 'name': 1 }
  };
  return axios.post(urlPath,body);
}

function getCpProvince(request){
  const axios = createAxios();
  let urlPath = `geoname/ql`;
  return axios.post(urlPath,request);
}

function make_query(filters) {
  if (!filters.length > 0) return {};
  let query = {$or:[]};
  for (const filter of filters) {
    query.$or.push({ [filter.field]: { $regex: filter.text } });
  }
  return query;
}

export const directionService = {
  getAllCountries,
  getCpProvince
}