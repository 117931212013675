<script setup>
import ArgonSnackbar from "@/components/ArgonSnackbar.vue";
import ModalBase from "@/components/modal/ModalBase.vue";
import ArgonInput from "@/components/ArgonInput.vue";
import ArgonSelect from "@/components/ArgonSelect.vue";
import Direction from "@/components/forms/Direction.vue";
import { ref, onMounted, watch } from "vue";
import { ArgonLoadingButton } from "@/components";
import { useStore } from "vuex";
import { useVuelidate } from "@vuelidate/core";
import { required, helpers } from "@vuelidate/validators";
import { otherPickupListStore } from "@/_store/stopOtherPickupStore";

const emits = defineEmits(["close", "success"]);
const store = useStore();
const bootstrap = store.state.bootstrap;
const modalRefOtherPickup = ref(null);
const snackbar = ref(null);
const disableButton = ref(false);
const otherPickupsStore = otherPickupListStore();

const data = ref({
  courier: "",
  date: null,
  address: {
    postal_code: "",
    street: "",
    street2: "",
    city: "",
    country: "ES",
    location: {
      lat: 0,
      lng: 0,
    },
    text: "",
    place_id: "",
    normalized: true,
    province: "",
  },
  schedules: {
    initial: "",
    final: "",
  },
  observations:""
});
const options = ref([{ value: "UPS", label: "UPS" }]);
const checkHoraMayor = () => {
  let inicioInt = horaToInt(data.value.schedules.initial);
  let finalInt = horaToInt(data.value.schedules.final);
  if (inicioInt == 0 || finalInt == 0) return true;
  if (inicioInt >= finalInt) return false;
  return true;
};
function horaToInt(value) {
  if (value == "") return 0;
  if (!value.includes(":")) return 0;
  let hora = parseInt(value.split(":")[0]) * 3600;
  let minutos = parseInt(value.split(":")[1]) * 60;
  return hora + minutos;
}
const rules = {
  courier: {
    required: helpers.withMessage("Por favor seleccion el courier.", required),
  },
  date: {
    required: helpers.withMessage("Por favor ingrese una fecha.", required),
  },
  schedules: {
    initial: {
      required: helpers.withMessage(
        "Por favor ingrese el horario de inicio.",
        required
      ),
    },
    final: {
      required: helpers.withMessage(
        "Por favor ingrese el horario de fin.",
        required
      ),
      checkHoraMayor: helpers.withMessage(
        "La hora fin debe de ser mayor a la inicial.",
        checkHoraMayor
      ),
    },
  },
};
const v$ = useVuelidate(rules, data);

async function validate() {
  await v$.value.$validate();
  return v$.value.$errors;
}
onMounted(() => {
  let myModal = new bootstrap.Modal(
    document.getElementById("modalOtherPickup"),
    {
      keyboard: true,
    }
  );
  myModal.show();
});
async function save() {
  const promise = validate();
  Promise.all([promise]).then((values) => {
    let message = "";
    for (const value of values) {
      for (const error of value) {
        message = `${message}<li>${error.$message}</li>`;
      }
    }
    if (message != "") {
      snackbar.value = {
        title: "Error",
        message: `<ul>${message}</ul>`,
        type: "danger",
        color: "white",
      };
      return;
    }
    disableButton.value = true;

    otherPickupsStore.sendOtherPickup(createBody(data.value));
  });
}
function createBody(body) {
  return {
    customer_id: null,
    type: "RECOGIDA",
    date: body.date,
    address: body.address,
    schedules: {first_frequency: body.schedules, second_frequency	:{initial: "", final: ""}},
    courier: body.courier,
    observations: body.observations
  };
}
function closeModal() {
  emits("close");
}

const closeSnackbar = () => {
  snackbar.value = null;
};

watch(
  () => otherPickupsStore.sendOtherPickupResponse,
  (newVal) => {
    if (newVal) {
      emits("success");
      setTimeout(() => {
        disableButton.value = false;
        modalRefOtherPickup.value.closeModal();
      }, 500);
    }
  }
);
</script>

<template>
  <modal-base
    id="modalOtherPickup"
    ref="modalRefOtherPickup"
    @hide="closeModal"
    class="card-detail-layout"
    width="700px"
  >
    <template #header>
      <h6 id="ModalLabel" class="modal-title">Nuevo</h6>
    </template>
    <template #body>
      <form class="form-control border-0" id="formData">
        <div class="row">
          <div class="col-3">
            <label for="inputPassword6" class="col-form-label required"
              >Courier</label
            >
          </div>
          <div class="col-9">
            <argon-select
              id="courier"
              v-model="data.courier"
              name="courier"
              :error="v$.courier.$error"
              :options="options"
              filterable
            >
            </argon-select>
          </div>
        </div>
        <div class="row">
          <div class="col-3">
            <label for="inputPassword6" class="col-form-label required"
              >Fecha</label
            >
          </div>
          <div class="col-9">
            <argon-input
              id="date"
              v-model="data.date"
              type="date"
              :error="v$.date.$error"
              placeholder=""
              required
            />
          </div>
        </div>
        <div class="row">
          <div class="col-3">
            <label for="inputPassword6" class="col-form-label required"
              >Dirección</label
            >
          </div>
          <div class="col-9">
            <direction
              class="ms-2"
              :key="keyUpdate"
              ref="directionForm"
              v-model="data.address"
            />
          </div>
        </div>
        <div class="row">
          <div class="col-6">
            <div class="row">
              <div class="col-6">
                <label class="col-form-label required">Horario inicio</label>
              </div>
              <div class="col-6">
                <argon-input
                  v-model="data.schedules.initial"
                  :error="v$.schedules.initial.$error"
                  type="time"
                />
              </div>
            </div>
          </div>
          <div class="col-6">
            <div class="row">
              <div class="col-6">
                <label class="col-form-label required">Horario fin</label>
              </div>
              <div class="col-6">
                <argon-input
                  v-model="data.schedules.final"
                  :error="v$.schedules.final.$error"
                  type="time"
                />
              </div>
            </div>
          </div>
        </div>
        <div class="row">
          <div class="col-3">
            <label for="inputPassword6" class="col-form-label"
              >Observaciones</label
            >
          </div>
          <div class="col-9">
            <argon-input
              id="observations"
              v-model="data.observations"
              placeholder=""
              :maxLength="57"
            />
          </div>
        </div>
      </form>
    </template>
    <template #footer>
      <argon-loading-button
        class="btn bg-gradient-primary btn-sm"
        color="primary"
        variant="gradient"
        :label="disableButton ? 'Guardando...' : 'Guardar'"
        :loading="disableButton"
        :disable-button="disableButton"
        @click="save"
      />
    </template>

    <div class="position-fixed top-0 end-0 z-index-2 p-3">
      <argon-snackbar
        v-if="snackbar"
        :title="snackbar.title"
        :description="snackbar.message"
        :icon="{ component: 'ni ni-notification-70', color: snackbar.type }"
        :color="snackbar.color"
        :close-handler="closeSnackbar"
      />
    </div>
  </modal-base>
</template>
