<script setup>
import { onMounted, onBeforeUnmount, watch, computed, reactive, ref } from "vue";
import { ArgonLoadingButton } from "@/components"
import ArgonInput from "@/components/ArgonInput.vue";
import { argonTableStore } from "@/_store/argonTableStore";
import { objectUtils } from "@/utils";
import FilterSearch from "./FilterSearch.vue";
import DefaultFilter from "./DefaultFilter.vue";
import { useRoute } from "vue-router";
import { useVuelidate } from "@vuelidate/core";
import { required, helpers } from "@vuelidate/validators";

var searchText = ref("");
var selectedFilters = reactive([]);
const route = useRoute();
const emit = defineEmits(["filterChanged"]);

const props = defineProps({
  size: {
    type: String,
    default: "default",
  },
  icon: {
    type: String,
    default: "",
  },
  name: {
    type: String,
    default: "",
  },
  id: {
    type: String,
    default: "input-search",
  },
  placeholder: {
    type: String,
    default: "",
  },
  fieldFilters: {
    type: Array,
    default() {
      return [];
    },
  },
  defaultFilters: {
    type: Array,
    default() {
      return [];
    },
  },
  showFavorites: {
    type: Boolean,
    default: true,
  },
});

const suggestionRefs = ref([]);
const index = ref(0);
const tableStore = argonTableStore();
const dropdownOpen = ref(false);
const disableButton = ref(false);
const newFavorite = ref("");
const loading =ref("")

onMounted(() => {
  getFilters();
  selectedFilters = reactive(objectUtils.copyByValue(tableStore?.filters));
  document.addEventListener("click", handleClickOutside);
});

onBeforeUnmount(() => {
  document.removeEventListener("click", handleClickOutside);
});

watch(
  () => tableStore.filters,
  (value) => {
    selectedFilters = reactive(objectUtils.copyByValue(value));
  }
);


watch(
  () => tableStore.filtersCustom,
  () => {
    disableButton.value = false
  }
);

watch(searchText, (newVal) => {
  if (searchText.value != "") dropdownOpen.value = true;
  if (newVal && suggestionRefs.value.length > 0) {
    index.value = 0;
    suggestionRefs.value[index.value].classList.add("focused");
  }
});

watch(dropdownOpen, () => {});

function searchOnText(filter) {
  selectedFilters.push({
    field: filter.field,
    description: filter.description,
    text: searchText.value,
    type: "text",
  });
  document.getElementById(props.id).focus();
  searchText.value = "";
  dropdownOpen.value = false;
  emit("filterChanged", selectedFilters);
}

function cleanFilters(filters) {
  const groupedData = filters.reduce(
    (acc, item) => {
      const key = `${item.field}-${item.description}`;
      if (item.type === "default") {
        acc.defaults.push(item);
      } else {
        if (!acc.grouped[key]) {
          acc.grouped[key] = {
            field: item.field,
            description: item.description,
            text: [],
            value: { $in: [] },
            type: "array",
          };
        }
        if (item.type === "text") {
          acc.grouped[key].text.push(item.text);
          acc.grouped[key].value.$in.push(
            item.text.toUpperCase().replace(/ /g, "_")
          );
        } else {
          acc.grouped[key].text.push(...item.text);
          acc.grouped[key].value.$in.push(
            ...item.text.map((text) => text.toUpperCase().replace(/ /g, "_"))
          );
        }
      }
      return acc;
    },
    { defaults: [], grouped: {} }
  );

  const result = Object.values(groupedData.grouped);

  return [...groupedData.defaults, ...result];
}

function removeFilter(filter) {
  var index = selectedFilters.findIndex(
    (element) => element.field == filter.field && element.text == filter.text
  );
  selectedFilters.splice(index, 1);
  emit("filterChanged", selectedFilters);
}

function updateMultiSelectFilter(filter, index) {
  if (filter.active) {
    selectedFilters[index].value.$in.push(filter.value);
    selectedFilters[index].text.push(filter.label);
  } else {
    removeValueFromMultiSelect(index, filter.value);
  }
}

function updateSingleSelectFilter(filter, index) {
  if (filter.active) {
    selectedFilters[index].value = filter.value;
    selectedFilters[index].text = filter.label;
  } else {
    selectedFilters.splice(index, 1);
  }
}

function removeValueFromMultiSelect(index, value) {
  const valueIndex = selectedFilters[index].value.$in.findIndex(
    (element) => element === value
  );
  selectedFilters[index].value.$in.splice(valueIndex, 1);
  selectedFilters[index].text.splice(valueIndex, 1);
  if (selectedFilters[index].text.length === 0) {
    selectedFilters.splice(index, 1);
  }
}

function updateFilterValue(filter, index) {
  if (filter.multi) {
    updateMultiSelectFilter(filter, index);
  } else {
    updateSingleSelectFilter(filter, index);
  }
}

function addFilter(filter) {
  let value;
  let label;
  if (filter.multi) {
    value = { $in: [filter.value] };
    label = [filter.label];
  } else {
    value = filter.value;
    label = filter.label;
  }
  selectedFilters.push({
    field: filter.field,
    description: filter.description,
    text: label,
    value: value,
    type: "default",
  });
}

function change(filter) {
  resetFavoriteField()
  const index = selectedFilters.findIndex(
    (element) => element.field === filter.field
  );
  if (index >= 0) {
    updateFilterValue(filter, index);
  } else {
    addFilter(filter);
  }
  emit("filterChanged", selectedFilters);
}

function handleKeydown(event) {
  const keyActions = {
    ArrowDown: () => {
      if (index.value < suggestionRefs.value.length - 1) index.value++;
    },
    ArrowUp: () => {
      if (index.value > 0) index.value--;
    },
    Enter: () => {
      if (index.value >= 0) suggestionRefs.value[index.value]?.click();
    },
    Backspace: () => {
      if (
        !searchText.value ||
        (searchText.value.length === 0 && selectedFilters.length > 0)
      ) {
        removeFilter(selectedFilters[selectedFilters.length - 1]);
      }
    },
  };

  const action = keyActions[event.key];
  if (action) {
    if (event.key !== "Backspace") {
      event.preventDefault();
    }
    if (event.key === "ArrowDown" || event.key === "ArrowUp") {
      suggestionRefs.value[index.value]?.classList.remove("focused");
    }
    action();
    if (event.key === "ArrowDown" || event.key === "ArrowUp") {
      suggestionRefs.value[index.value]?.classList.add("focused");
    }
  }
}

const handleClickOutside = (event) => {
  if (!event.target.closest(".dropdown")) {
      dropdownOpen.value = false;
      searchText.value = "";
  }
};

const rules = {
  newFavorite: {
    required: helpers.withMessage("Es necesario ingresar un valor", required),
  }
};

const v$ = ref(useVuelidate(rules, { newFavorite }));

async function insertFavorite() {
  const isValid = await v$.value.$validate()
  if (!isValid) return

  disableButton.value = true
  tableStore.addFavorite(newFavorite.value, route.name);
  resetFavoriteField()
}


function resetFavoriteField() {
  v$.value.newFavorite.$reset();
  newFavorite.value = "";
}

function deleteFilterCustom(idFilter) {
  tableStore.deleteFavorite(idFilter);
  newFavorite.value = "";
  loading.value=idFilter;
}

function getFilters() {
  const query = { route: route.name };
  tableStore.getFilters(query);
}

function setFilters(filters) {
  resetFavoriteField()
  tableStore.filters = objectUtils.copyByValue(filters);
  emit("filterChanged", objectUtils.copyByValue(filters));
}
function getStyle(){
  if(props.defaultFilters?.length > 0) return '';
  return "margin-left: 5px; !important";
}

const diabledFav = computed(() => tableStore.filters.length==0);

const filtersCustom = computed(() => tableStore.filtersCustom);

</script>
<template>
  <div
    class="searcher row d-flex border align-items-center justify-content-between ps-2"
  >
    <div class="col-md-auto p-0">
      <i class="fa fa-search"></i>
    </div>
    <div
      class="col row ms-0 p-0 border-0 dropdown me-2"
      :aria-expanded="dropdownOpen"
    >
      <filter-search
        v-for="filter in cleanFilters(selectedFilters)"
        :key="filter.field + filter.text"
        :field="filter.field"
        :text="filter.text"
        :description="filter.description"
        @remove-filter="removeFilter"
      >
      </filter-search>
      <input
        :id="id"
        v-model="searchText"
        :name="name"
        :placeholder="placeholder"
        type="input"
        class="col"
        @keydown="handleKeydown"
        @focus="dropdownOpen = true"
      />
      <div
        v-if="dropdownOpen && searchText"
        class="searcher-menu z-index-1030 border rounded mt-2 p-1"
        aria-labelledby="searcherTextDropdown"
        style
      >
        <a
          v-for="filter in fieldFilters"
          ref="suggestionRefs"
          :key="filter.field"
          class="dropdown-item border-radius-md"
          @click="searchOnText(filter)"
          >Buscar <b>{{ filter.description }}</b> por {{ searchText }}</a
        >
      </div>
    </div>
    <div v-if="showFavorites" class="col-md-auto p-0 dropdown">
      <a
        id="searcherFilterDropdown"
        data-bs-toggle="dropdown"
        aria-expanded="false"
        data-bs-auto-close="outside"
        ><i class="fas fa-caret-down pe-2"></i
      ></a>
      <div
        class="dropdown-menu z-index-1030 dropdown-menu-end border rounded mt-1 filters"
        aria-labelledby="searcherFilterDropdown"
      >
        <div
          class="row mt-1"
          :class="{ 'width-filters': defaultFilters.length > 0 }"
        >
          <div
            v-if="defaultFilters.length > 0"
            class="col-12 col-xl-6 col-lg-6 col-md-6 col-sm-12 col-xsm-12 divider"
          >
            <strong class="ms-3"><i class="fa fa-filter"></i> Filters</strong>
            <default-filter
              v-for="(filter, i) in defaultFilters"
              :key="i"
              :filter="filter"
              :divider="i != defaultFilters.length - 1"
              @change="change"
            />
          </div>
          <div class="col-auto position-relative" :style="getStyle()">
            <strong><i class="mb-2 fa fa-star"></i> Favoritos</strong>
            <div class="d-flex flex-column">
              <argon-input
                id="new-favorito"
                v-model="v$.newFavorite.$model"
                :is-required="true"
                :error="v$.newFavorite.$error"
                :max-length="15"
                placeholder="Favorito"
                class="mb-2 w-100"
                :disabled="diabledFav"
              />
              <argon-loading-button
                v-if="!diabledFav"
                color="primary"
                size="sm"
                icon="fa-solid fa-plus"
                :label="disableButton ? 'Añadiendo...' : 'Añadir'"
                :loading="disableButton"
                @click="insertFavorite">
              </argon-loading-button>
            </div>
            <hr class="dropdown-divider" />
            <li v-for="(filter, key) of filtersCustom" :key="key" class="d-flex justify-content-between align-items-center">
              <a
                class="dropdown-item border-radius-md"
                @click="setFilters(filter.filters)"
              >
                {{ filter.name }}
              </a>
              <div v-if="loading==filter._id" class="d-flex justify-content-center">
                <div class="spinner-border text-primary spinner-border-sm" role="status">
                  <span class="visually-hidden">Loading...</span>
                </div>
              </div>
              <i
                v-else
                class="fa fa-trash me-0"
                @click="deleteFilterCustom(filter._id)"
              ></i>
            </li>
          </div>
        </div>
      </div>
    </div>
  </div>
</template>
<style lang="scss" scoped>
.z-index-1030 {
  z-index: 1030 !important;
}

.width-filters {
  width: 400px;
}

.divider {
  border-right: 0.5px #e9ecef solid;
}

.filters {
  max-height: 75vh;
  overflow-y: auto;
  overflow-x: hidden;
  min-width: 185px !important;
}
.focused {
  color: #344767;
  background-color: #e9ecef;
}

</style>