<script setup>
import { onMounted, watch, ref, computed } from "vue";
import AutoComplete from "@/components/auto-complete/AutoComplete.vue";
import ArgonSelect from "@/components/ArgonSelect.vue";
import { customerListStore } from "@/_store/customerStore";
import { useVuelidate } from "@vuelidate/core";
import { required, helpers } from "@vuelidate/validators";
const customerStore = customerListStore();

const emit = defineEmits(["update:modelValue", "update:department"]);
const keyAuto = ref(0);
let departmentOptions = ref([])
let model = ref({
    customer_id:'',
    customer:{
      code:"",
      contact: {
        name:""
      },
    },
    department: '',
  });
const props = defineProps({
  modelValue: {
    type: Object,
    default: () => {
      return {
        customer_id:'',
        customer:{
          code:"",
          contact: {
            name:""
          },
        },
        department: '',
      };
    },
  },
  readonly: {
    type: Boolean,
    default: false,
  },
  showDepartments: {
    type: Boolean,
    default: false,
  },
  showClients: {
    type: Boolean,
    default: true,
  },
  size:{
    type: String,
    default: "col-sm-12 col-md-4 col-lg-4 col-xl-12"
  },
  isRequired: {
    type: Boolean,
    default: true,
  }
});
const rules = computed(() => ({
  customer_id: props.isRequired 
    ? { required: helpers.withMessage("Cliente es requerido", required) } 
    : {}
}));

/* let rules = {
    customer_id:  { required: helpers.withMessage("Cliente es requerido", required) },
}; */
const v$ = useVuelidate(rules, model);
let key = ref(1);
const departments=ref("")
const loading = ref(false);
const dataSuggestions = ref([]);
onMounted(() => {
  model.value = props.modelValue;
  if(!props.showClients){
    let dataClient = JSON.parse(localStorage.getItem('cliente'), null, 2);
    dataClient.departments = dataClient.departments.join(", ");
    assignCustomer(dataClient);
  }
});
function resetValidated(){
  v$.value.$reset();
}
let pagination = false;
function getCustomer(text,page) {
  if(page==1){
    pagination=true;
  }else pagination =false;
  let req=text!=""?{active:true, "$or": [{'contact.name': { $regex: text, $options: "i" } },{'code': { $regex: text, $options: "i" }}] }:{active:true}
  if(text==""){
    model.value.department="";
    model.value.customer_id = "";
  }
  customerStore.getAllCustomers(
    { page: page, limit: 80 },
    req,
    { _id: -1 }
  );
}
function assignCustomer(value){
  if (Object.keys(value).length>0){
    loading.value=true;
    model.value.customer_id= value._id
    departmentOptions.value= []
    departmentOptions.value= value.departments?.split(',').map((dep) => {return {label: dep.trim(), value: dep.trim()}})
    departments.value=value.departments;
    setTimeout(() => {
      loading.value=false;
    }, 500);
    key.value++;
  }
}
function selectedAutocomplete(value) {
  model.value.department=""
  assignCustomer(value);
}
async function validate() {
  keyAuto.value++;
  await v$.value.$validate();
  return v$.value.$errors;
}
defineExpose({
  validate,resetValidated
});

watch(
  () => model.value,
  (value) => {
    emit("update:modelValue", value);
  },
  { deep: true }
);
watch(
  () => props.modelValue,
  (value) => {
    if(value.customer_id=="")
      departmentOptions.value=[];
    model.value= value;
    key.value++;
  },
  { deep: true }
);
watch(
  () => model.value.department,
  (value) => {
    if(departments.value==""){
      departmentOptions.value=[{label: value, value: value}]
      key.value++
    }
  },
);
watch(
  () => customerStore.customers,
  (value) => {
    if(value && value.data.length > 0){
      if(pagination)
        dataSuggestions.value =[];
      dataSuggestions.value = [...dataSuggestions.value, ...value.data];
    }
  },
);
</script>
<template>
  <form v-if="model" role="form">
    <div class="row">
      <div v-if="showClients" :class="!showDepartments? 'col-sm-12 col-md-4 col-lg-4 col-xl-12' : 'col-sm-12 col-md-4 col-lg-4 col-xl-6'">
        <auto-complete
          placeholder="Seleccione un cliente"
          :key="keyAuto"
          v-model="model.customer.contact.name"
          :error="v$.customer_id.$error"
          :data-suggestions="dataSuggestions" :item-text="['contact.name', 'code']" separator=" - " size="default"
          :disabled="readonly" @text-changed="getCustomer" @page-changed="getCustomer" @selected-change="selectedAutocomplete" menu-width="290">
        </auto-complete>
      </div>
      <div v-if="showDepartments" :class="!showClients? 'col-sm-12 col-md-4 col-lg-4 col-xl-12' : 'col-sm-12 col-md-4 col-lg-4 col-xl-6'">
        <div v-if="loading" class="d-flex justify-content-center mt-2">
          <div class="spinner-border text-primary spinner-border-sm" role="status">
            <span class="visually-hidden">Loading...</span>
          </div>
        </div>
        <argon-select
          v-else
          :key="key"
          placeholder="Departamento" id="customer-department"
          v-model="model.department" name="customer-department"
          :options="departmentOptions" filterable :disabled="readonly" class="">
        </argon-select>
      </div>
    </div>
  </form>
</template>
<style lang="scss" scoped>
.table-label {
  vertical-align: top;
}
.width-min {
  min-width: 73px;
}
</style>

