import { defineStore } from "pinia";
import { pricingService } from "@/_services";

export const pricingConceptsListStore = defineStore("pricingConceptsList", {
  state: () => ({
    error: null,
    pricingConcepts: null,
    pricingConceptsAsExcel: null,
    deleteConcepts: null,
    conceptsList: null,
    create: null,
    createError: null
  }),
  actions: {
    getPricingConcepts(pagination, query, sort) {
      pricingService
        .getPricingConcepts(pagination, query, sort)
        .then((results) => (this.pricingConcepts = results))
        .catch((reason) => (this.error = reason));
    },
    getPricingConceptsAsExcel(query, sort){
      pricingService.getPricingConceptsAsExcel(query, sort)
      .then((results) => this.pricingConceptsAsExcel = results)
      .catch((reason) => this.error = reason);
    },
    deletePricingConcepts(body){
      pricingService.deletePricingConcepts(body)
      .then((results) => this.deleteConcepts = results)
      .catch((reason) => this.error = reason);
    },
    getConceptList() {
      pricingService
        .getConceptList()
        .then((results) => (this.conceptsList = results))
        .catch((reason) => (this.error = reason));
    },
    createConcept(body) {
      pricingService
        .createConcept(body)
        .then((results) => (this.create = results))
        .catch((reason) => (this.createError = reason));
    },
  },
});
