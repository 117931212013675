import { defineStore } from 'pinia';
import { contactService } from '@/_services';


export const contactListStore = defineStore('contactsList', {
  state: () => ({ 
    reload: false,
    contacts: null,
    error: null,
    contactsAsExcel: null,
    customerContacts: null,
    customerContactsDynamic:null
  }),
  actions: {
    getContacts(pagination, filters, sort) {
      this.reload = false
      contactService.getAllContacts(pagination, filters, sort)
        .then((results) => this.contacts = results)
        .catch((reason) => this.error = reason);
    },
    getContactsAsExcel(query, sort) {
      contactService.getContactsAsExcel(query, sort)
          .then((results) => this.contactsAsExcel = results)
          .catch((reason) => this.error = reason);
    },
    clearCustomerContacts(){
      this.customerContacts= null
    },
    getAllContactsByCustomer(customerId, query,pagination,localVar){
      this.clearCustomerContacts();
      contactService.getAllContactsByCustomer(customerId, query,pagination)
      .then((results) => {
        if (localVar) {
          this.customerContactsDynamic = { [localVar]: results };
        } else {
          this.customerContacts = results;
        }
      })
        .catch((reason) => {
          this.clearCustomerContacts();
          this.error = reason
        });
    }
  },
})
