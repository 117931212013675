<!-- eslint-disable no-unused-vars -->
<script setup>
import { computed, ref, watch, onMounted } from 'vue';
import { Shops } from "@/globalVars";
import ArgonSnackbar from "@/components/ArgonSnackbar.vue";
import PackageFields from './PackageFields.vue'
import { fulfillmentStore } from "@/_store";


const emit = defineEmits(["removeProduct", "addPackage", "removeAllProducts"]);
const store = fulfillmentStore();

const disableButton = ref(false);
const props = defineProps({
  order: {
    type: Object,
    default() {
      return {};
    },
  },
  modelValue: {
    type: Object,
    default() {
      return {
        products: [],
        mac: "",
        order: 1,
        length: 0,
        width: 0,
        height: 0
      };
    },
  },
  shop: {
    type: Object,
    default() {
      return {};
    },
  },
});

const snackbar = ref(null);
const closeSnackbar = () => {
  snackbar.value = null;
};

const model = ref(props.modelValue)

let key = ref(1);

onMounted(() => {
  document.querySelector("#fulfillmentModal").addEventListener("show.bs.modal", (ev) => {
    model.value = props.modelValue
  });
});

function removeProduct(product) {
  emit("removeProduct", product);
}

function removeAllProducts(product){
  emit("removeAllProducts", product);
}

function savePackage() {
  if (model.value.items.length == 0) {
    snackbar.value = {
      title: "Error",
      message: `<ul>Sin productos a preparar</ul>`,
      type: "danger",
      color: "white",
    };
    return
  }

  disableButton.value = true
  const mac = `${getShopPrefix(props.shop["marketplace"])}-${props.order['number']}-${model.value.order}`

  emit("addPackage", {
    ...model.value,
    length: Number(model.value["length"]).toFixed(2),
    width: Number(model.value["width"]).toFixed(2),
    height: Number(model.value["height"]).toFixed(2),
    mac: mac
  });
  store.generateBarcode(mac)
}

function getShopPrefix(marketplace) {
  if (marketplace == Shops.SHOPIFY) return "SH"
    
  if (marketplace == Shops.PRESTASHOP) return "PS"
}

function displayDocument(blob) {
  const URI = URL.createObjectURL(blob);
  window.open(URI, "_blank");
}

watch(
  () => props.modelValue,
  (newVal) => {
    if (newVal) {
      model.value = props.modelValue
    }
  }
);

watch(
  () => store.barcode,
  (newVal) => {
    if (newVal) {
      disableButton.value = false
      displayDocument(newVal);
    }
  }
);

</script>
<template>
  <div class="card card-detail-layout">
    <div class="card-header">
      Bulto {{ model.order }}
    </div>
    <div class="card-body">
      <div class="row">
        <div class="col-lg-12">
          <PackageFields
            v-model="model"
            :disable-button="disableButton"
            :shop="shop"
            :order="order"
            @save-package-item="savePackage"
            @remove-product="removeProduct"
            @remove-all-products="removeAllProducts"
          />
        </div>
      </div>
      <div class="position-fixed top-1 end-1 z-index-2">
        <argon-snackbar
          v-if="snackbar" 
          :title="snackbar.title" 
          :description="snackbar.message"
          :icon="{ component: 'ni ni-notification-70', color: snackbar.type }" 
          :color="snackbar.color"
          :close-handler="closeSnackbar"
        />
      </div>
    </div>
  </div>
</template>
