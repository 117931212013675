import createAxios from "./axiosHttp";

function getAllShipments(pagination, query, sort) {
  const axios = createAxios();
  let urlPath = `shipment/ql`;
  const ql = {
    query,
    pagination,
    sort,
  };
  return axios.post(urlPath, ql);
}

function createShipment(body) {
  const axios = createAxios();
  let urlPath = `shipment`;
  return axios.post(urlPath, body);
}

function getShipmentById(idShipment) {
  const axios = createAxios();
  let urlPath = `shipment/${idShipment}`;
  return axios.get(urlPath);
}

function updateShipment(id, body) {
  const axios = createAxios();
  let urlPath = `shipment/${id}`;
  return axios.put(urlPath, body);
}

function getShipmentTracking(shipmentId) {
  const axios = createAxios();
  let urlPath = `shipment/${shipmentId}/tracking`;
  return axios.get(urlPath);
}

function getShipmentLabel(listShipmentId) {
  const axios = createAxios();
  let urlPath = `shipment/label`;
  var body = {
    shipments_id: listShipmentId,
  };
  return axios
    .post(urlPath, body, { responseType: "blob" })
    .catch(async (error) => {
      const data = await error.text();
      throw JSON.parse(data);
    });
}

function getJustificante(idShipment) {
  const axios = createAxios();
  let urlPath = `/shipment/${idShipment}/receipt`;
  return axios.get(urlPath);
}
function getShipmentsAsExcel(query, sort) {
  const axios = createAxios();
  let urlPath = `/shipment/report/ql`;
  const ql = {
    query,
    sort,
  };
  return axios
    .post(urlPath, ql, {
      responseType: "blob",
    })
    .catch(async (error) => {
      const data = await error.text();
      throw JSON.parse(data);
    });
}

function cancelShipment(shipmentId) {
  const axios = createAxios();
  let urlPath = `shipment/${shipmentId}`;
  return axios.delete(urlPath);
}
function cancelMultiShipments(body) {
  const axios = createAxios();
  let urlPath = `shipment`;
  return axios.delete(urlPath, {data: {ids:body}});
}
function updateShipmentStatus(id, body) {
  const axios = createAxios();
  let urlPath = `shipment/${id}/event`;
  return axios.put(urlPath, body);
}

export const shipmentService = {
  getAllShipments,
  createShipment,
  getShipmentLabel,
  getShipmentById,
  updateShipment,
  getShipmentTracking,
  getJustificante,
  getShipmentsAsExcel,
  cancelShipment,
  cancelMultiShipments,
  updateShipmentStatus
};
