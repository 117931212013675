<script setup>
import { onMounted, ref } from "vue";
import Direction from "@/components/forms/Direction.vue";

let model = ref({
  postal_code: "",
  street: "",
  street2: "",
  city: "",
  country: "ES",
  location: {
    lat: 0,
    lng: 0,
  },
  text: "",
  place_id: "",
  normalized: true,
  province: "",
});

let props = defineProps({
  modelValue: {
    type: Object,
    default: () => ({
      postal_code: "",
      street: "",
      street2: "",
      city: "",
      country: "ES",
      location: {
        lat: 0,
        lng: 0,
      },
      text: "",
      place_id: "",
      normalized: true,
      province: "",
    }),
  },
});

let key = ref(1);
let fulfillmentDirectionForm = ref(null);

onMounted(() => {
  model.value = props.modelValue;
  key.value += 1;
  document
    .querySelector("#fulfillmentModal")
    .addEventListener("show.bs.modal", () => {
      model.value = props.modelValue;
      key.value += 1;
    });
});

async function validate() {
  return await fulfillmentDirectionForm.value.validate();
}

defineExpose({
  validate,
});
</script>
<template>
  <div class="row">
    <div class="col-2">
      <label class="col-form-label required">Dirección</label>
    </div>
    <div class="col-10">
      <direction ref="fulfillmentDirectionForm" :key="key" v-model="model" />
    </div>
  </div>
</template>
