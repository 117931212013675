import { defineStore } from "pinia";
import { batchFileService } from "@/_services";

export const contactBatchDetailStore = defineStore("contactBatchDetailStore", {
  state: () => ({ contactDetail: null, error: null, fileDownload: null }),
  actions: {
    getContact(id) {
      batchFileService
        .getBatchFile(id)
        .then((results) => (this.contactDetail = results))
        .catch((reason) => (this.error = reason));
    },
    downloadFile(id) {
      batchFileService
        .downloadFile(id)
        .then((results) => (this.fileDownload = results))
        .catch((reason) => (this.error = reason));
    },
  },
});
