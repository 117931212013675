import { defineStore } from 'pinia';
import { customerService } from '@/_services';


export const customerListStore = defineStore('customersList', {
  state: () => ({
    customers: null,
    error: null,
    customersAsExcel: null, 
    reload: false,
  }),
  actions: {
    getAllCustomers(pagination, filters, sort) {
      this.reload = false;
      customerService.getAllCustomers(pagination, filters, sort)
        .then((results) => this.customers = results)
        .catch((reason) => {
          this.customers= null;
          this.error = reason;
        });
    },
    getCustomersAsExcel(query, sort) {
      customerService.getCustomersAsExcel(query, sort)
          .then((results) => this.customersAsExcel = results)
          .catch((reason) => this.error = reason);
  },
  },
})
