<script setup>
const emit = defineEmits(["update:modelValue"]);
defineProps({
  id: {
    type: String,
    default: ''
  },
  placeholder: {
    type: String,
    default: "",
  },
  rows: {
    type: Number,
    default: 5,
  },
  resize: {
    type: Boolean,
    default: true
  },
  modelValue: {
    type: [String, Number],
    default: "",
  },
  maxLength: {
    type: Number,
    default: 500,
  },
})
</script>
<template>
  <div class="form-group">
    <label :for="id" v-if="id != ''">
      <slot />
    </label>
    <textarea :id="id" class="form-control" :rows="rows" :maxlength="maxLength" :value="modelValue" @input="emit('update:modelValue', $event.target.value)" :placeholder="placeholder" :class="{'resize' : resize, 'no-resize': !resize}"></textarea>
  </div>
</template>
