import createAxios from "./axiosHttp";

function getAllOrders(pagination, query, sort) {
    const axios = createAxios();
    let urlPath = `marketplace/order/ql`;
    const ql = {
      pagination,
      query,
      sort,
    };
    return axios.post(urlPath, ql);
}

function getOrderById(idOrder) {
  const axios = createAxios();
  let urlPath = `marketplace/order/${idOrder}`;
  return axios.get(urlPath);
}

function getOrdersAsExcel(query, sort) {
  const axios = createAxios();
  let urlPath = `marketplace/order/report/ql`;
  const ql = {
    query,
    sort,
  };
  return axios
    .post(urlPath, ql, {
      responseType: "blob",
    })
    .catch(async (error) => {
      const data = await error.text();
      throw JSON.parse(data);
    });
}

export const ordersService = {
    getAllOrders,
    getOrderById,
    getOrdersAsExcel
}