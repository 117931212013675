<script setup>
import { onMounted, ref, watch } from "vue";
import ArgonDetailLayout from "@/components/detail/ArgonDetailLayout.vue";
import DetailProduct from "@/components/DetailProduct.vue";
import { productListStore } from "@/_store/productsStore";
import { useRoute } from "vue-router";

const productStore = productListStore();
const route = useRoute();
const data = ref(null);
onMounted(() => {
    productStore.getProductById(route.params.id);
});

watch(
  () => productStore.productId,
  (value) => {
    data.value = value;
  }
);

</script>
<template>
  <div class="py-4 container-fluid">
    <div class="row">
      <div class="col-12">
        <div class="card">
          <div class="card-body">
            <argon-detail-layout v-if="data">
              <template #title>
                <argon-input
                  font-style="h4 fw-bold"
                  v-model="data.name"
                  :disabled="true"
                />
              </template>
              <template #state>
                <status-badge
                  :tooltip-message="data.error"
                  :item="data.status"
                ></status-badge>
              </template>
              <template #body>
                <detail-product :info="data" />
              </template>
            </argon-detail-layout>
          </div>
        </div>
      </div>
    </div>
  </div>
</template>