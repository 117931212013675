<script setup>
import { onMounted, ref, watch } from "vue";
const emit = defineEmits(["update:modelValue"]);
let localValue = ref(false);
const props = defineProps({
  name: {
    type: String,
    default: "",
  },
  id: {
    type: String,
    default: "",
  },
  modelValue: {
    type: Boolean,
    default: false,
  },
  disabled: {
    type: Boolean,
    default: false,
  },
});

onMounted(() => {
  localValue.value = props.modelValue;
});

watch(
  () => localValue.value,
  (value) => {
    emit("update:modelValue", value);
  }
);
watch(
  () => props.modelValue,
  (value) => {
    localValue.value = value;
  }
);
</script>
<template>
  <div class="form-check">
    <input
      :id="id"
      v-model="localValue"
      class="form-check-input"
      type="checkbox"
      :name="name"
      :disabled="disabled"
    />
    <label :for="id" class="custom-control-label" :class="$attrs.class">
      <slot />
    </label>
  </div>
</template>
