<template>
  <div class="loader-overlay contenedor d-flex flex-column align-items-center">
    <div class="min-size mb-4">
      <img class="img-fluid" src="/img/logos/BeePaketLogo.png" alt="logo" />
    </div>
    <div id="splash-screen" class="splash-screen">
      <div v-for="i in 4" :key="i" class="spinner-grow text-light me-1" role="status">
      </div>
    </div>
  </div>
</template>
<style>
.contenedor{
  display: block;
}
.min-size{
  width: 50% !important;
}
</style>
<style scoped>
.loader-overlay {
  position: fixed;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
  background-color: rgb(255, 255, 255);
  display: flex;
  justify-content: center;
  align-items: center;
  z-index: 9999;
}

@keyframes spin {
  0% { transform: rotate(0deg); }
  100% { transform: rotate(360deg); }
}
.spinner-grow{
  background-color: rgb(88, 87, 87) !important;
}
</style>
