<script setup>
import { onMounted, ref, watch, inject } from "vue";

import ArgonSnackbar from "@/components/ArgonSnackbar.vue";
import ArgonDetail from "@/components/detail/ArgonDetailLayout.vue";
import ArgonInput from "../../../components/ArgonInput.vue";

import DetailBatch from "@/components/DetailBatch.vue";
import StatusBadge from "@/components/badges/BatchFileStatus.vue";
import { shipmentBatchDetailStore } from "@/_store/shipmentBatchDetailStore";
import { useRoute, useRouter } from "vue-router";
import ShipmentImportList from "./ShipmentImportList.vue";

const snackbar = ref(null);
const closeSnackbar = () => {
  snackbar.value = null;
};
const route = useRoute();
const router = useRouter();
const detailStore = shipmentBatchDetailStore();
const data = ref(null);
const sweetAlert = inject("$swal");

const actions = ref([[{ label: "Descargar", event: "onDownload" }]]);
const events = {
  onDownload: getDownloadFile,
  onLabelsPrint: downloadEtiqueta,
  onCancel: cancelBatch,
};

onMounted(() => {
  detailStore.getBatchShipment(route.params.idFile);
});

function triggerEvent(action) {
  try {
    events[action.event]();
  } catch {
    console.log("Evento no implementado");
  }
}

function getDownloadFile() {
  detailStore.downloadFile(route.params.idFile);
}
function downloadFile(blob) {
  const fileName = data.value.filename;
  const link = document.createElement("a");
  link.setAttribute("download", fileName);
  link.href = URL.createObjectURL(blob);
  link.click();
  URL.revokeObjectURL(link.href);
}
function downloadEtiqueta() {
  detailStore.getBatchShipmentLabel(route.params.idFile);
}

function cancelBatch() {
  sweetAlert({
    title: "¿Está seguro?",
    text: "Se Anularán todos los envíos de esta importación ¡No podrá revertir esta acción!",
    showCancelButton: true,
    confirmButtonText: "Aceptar",
    cancelButtonText: "Cerrar",
    reverseButtons: true,
    customClass: {
      confirmButton: `btn ms-1 bg-gradient-primary`,
      cancelButton: "btn btn-outline-primary me-1",
    },
    buttonsStyling: false,
  }).then((result) => {
    if (result.isConfirmed) {
      detailStore.cancelShipmentsByBatch(route.params.idFile);
    }
  });
}

function viewPDF(blob) {
  const URI = URL.createObjectURL(blob);
  window.open(URI, "_blank");
}
watch(
  () => detailStore.batchFileShipment,
  (value) => {
    data.value = value;
    if (data.value.status != "ERROR") {
      actions.value = [
        [{ label: "Descargar", event: "onDownload" }],
        [{ label: "Etiquetas", event: "onLabelsPrint" },],
        [{ label: "Anular Envíos", event: "onCancel" }],
      ];
    }
  }
);

watch(
  () => detailStore.error,
  (value) => {
    if (value) {
      snackbar.value = {
        title: "Error",
        message: value.message,
        type: "danger",
        color: "white",
      };
    }
  }
);
watch(
  () => detailStore.fileDownload,
  (newVal) => {
    if (newVal) {
      downloadFile(newVal);
    }
  }
);
watch(
  () => detailStore.batchShipmentLabel,
  (newVal) => {
    if (newVal) {
      viewPDF(newVal);
    }
  }
);

watch(
  () => detailStore.canceledBatch,
  (value) => {
    if (value) {
      // snackbar.value = {
      //   title: "Cancelado",
      //   message: "Los envíos han sido canceladAnos",
      //   type: "success",
      //   color: "white",
      // };
      setTimeout(() => {
        router.push("/imports/shipments");
      }, 1000);
    }
  }
);
</script>

<template>
  <div class="card">
    <div class="card-body">
      <argon-detail v-if="data" :group-actions="actions" @click="triggerEvent">
        <template #title>
          <argon-input
            id="name"
            v-model="data.filename"
            type="text"
            placeholder="Nombre"
            size="lg"
            font-style="h4 fw-bold"
            :disabled="true"
          />
        </template>
        <template #buttons> </template>
        <template #body>
          <div class="row spacing">
            <div class="col-12">
              <detail-batch v-if="data" :info="data" />
            </div>
          </div>
          <div class="row spacing">
            <div class="col-12">
              <div class="card">
                <div class="card-body">
                  <shipment-import-list :id-file="route.params.idFile" />
                </div>
              </div>
            </div>
          </div>
        </template>
        <template #state>
          <status-badge
            :tooltip-message="data.error"
            :item="data.status"
          ></status-badge>
        </template>
        <template #tabs> </template>
      </argon-detail>
    </div>
  </div>
  <div class="position-fixed top-1 end-1 z-index-2">
    <argon-snackbar
      v-if="snackbar"
      :title="snackbar.title"
      :description="snackbar.message"
      :icon="{ component: 'ni ni-notification-70', color: snackbar.type }"
      :color="snackbar.color"
      :close-handler="closeSnackbar"
    />
  </div>
</template>
