<script setup>
const colors = {
  PENDIENTE_PREPARAR: "primary",
  PREPARADO_PARCIAL: "info",
  PREPARADO: "success",
  ANULADO: "danger",
  ERROR: "danger-1"
};
defineProps({
  label: {
    type: String,
    default: '',
  },
  side: {
    type: String,
    default: "right",
  },
});
function getLabel(label){
  return label.replace("_"," ");
}
</script>

<template>
  <div v-if="label!==''" :class="`ribbon ribbon-top-${side}`">
    <span class="text-bg-success span-lg" :class="`badge-gradient-${colors[label]}`">{{ getLabel(label) }}</span>
  </div>
</template>
<style lang="scss" scoped>
.ribbon-top-right {
  --Ribbon-gap-top-default: calc(var(--formView-sheet-padding-y, 0) *  - 1px);
  --Ribbon-gap-right-default: -1px;
  pointer-events: none;
  margin-top: var(--Ribbon-gap-top, var(--Ribbon-gap-top-default));
  right: var(--Ribbon-gap-right, var(--Ribbon-gap-right-default));
}

.ribbon {
  margin-top: -16px;
  --Ribbon-wrapper-width-default: 20rem;
  width: var(--Ribbon-wrapper-width, var(--Ribbon-wrapper-width-default));
  height: var(--Ribbon-wrapper-width, var(--Ribbon-wrapper-width-default)); /* Aquí se usa la misma variable */
  overflow: hidden;
  position: absolute;
}

.ribbon-top-right span {
  --Ribbon-top-position-default: calc(var(--Ribbon-height-default) * -1);
  --Ribbon-left-position-default: 1.5rem;
  left: var(--Ribbon-left-position, var(--Ribbon-left-position-default));
  top: var(--Ribbon-top-position, var(--Ribbon-top-position-default));
  transform: rotate(45deg);
}

.ribbon span {
  --Ribbon-height-default: calc(var(--Ribbon-font-size, 1.5rem) * 2.5);
  --Ribbon-shadow-distance: 0.25rem;
  --Ribbon-shadow-blur: 0.5rem;
  z-index: 1200 !important;
  position: absolute;
  width: 130%;
  height: var(--Ribbon-height, var(--Ribbon-height-default));
  box-shadow: 0 var(--Ribbon-shadow-distance) var(--Ribbon-shadow-blur) rgba(0, 0, 0, 0.1);
  color: #fff;
  line-height: var(--Ribbon-height, var(--Ribbon-height-default));
  font-weight: 700;
  font-family: 'Lato', sans-serif;
  text-shadow: 0 1px 1px rgba(0, 0, 0, 0.2);
  text-transform: uppercase;
  text-align: center;
  overflow: hidden;
  user-select: none;
  transform-origin: left bottom;
  pointer-events: none;
}

.text-bg-success {
  color: #fff !important;
}

.card-detail-layout .span-lg{
  font-size: 1.125rem !important;
}

</style>