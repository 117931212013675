<script setup>
import moment from "moment";

defineProps({
  message: {
    type: Object,
    default: () => ({}),
  },
  deleting: {
    type: Boolean,
    default: false
  }
});

const emit = defineEmits(["deleteChat"]);

function deleteChat(id) {
  emit("deleteChat", id);
}
</script>

<template>
  <div class="message">
    <div class="card border" :class="{
        'background-message-private': message.type === 'PRIVADO',
        'background-message-alert': message.type === 'ALERT'
      }">
      <div class="card-body">
        <div class="d-flex justify-content-between">
          <div class="d-flex align-items-center">
            <i class="fa fa-lock me-1 text-xs" v-tooltip :data-bs-title="'Privado'" v-if="message.type === 'PRIVADO'"></i>
            <i class="fa fa-envelope me-1 text-xs" v-tooltip :data-bs-title="'Email'" v-if="message.type === 'EMAIL'"></i>
            <i class="fa fa-exclamation-circle me-1 text-xs" v-tooltip :data-bs-title="'Incidencia'" v-if="message.type === 'ALERT'"></i>
            <i class="fa fa-lightbulb me-1 text-xs" v-tooltip :data-bs-title="'Solución'" v-if="message.type === 'SOLUCION'"></i>
            <h6 class="card-title mb-0 me-1">{{ message.user }}</h6>
          </div>
          <h6 class="text-xs text-body-secondary">{{ moment(message.created_at).format('DD/MM/YYYY') }}</h6>
        </div>
        <p v-html="message.message" class="card-text"></p>
        <div v-if="deleting" class="spinner-border text-primary spinner-border-sm" role="status">
          <span class="visually-hidden">Loading...</span>
        </div>
        <a href="#" class="card-link link-primary" @click="deleteChat(message._id)" v-if="!deleting && message.type === 'PUBLICO'">Eliminar</a>
      </div>
    </div>
  </div>
</template>

