<script setup>
import { onMounted, watch, ref } from "vue";
import { useStore } from "vuex";

import ArgonTable from "@/components/table/ArgonTable.vue";
import StatusBadge from "@/components/badges/BatchFileStatus.vue";
import ArgonButton from "@/components/ArgonButton.vue";
import ArgonSnackbar from "@/components/ArgonSnackbar.vue";
import TypeBadge from "@/components/badges/BatchType.vue";

import { userStore } from "@/_store/userStore";
import { argonTableStore } from "@/_store/argonTableStore";
import { useRouter } from "vue-router";
import { batchFileShipmentStore } from "@/_store/batchFileShipmentStore";
import { dropzoneStore } from "@/components/dropzone/dropzoneStore";
import TiposImportaciones from "/src/maestros/TiposImportaciones.json";
import ArgonTd from "@/components/table/ArgonTd.vue";
import moment from "moment";

var myModal = null;
const store = useStore();
const bootstrap = store.state.bootstrap;
const dropStore = dropzoneStore();
const router = useRouter();
const fieldFilters = ref( [
  {
    field: "filename",
    description: "Fichero",
  },
]);
const defaultFilters = [
  {
    field: "status",
    label: "Estado",
    multi: true,
    options: [
      { label: "Procesado", value: "PROCESADO" },
      { label: "Error", value: "ERROR" },
    ],
  },
  {
    field: "sub_type",
    label: "Tipo",
    multi: true,
    options: TiposImportaciones.SHIPMENT.options,
  },
];
const importOptions = {
  title: "Importar Envíos",
  options: TiposImportaciones.SHIPMENT.options,
  type: "SHIPMENT",
  url: `/shipment/batch`,
};

function getPosition(field) {
  const column = columns.value.find((col) => col.field === field);
  return column && column.position ? column.position : "start";
}

const batchFileShipment = batchFileShipmentStore();
const tableStore = argonTableStore();
const userStores = userStore();
const snackbar = ref(null);
const closeSnackbar = () => {
  snackbar.value = null;
};
const columns = ref( [
  {
    header: "Creado",
    field: "created_at",    
    sort: true,
  },
  {
    header: "Fichero",
    field: "filename",
    sort: false,
  },
  {
    header: "Tipo",
    field: "type",    
    position: "center",
    sort: false,
  },  
  {
    header: "Estado",
    field: "status",
    position: "center",
    width: "w-5",
    sort: false,
  },
]);
onMounted(() => {
  if( userStores.userSigned.portalType==='AGENCY'){
      loadAgencyConfigs();
  }
  tableStore.clear();
  if (userStores.filtersPage.batchesShipment)
    tableStore.changeFilters(userStores.filtersPage.batchesShipment);
  else tableStore.changeFilters([]);
  getBatchFileShipments();
});
function loadAgencyConfigs() {
  const newHeader = {
    header: "Cliente",
    field: "customer.name",
    width: "w-10",
    sort: false,
    position: "start"
  }
  columns.value.splice(3, 0, newHeader);
  const newFilters = [
  {
    field: "customer.code",
    description: "Código Cliente",
  }, 
  {
    field: "customer.name",
    description: "Nombre Cliente",
  }]
  fieldFilters.value.push(...newFilters);
}
function reloadPage() {
  userStores.filtersPage.batchesShipment = tableStore.filters;
  getBatchFileShipments();
}
function getBatchFileShipments() {
  batchFileShipment.getBatchFileShipments(
    tableStore.pagination,
    tableStore.query,
    tableStore.sort
  );
}
function newShipment() {
  myModal = new bootstrap.Modal(document.getElementById("modalImport"), {
    keyboard: true,
  });
  myModal.show();
}
function detailEnvio(item) {
  router.push(`/imports/shipments/${item._id}`);
}

watch(
  () => dropStore.uploadStatus,
  (value) => {
    if (value == "SUCCESS") {
      myModal.hide();
      dropStore.clearFiles(); 
      reloadPage();
    }
  }
);
</script>
<template>
  <div class="card">
    <div class="card-body">
      <argon-table
        v-if="batchFileShipment.batchFileShipments"
        :columns="columns"
        :items="batchFileShipment.batchFileShipments"
        :field-filters="fieldFilters"
        :default-filters="defaultFilters"
        :import-options="importOptions"
        :show-check-input="false"
        :show-config-option="false"
        @filter-changed="reloadPage"
        @page-changed="reloadPage"
        @new-item="newShipment"
        @on-click="detailEnvio"
        @on-reload="reloadPage"
        @sort-changed="reloadPage"
      >
        <template #buttons>
          <div class="dropdown d-inline">
            <argon-button
              id="navbarDropdownPrinter"
              color="success"
              variant="outline"
              class="dropdown-toggle me-2 btn-sm"
              data-bs-toggle="dropdown"
              aria-expanded="false"
              ><i class="fa fa-print me-sm-2"></i>Imprimir</argon-button
            >
            <ul
              class="dropdown-menu z-index-1030 dropdown-menu-lg-start px-2 py-3"
              aria-labelledby="navbarDropdownPrinter"
              style
            >
              <li>
                <a class="dropdown-item border-radius-md" href="javascript:;"
                  >Manifiesto</a
                >
              </li>
            </ul>
          </div>
        </template>
        <template #default="{ item }">
          <argon-td
            :position="getPosition('created_at')"
            :text-primary="moment(item.created_at).format('DD/MM/YYYY HH:mm')"
            :text-secondary="moment(item.created_at).fromNow()"
          ></argon-td>          
          <argon-td
            :text-secondary="item.filename"
            :position="getPosition('filename')"
          />
          <argon-td :position="getPosition('type')">
            <type-badge
              :item="TiposImportaciones.SHIPMENT[item.sub_type]"
            ></type-badge>
          </argon-td>
          <argon-td
            v-if="userStores.userSigned.portalType==='AGENCY'"
            :position="getPosition('customer.name')"
            :text-primary="item.customer.code"
            :text-secondary="item.customer.contact.name"
          ></argon-td>
          <argon-td :position="getPosition('status')">
            <status-badge
              :tooltip-message="item.error"
              :item="item.status"
            ></status-badge>
          </argon-td>
        </template>
      </argon-table>
    </div>
  </div>
  <div class="position-fixed top-1 end-1 z-index-2">
    <argon-snackbar
      v-if="snackbar"
      :title="snackbar.title"
      :description="snackbar.message"
      :icon="{ component: 'ni ni-notification-70', color: snackbar.type }"
      :color="snackbar.color"
      :close-handler="closeSnackbar"
    />
  </div>
</template>
<style lang="scss" scoped>
.z-index-1030 {
  z-index: 1030 !important;
}
</style>