<script setup>
import { ref, watch, onMounted } from 'vue';
import { ArgonLoadingButton } from "@/components";
import { logoListStore } from "@/_store/logoStore";
import ArgonSnackbar from "@/components/ArgonSnackbar.vue";
import ArgonAvatar from "@/components/ArgonAvatar.vue";

const imageUrl = ref(null);
const iconUrl= ref(null);
const fileInput = ref(null);
const iconInput = ref(null);
const loading = ref(false);
const uploadStore = logoListStore();
const error = ref(null);
const snackbar = ref(null);

const closeSnackbar = () => {
  snackbar.value = null;
};

const onFileChange = (event) => {
  const file = event.target.files[0];
  if (file) {
    if (file.type.startsWith('image/')) {
      const reader = new FileReader();
      reader.onload = (e) => {
        imageUrl.value = e.target.result;
        error.value = null;
      };
      reader.readAsDataURL(file);
    }
    else {
      error.value = 'Por favor, seleccione un archivo de imagen válido.';
      imageUrl.value = null;
    }
  }
};
const onIconChange = (event) => {
  const file = event.target.files[0];
  if (file) {
    if (file.type.startsWith('image/')) {
      const reader = new FileReader();
      reader.onload = (e) => {
        iconUrl.value = e.target.result;
        error.value = null;
      };
      reader.readAsDataURL(file);
    } else {
      error.value = 'Por favor, seleccione un archivo de imagen válido.';
      iconUrl.value = null;
    }
  }
};
function deleteLogo() {
  imageUrl.value = null;
}
function deleteIcon() {
  iconUrl.value = null;
}
onMounted(() => {
  uploadStore.getLogo();
});

const triggerFileInput = () => {
  fileInput.value.click();
};
const triggerIconInput = () => {
  iconInput.value.click();
};
function isEmptyImage(image){
  if(image===null || image===''){
    return true;
  }
  return false;
}
function saveLogo() {
  let contract={logo:imageUrl.value,icon: iconUrl.value}
  if (uploadStore.logo) {
    loading.value = true;
    uploadStore.updateLogoBinario(contract, uploadStore.logo._id);
  } else {
    if(imageUrl.value || iconUrl.value){
      loading.value = true;
      uploadStore.sendLogoBinario(contract);
    }
    else{
      snackbar.value = {
        title: "Error",
        message: "Para guardar por primera vez tiene que selecionar su logo o icono",
        type: "danger",
        color: "white",
      };
    }
  }
};

watch(
  () => uploadStore.logo,
  (newLogo) => {
    if (newLogo){
      imageUrl.value = newLogo.logo;
      iconUrl.value = newLogo.icon;
    }
  },
  { immediate: true }
);

watch(
  () => uploadStore.updateLogo,
  (value) => {
    if (value) {
      uploadStore.getLogo();
      loading.value = false;
      snackbar.value = {
        title: "Guardado",
        message: "Ajustes guardados",
        type: "info",
        color: "white",
      };
    } else if (value === false) {
      loading.value = false;
      snackbar.value = {
        title: "Error",
        message: "No se pudo actualizar.",
        type: "danger",
        color: "white",
      };
    }
  }
);

watch(
  () => uploadStore.sendLogo,
  (value) => {
    if (value) {
      uploadStore.getLogo();
      loading.value = false;
      snackbar.value = {
        title: "Éxito",
        message: "Ajustes guardados",
        type: "primary",
        color: "white",
      };
    } else if (value === false) {
      loading.value = false;
      snackbar.value = {
        title: "Error",
        message: "No se pudo guardar.",
        type: "danger",
        color: "white",
      };
    }
  }
);
</script>
<template>
  <div class="card h-100">
    <div class="card-header">Personalización</div>
    <div class="card-body">
      <div class="row p-3">
        <div class="col">
          <input id="logoInput" ref="fileInput" class="d-none" type="file" accept="image/png, image/jpeg" @change="onFileChange" />
          <div class="input-label box-empty">
            <div v-if="isEmptyImage(imageUrl)"  @click="triggerFileInput">
              <span>Seleccione su logo</span>
            </div>
            <div v-else class="position-relative image-logo-load">
              <img v-tooltip :data-bs-title="'Logo'" :src="imageUrl" alt="Vista previa" class=""
                @click="triggerFileInput">
              <button @click="deleteLogo" class="btn btn-danger position-absolute top-0 end-0 clear-image">
                &times;
              </button>
            </div>
          </div>
        </div>
        <div class="col mt-2">
          <input id="iconFile" ref="iconInput" class="form-control d-none" type="file" accept="image/png,image/jpeg"
            @change="onIconChange" />
          <div class="input-label box-empty-icon">
            <div v-if="isEmptyImage(iconUrl)" @click="triggerIconInput">
              <span class="text-xs">Seleccione su icono</span>
            </div>
            <div v-else class="position-relative image-icon-load">
              <argon-avatar v-tooltip :data-bs-title="'Icono'" :image="iconUrl" size="xs" shadow="xs" alt="Vista previa"
                :circular=" true" style="padding-top: 3px; padding-bottom: 3px" />
              <button class="btn btn-danger position-absolute top-0 end-0 clear-image" @click="deleteIcon">
                &times;
              </button>
            </div>
          </div>
        </div>
      </div>
      <div v-if="error" class="alert alert-danger mt-2">{{ error }}</div>
    </div>
    <div class="card-footer">
      <div class="position-absolute bottom-5 end-2">
        <argon-loading-button style="margin-bottom: 0px !important;" class="btn btn-sm bg-gradient-primary"
          color="primary" variant="gradient" :label="loading ? 'Guardando...' : 'Guardar'" :loading="loading"
          @click="saveLogo"></argon-loading-button>
      </div>
    </div>
  </div>
  <div class="position-fixed top-1 end-1 z-index-2">
    <argon-snackbar v-if="snackbar" :title="snackbar.title" :description="snackbar.message"
      :icon="{ component: 'ni ni-notification-70', color: snackbar.type }" :color="snackbar.color"
      :close-handler="closeSnackbar" />
  </div>
</template>
<style scoped>
.box-empty{
  width: 150px; height: 130px; border: 2px dashed #ccc; color: #888; cursor: pointer;
}
.box-empty-icon{
  width: 120px; height: 100px; border: 2px dashed #ccc; color: #888; cursor: pointer;
}
.image-logo-load {
  max-width:70px;
  max-height:34px;
  cursor: pointer;
}
.image-logo-load, img{
  width: 100%; 
  height:100%;
  object-fit: contain;

}
.image-icon-load {
  max-width:43px;
  max-height:43px;
  cursor: pointer;
}
.input-label{
  display: flex;
  justify-content: center;
  align-items: center;
  text-align: center;
}

.clear-image {
  display: none;
  width: 20px;
  height: 20px;
  padding: 0;
  font-size: 18px;
  line-height: 1;
  background: rgba(255, 0, 0, 0.7);
  color: white;
  border: none;
}
.image-logo-load:hover .clear-image{
  display: block;
}
.image-icon-load:hover .clear-image{
  display: block;
}
</style>
