<!-- eslint-disable no-unused-vars -->
<script setup>
import { computed, ref } from 'vue';
import { useStore } from 'vuex';
import ArgonAvatar from "@/components/ArgonAvatar.vue";
import NotFound from "@/assets/img/image.png";
import ProductItem from '../../ProductItem.vue';

const emit = defineEmits(["removeProduct", "removeAllProducts"]);
const store = useStore();
const isRTL = computed(() => store.state.isRTL)

const props = defineProps({
  items: {
    type: Array,
    default() {
      return [];
    },
  },
});


function removeProduct(product) {
  emit("removeProduct", product);
}
function removeAllProducts(product){
  emit("removeAllProducts", product);
}

</script>
<template>
  <div class="row">
    <div class="col-lg-12">
      <ul :class="`scrolling-wrapper-flexbox p-0 m-0 w-100`">
        <li
          v-for="(
            product, index
          ) of items" :key="index" :class="`item-product border-0 list-group-item d-flex justify-content-between border-radius-lg ps-1`">
          <product-item v-if="product.quantity > 0" :product="product" :in-order="true" @removeProduct="removeProduct" @removeAllProducts="removeAllProducts"/>
        </li>
      </ul>
      <div v-if="items.length == 0" class="row my-3">
        <div class="col-lg-12 text-center">
          <span class="label-placeholder text-lg">Sin productos seleccionados.</span>
        </div>
      </div>
    </div>
  </div>
</template>
<style lang="scss" scoped>
.scrolling-wrapper-flexbox {
  display: flex;
  flex-wrap: nowrap;
  overflow-x: auto;

  li {
    flex: 0 0 auto;
  }
}
.item-product{
  max-width: 224px;
}
.card h6,
.card span {
  overflow: hidden;
  text-overflow: ellipsis;
  white-space: nowrap;
  max-width: 100%;
}
.box-select-list {
  // max-height: 20vh;
  // max-height: 100vh;
  overflow-x: auto
}

.custom-direction {
  flex-direction: unset;
}

.center-ul {
  padding: 0px;
  text-align: center;
  justify-content: center;
  width: 100%;
}
.card-detail-layout .span-xs{
  font-size: 0.75rem !important;
}
</style>
