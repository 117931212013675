export const apiServer = process.env.VUE_APP_API_URI;
export const apiMarketplace = process.env.VUE_APP_MARKETPLACE_URI;
export const apiPrestashop = process.env.VUE_APP_MARKETPLACE_URI;
export const apiShipment = process.env.VUE_APP_SHIPMENT_URI;
export const cexSeguimientoEnvio = process.env.VUE_APP_CEX_SEGUIMIENTO_URI;
export const shipmentGoal = process.env.VUE_APP_SHIPMENT_GOAL;
export const appVersion = "0.0.1";
export const apiKeyMaps = "AIzaSyA6HMe4l770I-piy4a9b6FYWybU0v93ORs";

export const Shops = {
  SHOPIFY: 'SHOPIFY',
  PRESTASHOP: 'PRESTASHOP',
  WOOCOMMERCE: 'WOOCOMMERCE'
}
