<script setup>
import ArgonSelect from "@/components/ArgonSelect.vue";
import { pricingListStore } from "@/_store/pricingStore";
import { ref, onMounted, watch } from "vue";
import ArgonInput from "@/components/ArgonInput.vue";
import { useVuelidate } from "@vuelidate/core";
import { required, helpers } from "@vuelidate/validators";

const pricingStore = pricingListStore();

let model = ref({
  fare: "",
  fare_secondary: "",
  fare_inter: "",
  fare_secondary_inter: "",
  pct_fuel: 100
});

let allFares = ref(null);
let props = defineProps({
  modelValue: {
    type: Object,
    default: () => ({
      fare: "",
      fare_secondary: "",
      fare_inter: "",
      fare_secondary_inter: "",
      pct_fuel: 100,
    }),
  },
});
const rules = {
  pct_fuel: { required: helpers.withMessage("Combustible es requerido", required) },
}
const v$ = ref(useVuelidate(rules, model));
onMounted(() => {
  model.value = props.modelValue;
  pricingStore.getListPricings();
});

async function validate() {
  await v$.value.$validate();
  return v$.value.$errors;
}

watch(
  () => pricingStore.pricingsList,
  (value) => {
    if (value) {
      allFares.value = value;
    }
  }
);
defineExpose({
  validate,
});
</script>
<template>
  <div role="form" class="p-1">
    <div class="row">
      <div class="col-4">
        <div class="row">
          <div class="col-5">
            <label class="col-form-label">Tarifa nacional</label>
          </div>
          <div class="col-7">
            <argon-select v-if="allFares" id="fare" name-label="code,name" name-value="code" v-model="model.fare"
              :options="allFares" filterable clearable/>
          </div>
        </div>
      </div>
      <div class="col-4">
        <div class="row">
          <div class="col-5">
            <label class="col-form-label">Tarifa nacional sust.</label>
          </div>
          <div class="col-7">
            <argon-select v-if="allFares" id="fare_secondary" name-label="code,name" name-value="code" v-model="model.fare_secondary"
            :options="allFares" filterable clearable/>
          </div>
        </div>
      </div>
    </div>
    <div class="row">
      <div class="col-4">
        <div class="row">
          <div class="col-5">
            <label class="col-form-label">Tarifa internacional</label>
          </div>
          <div class="col-7">
            <argon-select v-if="allFares" id="fare_inter" name-label="code,name" name-value="code" v-model="model.fare_inter"
              :options="allFares" filterable clearable/>
          </div>
        </div>
      </div>
      <div class="col-4">
        <div class="row">
          <div class="col-5">
            <label class="col-form-label">Tarifa internacional sust.</label>
          </div>
          <div class="col-7">
            <argon-select v-if="allFares" id="fare_secondary_inter" name-label="code,name" name-value="code" v-model="model.fare_secondary_inter"
            :options="allFares" filterable clearable/>
          </div>
        </div>
      </div>
      </div>
      <div class="row">
        <div class="col-4">
          <div class="row">
            <div class="col-5">
              <label class="col-form-label">% Aplicación combustible</label>
            </div>
            <div class="col-7">
              <argon-input :error="v$.pct_fuel.$error" id="combustible" v-model.number="model.pct_fuel" :max-length="15" type="number" :is-required="true" />
            </div>
          </div>
        </div>
      </div>
  </div>
</template>