export const estados = [ 
  {
    label: "Trasnmitiendo",
    value: "TRANSMITIENDO",
  },
  {
    label: "Recogida Pendiente",
    value: "RECOGIDA_PENDIENTE",
  },
  {
    label: "Recogido",
    value: "RECOGIDO",
  },
  {
    label: "En transito",
    value: "EN_TRANSITO",
  },
  {
    label: "En reparto",
    value: "EN_REPARTO",
  },
  {
    label: "Entregado",
    value: "ENTREGADO",
  },
  {
    label: "Entrega Cancelada",
    value: "ENTREGA_CANCELADA",
  },
  {
    label: "Anulado",
    value: "ANULADO",
  },
  {
    label: "Incidencia",
    value: "INCIDENCIA",
  },
  {
    label: "Error",
    value: "ERROR",
  },
];

export function getStatusLabel(value) {
  const statusObject = estados.find((item) => item.value === value);
  return statusObject ? statusObject.label : value;
}
