<script setup>
import { onMounted, inject } from "vue";
import PreviousViewImage from "./PreviousViewImage.vue";
import { csv, excel, fileDefault, pdf, word } from "@/assets/img/file-icons";
import BadgeType from "@/components/badges/BadgeType.vue";

const emit = defineEmits(["delete", "download"]);
const deleteLoading = inject("loadingDelete");
const props = defineProps({
  file: {
    type: Object,
    default: () => ({
      name: "",
      id: "",
      url: "",
    }),
  },
  active:{ type: Boolean, default: false}
});
onMounted(() => {});

function isImageUrl(fileData) {
  const imageExtensions = [".jpg", ".jpeg", ".png", ".gif", ".bmp", ".webp"];
  return imageExtensions.some((ext) =>
    fileData.name.toLowerCase().endsWith(ext)
  );
}

function getTooltip() {
  if (props.file.name.length <= 8) return null;
  return getCleanFileName(props.file.name);
}

function getCleanFileName(fileName) {
  let chunk = fileName.split("/");
  return chunk[chunk.length - 1];
}

function getIllustration(file) {
  const fileName = file.name.toLowerCase();
  if (fileName.endsWith(".pdf")) return pdf;
  if (fileName.endsWith(".csv")) return csv;
  if (fileName.endsWith(".xls") || fileName.endsWith(".xlsx")) return excel;
  if (
    fileName.endsWith(".doc") ||
    fileName.endsWith(".docx") ||
    fileName.endsWith(".dot") ||
    fileName.endsWith(".dotx") ||
    fileName.endsWith(".odt")
  ) return word;

  return fileDefault;
}

function downloadFile() {
  const fileName = props.file.filename;
  const link = document.createElement("a");
  link.href = props.file.url;
  link.setAttribute("download", fileName);
  document.body.appendChild(link);
  link.click();
  document.body.removeChild(link);
}


function deleteFile(id) {
  emit("delete", id);
}
</script>

<template>
  <div class="m-2 row p-0 view-file-container"  :class="{'active':active}">
    <div class="col-12 view-file p-0">
      <div class="file-actions-container">
        <button
          class="file-action-btn left text-xs"
          @click="downloadFile(file.name)"
        >
          <i class="fa fa-download"></i>
        </button>
        <button
          v-if="!deleteLoading"
          class="file-action-btn right text-xs"
          @click="deleteFile(file._id)"
        >
          <i class="fa fa-trash"></i>
        </button>
        <div v-else class="loading-spinner right text-xs"></div>
      </div>
      <previous-view-image v-if="isImageUrl(file)" :imageUrl="file.url" />
      <div v-else class="d-flex justify-content-center align-items-center w-100 h-100">
        <img
          :src="getIllustration(file)"
          alt="Icono de archivo"
          class="file-icon"
        />
      </div>
    </div>
    <div
      class="col-12 information-file"
    >
      <p class="text-file text-xs" v-tooltip :data-bs-title="getTooltip()">
        {{ getCleanFileName(file.name) }}
      </p>
      <div class="section-icon d-flex align-items-center">
      <badge-type :item="file.type" />
      <i 
        v-tooltip 
        :data-bs-title="file.user" 
        class="fa fa-user text-xs ms-auto" 
        aria-hidden="true">
      </i>
    </div>
    </div>
  </div>
</template>
