<script setup>
import { onMounted, watch, ref, computed } from "vue";
import Tracking from "@/components/StopTracking.vue";
import ArgonSnackbar from "@/components/ArgonSnackbar.vue";
import ArgonInput from "@/components/ArgonInput.vue";
import TabsBootstrap from "@/components/TabsBootstrap.vue";
import ArgonDetailLayout from "@/components/detail/ArgonDetailLayout";
import { useRoute } from "vue-router";
import { stopListStore, stopDetailStore } from "@/_store";
import PartnerAvatar from "@/components/PartnerAvatar.vue";
import StatusBadge from "@/components/badges/StopStatus.vue";
import { dateUtils } from "@/utils";
import StopListShipment from "@/components/StopListShipment.vue";
import DocumentsButton from "@/components/gallery-files/DocumentsButton.vue";
import { useStore } from "vuex";
import ModalChangeStatus from "./ModalChangeStatus.vue";
import { userStore } from "@/_store/userStore";

const props = defineProps({
  showChangeStatus: {
    type: Boolean,
    default: false,
  },
});
let actionStops = ref(false);
let setError = ref("");
const route = useRoute();
const detailStore = stopDetailStore();
const stopStore = stopListStore();
let routeActual = ref("");
let tabSelected = ref("envios");
let isInitialized = ref(false);
const tabs = computed(() => {
  if (localValue.value.consolided) {
    return [{ label: "Seguimiento", value: "seguimiento" }];
  }
  return [
    { label: "Envíos", value: "envios" },
    { label: "Seguimiento", value: "seguimiento" },
  ];
});
const store = useStore();
let statusModal = null;
const bootstrap = store.state.bootstrap;
const userStores = userStore();
let localValue = ref({
  title: `${dateUtils.formatDate(dateUtils.getToday(), "YYYY-MM-DD")}`,
  customer: ``,
  address: "",
  schedules: "",
  observations: "",
  courier: "",
  status: "",
  consolided: false
});
const actions = ref([
  [{ label: "Etiquetas", event: "onLabelPrint" }],
  [{ label: "Exportar envíos", event: "onExportReport" }],
]);
const events = {
  onLabelPrint: getShipmentsLabel,
  onExportReport: exportData,
  onChangeStatus: changeStatus,
  onGetManifest: getManifest,
};
function changeStatus() {
  statusModal = new bootstrap.Modal(
    document.getElementById("modalChangeStopStatus"),
    {
      keyboard: true,
    }
  );
  statusModal.show();
}

onMounted(() => {
  routeActual.value = route.path.split("/").filter(Boolean);
  routeActual.value = `${routeActual.value[0]}/${routeActual.value[1]}`;
  loadStop();
  isInitialized.value = true;
});

function triggerEvent(action) {
  try {
    events[action.event]();
  } catch {
    console.log("Evento no implementado");
  }
}
function getShipmentsLabel() {
  stopStore.getStopShipmentLabel(route.params.id);
}
function getManifest() {
  stopStore.getStopManifest(route.params.id);
}
function exportData() {
  stopStore.getStopShipmentAsExcel(route.params.id);
}

function loadStop() {
  if (props.showChangeStatus) {
    actions.value.push([
      { label: "Transitar Estado", event: "onChangeStatus" },
    ]);
  }
  if (route.params.id) {
    detailStore.getStopById(route.params.id);
  }
  /* if (localValue.value.type!=='ENTREGA') {
    actions.value[1].push({ label: "Manifiesto", event: "onGetManifest" })
  } */
}

function makeExport(blob) {
  let URI = URL.createObjectURL(blob);
  window.open(URI, "_blank");
}

function changedState(state) {
  localValue.value.status = state;
}

watch(
  () => localValue.value.consolided,
  (newVal) => {
    if (newVal) {
      tabSelected.value = "seguimiento";
    } else {
      tabSelected.value = "envios";
    }
  }
);

watch(
  () => detailStore.error,
  (value) => {
    if (value) {
      snackbar.value = {
        title: "Error",
        message: value.message,
        type: "danger",
        color: "white",
      };
    }
  }
);

watch(
  () => detailStore.stopDetail,
  (value) => {
    if (value) {
      localValue.value = {
        title:  value.number  ? `${value.number} / ${dateUtils.formatDate(
          value.date,
          "DD-MM-YYYY"
        )}` : `${dateUtils.formatDate(
          value.date,
          "DD-MM-YYYY"
        )}`,
        customer: !value.consolided
          ? `${value.customer.contact?.name || ""} - ${value.customer.code || ""}`
          : "",
        address: value.address_text,
        schedules: value.schedules_text,
        observations: value.observations,
        courier: value.courier,
        status: value.status,
        consolided: value.consolided
      };
      setError.value = value.error;
      if(localValue.value.consolided) {
        actionStops.value = true;
      }
      if (localValue.value.type !== "ENTREGA") {
        actions.value[1].push({ label: "Manifiesto", event: "onGetManifest" });
      }
    }
  }
);
watch(
  () => stopStore.stopShipmentLabel,
  (newVal) => {
    if (newVal) {
      makeExport(newVal);
    }
  }
);
watch(
  () => stopStore.stopManifest,
  (newVal) => {
    if (newVal) {
      makeExport(newVal);
    }
  }
);
watch(
  () => stopStore.stopShipmentExcel,
  (newVal) => {
    if (newVal) {
      makeExport(newVal);
    }
  }
);
const isAgency = computed(() => userStores.userSigned?.portalType === "AGENCY");
const entity = computed(() => {
  if (localValue.value.type === "ENTREGA") return "stops_entrega";
  else return "stops_recogida";
});
const snackbar = ref(null);
const closeSnackbar = () => {
  snackbar.value = null;
};
</script>
<template>
  <div class="card">
    <div class="card-body">
      <argon-detail-layout :group-actions="actions" @click="triggerEvent" :show-config="actionStops">
        <template #buttons> </template>
        <template #buttons-right>
          <documents-button
            v-if="isAgency && localValue.type"
            label="Documentos"
            :id-entity="route.params.id"
            container="documents"
            :entity="entity"
            :origin="localValue.code"
            :redirect="routeActual"
          />
        </template>
        <template #title>
          <div class="row">
            <div class="col-12">
              <argon-input
                id="number"
                v-model="localValue.title"
                :max-length="30"
                type="text"
                size="lg"
                font-style="h4 fw-bold"
                :disabled="true"
              />
            </div>
          </div>
        </template>
        <template #body>
          <div class="row spacing">
            <div class="col-12">
              <div class="card">
                <div class="card-body">
                  <div class="row" v-if="!localValue.consolided">
                    <div class="col-1">
                      <label for="date" class="col-form-label">Cliente</label>
                    </div>
                    <div class="col-9">
                      <argon-input
                        id="date"
                        v-model="localValue.customer"
                        type="text"
                        :disabled="true"
                      />
                    </div>
                  </div>                  
                  <div class="row">
                    <div class="col-1">
                      <label for="client-name" class="col-form-label"
                        >Dirección</label
                      >
                    </div>
                    <div class="col-9">
                      <argon-input
                        id="client-name"
                        v-model="localValue.address"
                        type="text"
                        :disabled="true"
                      />
                    </div>
                  </div>
                  <div class="row">
                    <div class="col-1">
                      <label for="client-name" class="col-form-label"
                        >Horario</label
                      >
                    </div>
                    <div class="col-9">
                      <argon-input
                        id="client-name"
                        v-model="localValue.schedules"
                        type="text"
                        :disabled="true"
                      />
                    </div>
                  </div>
                  <div class="row">
                    <div class="col-1">
                      <label class="col-form-label">Observaciones</label>
                    </div>
                    <div class="col-9">
                      <argon-input
                        id="code"
                        v-model="localValue.observations"
                        type="text"
                        :disabled="true"
                      />
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </template>
        <template #state>
          <div class="row">
            <div class="col">
              <partner-avatar :item="localValue.courier"></partner-avatar>
            </div>
            <div class="col">
              <status-badge
                v-if="setError != ''"
                :item="localValue.status"
                :tooltip="setError"
              ></status-badge>
              <status-badge
                v-else
                :item="localValue.status"
                :tooltip="localValue.status_description"
              ></status-badge>
            </div>
          </div>
        </template>
        <template #tabs>
          <div v-if="isInitialized" class="card mt-3">
            <div class="card-body">
              <tabs-bootstrap v-model="tabSelected" :tabs="tabs" />
              <stop-list-shipment
                v-if="tabSelected === 'envios'"
                :id-stop="route.params.id" 
              />
              <tracking
                v-if="tabSelected == 'seguimiento'"
                :id-stop="route.params.id"
                :courier="localValue.courier"
              />
            </div>
          </div>
        </template>
      </argon-detail-layout>
    </div>
  </div>
  <modal-change-status
    id="modalChangeStopStatus"
    :stop-id="route.params.id"
    @successChange="changedState"
  />
  <div class="position-fixed top-1 end-1 z-index-2">
    <argon-snackbar
      v-if="snackbar"
      :title="snackbar.title"
      :description="snackbar.message"
      :icon="{ component: 'ni ni-notification-70', color: snackbar.type }"
      :color="snackbar.color"
      :close-handler="closeSnackbar"
    />
  </div>
</template>
