<script setup>
const emit = defineEmits(["change"]);
import { argonTableStore } from "@/_store/argonTableStore";

const props = defineProps({
  filter: {
    type: Object,
    required: true,
  },
  divider: {
    type: Boolean,
    default: false,
  },
});

function change(value, isActive) {
  emit("change", {
    description: props.filter.label,
    field: props.filter.field,
    multi: props.filter.multi,
    label: value.label,
    value: value.value,
    active: !isActive,
  });
}
const tableStore = argonTableStore();

function isActive(option) {
  const filter = tableStore.filters.find(
    (filter) => filter.field == props.filter.field
  );
  if (filter) {
    if (props.filter.multi) {
      if (filter?.value?.$in?.find((value) => option.value == value)) return true;
    } else if (filter.text == option.label) return true;
  }
  return false;
}
</script>

<template>
  <li v-for="(option, key) of filter.options" :key="key">
    <a
      class="dropdown-item border-radius-md"
      @click="change(option, isActive(option))"
    >
      <i v-if="isActive(option)" class="fa fa-check me-sm-2"></i>
      <span v-else class="ms-4"></span>
      {{ option.label }}
    </a>
  </li>
  <li v-if="divider">
    <hr class="dropdown-divider" />
  </li>
</template>