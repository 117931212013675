<!-- eslint-disable no-unused-vars -->
<script setup>
import { onMounted, ref, watch, inject, computed } from "vue";
import { useVuelidate } from "@vuelidate/core";
import ArgonSelect from "@/components/ArgonSelect.vue";
import ArgonInput from "@/components/ArgonInput.vue";
import { shipmentDetailStore } from "@/_store";
import { fulfillmentStore } from "@/_store";
import { required, helpers } from "@vuelidate/validators";
import cex from "@/assets/img/logos/partners/cex.jpeg";
import ctt from "@/assets/img/logos/partners/ctt.png";
import ups from "@/assets/img/logos/partners/ups.svg";
import propio from "@/assets/img/logos/logo.svg";
import propio_agency from "@/assets/img/logos/logo-agency.svg";
import { userStore } from "@/_store/userStore";
import { shopifyStore as marketplaceStore } from "@/_store";

const store = marketplaceStore();
const storeFulfillment = fulfillmentStore();
let props = defineProps({
  modelValue: {
    type: Object,
    default: () => ({
      boxes: [],
      service: "",
      contact: {
        name: "",
        vat_number: "",
        email: "",
        attention_of: "",
        phone: "",
      },
      features: {
        refund_amount: 0,
        insurance: 0,
        delivery_on_saturday: false,
        returns: false
      },
      address: {
        postal_code: "",
        street: "",
        street2: "",
        city: "",
        country: "ES",
        location: {
          lat: 0,
          lng: 0,
        },
        text: "",
        place_id: "",
        normalized: true,
        province: "",
      },
      schedules: {
        first_frequency: {
          initial: "",
          final: "",
        },
        second_frequency: {
          initial: "",
          final: "",
        },
      },
    }),
  },
  shop: {
    type: Object,
    default() {
      return {};
    },
  },
  order: {
    type: Object,
    default() {
      return {};
    },
  },
});




let model = ref(props.modelValue);
const userStores = userStore();
const isAgency = computed(() => userStores.userSigned?.portalType === 'AGENCY');
const beeImage = computed(() => isAgency.value ? propio_agency : propio);
const rules = {
  service: { required: helpers.withMessage("Servicio es requerido", required) },
  date: { required: helpers.withMessage("Fecha es requerida", required) },
};
const description = ref(null);
const shipmentStore = shipmentDetailStore();
const keyLabel =ref(0);
let key = ref(1);
const v$ = ref(useVuelidate(rules, model));

onMounted(() => {
  shipmentStore.getServices()
  model.value = props.modelValue;
  document.querySelector("#fulfillmentModal").addEventListener("show.bs.modal", () => {
    model.value = props.modelValue;
    key.value += 1
  });
});

async function validate() {
  await v$.value.$validate();
  return v$.value.$errors;
}

defineExpose({
  validate,
});


function getDescription(value) {
  if (!value || value === '') return null;
  const foundService = shipmentStore.services?.find(service => service.name === value);
  if (foundService != undefined && foundService != null) return foundService.description;
  else return null;
}

let servicios = ref([]);
watch(
  () => shipmentStore.services,
  (val) => {
    if (val && val.length > 0) {
      servicios.value = val.map((servicio) => {
        let image = "";
        switch (servicio.courier) {
          case "CEX":
            image = cex;
            break;
          case "CTT":
            image = ctt;
            break;
          case "UPS":
            image = ups;
            break;
          default:
            image = beeImage;
            break;
        }
        return {
          ...servicio,
          image,
        };
      });
    } else {
      servicios.value = [];
    }
  },
  { immediate: true }
);

watch(
  () => storeFulfillment.changeService,
  (newVal) => {
    if (newVal) {
      key.value += 1
    }
  }
);

watch(
  () => props.modelValue,
  (newVal) => {
    if (newVal) {
      model.value = props.modelValue;
      key.value += 1
    }
  }
);
watch(
  () => model.value.service,
  (newVal) => {
    if (newVal) {
      description.value = getDescription(newVal);
      keyLabel.value += 1
    }
  }
);

</script>
<template>
  <div>
    <div class="row">
      <div class="col-lg-12">
        <div class="row">
          <div class="col-3">
            <label :key="keyLabel" for="col-form-label" v-tooltip :data-bs-title="description"
              class="col-form-label">Servicio<sup v-if="model.service != ''"
                class="text-primary sup-question">?</sup></label>
          </div>
          <div class="col-9">
            <argon-select v-if="shipmentStore.services" class="position-service" id="fulfillment_box_service2" :key="key" v-model="model.service"
              name="service" :is-required="true" :options="servicios" name-value="name" label-value="name" filterable
              :error="v$.service.$error" />
          </div>
        </div>
      </div>
      <div class="col-lg-12">
        <div class="row">
          <div class="col-3">
            <label class="col-form-label">Fecha</label>
          </div>
          <div class="col-9">
            <argon-input id="date" v-model="model.date" :is-required="true" type="date" />
          </div>
        </div>
      </div>
    </div>
  </div>
</template>
<style scoped>
.position-service{
  position: fixed !important;
  width: 31.3% !important;
}
</style>
