export const estados = [
  {
    label: "Pendiente",
    value: "PENDIENTE",
  },
  {
    label: "Procesado",
    value: "PROCESADO",
  },
  {
    label: "Error",
    value: "ERROR",
  },
];

export function getStatusLabel(value) {
  const statusObject = estados.find((item) => item.value === value);
  return statusObject ? statusObject.label : value;
}
