<script setup>
import GalleryFilesContainer from "@/components/gallery-files/GalleryFilesContainer.vue";
import { useRoute } from "vue-router";
import { onMounted, ref } from "vue";

const route = useRoute();
const entity = ref("");
let routeActual = ref("");
const listType = [
  { label: "Recogida", value: "RECOGIDA" },
  { label: "Entrega", value: "ENTREGA" },
  { label: "Otros", value: "OTROS" },
];
onMounted(() => {
  routeActual.value = route.path;
  routeActual.value = routeActual.value.split('/')[1];
  if(routeActual.value === 'pickup') entity.value = 'stops_recogida';
  if(routeActual.value === 'delivery') entity.value = 'stops_entrega';
});
</script>
<template>
  <div class="card">
    <div class="card-body">
      <gallery-files-container v-if="entity != ''" :id-entity="route.params.id" container="documents"
        :entity="entity" :list-type="listType" :origin="route.query.locator" />
    </div>
  </div>
</template>