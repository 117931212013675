import { defineStore } from "pinia";
import { customerService } from "@/_services";

export const customerDetailStore = defineStore("customerDetail", {
  state: () => ({
    error: null,
    customer: null,
    deletedId: null,
    updatedCustomer: null,
    isNew: null,
    createdCustomer: null,
  }),
  actions: {
    getCustomerById(idCustomer) {
      customerService
        .getCustomerById(idCustomer)
        .then((results) => (this.customer = results))
        .catch((reason) => (this.error = reason));
    },
    updateCustomer(id, body) {
      customerService
        .updateCustomer(id, body)
        .then((result) => (this.updatedCustomer = result))
        .catch((reason) => (this.error = reason));
    },
    insertCustomer(body) {
      customerService
        .insertCustomer(body)
        .then((results) => (this.createdCustomer = results))
        .catch((reason) => (this.error = reason));
    },
  },
});
