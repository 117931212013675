<script setup>
import ArgonActions from "./ArgonActions.vue";
const emit = defineEmits(["click"]);

defineProps({
  groupActions: {
    type: Array,
    default() {
      return [];
    },
  },
  showConfig: {
    type: Boolean,
    default: false
  }
});

function click(value) {
  emit("click", value);
}
</script>
<template>
  <div class="row d-flex align-items-center justify-content-between">
    <div class="col-md-auto d-flex align-items-center">
      <slot name="buttons"></slot>
      <argon-actions
      class="ps-1"
      v-if="groupActions.length > 0"
      :group-actions="groupActions"
      :show-config="showConfig"
      @click="click"
      />
    </div>
    <div class="col-auto">
      <slot name="buttons-right"></slot>
    </div>
  </div>
</template>
