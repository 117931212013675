import createAxios from "./axiosHttp";

function getBatchesFilesCustomer(pagination, query, sort) {
  const axios = createAxios();
  let urlPath = `customer/batch/ql`;
  const ql = {
    query,
    pagination,
    sort,
  };
  return axios.post(urlPath, ql);
}

function getListBatchCustomers(batchId, pagination) {
  const axios = createAxios();
  pagination.limit = 20;
  let urlPath = `customer/summary/batch/${batchId}`;
  return axios.get(urlPath, {
    params: pagination
  });
}

function getBatchFile(id) {
  const axios = createAxios();
  let urlPath = `customer/batch/${id}`;
  return axios.get(urlPath);
}
function downloadFile(id) {
  const axios = createAxios();
  let urlPath = `batch/${id}`;
  return axios
    .post(urlPath, {}, { responseType: "blob" })
    .catch(async (error) => {
      const data = await error.text();
      throw JSON.parse(data);
    });
}

export const batchFileCustomersService = {
  getBatchesFilesCustomer,
  getBatchFile,
  downloadFile,
  getListBatchCustomers,
};
