import createAxios from "./axiosHttp";

function getAllBatchesFiles(pagination, query, sort) {
  const axios = createAxios();
  let urlPath = `shipment/batch/ql`;
  const body = {
    query,
    pagination,
    sort,
  };
  body.query.type = "SHIPMENT";
  return axios.post(urlPath, body);
}

function getBatchFile(shipmentId) {
  const axios = createAxios();
  let urlPath = `shipment/batch/${shipmentId}`;
  return axios.get(urlPath);
}

function getListBatchShipments(batchId, pagination) {
  const axios = createAxios();
  pagination.limit = 20;
  let urlPath = `shipment/summary/batch/${batchId}`;
  return axios.get(urlPath, {
    params: pagination
  });
}

function getBatchShipmentLabel(batchId) {
  const axios = createAxios();
  let urlPath = `shipment/batch/${batchId}/label`;
  return axios.get(urlPath, { responseType: "blob" }).catch(async (error) => {
    const data = await error.text();
    throw JSON.parse(data);
  });
}

function cancelShipmentsByBatch(batchId) {
  const axios = createAxios();
  let urlPath = `shipment/batch/${batchId}`;
  return axios.delete(urlPath);
}

export const batchFileShipmentsService = {
  getAllBatchesFiles,
  getBatchFile,
  getBatchShipmentLabel,
  getListBatchShipments,
  cancelShipmentsByBatch,
};
