import { defineStore } from "pinia";
import { batchFileService } from "@/_services";

export const contactImportListStore = defineStore("contactsImportList", {
  state: () => ({ batchesFiles: null, error: null, batchesListContacts: null }),
  actions: {
    getBatchesFiles(pagination, filters, sort) {
      batchFileService
        .getBatchesFilesContact(pagination, filters, sort)
        .then((results) => (this.batchesFiles = results))
        .catch((reason) => (this.error = reason));
    },
    getListBatchContacts(batchId, pagination) {
      batchFileService
        .getListBatchContacts(batchId, pagination)
        .then((results) => (this.batchesListContacts = results))
        .catch((reason) => (this.error = reason));
    },
    clearListBatchContacts(){
      this.batchesListContacts=null;
    }
  },
});
