<script setup>
import { computed } from "vue";
import { ArgonLoadingButton } from "@/components"
import { watch,ref } from "vue";
import { useUserStore } from "@/_store/userStore.js";
import { useVuelidate } from "@vuelidate/core";
import { required, email, helpers } from "@vuelidate/validators";
import ArgonInput from "@/components/ArgonInput.vue";
import ArgonSnackbar from "@/components/ArgonSnackbar.vue";
import ArgonSelect from "@/components/ArgonSelect.vue";
import CustomerAutocomplete from "@/components/forms/CustomerAutoComplete.vue";
import ModalBase from "@/components/modal/ModalBase.vue";
const userStore = useUserStore();
const isAgency = computed(() => userStore.userSigned?.portalType==='AGENCY');
const isEcom = computed(() => userStore.userSigned?.portalType==='ECOM');

const rules = {
  user: { required: helpers.withMessage("Email es requerido", required), email: helpers.withMessage("Ingrese un email valido", email) },
  type: { required: helpers.withMessage("Tipo es requerido", required)}
};
const userTypes = [
  { label: "Agencia", value: "AGENCY" },
  { label: "Cliente", value: "CUSTOMER" },
];
const data = ref({
  user: "",
  customer_id: "",
  customer: {
    code: "",
    contact: {
      name: ""
    },
  },
  type: isEcom.value ? 'ECOM' : ''
})
const v$ = ref(useVuelidate(rules, data));

async function validate() {
  await v$.value.$validate();
  return v$.value.$errors;
}
const snackbar = ref(null);
const closeSnackbar = () => {
  snackbar.value = null;
};
function submit() {
  const resValidation = validate()
  resValidation.then((values) => {
    let message= "";
    for (const value of values) {
      message = `${message}<li>${value.$message}</li>`;
    }
    if (message != "") {
      snackbar.value = {
        title: "Error",
        message: `<ul>${message}</ul>`,
        type: "danger",
        color: "white",
      };
    } else {
      let contract= {user: data.value.user, type: data.value.type}
      if(data.value.type=='CUSTOMER'){
        contract.customerId= data.value.customer_id
      }
      else if(data.value.type== 'ECOM'){
        contract.customerId= userStore.userSigned?.extraData.customerId
      }
      userStore.newUser(contract);
    }
  });
}
let key= ref(1);
function cleanData(){
  data.value={
    user: "",
    customer_id: "",
    customer: {
      code: "",
      contact: {
        name: ""
      },
    },
    type: isEcom.value ? 'ECOM' : ''
  }
  v$.value.$reset()
  key.value++;
}
watch(
  () => userStore.error,
  (value) => {
    if (value) {
      snackbar.value = {
        title: "Error",
        message: value.message,
        type: "danger",
        color: "white",
      };
    }
  }
);
const disableButton = computed({
  get() {
    return userStore.disableButton
  },
})

</script>

<template>
  <modal-base ref="newUserModal" @hide="cleanData">
    <template #header>
      <h6 id="ModalLabel" class="modal-title">
        Nuevo
      </h6>
    </template>
    <template #body>
      <form class="border-0">
        <p class="mb-4">
          Se enviará al nuevo usuario, un correo con las credenciales de acceso al portal.
        </p>
        <div v-if="isAgency" class="mt-2">
          <argon-select :key="key" placeholder="Tipo" id="user-type" v-model="data.type" name="user-type" :is-required="true"
            :options="userTypes" :error="v$.type.$error">
          </argon-select>
        </div>
        <div v-if="data.type=='CUSTOMER' && isAgency">
          <customer-autocomplete class="ancho"
            placeholder="Cliente"
            ref="customer" v-model="data"
            size= "col-lg-12"
            :show-departments='false'>
          </customer-autocomplete>
        </div>
        <div class="mb-3">
          <argon-input
            placeholder="Correo"
            id="email"
            v-model="data.user"
            :max-length="75"
            type="text"
            name="email"
            size="lg"
            :error="v$.user.$error"
          />
          </div>
      </form>
    </template>
    <template #footer>
      <argon-loading-button
        class="btn btn-sm bg-gradient-primary"
        color="primary"
        variant="gradient"
        :label="disableButton ? 'Enviando...' : 'Enviar'"
        :disable-button="disableButton"
        @click="submit"
      >
      </argon-loading-button>
    </template>
    <div class="position-fixed top-1 end-1 ">
      <argon-snackbar
        v-if="snackbar"
        :title="snackbar.title"
        :description="snackbar.message"
        :icon="{ component: 'ni ni-notification-70', color: snackbar.type }"
        :color="snackbar.color"
        :close-handler="closeSnackbar"
      />
    </div>
  </modal-base>
</template>
<style lang="scss">
.file-preview-container {
  gap: 1rem;
}
.drop-zone-area {
  max-height: 350px;
  overflow-x: auto;
}
.file-input-label {
  display: flex;
  justify-content: center;
  align-items: center;
  text-align: center;
}

.file-input-text {
  display: flex;
  flex-direction: column;
  align-items: center;
  margin-top: 40px;
  margin-bottom: 40px;
  margin-left: 80px;
  margin-right: 80px;
}
.drop-area:hover {
  border-color: #409eff;
}
.fa-upload {
  font-size: 20px;
  color: #67748e;
}

</style>