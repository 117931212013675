import { defineStore } from 'pinia';
import { stopService } from '@/_services';


export const stopListStore = defineStore('stopList', {
  state: () => ({ 
    stopListShipment: null,
    error: null,
    stopShipmentLabel: null,
    stopTracking: null,
    stopShipmentExcel: null,
    stopManifest: null
  }),
  actions: {
    getListStopShipments(stopId, pagination) {
      //this.stopListShipment = null;
      stopService.getStopShipments(stopId, pagination)
        .then((results) => this.stopListShipment = results)
        .catch((reason) => {
          this.error = reason
        });
    },
    getStopShipmentLabel(id) {
      this.stopShipmentLabel= null;
      stopService
        .getStopShipmentLabel(id)
        .then((results) => (this.stopShipmentLabel = results))
        .catch((reason) => {
          this.stopShipmentLabel= null;
          this.error = reason
        });
    },
    getStopTracking(shipmentId) {
      this.stopTracking= null
      stopService
        .getStopTracking(shipmentId)
        .then((results) => (this.stopTracking = results))
        .catch((reason) => {
          this.stopTracking= null
          this.error = reason
        });
    },
    getStopShipmentAsExcel(stopId) {
      this.stopShipmentExcel= null
      stopService
        .getStopShipmentAsExcel(stopId)
        .then((results) => (this.stopShipmentExcel = results))
        .catch((reason) => {
            this.stopShipmentExcel= null
            this.error = reason
          });
    },
    getStopManifest(id) {
      this.stopManifest= null;
      stopService
        .getStopManifest(id)
        .then((results) => (this.stopManifest = results))
        .catch((reason) => {
          this.stopManifest= null;
          this.error = reason
        });
    },
  },
})
