<!-- eslint-disable no-unused-vars -->
<script setup>

import { computed } from "vue";
import moment from "moment";
import { dashboardStore } from "@/_store";
import GradientLineChart from "../../../examples/Charts/GradientLineChart.vue";

const store = dashboardStore();

const labels = computed({
  get() {
    let records = [...store.couriers]
    if (records.length == 0) return false

    records = records.sort((a, b) => a["week"] - b["week"])

    const start = store.start
    const end = store.end

    var diff = moment.duration(end.diff(start));

    const labels = []
    let datasets = []

    for (let index = 0; index < diff.days() + 1; index++) {
      const label = `Día ${index + 1}`
      labels.push(label)
    }

    records.forEach((item) => {

      let dataset = {
        label: item["name"],
        data: [],
      }

      for (let index = 0; index < diff.days() + 1; index++) {
        const day = index + 1
        const dayFound = item.days.find((dayItem) => dayItem["day"] == day)

        if (!dayFound) {
          dataset["data"] = [...dataset["data"], 0]
        } else {
          dataset["data"] = [...dataset["data"], dayFound["quantity"]]
        }
      }
  
      datasets.push(dataset)
    })

    return {
      labels: labels,
      datasets: datasets,
    }
  },
})


</script>
<template>
  <gradient-line-chart
    v-if="labels"
    id="couriers-chart"
    title="Envíos por transporte"
    description=""
    :chart="labels"
  />
  <gradient-line-chart
    v-else
    id="couriers-chart"
    title="Envíos por transporte"
    description=""
    :chart="{
      labels: [],
      datasets: [],
    }"
  />
</template>
