import { defineStore } from 'pinia';
import { ordersService } from '@/_services';


export const orderListStore = defineStore('ordersList', {
  state: () => ({ orders: null, orderId: null, ordersAsExcel: null }),
  actions: {
    getAllOrders(pagination, filters, sort) {
        ordersService.getAllOrders(pagination, filters, sort)
        .then((results) => this.orders = results)
        .catch((reason) => this.error = reason);
    },
    getOrderById(idProduct) {
      ordersService.getOrderById(idProduct)
      .then((results) => this.orderId = results)
      .catch((reason) => this.error = reason);
  },
  getOrdersAsExcel(query, sort) {
    ordersService.getOrdersAsExcel(query, sort)
    .then((results) => this.ordersAsExcel = results)
    .catch((reason) => this.error = reason);
},
  },
})
