import Landing from "../views/dashboards/Landing.vue";
import Default from "../views/dashboards/Default.vue";
import Automotive from "../views/dashboards/Automotive.vue";
import SmartHome from "../views/dashboards/SmartHome.vue";
import VRDefault from "../views/dashboards/vr/VRDefault.vue";
import VRInfo from "../views/dashboards/vr/VRInfo.vue";
import CRM from "../views/dashboards/CRM.vue";
import Overview from "../views/pages/profile/Overview.vue";
import Teams from "../views/pages/profile/Teams.vue";
import Projects from "../views/pages/profile/Projects.vue";
import General from "../views/pages/projects/General.vue";
import Timeline from "../views/pages/projects/Timeline.vue";
import NewProject from "../views/pages/projects/NewProject.vue";
/* import Pricing from "../views/pages/Pricing.vue"; */
import RTL from "../views/pages/Rtl.vue";
import Charts from "../views/pages/Charts.vue";
import SweetAlerts from "../views/pages/SweetAlerts.vue";
import Notifications from "../views/pages/Notifications.vue";
import Kanban from "../views/applications/Kanban.vue";
import Wizard from "../views/applications/wizard/Wizard.vue";
import DataTables from "../views/applications/DataTables.vue";
import Calendar from "../views/applications/Calendar.vue";
import Analytics from "../views/applications/analytics/Analytics.vue";
import EcommerceOverview from "../views/ecommerce/overview/Overview.vue";
import NewProduct from "../views/ecommerce/products/NewProduct.vue";
import EditProduct from "../views/ecommerce/EditProduct.vue";
import ProductPage from "../views/ecommerce/ProductPage.vue";
import ProductsList from "../views/ecommerce/ProductsList.vue";
import OrderDetails from "../views/ecommerce/Orders/OrderDetails";
import OrderList from "../views/ecommerce/Orders/OrderList";
import Referral from "../views/ecommerce/Referral";
import Reports from "../views/pages/Users/Reports.vue";
import NewUser from "../views/pages/Users/NewUser.vue";
import Settings from "../views/pages/Account/Settings.vue";
import Billing from "../views/pages/Account/Billing.vue";
import Invoice from "../views/pages/Account/Invoice.vue";
import Security from "../views/pages/Account/Security.vue";
import Widgets from "../views/pages/Widgets.vue";
import Basic from "../views/auth/signin/Basic.vue";
import Cover from "../views/auth/signin/Cover.vue";
import Illustration from "../views/auth/signin/Illustration.vue";
import ResetBasic from "../views/auth/reset/Basic.vue";
import ResetCover from "../views/auth/reset/Cover.vue";
import ResetIllustration from "../views/auth/reset/Illustration.vue";
import VerificationBasic from "../views/auth/verification/Basic.vue";
import VerificationCover from "../views/auth/verification/Cover.vue";
import VerificationIllustration from "../views/auth/verification/Illustration.vue";
import SignupBasic from "../views/auth/signup/Basic.vue";
import SignupCover from "../views/auth/signup/Cover.vue";
import SignupIllustration from "../views/auth/signup/Illustration.vue";
import Error404 from "../views/auth/error/Error404.vue";
import Error403 from "../views/auth/error/Error403.vue";
import Error500 from "../views/auth/error/Error500.vue";
import lockBasic from "../views/auth/lock/Basic.vue";
import lockCover from "../views/auth/lock/Cover.vue";
import lockIllustration from "../views/auth/lock/Illustration.vue";

//BeePaket
import Shipments from "../views/shipments/Shipments.vue";
import Shipment from "../views/shipment/Shipment.vue";
import ShipmentsBatchFile from "../views/shipments-import/ShipmentsBatchFile.vue";
import BatchFilesContact from "../views/contacts-imports/ContactsImports.vue";
import BatchFilesCustomer from "../views/customers-imports/CustomersImports.vue";
import Contacts from "../views/contacts/Contacts.vue";
import Customers from "../views/customers/Customers.vue";
import Customer from "../views/customers/Customer.vue";
import Contact from "../views/contacts/Contact.vue";
import Issues from "../views/issue/Issues.vue";
import IssuesDetail from "../views/issue/Issue.vue";
import ContactBatchDetail from "../views/contacts-imports/ContactDetail.vue";
import CustomerBatchDetail from "../views/customers-imports/CustomersDetail.vue";
import ShipmentBatchDetail from "../views/shipments-import/ShipmentDetail.vue";
import Stock from "../views/stock/Stocks.vue";
import StockDetail from "../views/stock/components/StockDetailTable.vue";
import Products from "../views/products/Products.vue";
import ProductDetail from "../views/products/components/DetailProductTable.vue";
import Orders from "../views/orders/Orders.vue";
import OrderDetail from "../views/orders/components/DetailOrderTable.vue";
import Synchronization from "../views/synchronization/Synchronization.vue";
import Profile from "../views/profile/Profile.vue";
import Users from "../views/users/User.vue";
import RestorePassword from "../views/auth/reset/RestorePassword.vue";
import StopOwnPickups from "../views/stops/pickup/StopOwnPickups.vue";
import StopOwnPickupDetail from "../views/stops/pickup/StopOwnPickup.vue";
import StopOtherPickups from "../views/stops/pickup/StopOtherPickups.vue";
import StopOtherPickupDetail from "../views/stops/pickup/StopOtherPickup.vue";
import StopOwnDeliveries from "../views/stops/delivery/StopOwnDeliveries.vue";
import StopOwnDeliveryDetail from "../views/stops/delivery/StopOwnDelivery.vue";
import Tracking from "../views/tracking/Tracking.vue";

import PricingFare from "../views/pricing/PricingFares/PricingFares.vue";
import PricingPrices from "../views/pricing/PricingFarePrices/PricingFaresPrices.vue";
import PricingPricesDetail from "../views/pricing/PricingFarePrices/PricingFaresPrice.vue";
import PricingConcepts from "../views/pricing/PricingConcepts/PricingConcepts.vue";
import Valuations from "../views/pricing/Valuations/Valuations.vue";
import ValuationDetail from "../views/pricing/Valuations/Valuation.vue";
import ListDocuments from "../views/shipment/components/ListDocuments.vue";
import ListDocumentsStops from "../views/stops/components/ListDocuments.vue";
import ListDocumentsIssues from "../views/issue/components/ListDocuments.vue";
import ListDocumentsCustomers from "../views/customers/components/ListDocuments.vue";
export const routes = [
  {
    path: "/",
    name: "/",
    redirect: "/dashboard-default",
  },
  {
    path: "/user/profile",
    name: "User Profile",
    component: Settings,
  },
  // Rutas de shipments
  {
    path: "/shipments",
    name: "Envíos",
    component: Shipments,
  },
  {
    path: "/shipments/new",
    name: "Nuevo Envío",
    component: Shipment,
  },
  {
    path: "/shipments/:id",
    name: "Detalle Envío",
    component: Shipment,
    props: true,
  },
  //List Gallery files container
  {
    path: "/shipments/:id/gallery-files-container",
    name: "Documentación Envíos",
    component: ListDocuments,
  },
  // Rutas de contacts
  {
    path: "/contacts",
    name: "Contactos",
    component: Contacts,
  },
  {
    path: "/contacts/new",
    name: "Nuevo Contacto",
    component: Contact,
  },
  {
    path: "/contacts/:id",
    name: "Detalle Contacto",
    component: Contact,
  },
  // Rutas clientes
  {
    path: "/customers",
    name: "Clientes",
    component: Customers,
  },
  {
    path: "/customers/new",
    name: "Nuevo cliente",
    component: Customer,
  },
  {
    path: "/customers/:id",
    name: "Detalle Cliente",
    component: Customer,
  },
  {
    path: "/customers/:id/gallery-files-container",
    name: "Documentación clientes",
    component: ListDocumentsCustomers,
  },
  {
    path: "/imports",
    name: "Importaciones",
  },
  // Rutas incidencia
  {
    path: "/issues",
    name: "Incidencias",
    component: Issues,
  },
  {
    path: "/issues/:id",
    name: "Detalle Incidencia",
    component: IssuesDetail,
  },
  {
    path: "/issues/:id/gallery-files-container",
    name: "Documentación incidencias",
    component: ListDocumentsIssues,
  },
  // Rutas de imports shipments
  {
    path: "/imports/shipments",
    name: "Importación Envíos",
    component: ShipmentsBatchFile,
  },
  {
    path: "/imports/shipments/:idFile",
    name: "Detalle Envíos",
    component: ShipmentBatchDetail,
  },
  // Rutas de imports contacts
  {
    path: "/imports/contacts",
    name: "Importación Contactos",
    component: BatchFilesContact,
  },
  {
    path: "/imports/customers",
    name: "Importación Clientes",
    component: BatchFilesCustomer,
  },
  {
    path: "/imports/customers/:idFile",
    name: "Detalle Clientes",
    component: CustomerBatchDetail,
  },
  {
    path: "/imports/contacts/:idFile",
    name: "Detalle Contactos",
    component: ContactBatchDetail,
  },
  // Rutas marketplace
  {
    path: "/marketplace",
    name: "Marketplace",
  },
  {
    path: "/marketplace/stock",
    name: "Stock",
    component: Stock,
  },
  {
    path: "/marketplace/stock/:id",
    name: "Detalle Stock",
    component: StockDetail,
  },
  {
    path: "/marketplace/products",
    name: "Productos",
    component: Products,
  },
  {
    path: "/marketplace/products/:id",
    name: "Detalle Producto",
    component: ProductDetail,
  },
  {
    path: "/marketplace/orders",
    name: "Pedidos",
    component: Orders,
  },
  {
    path: "/marketplace/orders/:id",
    name: "Detalle Pedido",
    component: OrderDetail,
  },
  {
    path: "/marketplace/synchronization",
    name: "Sincronización",
    component: Synchronization,
  },
  //Account routes
  {
    path: "/account",
    name: "Cuenta",
  },
  {
    path: "/account/profile",
    name: "Mi Perfil",
    component: Profile,
  },
  {
    path: "/account/users",
    name: "Usuarios",
    component: Users,
  },
  //Rutas Parada Recogida
  {
    path: "/pickups",
    name: " Recogidas",
    component: StopOtherPickups,
  },
  {
    path: "/pickup",
    name: "Recogidas",
  },
  {
    path: "/pickup/own",
    name: "Recogidas Propias",
    component: StopOwnPickups,
  },
  {
    path: "/pickup/own/:id",
    name: "Detalle Recogida Propia",
    component: StopOwnPickupDetail,
  },
  {
    path: "/pickup/own/:id/gallery-files-container",
    name: "Documentación propia",
    component: ListDocumentsStops,
  },
  {
    path: "/pickup/others",
    name: "Otras Plazas",
    component: StopOtherPickups,
  },
  {
    path: "/pickup/others/:id",
    name: "Detalle Otras Plazas",
    component: StopOtherPickupDetail,
  },
  {
    path: "/pickup/others/:id/gallery-files-container",
    name: "Documentación",
    component: ListDocumentsStops,
  },
  //Rutas para Valoraciones
  {
    path: "/pricing",
    name: "Valoración",
  },
  {
    path: "/pricing/assesment",
    name: "Tarifas",
    component: PricingFare,
  },
  {
    path: "/pricing/prices",
    name: "Tarifas Precios",
    component: PricingPrices,
  },
  {
    path: "/pricing/prices/:id",
    name: "Detalle Tarifas Precios",
    component: PricingPricesDetail,
  },
  {
    path: "/pricing/concepts",
    name: "Suplementos",
    component: PricingConcepts,
  },
  {
    path: "/pricing/valuations",
    name: "Valoraciones",
    component: Valuations,
  },
  {
    path: "/pricing/valuations/:id",
    name: "Detalle Valoración",
    component: ValuationDetail,
  },
  //Rutas Parada Entrega
  {
    path: "/delivery",
    name: "Entregas",
  },
  {
    path: "/delivery/own",
    name: "Entregas Propias",
    component: StopOwnDeliveries,
  },
  {
    path: "/delivery/own/:id",
    name: "Detalle Entrega Propia",
    component: StopOwnDeliveryDetail,
  },
  {
    path: "/delivery/own/:id/gallery-files-container",
    name: "Documentación entrega",
    component: ListDocumentsStops,
  },
  // Antiguas
  {
    path: "/dashboard-default",
    name: "Dashboard",
    component: Default,
  },
  {
    path: "/dashboards/landing",
    name: "Landing",
    component: Landing,
  },
  {
    path: "/dashboards/automotive",
    name: "Automotive",
    component: Automotive,
  },
  {
    path: "/dashboards/smart-home",
    name: "Smart Home",
    component: SmartHome,
  },
  {
    path: "/dashboards/vr/vr-default",
    name: "VR Default",
    component: VRDefault,
  },
  {
    path: "/dashboards/vr/vr-info",
    name: "VR Info",
    component: VRInfo,
  },
  {
    path: "/dashboards/crm",
    name: "CRM",
    component: CRM,
  },
  {
    path: "/pages/profile/overview",
    name: "Profile Overview",
    component: Overview,
  },
  {
    path: "/pages/profile/teams",
    name: "Teams",
    component: Teams,
  },
  {
    path: "/pages/profile/projects",
    name: "All Projects",
    component: Projects,
  },
  {
    path: "/pages/projects/general",
    name: "General",
    component: General,
  },
  {
    path: "/pages/projects/timeline",
    name: "Timeline",
    component: Timeline,
  },
  {
    path: "/pages/projects/new-project",
    name: "New Project",
    component: NewProject,
  },
  /* {
    path: "/pages/pricing-page",
    name: "Pricing Page",
    component: Pricing,
  }, */
  {
    path: "/pages/rtl-page",
    name: "RTL",
    component: RTL,
  },
  {
    path: "/pages/charts",
    name: "Charts",
    component: Charts,
  },
  {
    path: "/pages/widgets",
    name: "Widgets",
    component: Widgets,
  },
  {
    path: "/pages/sweet-alerts",
    name: "Sweet Alerts",
    component: SweetAlerts,
  },
  {
    path: "/pages/notifications",
    name: "Notifications",
    component: Notifications,
  },
  {
    path: "/applications/kanban",
    name: "Kanban",
    component: Kanban,
  },
  {
    path: "/applications/wizard",
    name: "Wizard",
    component: Wizard,
  },
  {
    path: "/applications/data-tables",
    name: "Data Tables",
    component: DataTables,
  },
  {
    path: "/applications/calendar",
    name: "Calendar",
    component: Calendar,
  },
  {
    path: "/applications/analytics",
    name: "Analytics",
    component: Analytics,
  },
  {
    path: "/ecommerce/overview",
    name: "Overview",
    component: EcommerceOverview,
  },
  {
    path: "/ecommerce/products/new-product",
    name: "New Product",
    component: NewProduct,
  },
  {
    path: "/ecommerce/products/edit-product",
    name: "Edit Product",
    component: EditProduct,
  },
  {
    path: "/ecommerce/products/product-page",
    name: "Product Page",
    component: ProductPage,
  },
  {
    path: "/ecommerce/products/products-list",
    name: "Products List",
    component: ProductsList,
  },
  {
    path: "/ecommerce/Orders/order-details",
    name: "Order Details",
    component: OrderDetails,
  },
  {
    path: "/ecommerce/Orders/order-list",
    name: "Order List",
    component: OrderList,
  },
  {
    path: "/ecommerce/referral",
    name: "Referral",
    component: Referral,
  },
  {
    path: "/pages/users/reports",
    name: "Reports",
    component: Reports,
  },
  {
    path: "/pages/users/new-user",
    name: "New User",
    component: NewUser,
  },
  {
    path: "/pages/account/settings",
    name: "Settings",
    component: Settings,
  },
  {
    path: "/pages/account/billing",
    name: "Billing",
    component: Billing,
  },
  {
    path: "/pages/account/invoice",
    name: "Invoice",
    component: Invoice,
  },
  {
    path: "/pages/account/Security",
    name: "Security",
    component: Security,
  },
  {
    path: "/authentication/signin/basic",
    name: "Signin Basic",
    component: Basic,
  },
  {
    path: "/authentication/signin/cover",
    name: "Signin Cover",
    component: Cover,
  },
  {
    path: "/authentication/signin/illustration",
    name: "Signing Illustration",
    component: Illustration,
  },
  {
    path: "/authentication/signin",
    name: "Signing",
    component: Illustration,
  },
  {
    path: "/authentication/reset/basic",
    name: "Reset Basic",
    component: ResetBasic,
  },
  {
    path: "/authentication/reset/cover",
    name: "Reset Cover",
    component: ResetCover,
  },
  {
    path: "/authentication/reset/illustration",
    name: "Reset Illustration",
    component: ResetIllustration,
  },
  //Password
  {
    path: "/recover_password",
    name: "Reset Password",
    component: ResetIllustration,
  },
  {
    path: "/restore_login",
    name: "Restore Login",
    component: RestorePassword,
  },
  {
    path: "/authentication/lock/basic",
    name: "Lock Basic",
    component: lockBasic,
  },
  {
    path: "/authentication/lock/cover",
    name: "Lock Cover",
    component: lockCover,
  },
  {
    path: "/authentication/lock/illustration",
    name: "Lock Illustration",
    component: lockIllustration,
  },
  {
    path: "/authentication/verification/basic",
    name: "Verification Basic",
    component: VerificationBasic,
  },
  {
    path: "/authentication/verification/cover",
    name: "Verification Cover",
    component: VerificationCover,
  },
  {
    path: "/authentication/verification/illustration",
    name: "Verification Illustration",
    component: VerificationIllustration,
  },
  {
    path: "/authentication/signup/basic",
    name: "Signup Basic",
    component: SignupBasic,
  },
  {
    path: "/authentication/signup/cover",
    name: "Signup Cover",
    component: SignupCover,
  },
  {
    path: "/authentication/signup/illustration",
    name: "Signup Illustration",
    component: SignupIllustration,
  },
  {
    path: "/authentication/error/error404",
    name: "Error Error404",
    component: Error404,
  },
  {
    path: "/authentication/error/error403",
    name: "Error Error403",
    component: Error403,
  },
  {
    path: "/authentication/error/error500",
    name: "Error Error500",
    component: Error500,
  },
  // Tracking
  {
    path: "/tracking",
    name: "Tracking",
    component: Tracking,
  },
];
