<script setup>
import { onMounted, watch, ref } from "vue";
import { useStore } from "vuex";

import ArgonTable from "@/components/table/ArgonTable.vue";
import StatusBadge from "@/components/badges/BatchFileStatus.vue";
import ArgonSnackbar from "@/components/ArgonSnackbar.vue";
import TypeBadge from "@/components/badges/BatchType.vue";

import { userStore } from "@/_store/userStore";
import { argonTableStore } from "@/_store/argonTableStore";
import { useRouter } from "vue-router";
import { customersImportListStore } from "@/_store/customersImportsStore";
import { dropzoneStore } from "@/components/dropzone/dropzoneStore";
import TiposImportaciones from "/src/maestros/TiposImportaciones.json";
import ArgonTd from "@/components/table/ArgonTd.vue";
import moment from "moment";

const store = useStore();
const bootstrap = store.state.bootstrap;
const dropStore = dropzoneStore();
const router = useRouter();
var myModal = null;
const fieldFilters = [
  {
    field: "filename",
    description: "Fichero",
  },
];
const defaultFilters = [
  {
    field: "status",
    label: "Estado",
    multi: true,
    options: [
      { label: "Procesado", value: "PROCESADO" },
      { label: "Error", value: "ERROR" },
    ],
  },
  {
    field: "sub_type",
    label: "Tipo",
    multi: true,
    options: TiposImportaciones.CUSTOMER.options,
  },
];
const importOptions = {
  title: "Importar Clientes",
  options: TiposImportaciones.CUSTOMER.options,
  url: `/customer/batch`,
};

const userStores = userStore();
const batchFileStore = customersImportListStore();
const tableStore = argonTableStore();
const snackbar = ref(null);
const closeSnackbar = () => {
  snackbar.value = null;
};
const columns = [
  {
    header: "Creado",
    field: "created_at", 
    sort: true,
  }, 
  {
    header: "Fichero",
    field: "filename",
    sort: false,
  },
  {
    header: "Tipo",
    field: "type",
    position: "center", 
    sort: false,
  },
  {
    header: "Estado",
    field: "status",
    position: "center",
    width: "w-5",
    sort: false,
  },
];
onMounted(() => {
  tableStore.clear();
  if (userStores.filtersPage.batchesCustomer)
    tableStore.changeFilters(userStores.filtersPage.batchesCustomer);
  else tableStore.changeFilters([]);
  reloadPage();
});

function reloadPage() {
  userStores.filtersPage.batchesCustomer = tableStore.filters;
  batchFileStore.getBatchesFiles(
    tableStore.pagination,
    tableStore.query,
    tableStore.sort
  );
}

function importFile() {}

function onNewItem() {
  myModal = new bootstrap.Modal(document.getElementById("modalImport"), {
    keyboard: true,
  });
  myModal.show();
}
function detailCustomer(item) {
  router.push(`/imports/customers/${item._id}`);
}

function getPosition(field) {
  const column = columns.find((col) => col.field === field);
  return column && column.position ? column.position : "start";
}

watch(
  () => dropStore.uploadStatus,
  (value) => {
    if (value == "SUCCESS") {
      myModal.hide();
      dropStore.clearFiles(); 
      reloadPage();
    }
  }
);
</script>

<template>
  <div class="card">
    <div class="card-body">
      <argon-table
        v-if="batchFileStore.batchesFiles"
        :show-config-option="false"
        :show-check-input="false"
        :columns="columns"
        :items="batchFileStore.batchesFiles"
        :field-filters="fieldFilters"
        :default-filters="defaultFilters"
        :import-options="importOptions"
        @filter-changed="reloadPage"
        @page-changed="reloadPage"
        @import="importFile"
        @on-click="detailCustomer"
        @new-item="onNewItem"
        @sort-changed="reloadPage"
        @on-reload="reloadPage"
      >
        <template #buttons> </template>
        <template #default="{ item }">
          <argon-td
            :position="getPosition('created_at')"
            :text-primary="moment(item.created_at).format('DD/MM/YYYY HH:mm')"
            :text-secondary="moment(item.created_at).fromNow()"
          ></argon-td>
          <argon-td
            :text-secondary="item.filename"
            :position="getPosition('filename')"
          />
          <argon-td :position="getPosition('type')">
            <type-badge
              :item="TiposImportaciones.CUSTOMER[item.sub_type]"
            ></type-badge>
          </argon-td>          
          <argon-td :position="getPosition('status')">
            <status-badge
              :tooltip-message="item.error"
              :item="item.status"
            ></status-badge>
          </argon-td>
        </template>
      </argon-table>
    </div>
  </div>
  <div class="position-fixed top-1 end-1 z-index-2">
    <argon-snackbar
      v-if="snackbar"
      :title="snackbar.title"
      :description="snackbar.message"
      :icon="{ component: 'ni ni-notification-70', color: snackbar.type }"
      :color="snackbar.color"
      :close-handler="closeSnackbar"
    />
  </div>
</template>
