import { createAxios } from "./axiosHttp";

function getDashboard() {
  const axios = createAxios();
  let urlPath = `dashboard`;
  return axios.get(urlPath);
}

export const dashboardService = {
  getDashboard
};
