import { createAxios } from "./axiosMarketplaceHttp";
import { createAxios as createAxiosApi } from "./axiosHttp";


function generateBarcode(mac) {
  const axios = createAxiosApi();
  let urlPath = `marketplace/order/fulfillment/barcode?mac=${mac}`;

  return axios
    .get(urlPath, { responseType: "blob" })
    .catch(async (error) => {
      const data = await error.text();
      throw JSON.parse(data);
    });
}

function fulfillOrder(body, orderId) {
  const axios = createAxios();
  
  let urlPath = `api/order/${orderId}/fulfillment`;
  return axios.post(urlPath, body);
}

function getByOrder(orderId) {
  const axios = createAxiosApi();
  let urlPath = `marketplace/order/${orderId}/fulfillment`;
  return axios.get(urlPath);
}


export const fulfillmentService = {
  generateBarcode,
  fulfillOrder,
  getByOrder
};
