import { defineStore } from "pinia";
import { contactService } from "@/_services";

export const contactDetailStore = defineStore("contactDetail", {
  state: () => ({
    error: null,
    contact: null,
    deletedId: null,
    updatedContact: null,
    isNew: null,
    createdContact: null,
  }),
  actions: {
    getContactById(idContact) {
      contactService
        .getContactById(idContact)
        .then((results) => (this.contact = results))
        .catch((reason) => (this.error = reason));
    },
    deleteContact(idContact) {
      this.error = null
      contactService
        .deleteContact(idContact)
        .then((result) => (this.deletedId = result))
        .catch((reason) => (this.error = reason));
    },
    updateContact(id, body) {
      this.error = null
      contactService
        .updateContact(id, body)
        .then((result) => (this.updatedContact = result))
        .catch((reason) => (this.error = reason));
    },
    insertContact(body) {
      this.error = null
      contactService
        .insertContact(body)
        .then((results) => (this.createdContact = results))
        .catch((reason) => (this.error = reason));
    },
  },
});
