
export const globalMixin = {
  methods: {
    $formatPrice(input, path, isPercentage) {
      let value;
      if (typeof input === 'object') {
        const dict = input;
        if (!dict) return '0.00' + (isPercentage ? " %" : " €");
        value = dict?.[path];
      } else {
        value = input;
      }
      if (value == null || value === "") return '0.00' + (isPercentage ? " %" : " €");
      value = parseFloat(value);
      return value.toFixed(2) + (isPercentage ? " %" : " €");
    }
  }
};