import { defineStore } from "pinia";
import { userService } from "@/_services";

/* import axios from "axios";
import { apiServer } from "@/globalVars.js"; */

export const userTableStore = defineStore("userList", {
  state: () => ({
    error: null,
    users: null
  }),
  actions: {
    getUsers(pagination, filters, sort) {
      userService
        .getAllUsers(pagination, filters, sort)
        .then((results) => (this.users = results))
        .catch((reason) => (this.error = reason));
    },
  },
});

