import { defineStore } from "pinia";
import { valuationService } from "@/_services";

export const valuationStore = defineStore("valuationStore", {
  state: () => ({
    valuations: null,
    valuation: null,
    error: null,
    valuationAsExcel: null,
    simulation: null,
    reevaluation: null
  }),
  actions: {
    getValuationById(id) {
      valuationService
        .getValuation(id)
        .then((results) => (this.valuation = results))
        .catch((reason) => (this.error = reason));
    },
    getAllValuations(pagination, query, sort) {
      valuationService
        .getAllValuations(pagination, query, sort)
        .then((results) => (this.valuations = results))
        .catch((reason) => (this.error = reason));
    },
    getValuationAsExcel(query, sort){
      valuationService.getValuationAsExcel(query, sort)
      .then((results) => this.valuationAsExcel = results)
      .catch((reason) => this.error = reason);
    },
    reevaluate(body){
      valuationService.reevaluate(body)
      .then((results) => this.reevaluation = results)
      .catch((reason) => this.error = reason);
    },
  },
});
