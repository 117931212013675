import createAxios from "./axiosHttp";

function sendAccountStopConfig(data) {
  const axios = createAxios();
  let urlPath = `/account/stop/config`;
  return axios.post(urlPath, data);
}

function getAccountStopConfig() {
  const axios = createAxios();
  let urlPath = `/account/stop/config`;
  return axios.get(urlPath);
}

function putAccountStopConfig(idConfig, data) {
  const axios = createAxios();
  let urlPath = `/account/stop/config/${idConfig}`;
  return axios.put(urlPath, data);
}

export const accountConfigService = {
  sendAccountStopConfig,
  getAccountStopConfig,
  putAccountStopConfig
}