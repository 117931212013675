<script setup>
import ViewFiles from "./ViewFiles.vue";
import VisorPreviewFile from "./VisorPreviewFile.vue";
//import DropZone from "@/components/dropzone/DropZone.vue";
import DetailGallery from "@/components/gallery-files/DetailGallery.vue";
import { fileViewStore } from "./fileViewStore";
import { watch, ref, onMounted, computed, provide, inject } from "vue";
import SearcherFiles from "@/components/gallery-files/SearcherFiles.vue";
import ArgonSnackbar from "@/components/ArgonSnackbar.vue";
import { argonTableStore } from "@/_store/argonTableStore";
import { userStore } from "@/_store/userStore";

const tableStore = argonTableStore();
const sweetAlert = inject("$swal");
const keyPreview = ref(0);
const props = defineProps({
  idEntity: { type: String, default: "" },
  origin: { type: String, default: "" },
  container: { type: String, default: "general" },
  entity: { type: String, default: "general" },
  type: { type: String, default: "" },
  isNewDocument: { type: Boolean, default: false },
  listType: {
    type: Array, default() {
      return [];
    },
  }
});

const defaultFilters = [
  {
    field: "type",
    label: "Tipo",
    multi: true,
    options: props.listType
  },
];
const fileStore = fileViewStore();
const listFilesUploaded = ref([]);
const fileSelected = ref({});
const showPreviewVisor = ref(false);
const hasSaved = ref(false);
const userStores = userStore();
const refFileContainer = ref(null);
const genericContainer = ref(null);
const isLoading = ref(false);
let dataDetail = ref({
  size: 0,
  totalDocuments: 0
});
let dataPreview = ref({});
let generalDetail = ref(true);
onMounted(() => {
  tableStore.clear();
  const filters = userStores.filtersPage.galleryFilters?.filters || [];
  tableStore.changeFilters(filters);
  getFiles();
});
function getFiles() {
  displaySpinner(true)
  fileStore.getFiles(`${url.value}/ql`,
    fileStore.pagination,
    tableStore.query,
    fileStore.sort
  );
}
function displaySpinner(show = false) {
  isLoading.value=show;
  if (show) {
    let container = refFileContainer.value
    let table = genericContainer.value
    table.classList.add("overflow-hidden")
    container.classList.add('loading')
    container.style.setProperty('--size', '40px 40px');
    container.style.setProperty('--top-position', '0');
    return
  }

  let container = refFileContainer.value
  container.classList.remove('loading')


  let table = genericContainer.value
  table.classList.remove("overflow-hidden")
}
function deleteFile(id) {
  loadingDelete.value = true;
  sweetAlert({
    title: "¿Está seguro que desea eliminar el documento?",
    text: "¡No podrá revertir esta acción!",
    showCancelButton: true,
    confirmButtonText: "Eliminar",
    cancelButtonText: "Cancelar",
    reverseButtons: true,
    customClass: {
      confirmButton: `btn ms-1 bg-gradient-danger`,
      cancelButton: "btn btn-outline-primary me-1",
    },
    buttonsStyling: false,
  }).then((result) => {
    if (result.isConfirmed) {
      fileStore.deleteFile(`${url.value}/${id}`);
    } else if (result.isDismissed) {
      loadingDelete.value = false;
    }
  });
}

let indexTemp = ref(null);
function onClickFile(index) {
  if (indexTemp.value == index) {
    fileSelected.value = {};
    indexTemp.value = null;
    generalDetail.value = true;
  }
  else {
    fileSelected.value = listFilesUploaded.value[index];
    indexTemp.value = index;
    generalDetail.value = false;
  }
  dataPreview.value = listFilesUploaded.value[index];
  showPreview(listFilesUploaded.value[index]);
  keyPreview.value++;
}
function showPreview() {
  showPreviewVisor.value = false;
}
function closePreview() {
  generalDetail.value = true;
  showPreviewVisor.value = false;
}
const url = computed(() => `documents/${props.container}/entity/${props.entity}/folder/${props.idEntity}/document`);
const loadingDelete = ref(false);
provide('url', url);
provide('loadingDelete', loadingDelete);
provide('fileStore', fileStore);
provide('query', tableStore.query);
provide("displaySpinner", displaySpinner);
const fieldFilters = ref([
  {
    field: "name",
    description: "Nombre",
  },
  {
    field: "description",
    description: "Descripción",
  },
  {
    field: "user",
    description: "Usuario",
  },
]);

function reloadPage() {
  getFiles();
}


function filterChanged(changes) {
  tableStore.changeFilters(changes);
  getFiles();
}
function setDefaultValues(value) {
  dataDetail.value.totalDocuments = value.total;
  dataDetail.value.size = value.totalMb;
}
watch(
  () => fileStore.files,
  (value) => {
    if (value) {
      hasSaved.value = true;
      getFiles();
      tableStore.query = {};
    }
  }
);
watch(
  () => fileStore.filesResponse,
  (value) => {
    if (value) {
      listFilesUploaded.value = (value.documents || []).map((document) => ({
        ...document,
        origin: props.origin,
      }));
      if (hasSaved.value) {
        fileSelected.value = listFilesUploaded.value[0];
        generalDetail.value = false;
        dataPreview.value = fileSelected.value;
        hasSaved.value = false;
      }
      displaySpinner();
      setDefaultValues(value);
      fileStore.deleteResponse = null;
    }
  }, { deep: true }
);
watch(
  () => fileStore.deleteResponse,
  (value) => {
    if (value) {
      getFiles();
      setDefaultValues(value);
      snackbar.value = {
        title: "Eliminado",
        message: "El documento ha sido eliminado.",
        type: "info",
        color: "white",
      };
      loadingDelete.value = false;
      generalDetail.value = true;
    }
  }
);
const isAgency = computed(() => userStores.userSigned?.portalType === 'AGENCY');
const snackbar = ref(null);
const closeSnackbar = () => {
  snackbar.value = null;
};
</script>
<template>
  <searcher-files :field-filters="fieldFilters" :id-entity="idEntity" :container="container" :entity="entity"
    @page-changed="reloadPage" @on-reload="reloadPage"
    :default-filters="defaultFilters" @filter-changed="filterChanged" />
  <div class="row mt-3 card-detail-layout">
    <div class="col-12">
      <div class="row">
        <div class="col-xl-9 col-lg-7 col-md-6 col-sm-6">
          <div id="id-card" class="card h-100" :class="isAgency ? 'agency' : 'customer'" ref="refFileContainer">
            <div id="id-card-body" class="card-body ps-2 pe-2 pt-2" ref="genericContainer">
              <div class="gallery-files">
                <div class="gallery-files-container" v-if="listFilesUploaded.length>0">
                  <view-files class="hover-pointer" v-for="(file, index) in listFilesUploaded" :key="file.name"
                    :file="file" :active="file._id === fileSelected._id" @delete="deleteFile"
                    @click="onClickFile(index)" />
                  </div>
                  <div v-else-if="!isLoading" class="row my-3">
                    <div class="col-lg-12 text-center">
                      <span class="label-placeholder text-lg"
                        >Sín documentación</span
                      >
                    </div>
                  </div>
              </div>
            </div>
            <visor-preview-file v-if="false" :current-file="fileSelected" @close="closePreview"
              class="visor-preview-container" />
          </div>
        </div>
        <div class="col-xl-3 col-lg-5 col-md-6 col-sm-6" style="height: 71vh">
          <detail-gallery :key="keyPreview" :data-detail="dataDetail" :data-preview="dataPreview"
            :general-detail="generalDetail" :list-type="listType" />
        </div>
      </div>
    </div>
  </div>
  <div class="position-fixed top-1 end-1 z-index-2">
    <argon-snackbar v-if="snackbar" :title="snackbar.title" :description="snackbar.message"
      :icon="{ component: 'ni ni-notification-70', color: snackbar.type }" :color="snackbar.color"
      :close-handler="closeSnackbar" />
  </div>
</template>
<style lang="scss" scoped>
.hover-pointer {
  cursor: pointer;
}

#id-card.agency.loading #id-card-body:after {
  position: absolute;
  top: var(--top-position);
  left: 0;
  right: 0;
  bottom: 0;
  background-color: rgba(0, 0, 0, 0.046);
  background-image: url('../../assets/img/loading-agency.svg');
  background-position: center;
  background-repeat: no-repeat;
  background-size: var(--size);
  content: "";
}

#id-card.customer.loading #id-card-body:after {
  position: absolute;
  top: var(--top-position);
  left: 0;
  right: 0;
  bottom: 0;
  background-color: rgba(0, 0, 0, 0.046);
  background-image: url('../../assets/img/loading-customer.svg');
  background-position: center;
  background-repeat: no-repeat;
  background-size: var(--size);
  content: "";
}
</style>
