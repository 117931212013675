<script setup>
import { ref, onMounted, watch } from "vue";
import ArgonTable from "@/components/table/ArgonTable.vue";
import { valuationStore as valuationStoreRequest } from "@/_store/valuationStore";
import { argonTableStore } from "@/_store/argonTableStore";
import ArgonTd from "@/components/table/ArgonTd.vue";
import ArgonButton from "@/components/ArgonButton.vue";
import { userStore } from "@/_store/userStore";
import { useRouter } from "vue-router";
import moment from "moment";
import RevalorarModal from "./RevalorarModal.vue";
import ArgonSnackbar from "@/components/ArgonSnackbar.vue";
import StatusBadge from "@/components/badges/ValuationsStatus.vue";
import { getCurrentInstance } from "vue";

const { proxy } = getCurrentInstance();
const valuationStore = valuationStoreRequest();
const tableStore = argonTableStore();
const userStores = userStore();
const router = useRouter();
const acciones = [{label: 'Revalorar', event: 'onRevaluate' }];
let showValuationModal = ref(false);
const events= ref({'onRevaluate': onRevaluate })
const snackbar = ref(null);
const closeSnackbar = () => {
  snackbar.value = null;
};
const fieldFilters = ref([
  {
    field: "shipment.locator",
    description: "Localizador",
  },
  {
    field: "fare.code",
    description: "Código tarifa",
  },
  {
      field: "customer.code",
      description: "Cliente Código",
    },
    {
      field: "customer.contact.name",
      description: "Cliente Nombre",
    },
]);
const defaultFilters = [
  {
    field: "status",
    label: "Estado",
    multi: true,
    options: [
      { label: "Valorado", value: "VALORADO" },
      { label: "Error", value: "ERROR" },
    ],
  },
];
const columns = ref([ 
  {
    header: "Localizador",
    field: "shipment.locator",
    position: "start"
  },  
  {
    header: "Cliente",
    field: "customer.name",
    width: "w-10",
    sort: false,
    position: "start"
  },
  {
    header: "Fecha",
    field: "shipment.date",
    position: "start",
    sort: true
  },
  {
    header: "Tarifa",
    field: "fare.code",
    position: "start"
  }, 
  {
    header: "Bultos",
    field: "packages",
    position: "start"
  },
  {
    header: "Peso (kg)",
    field: "weight",
    position: "start"
  },
  {
    header: "Peso volumétrico (kg)",
    field: "volumetric_weight",
    position: "start"
  },
  {
    header: "Importe inicial",
    field: "initial.total.customer",
    position: "start"
  },
  {
    header: "Importe sorter",
    field: "sorter.total.customer",
    position: "start"
  },
  {
    header: "Importe Beneficiosa",
    field: "profitable.total.customer",
    position: "start"
  },
  {
    header: "Estado",
    field: "status",
    position: "center",
    width: "w-5",
  },
]);
onMounted(() => {
  tableStore.clear();
  const shipmentFilters = userStores.filtersPage.valuations?.filters || [];
  tableStore.changeFilters(shipmentFilters);
  reloadPage();
});

function reloadPage() {
  userStores.filtersPage.valuations = {
    filters: tableStore.filters,
    filtersFavorite: tableStore.filtersFavorite,
  };
  valuationStore.getAllValuations(
    tableStore.pagination,
    tableStore.query,
    tableStore.sort
  );
};
function getText(array, index, path) {
  if (!array[index]) return '0';
  const properties = path.split('.');
  let value = array[index];
  for (let prop of properties) {
    if (!value[prop]) return '0';
    value = value[prop];
  }
  return value;
}
function getTextValuations(valuations, type, path) {
  const valuation = valuations.find((x) => x.type === type);
  if (!valuation) return '0';
  return path.split('.').reduce((value, prop) => value?.[prop], valuation) ?? '0';
}
function getPosition(field) {
  const column = columns.value.find((col) => col.field === field);
  return column && column.position ? column.position : "start";
};
function exportData() {
  valuationStore.getValuationAsExcel(tableStore.query, tableStore.sort);
}
function onClick(val) {
  const url = `/pricing/valuations/${val._id}`;
  router.push(url);
}
function viewPDF(blob) {
  const URI = URL.createObjectURL(blob);
  window.open(URI, "_blank");
}
function valorar() {
  const body={
      ids: tableStore.checkedList,
      from_date: null,
      to_date: null,
      customer_id: null
    };
  valuationStore.reevaluate(body);
}
function closeRevalorar(){
  showValuationModal.value = false;
}
function onEventAction(action){
  events.value[action]()
}
function onRevaluate(){
  showValuationModal.value = true;
}
watch(
  () => valuationStore.valuationAsExcel,
  (newVal) => {
    if (newVal) {
      viewPDF(newVal);
    }
  }
);
watch(
  () => valuationStore.reevaluation,
  (value) => {
    if (value) {
      tableStore.checkedList = []
      snackbar.value = {
        title: "Actualizado",
        message: "La revaloración ha sido realizada.",
        type: "info",
        color: "white",
      };
    }
  }
);
watch(
  () => valuationStore.error,
  (value) => {
    if (value) {
      snackbar.value = {
        title: "Error",
        message: value.message,
        type: "danger",
        color: "white",
      };
    }
  }
);
</script>
<template>
  <div class="card"> 
    <div class="card-body">
      <argon-table v-if="valuationStore.valuations" :columns="columns" :items="valuationStore.valuations"
        :field-filters="fieldFilters" @filter-changed="reloadPage" @on-reload="reloadPage" @page-changed="reloadPage"
        @sort-changed="reloadPage" @on-click="onClick" :show-new-button="false"
        :default-filters="defaultFilters"
        @export-data="exportData" :show-importar="false" :moreActions="acciones" @event-action="onEventAction">
        <template #buttons>
          <div class="dropdown d-inline">
            <argon-button id="navbarDropdownPrinter" color="primary" variant="outline"
              class="dropdown-toggle me-2 btn-sm" data-bs-toggle="dropdown" aria-expanded="false"><i
                class="fa fa-exchange me-sm-2"></i>Acciones</argon-button>
            <ul class="dropdown-menu z-index-1030 dropdown-menu-lg-start px-2 py-3"
              aria-labelledby="navbarDropdownPrinter" style>
              <li>
                <a class="dropdown-item border-radius-md" @click="valorar">Revalorar</a>
              </li>
            </ul>
          </div>
        </template>
        <template #default="{ item }">
          <argon-td :position="getPosition('shipment.locator')" :text-primary="item.shipment.locator" />
          <argon-td
            :position="getPosition('customer.name')"
            :text-primary="item.customer?.code"
            :text-secondary="item.customer?.contact?.name"
          ></argon-td>    
          <argon-td :position="getPosition('shipment.date')"
            :text-secondary="moment(item.shipment.date).format('DD/MM/YYYY')"
            ></argon-td>           
          <argon-td :position="getPosition('fare.code')" :text-primary="item.fare?.code" :text-secondary="item.fare?.name" />
          <argon-td :position="getPosition('packages')" :text-primary="'Inicial: ' + getTextValuations(item.valuations,'initial','packages')" :text-secondary="'Sorter: ' + getTextValuations(item.valuations,'sorter', 'packages')" :text-third="'Beneficiosa: ' + getTextValuations(item.valuations,'profitable', 'packages')"/>
          <argon-td :position="getPosition('weight')" :text-primary="'Inicial: ' + getTextValuations(item.valuations,'initial', 'weight')" :text-secondary="'Sorter: ' + getTextValuations(item.valuations,'sorter', 'weight')" :text-third="'Beneficiosa: ' + getTextValuations(item.valuations,'profitable', 'weight')"/>
          <argon-td :position="getPosition('volumetric_weight')" :text-primary="'Inicial: ' + getTextValuations(item.valuations,'initial', 'volumetric_weight')" :text-secondary="'Sorter: ' + getTextValuations(item.valuations,'sorter', 'volumetric_weight')" :text-third="'Beneficiosa: ' + getTextValuations(item.valuations,'profitable', 'volumetric_weight')"/>
          <argon-td :position="getPosition('initial.total.customer')" :text-primary="'Cliente: ' + proxy.$formatPrice(getText(item.valuations,0, 'total.customer'))" :text-secondary="'Coste: ' + proxy.$formatPrice(getText(item.valuations,0, 'total.courier_cost'))" />
          <argon-td :position="getPosition('sorter.total.customer')" :text-primary="'Cliente: ' + proxy.$formatPrice(getText(item.valuations,1, 'total.customer'))" :text-secondary="'Coste: ' + proxy.$formatPrice(getText(item.valuations,1, 'total.courier_cost'))" />
          <argon-td :position="getPosition('profitable.total.customer')" :text-primary="'Cliente: ' + proxy.$formatPrice(getText(item.valuations,2, 'total.customer'))" :text-secondary="'Coste: ' + proxy.$formatPrice(getText(item.valuations,2, 'total.courier_cost'))" />
          <argon-td :position="getPosition('status')">
            <status-badge
              :tooltip-message="item.errors?item.errors[0]:''"
              :item="item.status"
            ></status-badge>
          </argon-td>
        </template>
      </argon-table>
    </div>
    <revalorar-modal id="modalRevaloracion" v-if="showValuationModal" @close="closeRevalorar" />
    <div class="position-fixed top-1 end-1 ">
      <argon-snackbar v-if="snackbar" :title="snackbar.title" :description="snackbar.message"
        :icon="{ component: 'ni ni-notification-70', color: snackbar.type }" :color="snackbar.color"
        :close-handler="closeSnackbar" />
    </div>
  </div>
</template>
<style lang="scss" scoped>
.z-index-1030 {
  z-index: 1030 !important;
}
</style>