<script setup>
import { onMounted, ref, watch } from "vue";
import ArgonSnackbar from "@/components/ArgonSnackbar.vue";
import ArgonDetail from "@/components/detail/ArgonDetailLayout.vue";
import ChangePassword from "./ChangePassword.vue";
import Accounts from "./Accounts.vue";
import AreaOfInfluence from "./AreaOfInfluence.vue";
import CuttoffTimeSettings from "./CuttoffTimeSettings.vue";
import CalendarSettings from "./CalendarSettings.vue";
import Notifications from "./Notifications.vue";
import { accountListStore } from "@/_store";
import { profileDetailStore } from "@/_store/profileDetailStore";
import { useUserStore } from "@/_store/userStore.js";

const userStore = useUserStore();
const profileStore = profileDetailStore();
const dataCutoffTime = ref({
  cutoff_time: "",
  cutoff_time_exceptions: [],
});
const snackbar = ref(null);
const closeSnackbar = () => {
  snackbar.value = null;
};

const actions = ref();
const events = {};
const accountStore = accountListStore();
const account = ref({ notifications: { active: false } });
const areaInfluence = ref({
  delivery_postal_codes: [],
  pickup_postal_codes: [],
  pickup_customer_codes: []
});
const notification = ref({
  data: [
    { status: "RECOGIDA_PENDIENTE", to_sender: false, to_receiver: false },
    { status: "EN_TRANSITO", to_sender: false, to_receiver: false },
    { status: "INCIDENCIA", to_sender: false, to_receiver: false },
    { status: "ENTREGADO", to_sender: false, to_receiver: false }
  ]
});

function getAccountById() {
  accountStore.getAccountsById();
}

onMounted(() => {
  getAccountById();
});

function triggerEvent(action) {
  try {
    events[action.event]();
  } catch {
    console.log("Evento no implementado");
  }
}

watch(
  () => accountStore.account,
  (value) => {
    if (value) {
      account.value = value;
      areaInfluence.value = account.value.area_of_influence;
      notification.value.data.forEach(item => {
        const apiItem = account.value.notifications.triggers.find(apiItem => apiItem.status === item.status);
        if (apiItem) {
          item.to_sender = apiItem.to_sender;
          item.to_receiver = apiItem.to_receiver;
        } else {
          item.to_sender = false;
          item.to_receiver = false;
        }
      });
    }
  }
);
watch(
  () => profileStore.error,
  (value) => {
    if (value) {
      snackbar.value = {
        title: "Error",
        message: value.message,
        type: "danger",
        color: "white",
      };
    }
  }
);
</script>

<template>
  <div class="card">
    <div class="card-body">
      <argon-detail :group-actions="actions" @click="triggerEvent">
        <template #body>
          <div class="row">
            <div class="col-12">
              <change-password />
            </div>
          </div>
          <div class="row spacing">
            <div class="col-12">
              <accounts v-if="userStore.userSigned?.portalType != 'AGENCY'" />
            </div>
            <div class="col-12 spacing">
              <div class="row">
                <div class="col-lg-5 col-md-4 my-sm-auto">
                  <area-of-influence v-if="userStore.userSigned?.portalType === 'AGENCY'" :area-influence="areaInfluence" style="max-height: 1600px !important;" />
                </div>
                <div class="col-lg-4 col-md-4 my-sm-auto">
                  <calendar-settings v-if="userStore.userSigned?.portalType === 'AGENCY'" />
                </div>
                <div class="col-lg-3 col-md-3 my-sm-auto">
                  <cuttoff-time-settings v-if="userStore.userSigned?.portalType === 'AGENCY'" :data-exceptions="dataCutoffTime" />
                </div>
              </div>
            </div>
            <div class="col-12 spacing">
              <notifications v-if="userStore.userSigned?.portalType != 'CUSTOMER'" :notification="notification" :notification-active="account.notifications.active"/>
            </div>
          </div>
        </template>
      </argon-detail>
    </div>
  </div>
  <div class="position-fixed top-1 end-1 z-index-2">
    <argon-snackbar v-if="snackbar" :title="snackbar.title" :description="snackbar.message"
      :icon="{ component: 'ni ni-notification-70', color: snackbar.type }" :color="snackbar.color"
      :close-handler="closeSnackbar" />
  </div>
</template>
