<script setup>
import { computed } from "vue";
import { useStore } from "vuex";

const store = useStore();
const isRTL = computed(() => store.state.isRTL);
const isNavFixed = computed(() => store.state.isNavFixed);

defineProps({
  currentPage: {
    type: String,
    default: "",
  },
  currentDirectory: {
    type: Object,
    default: null,
  },
});

function getNameBreadcrumb(name) {
  if(!name)
    return
  let newName = name;

  if (name.includes("Importación") && name.split(" ").length > 1) {
    newName = name.split(" ")[1];
  }

  if (name.includes("Detalle") && name.split(" ").length > 1) {
    newName = name.split(" ")[0];
  }
  if((name.includes("Recogida") && name.length >9) || (name.includes("Entrega")&& name.length >8)){
    newName = name.split(" ")[1]
  }
  return newName;
}
</script>
<template>
  <nav aria-label="breadcrumb">
    <ol class="px-0 pt-1 pb-0 mb-0 bg-transparent breadcrumb" :class="!isRTL && 'me-sm-6'">
      <li class="text-sm breadcrumb-item" :class="isRTL && 'ps-2'">
        <a :class="{
          'text-dark': isNavFixed,
          'text-white': !isNavFixed,
        }">
          <i class="ni ni-box-2"></i>
        </a>
      </li>
      <li class="text-sm breadcrumb-item active text-white" v-if="currentDirectory && currentDirectory.path.includes('imports') && currentDirectory.name!='Importaciones'">
        <a :class="{
          'text-dark': isNavFixed,
          'text-white': !isNavFixed,
        }">Importaciones</a>
      </li>
      <li v-if="currentDirectory" class="text-sm breadcrumb-item" 
      :class="{
        'text-dark': isNavFixed,
        'text-white': !isNavFixed,
      }">
        <router-link v-if="
          currentDirectory.matched &&
          currentDirectory.matched[0] &&
          currentDirectory.matched[0].components"
          class="link-active"
          :class="{
            'text-dark': isNavFixed,
            'text-white': !isNavFixed,
          }" :to="currentDirectory.path">{{ getNameBreadcrumb(currentDirectory.name) }}</router-link>
        <a v-else :class="{
          'text-dark': isNavFixed,
          'text-white': !isNavFixed,
        }">{{
          getNameBreadcrumb(currentDirectory.name)
          }}</a>
      </li>
      <li class="text-sm breadcrumb-item active text-white">
        <a :class="{
          'text-dark': isNavFixed,
          'text-white': !isNavFixed,
        }">{{
          getNameBreadcrumb(currentPage)
          }}</a>
      </li>
    </ol>
    <h5 class="mb-0 font-weight-bolder" :class="{
      'text-dark': isNavFixed,
      'text-white': !isNavFixed,
    }">
      {{ getNameBreadcrumb(currentPage) }}
    </h5>
  </nav>
</template>
<style scoped>
/* .white {
  color: white !important;
} */
</style>
