import { defineStore } from 'pinia';
import { directionService } from '@/_services';

export const directionStore = defineStore('direction', {
  state: () => ({
    countries: null,
    error: null,
    dataProvince:{},
    dataCity:{},
  }),
  actions: {
    getCountries() {
      directionService.getAllCountries()
        .then((results) => this.countries = results)
        .catch((reason) => this.error = reason);
    },
    getProvince(request){
      directionService.getCpProvince(request)
      .then((results) => this.dataProvince = results)
      .catch((reason) => {
        this.error = reason
      });
    },
    getCity(request){
      directionService.getCpProvince(request)
      .then((results) => this.dataCity = results)
      .catch((reason) => {
        this.error = reason
      });
    }
  },
})