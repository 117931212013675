<script setup>
import { useRoute } from "vue-router";
import { onMounted, watch, ref, onBeforeMount, onBeforeUnmount } from "vue";
import { dateUtils } from "@/utils";
import { trackingStore } from "@/_store/trackingStore";
import ArgonSnackbar from "@/components/ArgonSnackbar.vue";
import TimelineList from "@/components/tracking/ArgonTracking.vue";
import TimelineItem from "@/components/tracking/ArgonTrackingItem.vue";
import { getStatusLabel } from "@/maestros/shipmentStatus";
import { useStore } from "vuex";

const route = useRoute();
const trackingStores = trackingStore();
const snackbar = ref(null);
const store = useStore();
const toggleDefaultLayout = () => store.commit("toggleDefaultLayout");
const body = document.getElementsByTagName("body")[0];

let track = ref(null);

onMounted(() => {
  if (!route.query.locator) {
    console.log("Locator missing");
    return;
  }
  trackingStores.getTracking(route.query.locator);
});

function getIcon(alert) {
  if (alert) return { component: "fa fa-truck", color: "danger" };
  else return { component: "fa fa-truck", color: "success" };
}

onBeforeMount(() => {
  store.state.hideConfigButton = true;
  toggleDefaultLayout();
  body.classList.remove("bg-gray-100");
});
onBeforeUnmount(() => {
  store.state.hideConfigButton = false;
  toggleDefaultLayout();
  body.classList.add("bg-gray-100");
});

watch(
  () => trackingStores.error,
  (value) => {
    if (value) {
      snackbar.value = {
        title: "Error",
        message: value.message,
        type: "danger",
        color: "white",
      };
    }
  }
);

watch(
  () => trackingStores.tracking,
  (value) => {
    if (value) {
      track.value = value;
    }
  }
);
</script>
<template>
  <main
    class="main-content pt-4"
    :style="{
      backgroundImage: 'url(' + '/img/logos/public-back-ground.png' + ')',
      backgroundSize: 'cover',
      backgroundPosition: 'center',
      height: '100vh',
    }"
  >
    <div class="row">
      <div class="align-item-center">
        <img
          class="me-3 mb-2"
          src="/img/logos/BeePaketLogo.png"
          alt="logo"
          width="200px"
        />
      </div>
    </div>
    <section class="row justify-content-center">
      <div class="col-md-10 col-lg-8 col-xl-6 col-sm-12">
        <div class="card h-100">
          <div>
            <label class="col-form-label ms-4 mt-2"><h4>{{ route.query.locator }}</h4></label>
          </div>
          <div v-if="track" id="list-tracking" class="card-body pt-0">
            <timeline-list v-if="track">
              <timeline-item
                v-for="(item, key) in track.tracking.data"
                :key="key"
                :icon="getIcon(item.alert)"
                :title="getStatusLabel(item.status) + ' - ' + item.description"
                :date-time="dateUtils.formatDate(item.date)"
              />
            </timeline-list>
          </div>
        </div>
      </div>
    </section>
    <div class="position-fixed top-1 end-1 z-index-2">
      <argon-snackbar
        v-if="snackbar"
        :title="snackbar.title"
        :description="snackbar.message"
        :icon="{ component: 'ni ni-notification-70', color: snackbar.type }"
        :color="snackbar.color"
        :close-handler="closeSnackbar"
      />
    </div>
  </main>
</template>
<style>
#list-tracking {
  max-height: 80vh;
  overflow-y: auto;
}
</style>