<script setup>
import { ArgonLoadingButton } from "@/components"
import { watch,ref } from "vue";
import { useVuelidate } from "@vuelidate/core";
import { required, helpers } from "@vuelidate/validators";
import ArgonSnackbar from "@/components/ArgonSnackbar.vue";
import ArgonSelect from "@/components/ArgonSelect.vue";
import ModalBase from "@/components/modal/ModalBase.vue";
import { argonTableStore } from "@/_store/argonTableStore";
import { issueListStore } from "@/_store/issueStore";
const emits = defineEmits(['requestSuccess'])
const keySelect = ref(0);
const tableStore = argonTableStore();
const issueStore = issueListStore();
const rules = {
  status: { required: helpers.withMessage("Estado es requerido", required)}
};
const statusList = [
  { label: "Pendiente", value: 'PENDIENTE' },
  { label: "En curso", value: 'EN_CURSO' },
  { label: "Finalizado", value: 'FINALIZADO' },
];
const data = ref({
  status: ""
})
const v$ = ref(useVuelidate(rules, data));
const modalModificarEstado =ref(null);
const loading = ref(false);

async function validate() {
  await v$.value.$validate();
  return v$.value.$errors;
}
const snackbar = ref(null);
const closeSnackbar = () => {
  snackbar.value = null;
};
function submitStatus() {
  const resValidation = validate()
  resValidation.then((values) => {
    let message= "";
    for (const value of values) {
      message = `${message}<li>${value.$message}</li>`;
    }
    if (message != "") {
      snackbar.value = {
        title: "Error",
        message: `<ul>${message}</ul>`,
        type: "danger",
        color: "white",
      };
    } else {
      const contract=tableStore.checkedList.map(item => ({
        _id: item,
        status: data.value.status
      }));
      loading.value=true
      issueStore.putIssuesStatus(contract);
    }
  });
}
function cleanData(){
  data.value={
    status: "",
  };
  resetModalState();
  keySelect.value += 1;
}
function resetModalState(){
  v$.value.$reset()
}
/* watch(
  () => issueStore.error,
  (value) => {
    if (value) {
      snackbar.value = {
        title: "Error",
        message: value.message,
        type: "danger",
        color: "white",
      };
    }
  }
); */
watch(
  () => issueStore.modifyStatus,
  (value) => {
    if (value) {
      loading.value=false
      cleanData();
      emits('requestSuccess')
      modalModificarEstado.value?.closeModal();
    }
  }
);
</script>

<template>
  <modal-base ref="modalModificarEstado" @hide="cleanData">
    <template #header>
      <h6 id="ModalLabel" class="modal-title">
        Transitar Estado
      </h6>
      <i class="fas tags ms-3"></i>
    </template>
    <template #body>
      <form class="border-0">
        <div class="mt-4">
          <argon-select id="user-type" placeholder="Estado" v-model="data.status" name="user-type" :is-required="true"
            :options="statusList" :error="v$.status.$error" :key="keySelect">
          </argon-select>
        </div>
      </form>
    </template>
    <template #footer>
      <argon-loading-button
        class="btn btn-sm bg-gradient-primary"
        color="primary"
        variant="gradient"
        :label="loading ? 'Enviando...' : 'Enviar'"
        :loading="loading"
        :disable-button="loading"
        @click="submitStatus"
      />
    </template>
    <div class="position-fixed top-1 end-1 ">
      <argon-snackbar
        v-if="snackbar"
        :title="snackbar.title"
        :description="snackbar.message"
        :icon="{ component: 'ni ni-notification-70', color: snackbar.type }"
        :color="snackbar.color"
        :close-handler="closeSnackbar"
      />
    </div>
  </modal-base>
</template>
<style lang="scss">
.file-preview-container {
  gap: 1rem;
}
.drop-zone-area {
  max-height: 350px;
  overflow-x: auto;
}
.file-input-label {
  display: flex;
  justify-content: center;
  align-items: center;
  text-align: center;
}

.file-input-text {
  display: flex;
  flex-direction: column;
  align-items: center;
  margin-top: 40px;
  margin-bottom: 40px;
  margin-left: 80px;
  margin-right: 80px;
}
.drop-area:hover {
  border-color: #409eff;
}
.fa-upload {
  font-size: 20px;
  color: #67748e;
}

</style>