<script setup>
import ArgonBadge from "./../ArgonBadge";
defineProps({
  item: {
    type: String,
    default: "",
  },
  tooltipMessage: {
    type: String,
    default: "",
  },
});
const colors = {
  ERROR: "danger",
  PROCESADO: "success",
  EN_PROCESO : "info"
};

function camelToNormal(text) {
  return text.replace("_", " ");
}


</script>
<template>
  <argon-badge v-tooltip :data-bs-title="tooltipMessage!=''?tooltipMessage:null" :color="colors[item]" variant="gradient" class="size">{{
    camelToNormal(item)
  }}</argon-badge>
</template>
<style scoped>
.size {
  min-width: 100px;
}
</style>