<script setup>
import { ref, onMounted } from "vue";
import SimpleTable from "@/components/table/SimpleTable.vue";
import ArgonTd from "@/components/table/ArgonTd.vue";
const props = defineProps({
  packagesSorter: {
    type: Array,
    default: () => ([]),
  },
});
const headers = ref([
  {
    header: "Kilos",
    field: "weight",
  },
  {
    header: "Largo(cm)",
    field: "length",
  },
  {
    header: "Ancho(cm)",
    field: "width",
  },
  {
    header: "Alto(cm)",
    field: "height",
  },
  {
    header: "Localizador",
    field: "locator",
    position:"start"   
  }
]);
const listTable = ref({data:props.packagesSorter});

onMounted(() => {
});

function getPosition(field) {
  const column = headers.value.find((col) => col.field === field);
  return column && column.position ? column.position : "start";
}

</script>
<template>
  <simple-table
    v-if="listTable && listTable.data && listTable.data.length > 0"
    :headers="headers"
    :items="listTable"
  >
    <template #default="{ item }">
      <argon-td
        :position="getPosition('weight')"
        :text-secondary="item.weight"
      ></argon-td>
      <argon-td
        :position="getPosition('length')"
        :text-secondary="item.length"
      ></argon-td>
      <argon-td
        :position="getPosition('width')"
        :text-secondary="item.width"
      ></argon-td>
      <argon-td
        :position="getPosition('height')"
        :text-secondary="item.height"
      ></argon-td>
      <argon-td
        :position="getPosition('locator')"
        :text-secondary="item.locator"
      ></argon-td>
    </template>
  </simple-table>
  <div v-else class="row my-3">
    <div class="col-lg-12 text-center">
      <span class="label-placeholder text-lg"
        >Sin bultos sorter</span
      >
    </div>
  </div>
</template>