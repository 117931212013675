import { createAxios } from "./axiosHttp";

function getFilters(query) {
  const axios = createAxios();
  const user = JSON.parse(localStorage.getItem("user"));
  let urlPath = `user/${user.userId}/filter/ql`;
  const ql = { query };
  return axios.post(urlPath, ql);
}

function createFilter(body) {
  const axios = createAxios();
  const user = JSON.parse(localStorage.getItem("user"));
  let urlPath = `user/${user.userId}/filter`;
  return axios.post(urlPath, body);
}

function deleteFilter(idFilter) {
  const axios = createAxios();
  const user = JSON.parse(localStorage.getItem("user"));
  let urlPath = `user/${user.userId}/filter/${idFilter}`;
  return axios.delete(urlPath, idFilter);
}

export const filterService = {
  getFilters,
  createFilter,
  deleteFilter,
};
