<script setup>
const emit = defineEmits(["removeOption"]);

const props = defineProps({
  index: {
    type: Number,
    default: -1,
  },
  text: {
    type: [String, Array],
    default: "",
  },
});

function removeOption() {
  emit("removeOption", props.index);
}
</script>

<template>
  <div class="col-auto d-flex m-1 multi-lighten p-0">
    <span class="multi-search text-search badge-secondary text-sm">
      {{ text }}
      <button type="button" class="ms-2 me-1 choices__button" aria-label="Remove item: 'PAQ10'" data-button=""  @click="removeOption()">Remove item</button>
    </span>
  </div>
</template>

<style lang="scss" scoped>
.badge-secondary {
  max-width: 100;
  overflow: hidden;
  white-space: normal;
  overflow: visible;
}
.card-detail-layout .multi-search{
  padding-top: 2px !important;
  padding-bottom: 2px !important;
}
.fa-times{
  color: #393a3b;
  font-weight: bold;
}
</style>