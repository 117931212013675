import createPublicAxios from "./axiosHttp";

function getTracking(locator) {
  const axios = createPublicAxios();
  let urlPath = `tracking/${locator}`;
  return axios.get(urlPath);
}

export const trackingService = {
  getTracking,
};
