<script setup>
import { onMounted, watch, ref } from "vue";
import SimpleTable from "@/components/table/SimpleTable.vue";
import ArgonTd from "@/components/table/ArgonTd.vue";
import { customersImportListStore } from "@/_store/customersImportsStore";
import moment from "moment";
import { argonTableStore } from "@/_store/argonTableStore";

const customersStore = customersImportListStore();
const tableStore = argonTableStore();
const listTable = ref([])
const props = defineProps({
  idFile: {
    type: String,
    default: "",
  },
});

const headers = [
  {
    header: "Creado",
    field: "created_at",
    sort: false,
  },
  {
    header: "Prioridad",
    field: "priority",
    sort: false,
    position: "center",
  },  
  {
    header: "Nombre",
    field: "name",
    sort: false,
  },
  {
    header: "Dirección",
    field: "address",
    sort: false,
    position: "start",
  },
];
onMounted(() => {
  reloadCustomerBatch();
});
function getPosition(field) {
  const column = headers.find((col) => col.field === field);
  return column && column.position ? column.position : "start";
}
function reloadCustomerBatch() {
  customersStore.getListBatchCustomers(props.idFile, tableStore.pagination);
}

function getPriority(priority) {
  if(priority===0) priority = 1;
  switch (priority) {
    case 3:
      return {icono:'fa fa-arrow-up text-xxs', tooltip:"Alta"};
    case 2:
      return {icono:'fa fa-arrow-right text-xxs', tooltip:"Media"};
    case 1:
      return {icono:'fa fa-arrow-down text-xxs', tooltip:"Baja"};
    default:
      return {icono:null, tooltip: null};
  }
}

watch(
  () => customersStore.batchesListCustomers,
  (newVal) => {
    if (newVal) {
      listTable.value = newVal;
    }
  }
);
</script>
<template>
  <simple-table v-if="listTable" :headers="headers" :items="listTable" @filter-changed="reloadCustomerBatch"
    @on-reload="reloadCustomerBatch" @page-changed="reloadCustomerBatch" :show-pagination="true">
    <template #default="{ item }">
      <argon-td :position="getPosition('created_at')" :text-primary="moment(item.created_at).format('DD/MM/YYYY')"
        :text-secondary="moment(item.created_at).fromNow()"></argon-td>
      <argon-td v-tooltip :data-bs-title="getPriority(item.priority).tooltip" :position="getPosition('priority')">
        <i :class="getPriority(item.priority).icono"></i>
      </argon-td>      
      <argon-td :text-secondary="item.name" :position="getPosition('name')" />
      <argon-td :position="getPosition('address')" :text-secondary="item.address" max-width="130px" />
    </template>
  </simple-table>
</template>