<script setup>
import { onMounted, watch } from "vue";
import Chart from "chart.js/auto";

const props = defineProps({
  id: {
    type: String,
    default: "doughnut-chart",
  },
  title: {
    type: String,
    default: "",
  },
  height: {
    type: String,
    default: "300",
  },
  chart: {
    type: Object,
    required: true,
    labels: Array,
    datasets: {
      type: Object,
      label: String,
      data: Array,
    },
  },
});

onMounted(() => {
  loadChart()
});

watch(
  () => props.chart,
  // eslint-disable-next-line no-unused-vars
  (value) => {
    loadChart()
  }
);


function loadChart () {
  let ctx = document.getElementById(props.id).getContext("2d");

  let chartStatus = Chart.getChart(props.id);
  if (chartStatus != undefined) {
    chartStatus.destroy();
  }

  new Chart(ctx, {
    type: "doughnut",
    data: {
      labels: props.chart.labels,
      datasets: props.chart.datasets
    },
    options: {
      responsive: true,
      maintainAspectRatio: false,
      plugins: {
        legend: {
          display: true,
        },
      },
      interaction: {
        intersect: false,
        mode: "index",
      },
      scales: {
        y: {
          display: false,
          title: {
            display: false,
          },
          grid: {
            drawBorder: true,
            display: false,
            drawOnChartArea: true,
            drawTicks: false,
            borderDash: [5, 5],
          },
        },
        x: {
          display: false,
          grid: {
            drawBorder: true,
            display: false,
            drawOnChartArea: false,
            drawTicks: false,
            borderDash: [5, 5],
          },
        },
      },
    },
  });
}
</script>
<template>
  <div class="card z-index-2">
    <div class="p-3 pb-0 card-header">
      <h6>{{ props.title }}</h6>
    </div>
    <div class="p-3 card-body">
      <div class="chart">
        <canvas
          :id="props.id"
          class="chart-canvas"
          :height="props.height"
        ></canvas>
      </div>
    </div>
  </div>
</template>
