<script setup>
import { onMounted, ref } from "vue";
import { useVuelidate } from "@vuelidate/core";
import { helpers } from "@vuelidate/validators";

import ArgonInput from "@/components/ArgonInput";

let model = ref({
  first_frequency: {
    initial: "",
    final: "",
  },
  second_frequency: {
    initial: "",
    final: "",
  },
});
let props = defineProps({
  modelValue: {
    type: Object,
    default: () => ({
      first_frequency: {
        initial: "",
        final: "",
      },
      second_frequency: {
        initial: "",
        final: "",
      },
    }),
  },
  inputsSize: {
    type: String,
    default: "default",
  },
  readonly: {
    type: Boolean,
    default: false,
  },
});

onMounted(() => {
  model.value = props.modelValue;
});

const checkFranjaIncompleta = (value) => {
  const inicioExist = value.initial != "";
  const finalExist = value.final != "";
  if (!inicioExist && !finalExist) return true;
  if (inicioExist && finalExist) return true;
  return false;
}; 
const checkHoraMayor = (value) => {
  let inicioInt = horaToInt(value.initial);
  let finalInt = horaToInt(value.final);
  if (inicioInt == 0 || finalInt == 0) return true;
  if (inicioInt >= finalInt) return false;
  return true;
};
const checkFranjaTarde = (value) => {
  let finalManianaInt = horaToInt(model.value.first_frequency.final);
  let inicioTardeInt = horaToInt(value.initial);
  if (inicioTardeInt == 0) return true;
  if (finalManianaInt >= inicioTardeInt) return false;
  return true;
};

function horaToInt(value) {
  if (value == "") return 0;
  if (!value.includes(":")) return 0;
  let hora = parseInt(value.split(":")[0]) * 3600;
  let minutos = parseInt(value.split(":")[1]) * 60;
  return hora + minutos;
}

const rules = {
  first_frequency: {
    checkFranjaIncompleta: helpers.withMessage(
      "Primera franja horaria incompleta",
      checkFranjaIncompleta
    ), 
    checkHoraMayor: helpers.withMessage(
      "Hora final debe ser mayor a hora inicio",
      checkHoraMayor
    ),
  },
  second_frequency: {
    checkFranjaIncompleta: helpers.withMessage(
      "Segunda franja horaria incompleta",
      checkFranjaIncompleta
    ),   
    checkHoraMayor: helpers.withMessage(
      "Hora final debe ser mayor a hora inicio",
      checkHoraMayor
    ),
    checkFranjaTarde: helpers.withMessage(
      "Segunda franja horaria debe ser mayor a la primera",
      checkFranjaTarde
    ),
  },
};
const v$ = ref(useVuelidate(rules, model));

async function validate() {
  await v$.value.$validate();
  return v$.value.$errors;
}

defineExpose({
  validate,
});
</script>
<template>
  <div role="form">
    <div class="row">
      <argon-input
        ref="initialFirstFrequency"
        v-model="model.first_frequency.initial"
        :size="inputsSize"
        type="time"
        class="col-lg-6 col-xl-3 col-md-6 col-sm-12"
        :error="v$.first_frequency.$error"
        :disabled="readonly"
      />
      <argon-input
        ref="finalFirstFrequency"
        v-model="model.first_frequency.final"
        :size="inputsSize"
        type="time"
        class="col-lg-6 col-xl-3 col-md-6 col-sm-12"
        :error="v$.first_frequency.$error"
        :disabled="readonly"
      />
      <argon-input
        ref="initialSecondFrequency"
        v-model="model.second_frequency.initial"
        :size="inputsSize"
        type="time"
        class="col-lg-6 col-xl-3 col-md-6 col-sm-12"
        :error="v$.second_frequency.$error"
        :disabled="readonly"
      />
      <argon-input
        ref="finalSecondFrequency"
        v-model="model.second_frequency.final"
        :size="inputsSize"
        type="time"
        class="col-lg-6 col-xl-3 col-md-6 col-sm-12"
        :error="v$.second_frequency.$error"
        :disabled="readonly"
      />
    </div>
  </div>
</template>
