<script setup>
import ArgonTable from "@/components/table/ArgonTable.vue";
import { onMounted, watch } from "vue";
import { stockListStore } from "@/_store/stockStore";
import { userStore } from "@/_store/userStore";
import { argonTableStore } from "@/_store/argonTableStore";
import { useRouter } from "vue-router";
import MarketplaceTag from "@/components/badges/MarketplaceTag.vue";
import ArgonAvatar from "@/components/ArgonAvatar.vue";
import NotFound from "@/assets/img/image.png";
import ArgonTd from "@/components/table/ArgonTd.vue";


const fieldFilters = [
    {
        field: "wahrehouse_name",
        description: "Almacén",
    },
    {
        field: "product_name",
        description: "Producto",
    },
    {
        field: "sku",
        description: "Sku",
    },
    {
        field: "ean",
        description: "Ean",
    },
];
const defaultFilters = [
{
    field: "units",
    label: "Unidades",
    multi: false,
    options: [{
        label: "Disponibles",
        value: {
            $gt: 0
        }
    },
    {
        label: "No disponibles",
        value: {
            $lte: 0
        }
    }
    ],
  },
];
const columns = [
    {
        header: "Almacén",
        field: "wahrehouse_name",
        sort: true,
        width: "w-15"
    },
    {
        header: "Producto",
        field: "product_name",
        sort: true,
        width: "w-15"
    },
    {
        header: "Sku",
        field: "sku",
        sort: true,
        width: "w-10"
    },
    {
        header: "Ean",
        field: "ean",
        sort: true,
        width: "w-10"
    },
    {
        header: "Unidades",
        field: "units",
        sort: false,
        position: "end"
    },
    {
        header: "Marketplace",
        field: "marketplace",
        position:"center",
        sort: false
    },
];
const stockStore = stockListStore();
const userStores = userStore();
const tableStore = argonTableStore();
const router = useRouter();
onMounted(() => {
    tableStore.clear();
    tableStore.clearPagination();
    if (userStores.filtersPage.stocks)
        tableStore.changeFilters(userStores.filtersPage.stocks);
    else tableStore.changeFilters([]);
    reloadPage();
});
function reloadPage() {
    userStores.filtersPage.stocks = tableStore.filters;
    stockStore.getAllStocks(
        tableStore.pagination,
        tableStore.query,
        tableStore.sort
    );
}
function detailStock(item) {
  router.push(`/marketplace/stock/${item._id}`);
}
function exportData(){
    stockStore.getStocksAsExcel(tableStore.query, tableStore.sort);
}
function makeExport(blob) {
  const URI = URL.createObjectURL(blob);
  window.open(URI, "_blank");
}
function getPosition(field) {
  const column = columns.find(col => col.field === field);
  return column && column.position ? column.position : 'start';
}
watch(
  () => stockStore.stockAsExcel,
  (newVal) => {
    if (newVal) {
      makeExport(newVal);
    }
  }
);
</script>
<template>
    <div class="card">
      <div class="card-body px-0 pt-0 pb-2 pt-2">
        <argon-table
          v-if="stockStore.stocks" 
          :columns="columns" 
          :field-filters="fieldFilters"
          :items="stockStore.stocks"
          @filter-changed="reloadPage"
          @page-changed="reloadPage"
          @on-reload="reloadPage"
          :showCheckInput="false"
          :show-new-button="false"
          :default-filters="defaultFilters"
          :show-importar="false"
          @on-click="detailStock"
          @sort-changed="reloadPage"
          @export-data="exportData">
          <template #default="{ item }">
            <argon-td :text-primary="item.wahrehouse_name" :position="getPosition('wahrehouse_name')" />
            <argon-td class="align-middle text-left text-sm" :position="getPosition('product_name')"> 
              <div class="d-flex align-items-center">
                <argon-avatar class="p-0 me-2" :image="item.image || NotFound" size="sm" shadow="sm" alt="Avatar" />
                <p class="text-xs font-weight-bold mb-0">{{ item.product_name }}</p>
              </div>
            </argon-td>
            <argon-td :text-primary="item.sku" :position="getPosition('sku')" />
            <argon-td :text-primary="item.ean" :position="getPosition('ean')" />
            <argon-td :text-primary="item.units" :position="getPosition('units')" />
            <argon-td :position="getPosition('marketplace')">
              <MarketplaceTag v-if="item.marketplace" class="ms-4 mb-2" :item="item.marketplace"></MarketplaceTag>
            </argon-td>
          </template>
        </argon-table>
      </div>
    </div>
  </template>