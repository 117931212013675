<script setup>

import { computed } from "vue";

import { dashboardStore } from "@/_store";
import MiniStatisticsCard from "../../../examples/Cards/MiniStatisticsCard.vue";
import { userStore } from "@/_store/userStore";

const store = dashboardStore();
const userStores = userStore();

function formatAlert(performance, diff, inLoss=false) {
  if (!inLoss) {
    if (diff == 1) {
      return `
        <div class="row">
          <div class="col-lg-9 float-left">
            <span class='font-weight-bolder'>${diff}</span> envíos desde el último mes
          </div>
          <div class="col-lg-3 text-end">
            <span class='text-sm font-weight-bolder text-primary'>+${performance}%</span>
          </div>
        </div>
      `
    }
    return `
      <div class="row">
        <div class="col-lg-9 float-left">
          <span class='font-weight-bolder'>${diff}</span> envíos desde el último mes
        </div>
        <div class="col-lg-3 text-end">
          <span class='text-sm font-weight-bolder text-primary'>+${performance}%</span>
        </div>
      </div>
    `
  }

  return `
    <div class="row">
      <div class="col-lg-9 float-left">
        <span class='font-weight-bolder'>${diff}</span> envíos desde el último mes
      </div>
      <div class="col-lg-3 text-end">
        <span class='text-sm font-weight-bolder text-danger'>${performance}%</span>
      </div>
    </div>
  `
}

function calculatePercentage(number1, number2) {
  if (number2 == null || number2 == undefined) return [100, 0]

  const diff = number1 - number2

  if (number2 == 0) return [(diff  * 100).toFixed(0), Math.abs(diff)]

  return [((diff / number2) * 100).toFixed(0), Math.abs(diff)]
}

const international = computed({
  // getter
  get() {
    const { international } = store.totals
    
    if (international == 0) return "0"
    return international ?? "0"
  },
})

const returns = computed({
  // getter
  get() {
    return "0"
  },
})

const national = computed({
  // getter
  get() {
    const { national } = store.totals
    if (national == 0) return "0"
    return national ?? "0"
  },
})

const marketplace = computed({
  // getter
  get() {
    const { marketplace } = store.totals
    if (marketplace == 0) return "0"
    return marketplace ?? "0"
  },
})

const internationalPerformance = computed({
  // getter
  get() {
    const { international, international_last } = store.totals

    if (!Number.isInteger(international) || !Number.isInteger(international_last)) {
      return formatAlert(0, 0)
    }

    const [performance, diff] = calculatePercentage(international, international_last)
  
    if (performance >= 0) {
      return formatAlert(performance, diff)
    }

    return formatAlert(performance, diff, true)
  },
})

const returnsPerformance = computed({
  // getter
  get() {
    return "0"
  },
})

const nationalPerformance = computed({
  // getter
  get() {
    const { national, national_last } = store.totals

    if (!Number.isInteger(national) || !Number.isInteger(national_last)) {
      return formatAlert(0, 0)
    }

    const [performance, diff] = calculatePercentage(national, national_last)
  
    if (performance >= 0) {
      return formatAlert(performance, diff)
    }

    return formatAlert(performance, diff, true)
  },
})

const marketplacePerformance = computed({
  // getter
  get() {
    const { marketplace, marketplace_last } = store.totals

    if (!Number.isInteger(marketplace) || !Number.isInteger(marketplace_last)) {
      return formatAlert(0, 0)
    }

    const [performance, diff] = calculatePercentage(marketplace, marketplace_last)
  
    if (performance >= 0) {
      return formatAlert(performance, diff)
    }

    return formatAlert(performance, diff, true)
  },
})

/* const isAgency = computed(() => userStores.userSigned?.portalType==='AGENCY'); */

const cardClass = computed({
  get() {
    if (userStores.userSigned?.portalType === 'AGENCY') return 'col-lg-4 col-md-6 col-12'

    return 'col-lg-4 col-md-6 col-12'
  }
});

</script>
<template>
    <div :class="cardClass">
      <mini-statistics-card
        title="Envíos internacionales"
        :value="international"
        :description="internationalPerformance"
        :icon="{
          component: 'ni ni-world',
          background: 'card-counter-icon',
          shape: 'rounded-circle',
        }"
      />
    </div>
    <div v-if="false" class="col-lg-3 col-md-6 col-12">
      <mini-statistics-card
        title="Devoluciones"
        :value="returns"
        :description="`<span
          class='text-sm font-weight-bolder text-success'
          >${returnsPerformance}</span> desde el último mes`"
        :icon="{
          component: 'ni ni-world',
          background: 'bg-gradient-danger',
          shape: 'rounded-circle',
        }"
      />
    </div>
    <div :class="cardClass">
      <mini-statistics-card
        title="Envíos nacionales"
        :value="national"
        :description="nationalPerformance"
        :icon="{
          component: 'fas fa-home',
          background: 'card-counter-home-icon',
          shape: 'rounded-circle',
        }"
      />
    </div>
    <div :class="cardClass">
      <mini-statistics-card
        title="Envíos marketplaces"
        :value="marketplace"
        :description="marketplacePerformance"
        :icon="{
          component: 'ni ni-cart',
          background: 'card-counter-icon',
          shape: 'rounded-circle',
        }"
      />
    </div>
    <!-- <div v-if="isAgency" class="col-lg-3 col-md-6 col-12">
      <ShipmentPerformance />
    </div> -->
</template>
