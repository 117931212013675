import { defineStore } from "pinia";
import { stopService } from "@/_services";

export const stopDetailStore = defineStore("stopDetail", {
  state: () => ({
    error: null,
    stopDetail: null,
    stopStatusChange: null
  }),
  actions: {
    getStopById(stopId) {
      this.stopDetail= null;
      stopService
        .getStopById(stopId)
        .then((results) => (this.stopDetail = results))
        .catch((reason) => {
          this.stopDetail = null
          this.error = reason
        });
    },
    changeStatusStop(stopId, contract){
      this.stopStatusChange= null;
      stopService
        .updateStopStatus(stopId,contract)
        .then((results) => (this.stopStatusChange = results))
        .catch((reason) => {
          this.stopStatusChange = null
          this.error = reason
        });
    }
  },
});
