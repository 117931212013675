import createAxios from "./axiosHttp";

function sendNotifications(body) {
  const axios = createAxios();
  const user = JSON.parse(localStorage.getItem('user'));
  let urlPath = `account/${user.extraData.accountId}/notification`;
  return axios.put(urlPath, body);
}

export const notificacionService = {
  sendNotifications
}