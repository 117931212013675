import { createAxios } from './axiosHttp';
import { userService } from './user.service';

export const accountService = {
  getAccount,
  loadAccount,
  reloadAccount,
  updateAccount,
  getAccountsById
};

function getAccountsById() {
  const axios = createAxios();
  const user = JSON.parse(localStorage.getItem('user'));
  let urlPath = `/account/${user.extraData.accountId}`;
  return axios.get(urlPath);
}

function getAccount() {
  return new Promise((resolve, reject) => {
    const axios = createAxios();
    const user = JSON.parse(localStorage.getItem('user'));
    if (user && userService.esAccountAdmin(user)) {
      let urlPath = `account/${user.extraData.accountId}`;
      return axios.get(urlPath)
        .then((response) => { resolve(response); })
        .catch((reason) => { reject(reason) });
    } else {
      reject(new Error("Usuario no logado"))
    }
  });
}

function loadAccount() {
  return new Promise((resolve, reject) => {
    getAccount().then((account) => {
      resolve(account);
    }).catch((error) => {
      reject(error);
    });
  });
}

function reloadAccount() {
  setInterval(() => { loadAccount(); }, 3600000);
}

function updateAccount(body) {
  const axios = createAxios();
  let urlPath = `/account/${body._id}`;
  return axios.put(urlPath, body);
}
