import createAxios from "./axiosHttp";

function getAllStocks(pagination, query, sort) {
    const axios = createAxios();
    let urlPath = `marketplace/stock/ql`;
    const ql = {
      query,
      pagination,
      sort,
    };
    return axios.post(urlPath, ql);
}

function getStockById(idStock) {
  const axios = createAxios();
  let urlPath = `marketplace/stock/${idStock}`;
  return axios.get(urlPath);
}

function getStocksAsExcel(query, sort) {
  const axios = createAxios();
  let urlPath = `marketplace/stock/report/ql`;
  const ql = {
    query,
    sort,
  };
  return axios
    .post(urlPath, ql, {
      responseType: "blob",
    })
    .catch(async (error) => {
      const data = await error.text();
      throw JSON.parse(data);
    });
}

export const stockService = {
    getAllStocks,
    getStockById,
    getStocksAsExcel
}