<script setup>
import { onMounted, watch, ref } from "vue";

import ArgonSnackbar from "@/components/ArgonSnackbar.vue";
import ArgonDetail from "@/components/detail/ArgonDetailLayout.vue";
import ArgonInput from "@/components/ArgonInput.vue";

import DetailBatch from "@/components/DetailBatch.vue";
import StatusBadge from "@/components/badges/BatchFileStatus.vue";
import { customerBatchDetailStore } from "@/_store/customerBatchDetailStore";
import { useRoute } from "vue-router";
import CustomerImportList from "./CustomerImportList.vue";

const snackbar = ref(null);
const closeSnackbar = () => {
  snackbar.value = null;
};
const route = useRoute();
const customerBatchStore = customerBatchDetailStore();
var data = ref(null);

const actions = [[{ label: "Descargar", event: "onDownload" }]];
const events = {
  onDownload: getDownloadFile,
};

onMounted(() => {
  getBatchCustomer();
});

function triggerEvent(action) {
  try {
    events[action.event]();
  } catch {
    console.log("Evento no implementado");
  }
}

function getBatchCustomer() {
  customerBatchStore.getCustomer(route.params.idFile);
}
function getDownloadFile() {
  customerBatchStore.downloadFile(route.params.idFile);
}

function downloadFile(blob) {
  const fileName = data.value.filename;
  const link = document.createElement("a");
  link.setAttribute("download", fileName);
  link.href = URL.createObjectURL(blob);
  link.click();
  URL.revokeObjectURL(link.href);
}

watch(
  () => customerBatchStore.customerDetail,
  (value) => {
    data.value = value;
  }
);
watch(
  () => customerBatchStore.error,
  (value) => {
    if (value) {
      snackbar.value = {
        title: "Error",
        message: value.message,
        type: "danger",
        color: "white",
      };
    }
  }
);
watch(
  () => customerBatchStore.fileDownload,
  (newVal) => {
    if (newVal) {
      downloadFile(newVal);
    }
  }
);
</script>

<template>
  <div class="card">
    <div class="card-body">
      <argon-detail v-if="data" :group-actions="actions" @click="triggerEvent">
        <template #title>
          <argon-input
            id="name"
            v-model="data.filename"
            type="text"
            placeholder="Nombre"
            size="lg"
            font-style="h4 fw-bold"
            :disabled="true"
          />
        </template>
        <template #body>
          <div class="row spacing">
            <div class="col-12">
              <detail-batch v-if="data" :info="data" />
            </div>
          </div>
          <div class="row spacing">
            <div class="col-12">
              <div class="card">                
                <div class="card-body">
                  <customer-import-list :id-file="route.params.idFile" />
                </div>
              </div>
            </div>
          </div>
        </template>
        <template #state>
          <status-badge
            :tooltip-message="data.error"
            :item="data.status"
          ></status-badge>
        </template>
        <template #tabs> </template>
      </argon-detail>
    </div>
  </div>
  <div class="position-fixed top-1 end-1 z-index-2">
    <argon-snackbar
      v-if="snackbar"
      :title="snackbar.title"
      :description="snackbar.message"
      :icon="{ component: 'ni ni-notification-70', color: snackbar.type }"
      :color="snackbar.color"
      :close-handler="closeSnackbar"
    />
  </div>
</template>
