import { createAxios } from "./axiosHttp";

function getValuation(id) {
  const axios = createAxios();
  let urlPath = `pricing/valuation/${id}`;
  return axios.get(urlPath);
}

function getAllValuations(pagination, query, sort) {
  const axios = createAxios();
  let urlPath = `pricing/valuation/ql`;
  const ql = {
    query,
    pagination,
    sort,
  };
  return axios.post(urlPath, ql);
}

function getValuationAsExcel(query, sort) {
  const axios = createAxios();
  let urlPath = `/pricing/valuation/report/ql`;
  const ql = {
    query,
    sort,
  };
  return axios
    .post(urlPath, ql, {
      responseType: "blob",
    })
    .catch(async (error) => {
      const data = await error.text();
      throw JSON.parse(data);
    });
}

function calculateValuation(body) {
  const axios = createAxios();
  let urlPath = `pricing/valuation/simulation`;
  return axios.post(urlPath, body);
}

function reevaluate(body) {
  const axios = createAxios();
  let urlPath = `pricing/valuation`;
  return axios.put(urlPath, body);
}

export const valuationService = {
  getValuation,
  getAllValuations,
  getValuationAsExcel,
  calculateValuation,
  reevaluate
};
