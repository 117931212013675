<script setup>
import { computed } from "vue";
import ArgonAvatar from "@/components/ArgonAvatar.vue";
import NotFound from "@/assets/img/image.png";

const emits = defineEmits(["addProduct", "addAllProduct", "removeProduct"]);
const props = defineProps({
  product: {
    type: Object,
    required: true,
  },
  showButtons: {
    type: Boolean,
    default: false,
  },
  inOrder: {
    type: Boolean,
    default: false,
  },
});

const textCantidad = computed(() =>
  !props.inOrder
    ? "Disponibles: " + props.product.quantity_pending
    : "En preparación: " + props.product.quantity
);
</script>

<template>
  <div class="d-flex flex-column align-items-center w-100 overflow-hidden">
    <div class="w-100">
      <label
        v-tooltip
        :data-bs-title="product.product_name"
        class="product-name text-size-xs text-dark"
      >
        {{ product.product_name }}
      </label>
    </div>
    <div class="d-flex flex-row justify-content-between w-100 mb-2">
      <argon-avatar
        class="me-2 ms-1"
        :image="product.image || NotFound"
        size="sm"
        shadow="sm"
        alt="Avatar"
      />
      <div class="d-flex flex-column flex-grow-1">
        <div class="container-span d-flex justify-content-between">
          <div class="ean-container">
            <span
              v-tooltip
              :data-bs-title="product.variant_ean"
              class="text-size-xs text-truncate text-bold ean-size"
            >
              {{product.variant_ean}}
            </span>
          </div>
          <button
            v-if="showButtons"
            v-tooltip
            data-bs-title="Preparar todo"
            class="btn btn-link btn-icon-only btn-sm text-dark p-0 pb-2 mb-0"
            :disabled="product.quantity_pending == 0"
            @click="emits('addAllProduct',product)"
          >
            <i class="fa fa-archive text-xs" aria-hidden="true"></i>
          </button>
          <button
            v-if="!showButtons && inOrder"
            v-tooltip
            data-bs-title="Deshacer todo"
            class="btn btn-link btn-icon-only btn-sm text-dark p-0 pb-2 mb-0"
            @click="emits('removeAllProducts', product)"
          >
            <i class="fa fa-trash text-xs" aria-hidden="true"></i>
          </button>
        </div>
        <div
          class="container-span d-flex justify-content-between align-items-center"
        >
          <span class="text-size-xs">{{ textCantidad }}</span>
          <button
            v-if="showButtons"
            v-tooltip
            data-bs-title="Preparar una unidad"
            class="btn btn-link btn-icon-only btn-sm text-dark p-0 mb-0"
            :disabled="product.quantity_pending == 0"
            @click="emits('addProduct', product)"
          >
            <i class="fa fa-plus-circle text-xs" aria-hidden="true"></i>
          </button>
          <button
            v-if="!showButtons && inOrder"
            v-tooltip
            data-bs-title="Deshacer una unidad"
            class="btn btn-link btn-icon-only btn-sm text-dark p-0 mb-0"
            @click="emits('removeProduct', product)"
          >
            <i class="fa fa-circle-minus text-xs" aria-hidden="true"></i>
          </button>
        </div>
      </div>
    </div>
  </div>
</template>

<style lang="scss" scoped>
.card-detail-layout .text-size-xs {
  font-size: 0.75rem !important;
}
.product-name {
  max-width: 172px;
  display: inline-block;
}
.product-name,
.ean-size {
  white-space: nowrap;
  overflow: hidden;
  text-overflow: ellipsis;
  display: inline-block;
}
.btn-placeholder {
  display: inline-block;
  width: 1.5rem;
  height: 1.5rem;
}
.container-span {
  height: 20px;
}
.ean-container {
  max-width: 100px;
  overflow: hidden;
  white-space: nowrap;
  text-overflow: ellipsis;
  flex-shrink: 1;
}
</style>
