<script setup>
import TimelineList from "@/components/tracking/ArgonTracking.vue";
import TimelineItem from "@/components/tracking/ArgonTrackingItem.vue";
import { onMounted } from "vue";
import { getStatusLabel } from "@/maestros/stopStatus";
import { dateUtils } from "@/utils";
import { stopListStore } from "@/_store";


const stopStore = stopListStore();

const props = defineProps({
  idStop: {
    type: String,
    default: "",
  }
});
onMounted(() => {
  if (props.idStop) stopStore.getStopTracking(props.idStop);
});

function getIcon(alert) {
  if (alert) return { component: "fa fa-truck", color: "danger" };
  else return { component: "fa fa-truck", color: "success" };
}
</script>
<template>
  <div class="p-1">
    <div class="timeline-container" v-if="stopStore.stopTracking">
      <timeline-list>
        <timeline-item
          v-for="(item, key) in stopStore.stopTracking.data"
          :key="key"
          :icon="getIcon(item.alert)"
          :title="getStatusLabel(item.status) + ' - ' + item.description"
          :date-time="dateUtils.formatDate(item.date)"
        />
      </timeline-list>
    </div>
  </div>
</template>

<style scoped>
.timeline-container {
  max-height: 300px;
  overflow-y: auto;
}
</style>
