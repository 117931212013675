import createAxios from "./axiosHttp";

function getAllProducts(pagination, query, sort) {
    const axios = createAxios();
    let urlPath = `marketplace/product/ql`;
    const ql = {
      query,
      pagination,
      sort,
    };
    return axios.post(urlPath, ql);
}

function getProductById(idProduct) {
  const axios = createAxios();
  let urlPath = `marketplace/product/${idProduct}`;
  return axios.get(urlPath);
}

function getProductsAsExcel(query, sort) {
  const axios = createAxios();
  let urlPath = `marketplace/product/report/ql`;
  const ql = {
    query,
    sort,
  };
  return axios
    .post(urlPath, ql, {
      responseType: "blob",
    })
    .catch(async (error) => {
      const data = await error.text();
      throw JSON.parse(data);
    });
}

export const productsService = {
  getAllProducts,
  getProductById,
  getProductsAsExcel
}