import { defineStore } from "pinia";
import { logoService } from "@/_services";
export const logoListStore = defineStore("logoStore", {
  state: () => ({
    sendLogo: null,
    updateLogo: null,
    logo: null,
  }),
  actions: {
    sendLogoBinario(data) {
      let formData = new FormData();
      const promises = [];
      const emptyBlob = new Blob([], { type: "image/png" });
      formData.append("logo", emptyBlob);
      formData.append("icon",emptyBlob);
      if (data.icon){
        promises.push(this.base64ToBlob(data.icon).then(blob => {
          formData.set('icon', blob);
          })
        );
      }
      if (data.logo){
        promises.push(this.base64ToBlob(data.logo).then(blob => {
          formData.set('logo', blob);
          })
        );
      }
      Promise.all(promises).then(()=>{
        this.sendLogo= null
        logoService
          .sendLogo(formData)
          .then((result) => (this.sendLogo = result))
          .catch((reason) => (this.error = reason));
        });
    },
    updateLogoBinario(data, idLogo) {
      let formData = new FormData();
      const promises = [];
      const emptyBlob = new Blob([], { type: "image/png" });
      formData.append("logo", emptyBlob);
      formData.append("icon",emptyBlob);
      if (data.logo) {
        promises.push(this.base64ToBlob(data.logo).then(blob => {
          formData.set('logo', blob);
          })
        );
      }
      if(data.icon){
        promises.push(this.base64ToBlob(data.icon).then(blob => {
          formData.set('icon', blob);
          })
        );
      }
      Promise.all(promises).then(()=>{
        this.updateLogo= null
        logoService
          .updateLogo(formData, idLogo)
          .then((result) => (this.updateLogo = result))
          .catch((reason) => (this.error = reason));
      });
    },
    cleanLogo() {
      this.logo = null;
    },
    getLogo() {
      logoService
        .getLogo()
        .then((result) => (this.logo = result))
        .catch((reason) => (this.error = reason));
    },
    base64ToBlob(base64String) {
      return fetch(base64String)
        .then(response => {
          if (!response.ok) {
            throw new Error('Error al convertir Base64 a Blob');
          }
          return response.blob();
        })
        .catch(error => {
          console.error('Error en la conversión:', error);
          return null;
        });
    }
  },
});
