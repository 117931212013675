import { defineStore } from "pinia";
import { trackingService } from "@/_services";

export const trackingStore = defineStore("tracking", {
  state: () => ({ tracking: null, error: null }),
  actions: {
    getTracking(locator) {
      trackingService
        .getTracking(locator)
        .then((result) => (this.tracking = result))
        .catch((reason) => (this.error = reason));
    },
  },
});
