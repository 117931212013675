import { defineStore } from "pinia";
import { issueService } from "@/_services";

export const issueDetailStore = defineStore("issueDetail", {
  state: () => ({
    error: null,
    issue: null,
    deletedId: null,
    updatedIssue: null,
    isNew: null,
    createdIssue: null,
    chats:null,
    newChat: null,
    chatSolution: null
  }),
  actions: {
    getIssueById(idIssue) {
      issueService
        .getIssueById(idIssue)
        .then((results) => (this.issue = results))
        .catch((reason) => (this.error = reason));
    },
    updateIssue(id, body) {
      issueService
        .updateIssue(id, body)
        .then((result) => (this.updatedIssue = result))
        .catch((reason) => (this.error = reason));
    },
    insertIssue(body) {
      issueService
        .insertIssue(body)
        .then((results) => (this.createdIssue = results))
        .catch((reason) => (this.error = reason));
    },
    getChats(id){
      issueService
        .getChats(id)
        .then((results) => (this.chats = results))
        .catch((reason) => (this.error = reason));
    },
    sendChat(body) {
      issueService
        .sendChat(body)
        .then((result) => (this.newChat = result))
        .catch((reason) => (this.error = reason));
    },
    sendChatSolution(body) {
      issueService
        .sendChatSolution(body)
        .then((result) => (this.chatSolution = result))
        .catch((reason) => (this.error = reason));
    },
    deleteChat(idIssue,idChat) {
      issueService
        .deleteChat(idIssue,idChat)
        .then((result) => (this.deletedId = result))
        .catch((reason) => (this.error = reason));
    },
  },
});
