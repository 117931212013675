import { defineStore } from 'pinia';
import { stockService } from '@/_services';


export const stockListStore = defineStore('stocksList', {
  state: () => ({ stocks: null, stockById: null, stockAsExcel: null }),
  actions: {
    getAllStocks(pagination, filters, sort) {
        stockService.getAllStocks(pagination, filters, sort)
        .then((results) => this.stocks = results)
        .catch((reason) => this.error = reason);
    },
    getStockById(stockId) {
      stockService.getStockById(stockId)
      .then((results) => this.stockById = results)
      .catch((reason) => this.error = reason);
  },
  getStocksAsExcel(query, sort) {
    stockService.getStocksAsExcel(query, sort)
    .then((results) => this.stockAsExcel = results)
    .catch((reason) => this.error = reason);
},
  },
})
