<script setup>
import { computed, onMounted, onBeforeUnmount, watch, ref} from 'vue';
import { useStore } from "vuex";

import Sidenav from "./layout/Sidenav";
import Navbar from "@/layout/Navbars/Navbar.vue";
import AppFooter from "@/layout/Footer.vue";
import LoadingPage from './components/LoadingPage.vue';import { useUserStore } from "@/_store/userStore.js";

const userStore = useUserStore();
const store = useStore();
const layout = computed(() => store.state.layout);
const showSidenav = computed(() => store.state.showSidenav);
const showNavbar = computed(() => store.state.showNavbar);
const showFooter = computed(() => store.state.showFooter);
//const hasReloaded = computed(() => sessionStorage.getItem('hasReloaded'));
const loading = ref(true);

onMounted(() => {
  window.addEventListener("load", pageLoaded);
});
function pageLoaded() {
  setTimeout(() => {
    loading.value = false;
  }, 500);
}
onBeforeUnmount(() => {
  loading.value = false;
  window.removeEventListener("load", pageLoaded);
})
watch(
  () => userStore.eventLogin,
  (value) => {
    if(value){
      loading.value=true;
    }
  }
);
</script>
<template>
  <loading-page v-if="loading" />
    <div v-show="layout === 'landing'" class="landing-bg h-100 bg-gradient-primary position-fixed w-100"></div>
    <sidenav v-if="showSidenav" />
    <main class="main-content position-relative max-height-vh-100 h-100">
      <navbar v-if="showNavbar" />
      <router-view />
      <app-footer v-show="showFooter" />
    </main>
</template>
