<script setup>
import { onBeforeMount, onBeforeUnmount, ref, watch } from "vue";
import { useRouter } from "vue-router";
import { useStore } from "vuex";

import { useUserStore } from "@/_store/userStore.js";
//const swal = inject("$swal");
const userStore = useUserStore();
const router = useRouter();

// import Navbar from "@/examples/PageLayout/Navbar.vue";
import ArgonInput from "@/components/ArgonInput.vue";
import { ArgonLoadingButton } from "@/components";
import ArgonSnackbar from "@/components/ArgonSnackbar.vue";


import { useVuelidate } from "@vuelidate/core";
import { required, email, helpers } from "@vuelidate/validators";

const body = document.getElementsByTagName("body")[0];

const store = useStore();
const toggleDefaultLayout = () => store.commit("toggleDefaultLayout");

const rules = {
  user: { required: helpers.withMessage("Email es requerido", required), email: helpers.withMessage("Ingrese un email valido", email) },
};

const data = ref({
  user: "",
})
const v$ = ref(useVuelidate(rules, data));
const loading = ref(false);
onBeforeMount(() => {
  store.state.hideConfigButton = true;
  toggleDefaultLayout();
  body.classList.remove("bg-gray-100");
});
onBeforeUnmount(() => {
  store.state.hideConfigButton = false;
  toggleDefaultLayout();
  body.classList.add("bg-gray-100");
});

function submit() {
  const resValidation = validate()
  resValidation.then((values) => {
    let message = "";
    for (const value of values) {
      message = `${message}<li>${value.$message}</li>`;
    }
    if (message != "") {
      snackbar.value = {
        title: "Error",
        message: `<ul>${message}</ul>`,
        type: "danger",
        color: "white",
      };
    } else {
      loading.value = true;
      userStore.getRecoverLink(data.value);
    }
  });
}
watch(
  () => userStore.user,
  (value) => {
    if (value) {
      loading.value = false;
      router.push({ name: "/" });
    }
  }
);
watch(
  () => userStore.linkRecover,
  (value) => {
    if (value) {
      setTimeout(() => {
      loading.value = false;
      }, 2000);
    }
  }
);
watch(
  () => userStore.error,
  (value) => {
    if (value) {
      snackbar.value = {
        title: "Error",
        message: value.message,
        type: "danger",
        color: "white",
      };
    }
  }
);
async function validate() {
  await v$.value.$validate();
  return v$.value.$errors;
}
const snackbar = ref(null);
const closeSnackbar = () => {
  snackbar.value = null;
};
</script>
<template>
  <main class="mt-0 main-content card-detail-layout">
    <section>
      <div class="page-header min-vh-100" :style="{
        backgroundImage: 'url(' + '/img/logos/public-back-ground.png' + ')',
        backgroundSize: 'cover',
        backgroundPosition: 'center',
      }">
        <span class="mask opacity-3"></span>
        <div class="container text-center">
          <div class="row justify-content-md-center mb-6">
            <div class="w-50">
              <img class="img-fluid" src="/img/logos/BeePaketLogo.png" alt="logo" />
            </div>
          </div>
          <div class="row justify-content-md-center">
            <div class="mx-auto col-xl-4 col-lg-5 col-md-7 d-flex flex-column mx-lg-0">
              <div class="card">
                <div class="card-header text-center">
                  <h3 class="font-weight-bolder">Restablecer Contraseña</h3>
                  <p class="mb-0">
                    Introduzca su correo de usuario, le enviaremos un email con instrucciones sobre cómo restablecer su
                    contraseña.
                  </p>
                </div>
                <div class="card-body">
                  <form role="form" @submit.prevent="submit">
                    <div class="mb-3">
                      <argon-input id="email" v-model="data.user" :max-length="75" type="text" placeholder="Email"
                        name="email" size="lg" :error="v$.user.$error" />
                    </div>
                    <div class="text-center">
                      <argon-loading-button class="mt-4 mb-4" color="default" variant="gradient"
            :label="loading ? 'Enviando...' : 'Enviar'" :loading="loading" full-width size="lg"></argon-loading-button>
                      <!-- <argon-button class="mt-4 mb-4" variant="gradient" color="default" full-width
                        size="lg">Enviar</argon-button> -->
                    </div>
                  </form>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
      <!-- <div class="page-header min-vh-100">
        <div class="container">
          <div class="row">
            <div
              class="mx-auto col-xl-4 col-lg-5 col-md-7 d-flex flex-column mx-lg-0"
            >
              <div class="card card-plain">
                <div class="pb-0 card-header text-start">
                  <h3 class="font-weight-bolder">Restablecer Contraseña</h3>
                  <p class="mb-0">
                    Introduzca su correo de usuario, le enviaremos un email con instrucciones sobre cómo restablecer su contraseña.
                  </p>
                </div>
                <div class="card-body">
                  <form role="form" @submit.prevent="submit">
                    <div class="mb-3">
                      <argon-input
                        id="email"
                        v-model="data.user"
                        :max-length="75"
                        type="text"
                        placeholder="Email"
                        name="email"
                        size="lg"
                        :error="v$.user.$error"
                      />
                    </div>
                    <div class="text-center">
                      <argon-button
                        class="mt-4"
                        variant="gradient"
                        color="primary"
                        full-width
                        size="lg"
                        >Enviar</argon-button
                      >
                    </div>
                  </form>
                </div>
              </div>
            </div>
            <div
              class="col-6 d-lg-flex d-none h-100 my-auto pe-0 position-absolute top-0 end-0 text-center justify-content-center flex-column"
            >
              <div
                class="position-relative h-100 m-3 px-7 border-radius-lg d-flex flex-column justify-content-center overflow-hidden"
                :style="{
                  backgroundImage: 'url(' + '/img/logos/back-ground.png' + ')',
                  backgroundSize: 'cover',
                  backgroundPosition: 'center',
                }"
              >
                <span class="mask bg-gradient-primary opacity-5"></span>

                <h2
                  class="mt-5 text-white font-weight-bolder position-relative"
                >
                <img
                    class="w-10 me-3 mb-0"
                    src="../../../assets/img/logos/logo-192.png"
                    alt="logo"
                  />
                  BeePaket
                </h2>
              </div>
            </div>
          </div>
        </div>
      </div> -->
    </section>
  </main>
  <div class="position-fixed top-1 end-1 z-index-2">
    <argon-snackbar v-if="snackbar" :title="snackbar.title" :description="snackbar.message"
      :icon="{ component: 'ni ni-notification-70', color: snackbar.type }" :color="snackbar.color"
      :close-handler="closeSnackbar" />
  </div>
</template>
<style scoped>
.mask {
  background-color: #ced4da !important;
}
</style>