<script setup>
import { onMounted, ref, watch } from "vue";
import ArgonDetail from "@/components/detail/ArgonDetailLayout.vue";
import DetailStock from "@/components/DetailStock.vue";
import { stockListStore } from "@/_store/stockStore";
import { useRoute } from "vue-router";

const stockStore = stockListStore();
const route = useRoute();
const data = ref(null);
onMounted(() => {
    stockStore.getStockById(route.params.id);
});

watch(
  () => stockStore.stockById,
  (value) => {
    data.value = value;
  }
);

</script>
<template>
    <div class="card ms-4 me-4">
        <div class="card-body px-0 pt-0 pb-3">
            <argon-detail v-if="data">
                <template #body>
                    <detail-stock class="mt-2" v-if="data" :info="data" />
                </template>
            </argon-detail>
        </div>
    </div>
</template>