import createAxios from "./axiosHttp";

function getLabelFromMac(mac){
  const axios = createAxios();
  
  let urlPath = `/shipment/${mac}/label`;
  return axios
    .get(urlPath, { responseType: "blob" })
    .catch(async (error) => {
      const data = await error.text();
      throw JSON.parse(data);
    });
}

export const expediteService = {
  getLabelFromMac
};
