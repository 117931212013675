<script setup>
import ArgonDetailToolbar from "./ArgonDetailToolbar.vue";
import { onMounted, computed,useSlots } from "vue";
const emit = defineEmits(["click"]);
const slots = useSlots();
defineProps({
  groupActions: {
    type: Array,
    default() {
      return [];
    },
  },
  showConfig: {
    type: Boolean,
    default: false
  }
});

function click(value) {
  emit("click", value);
}

onMounted(() => {});

const hasButtonsSlot = computed(() => {
  return !!slots.buttons;
})
const hasTitleOrStateSlot = computed(() => {
  return !!slots.title || !!slots.state;
});
</script>
<template>
  <div class="p-0 card-detail-layout">
    <div v-if="hasButtonsSlot || groupActions.length>0">
      <argon-detail-toolbar :group-actions="groupActions" @click="click" :show-config="showConfig">
        <template #buttons>
          <slot name="buttons"></slot>
        </template>
        <template #buttons-right>
          <slot name="buttons-right"></slot>
        </template>
      </argon-detail-toolbar>
    </div>
    <div>
      <div v-if="hasTitleOrStateSlot" class="row mt-2">
        <div class="col-xl-9 col-lg-8 col-md-8 col-sm-12">
          <slot name="title"></slot>
        </div>
        <div
          class="col-xl-3 col-lg-4 col-md-4 col-sm-12 d-flex justify-content-end align-items-start"
        >
        <div class="state">
          <slot name="state"></slot>
        </div>
        </div>
      </div>
      <slot name="body"></slot>
      <slot name="tabs"></slot>
    </div>
  </div>
</template>
<style>

</style>
