import { createApp } from "vue";
import { createPinia } from "pinia";
import moment from 'moment';

import App from "./App.vue";
import store from "./store";
import router from "./router";
import "./assets/css/nucleo-icons.css";
import "./assets/css/nucleo-svg.css";
import VueTilt from "vue-tilt.js";
import VueSweetalert2 from "vue-sweetalert2";
import { userStore } from "./_store/userStore";
import setTooltip from './assets/js/tooltip';
import {globalMixin} from './assets/js/global-functions';
import clickOutside from './assets/js/click-outside';

//Creacion estatica
//Creacion estatica
moment.locale('es');

const appInstance = createApp(App);
const pinia = createPinia();
appInstance.directive("click-outside", clickOutside);
appInstance.directive('tooltip', setTooltip);

appInstance.mixin(globalMixin);
appInstance.use(store);
appInstance.use(pinia);
appInstance.use(router);
appInstance.use(VueTilt);
appInstance.use(VueSweetalert2);
//appInstance.use(StyleDashboard);
appInstance.mount("#app");
//Creacion Dinamica
const useUser = userStore();

let styleDashboard;
if (useUser.isAuthenticated) {
  if (useUser.user.portalType === "AGENCY") {
    styleDashboard = import("./argon-dashboard-agency");
  } else {
    styleDashboard = import("./argon-dashboard");
  }
} else {
  styleDashboard = import("./argon-dashboard");
}
appInstance.use(styleDashboard);


/* watch(
  () => useUser.user,
  (newUser) => {
    loadStyleDashboard(newUser);
  }
); */