<script setup>
import { computed } from "vue";
import DropZone from "./dropzone/DropZone.vue";
import { ArgonLoadingButton } from "@/components";
import FileView from "./dropzone/FileView.vue";
import { dropzoneStore } from "./dropzone/dropzoneStore";
//import Choices from "choices.js";
import ArgonSelect from "./ArgonSelect.vue"
import CustomerAutocomplete from "@/components/forms/CustomerAutoComplete.vue";
import { userStore } from "@/_store/userStore";
import ArgonSnackbar from "@/components/ArgonSnackbar.vue";
import ModalBase from "./modal/ModalBase.vue";
const userStores = userStore();
import { onMounted, watch, ref } from "vue";
const importModal = ref(null);
const dropStore = dropzoneStore();
const customer = ref(null);
const isAgency = computed(() => userStores.userSigned?.portalType === "AGENCY");

function onInputChange(e) {
  dropStore.addFiles(e.target.files);
  e.target.value = null; // reset so that selecting the same file again will still cause it to fire this change
}
let customerModel = ref({
  customer_id: "",
  customer: {
    contact: {
      name: "",
    },
  },
});
const props = defineProps({
  title: {
    type: String,
    default: "Importar Fichero",
  },
  options: {
    type: Array,
    default() {
      return [];
    },
  },
  url: {
    type: String,
    default: "/",
  },
});

var fileType = ref("");

onMounted(() => {
  dropStore.clearFiles();
  var selected = props.options.find((option) => option.selected);
  if (selected) {
    fileType.value = selected.value;
  }
  /* if (document.getElementById("file-format")) {
    var element = document.getElementById("file-format");
    const fileFormatChoices = new Choices(element, {
      searchEnabled: false,
      allowHTML: true,
      itemSelectText: "Click para seleccionar",
    });
    var selected = props.options.find((option) => option.selected);
    if (selected) {
      fileType = selected.value;
    }
    fileFormatChoices.setChoices(props.options, "value", "label", false);
  } */
});
function resetModalState() {
  dropStore.clearFiles();
  let formData =document.getElementById("formData");
  const invalidElements = formData?.querySelectorAll(".is-invalid");
  invalidElements.forEach((element) => {
    element.classList.remove("is-invalid");
  });
}
async function validateCustomer() {
  let errors = [];
  if (customer.value) errors = await customer.value.validate();
  else return false;
  return errors.length === 0;
}
function validateFile() {
  return dropStore.files.length > 0;
}

function removeFile(file) {
  dropStore.removeFile(file);
}

async function upload() {
  let customerValid = await validateCustomer();
  let message = "";
  if (isAgency.value && props.title != "Importar Clientes") {
    message = !customerValid ? `<li>Cliente es requerido</li>` : "";
  }
  if(!validateFile()){
    message = `${message}<li>Debe seleccionar al menos un fichero</li>`;
  }
  if (message != "") {
    if (!validateFile()) {
      let idDropZone = document.getElementById("idDropZone");
      if (idDropZone) {
        if (!idDropZone.classList.contains("is-invalid")) {
          idDropZone.classList.add("is-invalid");
        }
      }
    }
    snackbar.value = {
      title: "Error",
      message: message,
      type: "danger",
      color: "white",
    };
    return;
  }
  const contract = { sub_type: fileType.value };
  if (isAgency.value && props.title != "Importar Clientes") {
    contract.customer_id = customerModel.value.customer_id;
    if (props.title != "Importar Contactos")
      contract.department = customerModel.value.department;
  }
  dropStore.uploadFiles(props.url, contract);
}
function clearCustomer() {
  customerModel.value = {
    customer_id: "",
    customer: {
      contact: {
        name: "",
      },
    },
  };
  if(isAgency.value && props.title != "Importar Clientes")
    customer.value.resetValidated();
  resetModalState();
}
function onClick() {
  let idDropZone = document.getElementById("idDropZone");
  if (idDropZone) {
    if (idDropZone.classList.contains("is-invalid")) {
      idDropZone.classList.remove("is-invalid");
    }
  }
}

watch(
  () => dropStore.uploadStatus,
  (newSelection) => {
    if (newSelection == "ERROR") {
      snackbar.value = {
        title: "Error",
        message: dropStore.error.message,
        type: "danger",
        color: "white",
      };
    }
    if (newSelection == "SUCCESS") {
      importModal.value.closeModal();
    }
  }
);
const disableButton = computed({
  get() {
    return dropStore.disableButton;
  },
});
const snackbar = ref(null);
const closeSnackbar = () => {
  snackbar.value = null;
};
</script>

<template>
  <modal-base ref="importModal" @hide="clearCustomer()">
    <template #header>
      <h6 id="ModalLabel" class="modal-title">
        {{ title }}
      </h6>
    </template>
    <template #body>
      <form class="form-control border-0" id="formData">
        <div v-if="isAgency && title != 'Importar Clientes'">
          <customer-autocomplete
            ref="customer"
            size="col-sm-12 col-md-4 col-lg-4 col-xl-12"
            v-model="customerModel"
            :show-departments="title != 'Importar Contactos'"
            placeholder="Cliente"
          >
          </customer-autocomplete>
        </div>
        <DropZone
          @click="onClick"
          id="idDropZone"
          v-slot="{ dropZoneActive }"
          class="drop-area radius drop-zone-area p-0"
          @files-dropped="dropStore.addFiles"
        >
          <label for="file-input" class="file-input-label">
            <span v-if="dropZoneActive" class="file-input-text">
              <i class="fa fa-upload"></i>
              <span>Soltar sus archivos aquí</span>
              <span class="smaller">para agregarlo</span>
            </span>
            <span v-else class="file-input-text">
              <span class="col-form-label">Arrastre sus archivos o pulsa aquí para seleccionarlos</span> 
            </span>
          </label>
          <input id="file-input" type="file" multiple @change="onInputChange" />
        </DropZone>
        <file-view :files="dropStore.files" @remove="removeFile"></file-view>
        <div class="mt-2" v-if="options.length > 0">
          <argon-select
            id="file-format"
            v-model="fileType" name="file-format"
            :options="props.options" filterable
            placeholder="Formato">
          </argon-select>
        </div>
      </form>
    </template>
    <template #footer>
      <argon-loading-button
        class="btn bg-gradient-primary btn-sm"
        color="primary"
        variant="gradient"
        :label="disableButton ? 'Importando...' : 'Importar'"
        :loading="disableButton"
        :disable-button="disableButton"
        @click="upload"
      >
      </argon-loading-button>
    </template>
    <div class="position-fixed top-0 end-0 z-index-2 p-3">
      <argon-snackbar
        v-if="snackbar"
        :title="snackbar.title"
        :description="snackbar.message"
        :icon="{ component: 'ni ni-notification-70', color: snackbar.type }"
        :color="snackbar.color"
        :close-handler="closeSnackbar"
      />
    </div>
  </modal-base>
</template>