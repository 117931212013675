<script setup>
import ModalBase from "@/components/modal/ModalBase.vue";
import ArgonSnackbar from "@/components/ArgonSnackbar.vue";
import { ref, onMounted, watch } from "vue";
import { useStore } from "vuex";
import { ArgonLoadingButton } from "@/components";
import ArgonInput from "@/components/ArgonInput.vue";
import { required, helpers } from "@vuelidate/validators";
import { useVuelidate } from "@vuelidate/core";
import CustomerAutocomplete from "@/components/forms/CustomerAutoComplete.vue";
import { valuationStore as valuationStoreRequest } from "@/_store/valuationStore";
import { dateUtils } from "@/utils";

const loading = ref(false);
const modalReevaluate = ref(null);
const store = useStore();
const bootstrap = store.state.bootstrap;
const emits = defineEmits(['close', 'valorar']);
const valuationStore = valuationStoreRequest();
const rules = {
  from_date: {
    required: helpers.withMessage("Ingrese una fecha de inicio válida", required),
  },
  to_date: {
    required: helpers.withMessage("Ingrese una fecha final válida", required),
    dateRange: helpers.withMessage(
      "La fecha final no puede ser anterior a la fecha de inicio",
      (to_date, data) => !to_date || new Date(to_date) >= new Date(data.from_date)
    )
  }
};

let data = ref({
  from_date: null,
  to_date: null,
  customerModel: {
    customer_id: "",
    customer: {
      contact: {
        name: ""
      }
    }
  }
});

const resetData = () => {
  data.value = {
    from_date: null,
    to_date: null,
    customerModel: {
      customer_id: "",
      customer: {
        contact: {
          name: ""
        }
      }
    }
  };
};

const v$ = useVuelidate(rules, data);

onMounted(() => {
  let statusModal = new bootstrap.Modal(
    document.getElementById("modalRevaloracion"),
    {
      keyboard: true,
    }
  );
  statusModal.show();
});

const type = ref("multiple");
const snackbar = ref(null);


const closeSnackbar = () => {
  snackbar.value = null;
};

function submitReevaluate() {
  const promiseRevalorar = validate();
  promiseRevalorar.then((values) => {
    let message = "";
    for (const value of values) {
      message = `${message}<li>${value.$message}</li>`;
    }
    if (message != "") {
      snackbar.value = {
        title: "Error",
        message: `<ul>${message}</ul>`,
        type: "danger",
        color: "white",
      };
    } else {
      let body = {
        ids: [],
        customer_id: data.value.customerModel.customer_id,
        from_date: dateUtils.add(data.value.from_date, 'hours', 12),
        to_date: dateUtils.add(data.value.to_date, 'hours', 12),
      };
      loading.value = true;
      valuationStore.reevaluate(body);
    }
  });
};

async function validate() {
  await v$.value.$validate();
  return v$.value.$errors;
}

function closeModal() {
  emits('close');
}

watch(type, () => {
  resetData();
});

watch(
  () => valuationStore.error,
  (value) => {
    if (value) {
      snackbar.value = {
        title: "Error",
        message: value.message,
        type: "danger",
        color: "white",
      };
      loading.value = false;
    }
  }
);
watch(
  () => valuationStore.reevaluation,
  (value) => {
    if (value) {
      loading.value = false;
      modalReevaluate.value.closeModal();
    }
  }
);
</script>

<template>
  <modal-base ref="modalReevaluate" @hide="closeModal">
    <template #header>
      <h6 id="ModalLabel" class="modal-title">
        Revalorar
      </h6>
      <i class="fas tags ms-3"></i>
    </template>
    <template #body>
      <form class="border-0" if="formData">
        <div class="row">
          <div class="col-4">
            <label for="inputPassword6" class="col-form-label">Desde fecha</label>
          </div>
          <div class="col-8">
            <argon-input id="fecha" :error="v$.from_date.$error" v-model="data.from_date" type="date"
              placeholder="Seleccione la fecha" required />
          </div>
        </div>
        <div class="row">
          <div class="col-4">
            <label for="inputPassword6" class="col-form-label">Hasta fecha</label>
          </div>
          <div class="col-8">
            <argon-input id="fecha" :error="v$.to_date.$error" v-model="data.to_date" type="date"
              placeholder="Seleccione la fecha" required />
          </div>
        </div>
        <customer-autocomplete :is-required="false" ref="customer" v-model="data.customerModel" :show-departments="false"
          placeholder="Cliente">
        </customer-autocomplete>
      </form>
    </template>
    <template #footer>
      <argon-loading-button class="btn btn-sm bg-gradient-primary" color="primary" variant="gradient"
        :label="loading ? 'Enviando...' : 'Enviar'" :loading="loading" :disable-button="loading"
        @click="submitReevaluate" />
    </template>
    <div class="position-fixed top-1 end-1 ">
      <argon-snackbar v-if="snackbar" :title="snackbar.title" :description="snackbar.message"
        :icon="{ component: 'ni ni-notification-70', color: snackbar.type }" :color="snackbar.color"
        :close-handler="closeSnackbar" />
    </div>
  </modal-base>
</template>
<style scoped>
.no-margin {
  margin-left: 0px !important;
}
</style>
