<script setup>
import { onMounted, watch, ref, computed, inject } from "vue";
import ArgonTable from "@/components/table/ArgonTable.vue";
import ArgonButton from "@/components/ArgonButton.vue";
import TypeBadge from "@/components/badges/ContactType.vue";
import TiposImportaciones from "/src/maestros/TiposImportaciones.json";
import { userStore } from "@/_store/userStore";
import { contactListStore } from "@/_store/contactStore";
import { argonTableStore } from "@/_store/argonTableStore";
import { useRouter } from "vue-router";
import ArgonTd from "@/components/table/ArgonTd.vue";
import { contactDetailStore } from "@/_store";
import ArgonSnackbar from "@/components/ArgonSnackbar.vue";

const contactStoreRequest = contactDetailStore();
const sweetAlert = inject("$swal");
const fieldFilters = ref([
  {
    field: "contact.name",
    description: "Nombre",
  },
  {
    field: "contact.attention_of",
    description: "Atención de",
  },
  {
    field: "address",
    description: "Dirección",
  },
]);
const defaultFilters = [
  {
    field: "type",
    label: "Tipo",
    multi: true,
    options: [
      { label: "Recogida", value: "RECOGIDA" },
      { label: "Entrega", value: "ENTREGA" },
    ],
  },
  {
    field: "status",
    label: "Estado",
    multi: true,
    options: [
      { label: "Creado", value: "CREADO" },
      { label: "Error", value: "ERROR" },
    ],
  },
  {
    field: "default",
    label: "Defecto",
    multi: false,
    options: [{ label: "Defecto", value: true }],
  },
];

const importOptions = {
  title: "Importar Contactos",
  options: TiposImportaciones.CONTACT.options,
  url: `/contact/batch`,
};

const contactStore = contactListStore();
const userStores = userStore();
const tableStore = argonTableStore();
const router = useRouter();

const columns = ref([
  {
    header: "Nombre",
    field: "contact.name",
    sort: false,
    position: "start",
  },
  {
    header: "Dirección",
    field: "address",
    sort: false,
    position: "start",
  },
  {
    header: "Defecto",
    field: "default",
    sort: false,
    position: "center",
    width: "w-1",
  },
  {
    header: "Tipo",
    field: "type",
    sort: false,
    position: "center",
  },
]);
onMounted(() => {
  if (userStores.userSigned.portalType === 'AGENCY') {
    loadAgencyConfigs();
  }
  tableStore.clear();
  const filters = userStores.filtersPage.contacts?.filters || [];
  const filtersFavorite = userStores.filtersPage.contacts?.filtersFavorite || [];
  tableStore.changeFilters(filters);
  tableStore.addFavorite(filtersFavorite);

  if (reload.value) {
    setTimeout(() => {
      reloadPage()
    }, 1000)
  }

  reloadPage();
});
function loadAgencyConfigs() {
  const newHeader = {
    header: "Cliente",
    field: "customer.name",
    sort: false,
    position: "start"
  }
  columns.value.splice(0, 0, newHeader);

  const newFilters = [
    {
      field: "customer.code",
      description: "Cliente Código",
    },
    {
      field: "customer.name",
      description: "Cliente Nombre",
    }]
  fieldFilters.value.push(...newFilters);
}
function reloadPage() {
  userStores.filtersPage.contacts = {
    filters: tableStore.filters,
    filtersFavorite: tableStore.filtersFavorite,
  }
  contactStore.getContacts(
    tableStore.pagination,
    tableStore.query,
    tableStore.sort
  );
}

function newContact() {
  const url = `/contacts/new`;
  router.push(url);
}

function deleteContacts() {
  sweetAlert({
    title: "¿Está seguro de querer eliminar los contactos seleccionados?",
    text: "¡No podrá revertir esta acción!",
    showCancelButton: true,
    confirmButtonText: "Eliminar",
    cancelButtonText: "Cancelar",
    reverseButtons: true,
    customClass: {
      confirmButton: `btn ms-1 bg-gradient-danger`,
      cancelButton: "btn btn-outline-primary me-1",
    },
    buttonsStyling: false,
  }).then((result) => {
    if (result.isConfirmed) {
      tableStore.checkedList.forEach(contact => {
        contactStoreRequest.deleteContact(contact);
      });
    }
  });
}

function exportData() {
  contactStore.getContactsAsExcel(tableStore.query, tableStore.sort);
}
function viewPDF(blob) {
  const URI = URL.createObjectURL(blob);
  window.open(URI, "_blank");
}

watch(
  () => contactStoreRequest.deletedId,
  (value) => {
    if (value) {
      setTimeout(() => {
        tableStore.checkedList = [];
        reloadPage();
      }, 600);
      snackbar.value = {
        title: "Actualizado",
        message: "Han sido eliminados.",
        type: "info",
        color: "white",
      };      
    }
  }
);

watch(
  () => contactStore.contactsAsExcel,
  (newVal) => {
    if (newVal) {
      viewPDF(newVal);
    }
  }
);

function onClick(val) {
  const url = `/contacts/${val._id}`;
  router.push(url);
}
function getPosition(field) {
  const column = columns.value.find((col) => col.field === field);
  return column && column.position ? column.position : "start";
}

const snackbar = ref(null);
const closeSnackbar = () => {
  snackbar.value = null;
};

const reload = computed({
  get() {
    return contactStore.reload
  },
})
</script>
<template>
  <div class="card">
    <div class="card-body">
      <argon-table v-if="contactStore.contacts" :columns="columns" :items="contactStore.contacts"
        :field-filters="fieldFilters" :default-filters="defaultFilters" :import-options="importOptions"
        :show-check-input="true" @filter-changed="reloadPage" @page-changed="reloadPage" @new-item="newContact"
        @on-reload="reloadPage" @on-click="onClick" @sort-changed="reloadPage" @export-data="exportData">
        <template #buttons>
          <div class="dropdown d-inline">
            <argon-button id="navbarDropdownPrinter" color="primary" variant="outline"
              class="dropdown-toggle me-2 btn-sm" data-bs-toggle="dropdown" aria-expanded="false"><i
                class="fa fa-print me-sm-2"></i>Acciones</argon-button>
            <ul class="dropdown-menu z-index-1030 dropdown-menu-lg-start px-2 py-3"
              aria-labelledby="navbarDropdownPrinter" style>
              <li>
                <a class="dropdown-item border-radius-md" @click="deleteContacts">Eliminar</a>
              </li>
            </ul>
          </div>
        </template>
        <template #default="{ item }">
          <argon-td v-if="userStores.userSigned.portalType === 'AGENCY'" :position="getPosition('customer.name')"
            :text-primary="item.customer?.name" :text-secondary="item.customer?.code"></argon-td>
          <argon-td :position="getPosition('contact.name')" :text-primary="item.contact.name"
            :text-secondary="item.contact.attention_of"></argon-td>
          <argon-td :position="getPosition('address')" :text-secondary="item.address" max-width="130px" />
          <argon-td :position="getPosition('default')">
            <i v-if="item.default" class="fa fa-check text-xxs"></i>
          </argon-td>
          <argon-td :position="getPosition('type')">
            <type-badge class="badge badge-md" :item="item.type"></type-badge>
          </argon-td>
        </template>
      </argon-table>
    </div>
  </div>
  <div class="position-fixed top-1 end-1 z-index-2">
    <argon-snackbar v-if="snackbar" :title="snackbar.title" :description="snackbar.message"
      :icon="{ component: 'ni ni-notification-70', color: snackbar.type }" :color="snackbar.color"
      :close-handler="closeSnackbar" />
  </div>
</template>
<style lang="scss" scoped>
.z-index-1030 {
  z-index: 1030 !important;
}
</style>