<script setup>
import { computed, ref } from "vue";
import { useStore } from "vuex";
import SidenavList from "./SidenavList.vue";
/* import logo from "@/assets/img/logos/logo-customer-sidenav.svg"; */
import logo from "@/assets/img/logos/newLogoBeepaket.png";
import smallIcon from "@/assets/img/logos/newLogoAgency.png";
import logoWhite from "@/assets/img/logo-ct.png";
import image from "@/assets/img/profile-layout-header.jpg";
import { useUserStore } from "@/_store/userStore.js";
import { logoListStore } from "@/_store/logoStore";

const userStore = useUserStore();

const store = useStore();
const isRTL = computed(() => store.state.isRTL);
const layout = computed(() => store.state.layout);
const sidebarType = computed(() => store.state.sidebarType);
const darkMode = computed(() => store.state.darkMode);
const uploadStore = logoListStore();
const sidebarWidth = ref(0);


/* const isAgency = computed(() => userStore.userSigned?.portalType === 'AGENCY'); */

function getLogo() {
  if (uploadStore.logo && uploadStore.logo.logo != '') {
    if (sidebarWidth.value <= 96) {
      return uploadStore.logo.icon;
    } else {
      return uploadStore.logo.logo;
    }
  }
  if (sidebarWidth.value <= 96) {
    return smallIcon;
  } else {
    return logo;
  }
}

function resize(size) {
  sidebarWidth.value = size;
}
</script>
<template>
  <div v-if="layout === 'custom'" class="position-absolute w-100 min-height-300 top-0" :style="{
    'background-image': 'url(' + image + ')',
    'background-position-y': '50%',
  }">
    <span class="mask bg-success opacity-6"></span>
  </div>
  <div v-show="layout === 'default'" class="min-height-300 position-absolute w-100"
    :class="userStore.userSigned?.portalType != 'AGENCY' ? 'bg-textutre-gold' : 'bg-textutre-agencia'" />

  <aside id="sidenav-main" class="sidenav navbar navbar-vertical navbar-expand-xs border-0 border-radius-xl my-3 ms-4"
    :class="` ${sidebarType} ${isRTL ? 'fixed-end me-3 rotate-caret' : 'fixed-start ms-3'
      } ${layout === 'landing' ? 'bg-transparent shadow-none' : ''}`">
    <div class="sidenav-header">
      <i id="iconSidenav"
        class="top-0 p-3 cursor-pointer fas fa-times text-secondary opacity-5 position-absolute end-0 d-none d-xl-none"
        aria-hidden="true"></i>
      <router-link class="m-0 navbar-brand" to="/">
        <div class="d-flex justify-content-center">
          <img :src="layout === 'landing' || darkMode || sidebarType === 'bg-default' ? logoWhite : getLogo()" :class="{
            'navbar-brand-img h-100 center': true,
            'w-100': getLogo() === ''
          }" alt="main_logo" />
        </div>
      </router-link>
    </div>
    <hr class="mt-0 horizontal dark" />
    <sidenav-list @resize="resize" />
  </aside>
</template>
