<script setup>
import { useVuelidate } from "@vuelidate/core";
import { required, helpers } from "@vuelidate/validators";
import { onMounted, ref, watch } from "vue";
import ArgonInput from "@/components/ArgonInput.vue";
import ArgonSelect from "@/components/ArgonSelect.vue";
import AutoComplete from "@/components/auto-complete/AutoComplete.vue";
import { directionStore } from "@/_store/directionStore";

const emit = defineEmits(["update:modelValue"]);
const model = ref({
  postal_code: "",
  street: "",
  street2: "",
  city: "",
  country: "ES",
  location: null,
  text: "",
  place_id: "",
  normalized: true,
  province: "",
});
let props = defineProps({
  modelValue: {
    type: Object,
    default: () => ({
      postal_code: "",
      street: "",
      street2: "",
      city: "",
      country: "ES",
      location: null,
      text: "",
      place_id: "",
      normalized: true,
      province: "",
    }),
  },
  inputsSize: {
    type: String,
    default: "default",
  },
  readonly: {
    type: Boolean,
    default: false,
  },
});

const rules = {
  street: { required: helpers.withMessage("Dirección es requerida", required) },
  postal_code: {
    required: helpers.withMessage(
      "Seleccione código postal o provincia",
      required
    ),
  },
};
const v$ = useVuelidate(rules, model);
const directionRequest = directionStore();
const idSelect = ref(generateUniqueId());
const dataSuggestionsPostal = ref([]);
const dataSuggestionsCity = ref([]);
const directionInputRef = ref(null);
onMounted(() => {
  model.value = props.modelValue;
  getCountries();
});

function selectedCountry() {
  model.value.postal_code = "";
  model.value.province = "";
  model.value.city = "";
}
const keyP=ref(0)
function selectedAutocomplete(item) {
  if (item.length == 0 || item == {}) {
    model.value.postal_code = "";
    model.value.province = "";
    model.value.city = "";
  } else {
    model.value.postal_code = item.postal_code;
    model.value.province = item.admin_name2;
    model.value.city = item.place_name;
  }
  keyP.value++;
  if (directionInputRef.value) {
    directionInputRef.value.inputRef.focus();
  }
}
function generateUniqueId() {
  return "choices-country-" + Math.random().toString(36).substr(2, 9);
}
let paginationCity=false;
function getCity(text,page) {
  if(page==1){
    paginationCity=true;
  }else paginationCity =false;
  let req = {
    country_code: model.value.country
  };
  if (text !== "") {
    req.place_name = { "$regex": text, "$options": "i" };
  }
  if (model.value && model.value.postal_code !== "") {
    req.postal_code = model.value.postal_code;
  }
  directionRequest.getCity({
    query: req,
    pagination: {
      limit: 80,
      page: page
    },
    sort: { 'place_name': 1 }
  });
}
let paginationPostal=false;
function getCodePostal(text,page){
  if(page==1){
    paginationPostal=true;
  }else paginationPostal =false;
  let req=text!=""? {
      country_code: model.value.country,
      postal_code:{"$regex":text}
    }: {country_code: model.value.country};
  directionRequest.getProvince({
    query: req,
    pagination: {
      limit: 80,
      page: page
    },
    sort: { 'place_name': 1 }
  });
}

function getCountries() {
  directionRequest.getCountries();
}

watch(
  () => model.value,
  (value) => {
    emit("update:modelValue", value);
  }
);
watch(
  () => directionRequest.dataProvince,
  (value) => {
    if(value.data && value.data.length > 0){
      if(paginationPostal)
        dataSuggestionsPostal.value =[];
      dataSuggestionsPostal.value = [...dataSuggestionsPostal.value, ...value.data];
    }
  },
);
watch(
  () => directionRequest.dataCity,
  (value) => {
    if(value.data && value.data.length > 0){
      if(paginationCity)
        dataSuggestionsCity.value =[];
      dataSuggestionsCity.value = [...dataSuggestionsCity.value, ...value.data];
    }
  },
);


async function validate() {
  await v$.value.$validate();
  return v$.value.$errors;
}

defineExpose({
  validate,
});
</script>
<template>
  <form role="form">
    <div class="row">
      <div class="col-md-4 col-lg-6 col-xl-4 col-sm-12">
        <argon-select
          v-if="directionRequest.countries"
          :id="idSelect"
          v-model="model.country"
          :name="idSelect"
          name-value="code"
          name-label="name"
          :is-required="true"
          :options="directionRequest.countries.data"
          :size="inputsSize"
          :filterable="true"
          :disabled="readonly"
          @selected-change="selectedCountry"
          tabindex="1"
        >
        </argon-select>
      </div>
      <div class="col-md-8 col-lg-6 col-xl-2 col-sm-12">
        <auto-complete
          v-if="model != null"
          :key="keyP"
          :max-length="10"
          v-model="model.postal_code"
          placeholder="C.P."
          :error="v$.postal_code.$error"
          :data-suggestions="dataSuggestionsPostal"
          :item-text="['postal_code', 'place_name']"
          separator=" "
          :size="inputsSize"
          :disabled="readonly"
          @text-changed="getCodePostal"
          @selected-change="selectedAutocomplete"
          @page-changed="getCodePostal"
          tabindex="2"
          menu-width="300"
        ></auto-complete>
      </div>
      <div class="col-md-8 col-lg-6 col-xl-6 col-sm-12">
        <auto-complete
          v-if="model != null"
          v-model="model.city"
          :key="keyP"
          :max-length="100"
          placeholder="Población"
          :error="v$.postal_code.$error"
          :data-suggestions="dataSuggestionsCity"
          :item-text="['place_name', 'postal_code']"
          separator=" "
          :size="inputsSize"
          :disabled="readonly"
          @text-changed="getCity"
          @selected-change="selectedAutocomplete"
          @page-changed="getCity"
          tabindex="3"
          menu-width="320"
        ></auto-complete>
      </div>
    </div>
    <div class="row">
      <div class="col-md-4">
        <argon-input
          id="province"
          v-model="model.province"
          :is-required="true"
          :size="inputsSize"
          placeholder="Provincia"
          name="province"
          :disabled="readonly"
          tabindex="4"
        />
      </div>
      <div class="col-md-8">
        <argon-input
          id="direction"
          ref="directionInputRef"
          v-model="model.street"
          :is-required="true"
          :error="v$.street.$error"
          :size="inputsSize"
          placeholder="Dirección"
          name="direction"
          :disabled="readonly"
          tabindex="4"
        />
      </div>
    </div>
  </form>
</template>