import { defineStore } from "pinia";
import moment from "moment";
import { dashboardService } from "@/_services";

export const dashboardStore = defineStore("dashboard", {
  state: () => ({
    totals: {},
    couriers: [],
    cities: [],
    start: null,
    end: null,
    error: null,
  }),
  actions: {
    getDashboard() {
      this.totals = {}
      this.couriers = []
      this.cities = []
      this.start = null
      this.end = null
      dashboardService
        .getDashboard()
        .then((result) => {
          const { totals, couriers, cities, range: { start, end }  } = result
          this.totals = JSON.parse(JSON.stringify(totals))
          this.couriers = JSON.parse(JSON.stringify(couriers))
          this.cities = [...cities]
          this.start = moment(start)
          this.end = moment(end)
        })
        .catch((reason) => (this.error = reason));
    },
  },
});
