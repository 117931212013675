<script setup>
import { onMounted, watch, computed, ref } from "vue";
import ArgonTable from "@/components/table/ArgonTable.vue";
import ArgonButton from "@/components/ArgonButton.vue";
import StatusBadge from "@/components/badges/IssueStatus.vue";
import PartnerAvatar from "@/components/PartnerAvatar.vue";
import { userStore } from "@/_store/userStore";
import { issueListStore } from "@/_store/issueStore";
import { argonTableStore } from "@/_store/argonTableStore";
import { useRouter } from "vue-router";
import ArgonTd from "@/components/table/ArgonTd.vue";
import ModalModificarEstado from "./ModalModificarEstado.vue";
import moment from "moment";


const userStores = userStore();

const fieldFilters = ref([
  {
    field: "number",
    description: "Ticket",
  },
  {
    field: "locator",
    description: "Localizador",
  },
  {
    field: "reference",
    description: "Referencia",
  }

]);
const issueStore = issueListStore();
const tableStore = argonTableStore();
const router = useRouter();
const defaultFilters = [
  {
    field: "status",
    label: "Estado",
    multi: false,
    options: [
      { label: "Pendiente", value: 'PENDIENTE' },
      { label: "En curso", value: 'EN_CURSO' },
      { label: "Finalizado", value: 'FINALIZADO' },
    ],
  },
  {
    field: "courier",
    label: "Courier",
    multi: true,
    options: [
      { label: "Correos Express", value: 'CEX'},
      { label: "Ctt Express", value: 'CTT' },
      { label: "Ups", value: 'UPS' },
      { label: "Propio", value: 'PROPIO'}
    ],
  },
];

if (userStores.userSigned.portalType === 'AGENCY') {
  defaultFilters.push({
    field: "priority",
    label: "Prioridad",
    multi: true,
    options: [
      { label: "Prioridad Alta", value: 3 },
      { label: "Prioridad Media", value: 2 },
      { label: "Prioridad Baja", value: 1 },
    ],
  });
}

const columns = ref([
  {
    header: "Ticket",
    field: "number",
    sort: false,
  },
  {
    header: "Fecha",
    field: "date",
    sort: true,
  },
  
  {
    header: "Descripción",
    field: "description",
    sort: false,
    position: "start",
  },
  {
    header: "Localizador",
    field: "locator",
    sort: false,
    position: "start",
  },
  {
    header: "Referencia",
    field: "reference",
    sort: false,
    position: "start",
  },
  {
    header: "Partner",
    field: "courier",
    sort: false,
    position: "start",
  },
  {
    header: "Estado",
    field: "status",
    sort: false,
    position: "center",
    width: "w-5",
  },
]);

onMounted(() => {
  if (userStores.userSigned.portalType === 'AGENCY') {
    loadAgencyConfigs();
  }
  tableStore.clear();
  const filters = userStores.filtersPage.issues?.filters || [];
  const filtersFavorite = userStores.filtersPage.issues?.filtersFavorite || [];
  tableStore.changeFilters(filters);
  tableStore.addFavorite(filtersFavorite);

  if (reload.value) {
    setTimeout(() => {
      reloadPage()
    }, 800)
  }
  reloadPage();
});

function reloadPage() {
  userStores.filtersPage.issues = {
    filters: tableStore.filters,
    filtersFavorite: tableStore.filtersFavorite,
  }
  issueStore.getAllIssues(
    tableStore.pagination,
    tableStore.query,
    tableStore.sort
  );
}

function newIssue() {
  const url = `/issues/new`;
  router.push(url);
}

function exportData() {
  issueStore.getIssuesAsExcel(tableStore.query, tableStore.sort);
}
function viewPDF(blob) {
  const URI = URL.createObjectURL(blob);
  window.open(URI, "_blank");
}
watch(
  () => issueStore.issuesAsExcel,
  (newVal) => {
    if (newVal) {
      viewPDF(newVal);
    }
  }
);

function onClick(val) {
  const url = `/issues/${val._id}`;
  router.push(url);
}
function getPosition(field) {
  const column = columns.value.find((col) => col.field === field);
  return column && column.position ? column.position : "start";
}
function getPriority(priority) {
  priority = parseInt(priority);
  switch (priority) {
    case 3:
      return { icono: 'fa fa-arrow-up text-xxs', tooltip: "Alta" };
    case 2:
      return { icono: 'fa fa-arrow-right text-xxs', tooltip: "Media" };
    case 1:
      return { icono: 'fa fa-arrow-down text-xxs', tooltip: "Baja" };
    default:
      return { icono: '', tooltip: "" };
  }
}

function loadAgencyConfigs() {
  const newHeader = {
    header: "Cliente",
    field: "customer.name",
    width: "w-10",
    sort: false,
    position: "start"
  }
  const headerPriority = {
    header: "Prioridad",
    field: "priority",
    width: "w-10",
    sort: true,
    position: "center"
  }
  columns.value.splice(5, 0, newHeader);
  columns.value.splice(2, 0, headerPriority);
  const newFilters = [
    {
      field: "customer.code",
      description: "Cliente Código",
    },
    {
      field: "customer.contact.name",
      description: "Cliente Nombre",
    }]
  fieldFilters.value.push(...newFilters);
}

function requestSuccess(){
  setTimeout(() => {
    tableStore.clearSelection()
  }, 1000);
}

const reload = computed({
  get() {
    return issueStore.reload
  },
})
</script>
<template>
  <div class="card">
    <div class="card-body">
      <argon-table v-if="issueStore.issues" :columns="columns" :items="issueStore.issues" :field-filters="fieldFilters"
        @filter-changed="reloadPage" @page-changed="reloadPage" @new-item="newIssue" @on-reload="reloadPage"
        @on-click="onClick" @sort-changed="reloadPage" @export-data="exportData" :default-filters="defaultFilters"
        :show-importar="false" :show-new-button="false">
        <template #buttons>
          <div class="dropdown d-inline">
            <argon-button id="navbarDropdownPrinter" color="primary" variant="outline"
              class="dropdown-toggle me-2 btn-sm" data-bs-toggle="dropdown" aria-expanded="false"><i
                class="fa fa-print me-sm-2"></i>Acciones</argon-button>
            <ul class="dropdown-menu z-index-1030 dropdown-menu-lg-start px-2 py-3"
              aria-labelledby="navbarDropdownPrinter" style>
              <li>
                <a class="dropdown-item border-radius-md" href="javascript:;" data-bs-toggle="modal"
                  data-bs-target="#modalModificarEstado">Transitar Estado</a>
              </li>
            </ul>
          </div>
        </template>
        <template #default="{ item }">
          <argon-td :position="getPosition('number')" :text-primary="item.number" />
          <argon-td :position="getPosition('date')" :text-primary="moment(item.date).format('DD/MM/YYYY HH:mm')"
            :text-secondary="moment(item.date).fromNow()" />         
          <argon-td v-if="userStores.userSigned.portalType === 'AGENCY'" v-tooltip
            :data-bs-title="getPriority(item.priority).tooltip" :position="getPosition('priority')">
            <i :class="getPriority(item.priority).icono"></i>
          </argon-td>
          <argon-td :position="getPosition('description')" max-width="130px" :text-secondary="item.description" />         
          <argon-td :position="getPosition('locator')" :text-secondary="item.locator" />
          <argon-td :position="getPosition('reference')" :text-secondary="item.reference" />
          <argon-td v-if="userStores.userSigned.portalType === 'AGENCY'" :position="getPosition('customer.name')"
            :text-primary="item.customer.code" :text-secondary="item.customer.contact.name">
          </argon-td>
          <argon-td :position="getPosition('courier')">
            <partner-avatar :item="item.courier"></partner-avatar>
          </argon-td>
          <argon-td :position="getPosition('status')">
            <status-badge class="badge badge-md" :item="item.status" :tooltip="item.errors"></status-badge>
          </argon-td>
        </template>
      </argon-table>
    </div>
    <modal-modificar-estado id="modalModificarEstado" @requestSuccess="requestSuccess"/>
  </div>
</template>
<style lang="scss" scoped>
.z-index-1030 {
  z-index: 1030 !important;
}
</style>