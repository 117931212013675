<script setup>
import ArgonButton from "@/components/ArgonButton.vue";
import { fileViewStore } from "./fileViewStore";
import { ref, watch, computed, onMounted } from "vue";
import { useRoute, useRouter } from "vue-router";

const fileStore = fileViewStore();
const totalDocuments = ref(null);
const route = useRoute();
const router = useRouter();
/* const router = inject('router');
const route = inject('route'); */
const props = defineProps({
  url: {
    type: String,
    default: "",
  },
  label: {
    type: String,
    default: "",
  },
  container: {
    type: String,
    default: "",
  },
  entity: {
    type: String,
    default: "",
  },
  idEntity: {
    type: String,
    default: "",
  },
  origin: {
    type: String,
    default: "",
  },
  redirect: {
    type: String,
    default: "",
  },
});

onMounted(() => {
  getTotalDocuments();
});

function getTotalDocuments() {
  fileStore.getTotalDocuments(`${url.value}`);
}

watch(
  () => fileStore.totalDocResponse,
  (value) => {
    if (value !== null) {
      totalDocuments.value = value;
      fileStore.totalDocResponse = null;
    }
  },
  { deep: true }
);

function redirectToDocument() {
  let ruta = props.redirect != "" ? props.redirect : props.entity;
  router.push({
    path: `/${ruta}/${route.params.id}/gallery-files-container`,
    query: { locator: props.origin },
  });
}
const url = computed(
  () =>
    `documents/${props.container}/entity/${props.entity}/folder/${props.idEntity}/document`
);
</script>

<template>
  <div>
    <argon-button
      class="btn-xs d-flex align-items-center hover-background btn-documents"
      color="transparent"
      @click="redirectToDocument"
    >
      <i class="fa fa-file-text icon-style fa-light" aria-hidden="true"></i>
      <div class="d-flex flex-column mt-1" style="height: 20px !important">
        <label class="col-form-label fw-light">{{ label }}</label>
        <label class="col-form-label total fw-light">{{
          totalDocuments
        }}</label>
      </div>
    </argon-button>
  </div>
</template>

<style lang="scss" scoped>
.hover-background {
  transition: background-color 0.3s ease;
}

.hover-background:hover {
  background-color: rgba(0, 0, 0, 0.05);
  cursor: pointer !important;
}
</style>
