<script setup>
import { onMounted, ref, watch, inject } from "vue";
import ArgonSelect from "@/components/ArgonSelect.vue";
import ArgonTextarea from "@/components/ArgonTextarea.vue";
import { ArgonLoadingButton } from "@/components";
import ArgonSnackbar from "@/components/ArgonSnackbar.vue";
import SendMail from "./SendMail.vue";

const sweetAlert = inject("$swal");
let disableDownload = ref(false);
let disableDelete = ref(false);
let disableSave = ref(false);
let disableEmailButton = ref(false);
let showSentEmail = ref(false);

let props = defineProps({
  dataDetail: {
    type: Object,
    default() {
      return {
        size: 0,
        totalDocuments: 0.0,
      };
    },
  },
  generalDetail: {
    type: Boolean,
    default: true,
  },
  listType: {
    type: Array,
    default() {
      return [];
    },
  },
  dataPreview: {
    type: Object,
    default() {
      return {
        name: "",
        type: "",
        path: "",
        user: "",
        description: "",
        reference: "",
        size: 0,
        url: "",
      };
    },
  },
});

const url = inject("url");
const fileStore = inject("fileStore");
const query = inject("query");
const displaySpinner = inject("displaySpinner");

let file = ref({});
let fileDetail = ref({});

onMounted(() => {
  if (props.generalDetail) file.value = props.dataDetail;
  else fileDetail.value = { ...props.dataPreview };
});

function getFiles() {
  displaySpinner(true);
  fileStore.getFiles(
    `${url.value}/ql`,
    fileStore.pagination,
    query,
    fileStore.sort
  );
}

function descargarArchivo() {
  disableDownload.value = true;
  const fileName = fileDetail.value.filename;
  const link = document.createElement("a");
  link.href = fileDetail.value.url;
  link.setAttribute("download", fileName);
  document.body.appendChild(link);
  link.click();
  document.body.removeChild(link);
  setTimeout(() => {
    disableDownload.value = false;
  }, 1500);
}

function eliminarArchivo() {
  sweetAlert({
    title: "¿Está seguro que desea eliminar el documento?",
    text: "¡No podrá revertir esta acción!",
    showCancelButton: true,
    confirmButtonText: "Eliminar",
    cancelButtonText: "Cancelar",
    reverseButtons: true,
    customClass: {
      confirmButton: `btn ms-1 bg-gradient-danger`,
      cancelButton: "btn btn-outline-primary me-1",
    },
    buttonsStyling: false,
  }).then((result) => {
    if (result.isConfirmed) {
      disableDelete.value = true;
      fileStore.deleteFile(`${url.value}/${fileDetail.value._id}`);
    } else if (result.isDismissed) {
      disableDelete.value = false;
    }
  });
}

function guardarArchivo() {
  let body = {
    description: fileDetail.value.description,
    type: fileDetail.value.type,
  };
  disableSave.value = true;
  fileStore.putFile(String(url.value) + `/${fileDetail.value._id}`, body);
}
function isSendEmail() {
  showSentEmail.value = !showSentEmail.value;
}
function sendCorreo(dataEmail) {
  let body = {
    recipient: dataEmail,
    file_name: fileDetail.value.name,
  };
  fileStore.sendDocumentationEmail(String(url.value) + "/send_email", body);
}
watch(
  () => fileStore.emailResponse,
  (value) => {
    if (value) {
      snackbar.value = {
        title: "Enviado",
        message: "El documento ha sido enviado.",
        type: "info",
        color: "white",
      };
      disableEmailButton.value = false;
      isSendEmail();
      getFiles();
      fileStore.sendLoading=false;
      fileStore.emailResponse = null;
    }
  }
);
watch(
  () => fileStore.error,
  (value) => {
    snackbar.value = {
      title: "Error",
      message: value.message,
      type: "danger",
      color: "white",
    };
    disableEmailButton.value = false;
    getFiles();
    fileStore.emailResponse = null;
  }
);
watch(
  () => fileStore.deleteResponse,
  (value) => {
    if (value) {
      disableDelete.value = false;
      getFiles();
      fileStore.deleteResponse = null;
    }
  },
  { deep: true }
);
watch(
  () => fileStore.putResponse,
  (value) => {
    if (value) {
      disableSave.value = false;
      snackbar.value = {
        title: "Actualizado",
        message: "El documento ha sido actualizado.",
        type: "info",
        color: "white",
      };
      getFiles();
      fileStore.putResponse = null;
    }
  },
  { deep: true }
);

watch(
  () => props.dataPreview,
  (newVal) => {
    fileDetail.value = { ...newVal };
  },
  { immediate: true }
);
watch(
  () => props.dataDetail,
  (newVal) => {
    file.value = { ...newVal };
  },
  { immediate: true, deep: true }
);

const snackbar = ref(null);
const closeSnackbar = () => {
  snackbar.value = null;
};
</script>
<template>
  <div class="card h-100">
    <div class="card-body" v-if="generalDetail">
      <div class="row">
        <div class="col-4">
          <label class="col-form-label">Documentos</label>
        </div>
        <div class="col-8">
          <span>{{ file.totalDocuments ? file.totalDocuments : "0" }}</span>
        </div>
      </div>
      <div class="row">
        <div class="col-4">
          <label class="col-form-label">Tamaño</label>
        </div>
        <div class="col-8">
          <span>{{ file.size ? file.size.toFixed(2) : "0.00" }} MB</span>
        </div>
      </div>
    </div>
    <div class="card-body" v-else>
      <div class="row">
        <div class="col">
          <div class="btn-group mt-1">
            <argon-loading-button
              v-tooltip
              size="sm"
              class="btn-xs size-button"
              icon="fa fa-download"
              :data-bs-title="'Descargar'"
              color="primary"
              variant="outline"
              @click="descargarArchivo"
              :loading="disableDownload"
              :disable-button="disableDownload"
            >
            </argon-loading-button>
            <argon-loading-button
              v-tooltip
              size="sm"
              class="btn-xs size-button"
              icon="fa fa-paper-plane"
              :data-bs-title="'Enviar'"
              color="primary"
              variant="outline"
              :loading="fileStore.sendLoading"
              @click="isSendEmail"
            >
            </argon-loading-button>
            <argon-loading-button
              v-tooltip
              size="sm"
              class="btn-xs size-button"
              icon="fas fa-save"
              :data-bs-title="'Guardar'"
              color="primary"
              variant="outline"
              @click="guardarArchivo"
              :loading="disableSave"
              :disable-button="disableSave"
            >
            </argon-loading-button>
            <argon-loading-button
              v-tooltip
              size="sm"
              class="btn-xs size-button"
              icon="fa fa-trash"
              :data-bs-title="'Eliminar'"
              color="danger"
              @click="eliminarArchivo"
              :loading="disableDelete"
              :disable-button="disableDelete"
            >
            </argon-loading-button>
          </div>
        </div>
      </div>
      <transition
        name="fade-slide"
        @before-enter="beforeEnter"
        @enter="enter"
        @leave="leave"
      >
        <send-mail v-if="showSentEmail" @cancel="isSendEmail" @accept="sendCorreo"/>
      </transition>
      <div class="row">
        <div class="col-4">
          <label class="col-form-label">Tipo</label>
        </div>
        <div class="col-8">
          <argon-select
            id="list-type"
            v-model="fileDetail.type"
            name="list-type"
            :options="listType"
          >
          </argon-select>
        </div>
      </div>
      <div class="row">
        <div class="col-4">
          <label class="col-form-label">Nombre</label>
        </div>
        <div class="col-8">
          <span
            class="d-inline-block text-truncate"
            style="max-width: 200px"
            v-tooltip
            :data-bs-title="fileDetail.name"
          >
            {{ fileDetail.name }}
          </span>
        </div>
      </div>
      <div class="row row-spacing">
        <div class="col-4">
          <label class="col-form-label">Descripción</label>
        </div>
        <div class="col-8">
          <argon-textarea
            :rows="2"
            :max-length="54"
            v-model="fileDetail.description"
            type="textarea"
            :resize="false"
          />
        </div>
      </div>
      <div class="row">
        <div class="col-4">
          <label class="col-form-label">Origen</label>
        </div>
        <div class="col-8">
          <span class="d-inline-block text-truncate">
            {{ fileDetail.origin }}
          </span>
        </div>
      </div>
      <div class="row">
        <div class="col-4">
          <label class="col-form-label">Usuario</label>
        </div>
        <div class="col-8">
          <span>{{ fileDetail.user }}</span>
        </div>
      </div>
    </div>
  </div>
  <div class="position-fixed top-1 end-1 z-index-2">
    <argon-snackbar
      v-if="snackbar"
      :title="snackbar.title"
      :description="snackbar.message"
      :icon="{ component: 'ni ni-notification-70', color: snackbar.type }"
      :color="snackbar.color"
      :close-handler="closeSnackbar"
    />
  </div>
</template>
<style scoped>
::v-deep(.transparent input) {
  background-color: transparent !important;
}

.transparent {
  background-color: transparent !important;
}

.button-message {
  font-size: 11px;
  padding: 2px 8px;
  width: 65px;
  border-radius: 5px;
}

.card-design {
  width: 300px !important;
  position: absolute;
  border-radius: 10px;
  background-color: #ffffff;
  box-shadow: 0 8px 26px -4px rgba(20, 20, 20, 0.15),
    0 8px 9px -5px rgba(20, 20, 20, 0.06);
}

.fade-slide-enter-active,
.fade-slide-leave-active {
  transition: opacity 0.5s ease, transform 0.5s ease;
}

.fade-slide-enter,
.fade-slide-leave-to {
  opacity: 0;
  transform: translateY(-20px);
}

.fade-slide-enter-to {
  opacity: 1;
  transform: translateY(0);
}

.card-email {
  position: fixed;
  z-index: 99999;
  right: 20px;
  width: 300px !important;
  border-radius: 10px;
  box-shadow: 0 4px 8px rgba(0, 0, 0, 0.1);
  background-color: #f8f9fa;
}

.size-button {
  width: 45px;
}

.row-spacing {
  margin-bottom: 35px;
}
</style>