<script setup>
import { onMounted, watch, ref, inject } from "vue";
import ArgonSnackbar from "@/components/ArgonSnackbar.vue";
import ArgonInput from "../../../components/ArgonInput.vue";
import ArgonButton from "../../../components/ArgonButton.vue";
import ArgonDetailLayout from "@/components/detail/ArgonDetailLayout";
import ArgonSelect from "@/components/ArgonSelect.vue";
import IssueMessage from "./IssueMessage.vue";
import OfficeSolution from "./OfficeSolution.vue";
import ChangeAddressDeliver from "./ChangeAddressDeliver.vue";
import ChangeDate from "./ChangeDate.vue";
/* import Solution from "./Solution"; */
import TabsBootstrap from "@/components/TabsBootstrap.vue";
import ResumeShipment from "./ResumeShipment.vue";
import ResumeStop from "./ResumeStop.vue";
import PartnerAvatar from "@/components/PartnerAvatar.vue";

import { ArgonLoadingButton } from "@/components";
import { useRoute } from "vue-router";
import { issueDetailStore } from "@/_store/issueDetailStore";
import { issueListStore } from "@/_store/issueStore";
import { shipmentDetailStore } from "@/_store/shipmentDetailStore";
import { stopDetailStore } from "@/_store/stopDetailStore";
import QuillEditor from "@/components/QuillEditor.vue";
import moment from "moment";
import DocumentsButton from "@/components/gallery-files/DocumentsButton.vue"
import { dateUtils } from "@/utils";
import { userStore } from "@/_store/userStore";
import { computed } from "vue";

const route = useRoute();
const sweetAlert = inject("$swal");
const issueStoreRequest = issueDetailStore();
const shipmentStore = shipmentDetailStore();
const currentStatus = ref(null);
const currentTab = ref("PRIVADO");
const currentTabSolucion = ref("oficina");
const stopRequest = stopDetailStore();
const listChats = ref(null);
const issueStore = issueListStore();
const userStores = userStore();

const isAgency = computed(() => userStores.userSigned?.portalType === "AGENCY");
const isEcom = computed(() => userStores.userSigned?.portalType === "ECOM");

const isLoading = ref(false);
let tabs = [{ label: "Nota Interna", value: "PRIVADO" }];
const tabsSoluciones = [
  { label: "Entrega en oficina", value: "oficina" },
  { label: "Devolver envío", value: "devolucion" },
  { label: "Cambiar dirección entrega", value: "reexpedir" },
  { label: "Cambiar fecha", value: "fecha" },
];
const loading = ref(false);
const localValue = ref(null);
const shipment = ref(null);
const stop = ref(null);
const prioridades = [
  { name: "Alta", value: 3 },
  { name: "Media", value: 2 },
  { name: "Baja", value: 1 },
];
/* const toolbarOptions = ref([
  ['bold', 'italic', 'underline', 'strike'],
  ['blockquote', 'code-block'],
  [{ 'list': 'ordered' }, { 'list': 'bullet' }, { 'list': 'check' }],
  [{ 'indent': '-1' }, { 'indent': '+1' }],
  [{ 'color': [] }, { 'background': [] }],
  [{ 'align': [] }],
  ['clean']
]); */
/* const editorOptions = ref({
  placeholder: '',
  theme: 'snow',
  modules: {
    toolbar: toolbarOptions.value,
  }
}); */

const message = ref("");
let dataOffice = ref({ point_code: "", observations: "" });
let dataAddress = ref({
  name: "",
  contact: "",
  phone: "",
  email: "",
  date: dateUtils.formatDate(dateUtils.getToday(), "YYYY-MM-DD"),
  schedules: {
    initial: "",
    final: "",
  },
  instructions: "",
  address: {
    postal_code: "",
    street: "",
    street2: "",
    city: "",
    country: "ES",
    location: {
      lat: 0,
      lng: 0,
    },
    text: "",
    place_id: "",
    normalized: true,
    province: "",
  },
});
let changeDateInfo = ref({
  date: dateUtils.formatDate(dateUtils.getToday(), "YYYY-MM-DD"),
  schedules: {
    initial: "",
    final: "",
  },
  instructions: "",
});

function resetAllModels() {
  dataOffice.value.point_code = "";
  dataOffice.value.observations = "";

  changeDateInfo.value.date = dateUtils.formatDate(
    dateUtils.getToday(),
    "YYYY-MM-DD"
  );
  changeDateInfo.value.schedules.initial = "";
  changeDateInfo.value.schedules.final = "";
  changeDateInfo.value.instructions = "";

  dataAddress.value.name = "";
  dataAddress.value.contact = "";
  dataAddress.value.phone = "";
  dataAddress.value.email = "";
  dataAddress.value.date = dateUtils.formatDate(
    dateUtils.getToday(),
    "YYYY-MM-DD"
  );
  dataAddress.value.schedules.initial = "";
  dataAddress.value.schedules.final = "";
  dataAddress.value.instructions = "";
  dataAddress.value.address.postal_code = "";
  dataAddress.value.address.street = "";
  dataAddress.value.address.street2 = "";
  dataAddress.value.address.city = "";
  dataAddress.value.address.country = "ES";
  dataAddress.value.address.location.lat = 0;
  dataAddress.value.address.location.lng = 0;
  dataAddress.value.address.text = "";
  dataAddress.value.address.place_id = "";
  dataAddress.value.address.normalized = true;
  dataAddress.value.address.province = "";
}

onMounted(() => {
  if (!isEcom.value) {
    tabs.splice(0, 0, { label: "Responder", value: "PUBLICO" });
    currentTab.value = "PUBLICO";
  }
  loadIssue();
});



function loadIssue() {
  if (route.params.id) {
    issueStoreRequest.getIssueById(route.params.id);
    issueStoreRequest.isNew = false;
  } else {
    issueStoreRequest.isNew = true;
    localValue.value = {};
  }
}
function getShipment(id) {
  shipmentStore.getShipmentById(id);
}

function getStop(id) {
  stopRequest.getStopById(id);
}

function getChats(id) {
  issueStoreRequest.getChats(id);
}

function updateStatus(status) {
  currentStatus.value = status;
  issueStore.putIssuesStatus([{ _id: localValue.value._id, status: status }]);
}

const oficceSolutionRef = ref(null);
const changeAdress = ref(null);
const changeDateTab = ref(null);

function validateSolution(errors) {
  let message = "";
  for (const error of errors) {
    message += `<li>${error.$message}</li>`;
  }
  if (message !== "") {
    snackbar.value = {
      title: "Error",
      message: `<ul>${message}</ul>`,
      type: "danger",
      color: "white",
    };
    return true;
  }
  return false;
}

async function sendMessage() {
  if (currentTab.value === "SOLUCION") {
    if (currentTabSolucion.value === "devolucion") {
      loading.value = true;
      sendDataSolution();
      return;
    }
    const tabConfigs = {
      oficina: {
        ref: oficceSolutionRef,
        validateFunc: "validateOfficeSolutions",
        resetFunc: "resetModel",
      },
      reexpedir: {
        ref: changeAdress,
        validateFunc: "validateChangeAddress",
        resetFunc: "resetModel",
      },
      fecha: {
        ref: changeDateTab,
        validateFunc: "validateDateChange",
        resetFunc: "resetModel",
      },
    };
    const config = tabConfigs[currentTabSolucion.value];
    if (config && config.ref.value) {
      try {
        const validateFunc = config.ref.value[config.validateFunc];
        const resetFunc = config.ref.value[config.resetFunc];

        const error = await validateFunc();
        const validateSolutions = validateSolution(error);
        if (!validateSolutions) {
          loading.value = true;
          sendDataSolution();
          setTimeout(() => {
            resetFunc();
          }, 1000);
        }
      } catch (error) {
        console.error("Error en la validación:", error);
      }
    }
  } else {
    loading.value = true;
    sendOtherData();
  }
}

function sendDataSolution() {
  let body = {
    issue_id: route.params.id,
    type: currentTabSolucion.value,
    origin: isAgency.value ? "AGENCIA" : "CLIENTE",
  };
  switch (currentTabSolucion.value) {
    case "oficina":
      body.point_code = dataOffice.value.point_code;
      body.observations = dataOffice.value.observations;
      break;
    case "devolucion":
      break;
    case "reexpedir":
      body.new_date = dateUtils.formatDate(
        dataAddress.value.date,
        "YYYY-MM-DD HH:mm"
      );
      body.schedules = dataAddress.value.schedules;
      body.name = dataAddress.value.name;
      body.email = dataAddress.value.email;
      body.street = dataAddress.value.address.street;
      body.city = dataAddress.value.address.city;
      body.country = dataAddress.value.address.country;
      body.postal_code = dataAddress.value.address.postal_code;
      body.phone = dataAddress.value.phone;
      body.observations = dataAddress.value.instructions;
      body.contact = dataAddress.value.contact;
      break;
    case "fecha":
      body.new_date = dateUtils.formatDate(
        changeDateInfo.value.date,
        "YYYY-MM-DD HH:mm"
      );
      body.schedules = changeDateInfo.value.schedules;
      body.observations = changeDateInfo.value.instructions;
      break;
  }
  issueStoreRequest.sendChatSolution(body);
}

function sendOtherData() {
  let body = {
    issue_id: route.params.id,
    message: message.value,
    type: currentTab.value,
  };
  issueStoreRequest.sendChat(body);
}

const deleteCurrent = ref(null);
function deleteChat(id) {
  sweetAlert({
    title: "¿Está seguro de querer eliminar el mensaje?",
    text: "¡No podrá revertir esta acción!",
    showCancelButton: true,
    confirmButtonText: "Eliminar",
    cancelButtonText: "Cancelar",
    reverseButtons: true,
    customClass: {
      confirmButton: `btn ms-1 bg-gradient-danger`,
      cancelButton: "btn btn-outline-primary me-1",
    },
    buttonsStyling: false,
  }).then((result) => {
    if (result.isConfirmed) {
      deleteCurrent.value = id;
      issueStoreRequest.deleteChat(localValue.value._id, id);
    } else {
      isLoading.value = false;
    }
  });
}

function setLabelStatus(status) {
  if (status === "EN_CURSO") return "En curso";
  if (status === "PENDIENTE") return "Pendiente";
  if (status === "FINALIZADO") return "Finalizado";
}

function getDisabled(currentTab) {
  if (currentTab === "SOLUCION") {
    return loading.value;
  } else {
    return loading.value || message.value === "";
  }
}
watch(
  () => currentTabSolucion.value,
  (newTab, oldTab) => {
    if (newTab !== oldTab) {
      resetAllModels();
    }
  }
);
watch(
  () => issueStoreRequest.deletedId,
  (value) => {
    if (value) {
      setTimeout(() => {
        getChats(localValue.value._id);
        deleteCurrent.value = "";
      }, 1000);
    }
  }
);

watch(
  () => issueStore.error,
  (value) => {
    if (value) {
      snackbar.value = {
        title: "Error",
        message: value.message,
        type: "danger",
        color: "white",
      };
    }
  }
);

watch(
  () => issueStoreRequest.issue,
  (value) => {
    if (value) {
      localValue.value = value;
      currentStatus.value = localValue.value.status;

      const isEnvio = localValue.value.type === "ENVIO";
      const isCourierNotPropio = localValue.value.courier !== "PROPIO";

      if (isEnvio) {
        getShipment(localValue.value.entity_id);

        if (isCourierNotPropio) {
          tabs.push(
            { label: "Mail Courier", value: "EMAIL" },
            { label: "Soluciones", value: "SOLUCION" }
          );
        }
      } else {
        if (isCourierNotPropio) {
          tabs.push({ label: "Mail Partner", value: "EMAIL" });
        }
        getStop(localValue.value.entity_id);
      }

      getChats(localValue.value._id);
    }
  }
);
watch(
  () => shipmentStore.shipment,
  (value) => {
    if (value) {
      shipment.value = value;
    }
  }
);
watch(
  () => stopRequest.stopDetail,
  (value) => {
    if (value) {
      stop.value = value;
    }
  }
);
watch(
  () => issueStoreRequest.chats,
  (value) => {
    if (value) {
      listChats.value = value.data;
    }
  }
);
watch(
  () => issueStoreRequest.newChat,
  (value) => {
    if (value) {
      setTimeout(() => {
        getChats(localValue.value._id);
        message.value = "";
        loading.value = false;
      }, 1000);
    }
  }
);

watch(
  () => issueStoreRequest.chatSolution,
  (value) => {
    if (value) {
      setTimeout(() => {
        getChats(localValue.value._id);
        loading.value = false;
      }, 1000);
    }
  }
);

const snackbar = ref(null);
const closeSnackbar = () => {
  snackbar.value = null;
};
</script>
<template>
  <div class="card">
    <div v-if="localValue" class="card-body">
      <argon-detail-layout :group-actions="actions" @click="triggerEvent">
        <template #title>
          <argon-input
            id="name"
            v-model="localValue.description"
            type="text"
            size="lg"
            font-style="h4 fw-bold"
            :disabled="true"
          />
        </template>
        <template #buttons>
          <div class="dropdown d-inline">
            <argon-button
              id="navbarDropdownPrinter"
              color="primary"
              class="dropdown-toggle me-2 btn-sm"
              data-bs-toggle="dropdown"
              aria-expanded="false"
              >{{
                currentStatus ? setLabelStatus(currentStatus) : "Estado"
              }}</argon-button
            >
            <ul
              class="dropdown-menu z-index-1030 dropdown-menu-lg-start px-2 py-3"
              aria-labelledby="navbarDropdownPrinter"
              style
            >
              <li>
                <a
                  class="dropdown-item border-radius-md"
                  href="javascript:;"
                  @click="updateStatus('PENDIENTE')"
                  >Pendiente</a
                >
              </li>
              <li>
                <a
                  class="dropdown-item border-radius-md"
                  href="javascript:;"
                  @click="updateStatus('EN_CURSO')"
                  >En curso</a
                >
              </li>
              <li>
                <a
                  class="dropdown-item border-radius-md"
                  href="javascript:;"
                  @click="updateStatus('FINALIZADO')"
                  >Finalizado</a
                >
              </li>
            </ul>
          </div>
        </template>
        <template #buttons-right>
          <documents-button
            label="Documentos" :id-entity="route.params.id" container="documents"
            entity="issues" :origin="localValue.number"/>
        </template>
        <template #body>
          <div class="row">
            <div class="col-md-8 col-lg-8 col-sm-12">
              <div class="card h-100" style="min-height: 250px !important">
                <div class="card-body">
                  <tabs-bootstrap v-model="currentTab" :tabs="tabs" />
                  <div
                    class="border mb-2 radious-border"
                    v-if="currentTab === 'SOLUCION'"
                  >
                    <div class="header">
                      <tabs-bootstrap
                        v-model="currentTabSolucion"
                        :style-nav="'nav-underline'"
                        :tabs="tabsSoluciones"
                      />
                    </div>
                    <div class="body p-3">
                      <office-solution
                        ref="oficceSolutionRef"
                        v-model="dataOffice"
                        v-if="currentTabSolucion === 'oficina'"
                        :courier="localValue.courier"
                      />
                      <div
                        v-if="currentTabSolucion === 'devolucion'"
                        class="mb-4 tab-content"
                      >
                        <label class="col-form-label"
                          >¿Está seguro de retornar el envío?</label
                        >
                      </div>
                      <change-address-deliver
                        v-model="dataAddress"
                        ref="changeAdress"
                        v-if="currentTabSolucion === 'reexpedir'"
                        :courier="localValue.courier"
                      />
                      <change-date
                        ref="changeDateTab"
                        v-model="changeDateInfo"
                        v-if="currentTabSolucion === 'fecha'"
                        :courier="localValue.courier"
                      />
                    </div>
                  </div>
                  <!-- <solution v-if="currentTab === 'SOLUCION'" /> -->
                  <quill-editor
                    v-if="currentTab != 'SOLUCION'"
                    :max-length="70"
                    v-model="message"
                  />

                  <argon-loading-button
                    class="btn btn-sm bg-gradient-primary"
                    color="primary"
                    variant="gradient"
                    :label="loading ? 'Enviando...' : 'Enviar'"
                    :loading="loading"
                    icon="fa fa-paper-plane"
                    :disable-button="getDisabled(currentTab)"
                    @click="sendMessage"
                  >
                  </argon-loading-button>
                  <div style="max-height: 65%; overflow-y: auto">
                    <issue-message
                      v-for="chat in listChats"
                      :deleting="deleteCurrent == chat._id"
                      :key="chat._id"
                      :message="chat"
                      @deleteChat="deleteChat"
                    />
                  </div>
                </div>
              </div>
            </div>
            <div class="col-md-4 col-lg-4 col-sm-12">
              <div class="row spacing">
                <div class="col">
                  <div class="card">
                    <div class="card-header">Detalle</div>
                    <div class="card-body">
                      <div class="row">
                        <div class="col-4">
                          <label class="col-form-label">Ticket</label>
                        </div>
                        <div class="col-8">
                          <argon-input
                            v-model="localValue.number"
                            :disabled="true"
                          />
                        </div>
                      </div>
                      <div class="row">
                        <div class="col-4">
                          <label class="col-form-label">Fecha</label>
                        </div>
                        <div class="col-8">
                          <span>{{
                            moment(localValue.date).format("DD/MM/YYYY HH:mm")
                          }}</span>
                        </div>
                      </div>
                      <div class="row">
                        <div class="col-4">
                          <label class="col-form-label">Courier</label>
                        </div>
                        <div class="col-8">
                          <partner-avatar :item="localValue.courier" />
                        </div>
                      </div>
                      <div class="row">
                        <div class="col-4">
                          <label class="col-form-label">Prioridad</label>
                        </div>
                        <div class="col-8">
                          <argon-select
                            id="priority"
                            v-model="localValue.priority"
                            name-label="name"
                            name-value="value"
                            name="priority"
                            :disabled="true"
                            :options="prioridades"
                          >
                          </argon-select>
                        </div>
                      </div>
                    </div>
                  </div>
                </div>
              </div>
              <resume-shipment
                v-if="shipment && localValue.type === 'ENVIO'"
                :shipment="shipment"
              />
              <resume-stop
                v-if="stop && localValue.type !== 'ENVIO'"
                :stop="stop"
              />
            </div>
          </div>
        </template>
      </argon-detail-layout>
    </div>
  </div>
  <div class="position-fixed top-1 end-1 z-index-2">
    <argon-snackbar
      v-if="snackbar"
      :title="snackbar.title"
      :description="snackbar.message"
      :icon="{ component: 'ni ni-notification-70', color: snackbar.type }"
      :color="snackbar.color"
      :close-handler="closeSnackbar"
    />
  </div>
</template>
<style lang="scss" scoped>
.ql-container {
  max-height: 100px;
}

.radious-border {
  border-radius: 1rem;
}
</style>
