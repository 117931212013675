import createAxios from "./axiosHttp";

function sendLogo(formData) {
  const axios = createAxios({ "Content-Type": "multipart/form-data" });
  let urlPath = `portal/logo`;
  return axios.post(urlPath, formData);
}

function updateLogo(formData, idLogo) {
  const axios = createAxios({ "Content-Type": "multipart/form-data" });
  let urlPath = `portal/logo/${idLogo}`;
  return axios.put(urlPath, formData);
}

function getLogo() {
  const axios = createAxios();
  let urlPath = `portal/logo`;
  return axios.get(urlPath);
}

export const logoService = {
  sendLogo,
  getLogo,
  updateLogo
}