<!-- eslint-disable no-unused-vars -->
<script setup>
import { objectUtils } from "@/utils";
import { onMounted, watch, ref, toRefs, computed } from "vue";
import { Shops } from "@/globalVars";
import ArgonInput from "@/components/ArgonInput.vue";
import ArgonAvatar from "@/components/ArgonAvatar.vue";
import ArgonSwitch from "@/components/ArgonSwitch.vue";
import { ArgonLoadingButton } from "@/components"
import ArgonSelect from "@/components/ArgonSelect.vue";
import ArgonSnackbar from "@/components/ArgonSnackbar.vue";
import Shopify from "@/assets/img/small-logos/logo-shopify.svg";
import { syncListStore } from "@/_store/syncStore";
import { shopifyStore as shopifyTypeStore, marketplaceStore } from "@/_store";
import { useVuelidate } from "@vuelidate/core";
import { required, helpers, minValue } from "@vuelidate/validators";

const emit = defineEmits(["pingStore"]);

const props = defineProps({
  shopType: {
    type: String,
    default: () => ""
  },
  disableButton: {
    type: Boolean,
    default: () => false
  }
})

function ping() {
  emit("pingStore")
}

</script>
<template>
  <div class="row">
    <div class="col-12">
      <argon-loading-button
        color="primary"
        class="me-4"
        variant="outline"
        size="sm"
        :label="disableButton ? 'Comprobando...' : 'Probar conexión'"
        :loading="disableButton"
        :disable-button="disableButton"
        @click="ping"
      />
    </div>
  </div>
</template>
<style scoped>
.spinner-size {
  width: 26px !important;
  height: 26px !important;
}

.text-primary {
  color: linear-gradient(310deg, #ea7c07 0%, #fa8f1d 100%);
}
</style>