import { inject, provide } from "vue";
import { defineStore } from "pinia";
import { userService } from "@/_services";

/* import axios from "axios";
import { apiServer } from "@/globalVars.js"; */

export const userStore = defineStore("user", {
  state: () => ({
    user: null,
    error: null,
    filtersPage: {},
    changedPassword: null,
    resetedPassword: null,
    linkRecover: null,
    newedUser: null,
    disableButton: false,
    eventLogin:false
  }),
  getters: {
    userSigned: (state) => {
      if (state.user == null) {
        let usr = localStorage.getItem("user");
        if (usr) {
          state.user = JSON.parse(usr);
        }
      }
      return state.user;
    },
    isAuthenticated: (state) => state.user != null,
  },
  actions: {
    authenticate(user) {
      userService.login(user.email, user.password)
      .then((results) => {
        this.user = results;
        this.eventLogin = true;
      })
      .catch((reason) => this.error = reason);
    },
    changePassword(body){
      userService.changePassword(body)
      .then((result)=> this.changedPassword= result )
      .catch((reason) => (this.error = reason));
    },
    getRecoverLink(body){
      userService.getEnlaceRecuperacion(body)
      .then((result)=> this.linkRecover= result )
      .catch((reason) => (this.error = reason));
    },
    resetPassword(req){
      userService.resetPassword(req.body, req.token)
      .then((result)=> this.resetedPassword= result )
      .catch((reason) => (this.error = reason));
    },
    newUser(body){
      this.disableButton = true
      userService.newUser(body)
      .then((result)=> {
        this.newedUser = result
        this.disableButton = false
      })
      .catch((reason) => {
        this.error = reason
        this.disableButton = false
      });
    },
    onDownloadFile(fileName){
      userService.onDownloadFile(fileName)
      .then((result)=> {
        this.templateFile = result
      })
      .catch((reason) => {
        this.error = reason
      });
    }
  },
});

function getUserStore() {
  let store = inject("userStore", null);
  if (store === null) {
    store = userStore();
    provide("userStore", store);
  }
  return store;
}

export const useUserStore = getUserStore;
