import { defineStore } from "pinia";
import { batchFileShipmentsService } from "@/_services";

export const batchFileShipmentStore = defineStore("batchFileShipmentStore", {
  state: () => ({ batchFileShipments: null, error: null, batchListShipment:null }),
  actions: {
    getBatchFileShipments(pagination, filters,sort) {
      batchFileShipmentsService
        .getAllBatchesFiles(pagination, filters,sort)
        .then((results) => (this.batchFileShipments = results))
        .catch((reason) => (this.error = reason));
    },
    getListBatchShipments(batchId, pagination) {
      batchFileShipmentsService
        .getListBatchShipments(batchId, pagination)
        .then((results) => (this.batchListShipment = results),)
        .catch((reason) => (this.error = reason));
    },
    clearListBatchShipments(){
      this.batchListShipment= null;
    }
  },
});
