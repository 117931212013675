import { defineStore } from "pinia";
import { shopifyService, serviceService } from "@/_services";

export const profileDetailStore = defineStore("profile", {
  state: () => ({
    shop: null,
    error: null,
    services: null,
    createShop: null,
    updateShop: null,
    deactivateShopState: null,
  }),
  actions: {
    getShopify() {
      shopifyService
        .getShop()
        .then((result) => (this.shop = result))
        .catch((reason) => (this.error = reason));
    },
    createShopify(body) {
      shopifyService
        .createShop(body)
        .then((result) => (this.createShop = result))
        .catch((reason) => (this.error = reason));
    },
    updateShopify(id, body) {
      shopifyService
        .updateShop(id, body)
        .then((result) => (this.updateShop = result))
        .catch((reason) => (this.error = reason));
    },
    deactivateShop(id) {
      shopifyService
        .deactivateShop(id)
        .then((result) => (this.deactivateShopState = result))
        .catch((reason) => (this.error = reason));
    },
    getServices() {
      serviceService
        .getAllServices()
        .then((result) => (this.services = result.data))
        .catch((reason) => (this.error = reason));
    },
  },
});
