import { defineStore } from 'pinia';
import { productsService } from '@/_services';


export const productListStore = defineStore('productsList', {
  state: () => ({ products: null, productId: null, productAsExcel: null }),
  actions: {
    getAllProducts(pagination, filters, sort) {
        productsService.getAllProducts(pagination, filters, sort)
        .then((results) => this.products = results)
        .catch((reason) => this.error = reason);
    },
    getProductById(idProduct) {
      productsService.getProductById(idProduct)
      .then((results) => this.productId = results)
      .catch((reason) => this.error = reason);
  },
  getProductsAsExcel(query, sort) {
    productsService.getProductsAsExcel(query, sort)
    .then((results) => this.productAsExcel = results)
    .catch((reason) => this.error = reason);
},
  },
})
