
import { defineStore } from 'pinia'
import { createAxios } from '@/_services/axiosHttp';
import { objectUtils } from "@/utils";
import { fileService } from "@/_services";
import { ref } from "vue";

class UploadableFile {
  constructor(file) {
    this.file = file
    this.id = `${file.name}-${file.size}-${file.lastModified}-${file.type}`
    this.url = URL.createObjectURL(file)
    this.status = null
  }
}
export const fileViewStore = defineStore('fileView', {
  state: () => ({
    files: null,
    error: null,
    uploadStatus: null,
    disableButton: false,
    filesResponse: null,
    deleteResponse: ref(null),
    putResponse: null,
    emailResponse: null,
    totalDocResponse: null,
    filters: [],
    query: {},
    pagination: {
      page: 1,
      limit: 80,
    },
    sort: {
      _id: -1,
    },
    sendLoading:false,
  }),
  actions: {
    changeFilters(newFilters) {
      this.filters = objectUtils.copyByValue(newFilters);
      this.query = this.makeQuery();
    },
    makeQuery() {
      if (!this.filters.length > 0) return {};
      const defaultFilters = this.filtersByType("default");
      const textFilters = this.groupFilters(this.filtersByType("text"));
      const queryDefault = this.makeQueryDefault(defaultFilters);
      const queryText = this.makeQueryText(textFilters);
      return { ...queryText, ...queryDefault };
    },
    makeQueryDefault(filters) {
      if (filters.length == 0) return {};
      return Object.fromEntries(
        filters.map((filter) => [filter.field, filter.value])
      );
    },
    makeQueryText(filters) {
      if (filters.length == 0) return {};
      const query = { $and: [] };
      for (const filter of filters) {
        query.$and.push(this.getValueFilterText(filter));
      }
      return query;
    },
    filtersByType(type) {
      return this.filters.filter((filter) => filter.type == type);
    },
    getValueFilterText(filter) {
      const query = { $or: [] };
      if (filter.text.length == 1) {
        if (filter.isArray) {
          return {
            [filter.field]: {
              $elemMatch: { [filter.subField]: { $regex: filter.text[0], $options: "i" } }
            }
          };
        } else {
          return { [filter.field]: { $regex: filter.text[0], $options: "i" } };
        }
      }
      for (const value of filter.text) {
        if (filter.isArray) {
          query.$or.push({
            [filter.field]: {
              $elemMatch: { [filter.subField]: { $regex: value, $options: "i" } }
            }
          });
        } else {
          query.$or.push({ [filter.field]: { $regex: value, $options: "i" } });
        }
      }
      return query;
    },
    groupFilters(filters) {
      if (filters.length == 0) return [];
      return filters.reduce((acc, obj) => {
        const key = obj.field;
        let group = acc.find((group) => group.field === key); // Buscar grupo existente
        if (!group) {
          group = { field: obj.field, text: [obj.text] }; // Crear nuevo grupo si no existe
          acc.push(group);
        } else {
          group.text.push(obj.text); // Agregar valor "text" al grupo existente
        }
        return acc;
      }, []);
    },
    sendFile(file, url, extraData) {
      return new Promise((resolve, reject) => {
        let formData = new FormData();
        formData.append('file', file.file);
        if (extraData) {
          const data=JSON.stringify(extraData)
          formData.append("data",data);
        }
        file.status = 'loading'
        const axios = createAxios({ "Content-Type": "multipart/form-data" });
        axios.post(url, formData).then((data) => {
          file.status = 200;
          resolve(data);

        }).catch((reason) => {
          file.status = reason.statusCode;
          reject(reason);
        });
      });
    },
    addFavorite(favorite, route) {
      if (favorite == "") return;
      if (!this.filters.length > 0) return {};
      const filterFav = {
        name: favorite,
        filters: this.filters,
        route,
      };
      fileService
        .createFilter(filterFav)
        .then((results) => (this.filtersCustom = objectUtils.copyByValue(results.data)))
        .catch((reason) => (this.error = reason));
    },
    uploadFile(url, file, extraData) {
      this.disableButton = true
      let newFile = new UploadableFile(file)
      this.sendFile(newFile, url, extraData)
        .then((response) => {
          this.files = response;
          this.uploadStatus = "SUCCESS";
          this.disableButton = false
        })
        .catch((reason) => {
          this.files = null;
          this.uploadStatus = "ERROR";
          this.error = reason;
          this.disableButton = false
        })
    },
    getFiles(url, pagination, filters, sort) {
      fileService
        .getFiles(url, pagination, filters, sort)
        .then((results) => (this.filesResponse = results))
        .catch((reason) => {
          this.error = reason
        });
    },
    getTotalDocuments(url) {
      fileService
        .getTotalDocuments(url)
        .then((results) => (this.totalDocResponse = results))
        .catch((reason) => {
          this.error = reason
        });
    },
    putFile(url, body){
      fileService
      .putFile(url, body)
      .then((results) => (this.putResponse = results))
      .catch((reason) => {
        this.error = reason
      });
    },
    sendDocumentationEmail(url, body){
      fileService
      .sendDocumentationEmail(url, body)
      .then((results) => (this.emailResponse = results))
      .catch((reason) => {
        this.error = reason
      });
    },
    deleteFile(url) {
      fileService
        .deleteFile(url)
        .then((results) => (this.deleteResponse = results))
        .catch((reason) => {
          this.error = reason
        });
    },
  },
});

