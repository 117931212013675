import { defineStore } from 'pinia';
import { influenceAreaService } from '@/_services';


export const influenceListStore = defineStore('influenceList', {
  state: () => ({
    influence: null,
    error: null,
  }),
  actions: {
    sendInfluenceArea(postal_codes) {
      influenceAreaService.sendInfluenceArea(postal_codes)
          .then((results) => this.influence = results)
          .catch((reason) => this.error = reason);
    },
  },
})
