import moment from "moment";

function getToday() {
  return moment.utc();
}

function getRangeOfToday() {
  const today = getToday();
  return {
    startOfDay: today.clone().startOf("day"),
    endOfDay: today.clone().endOf("day"),
  };
}

function getRangeThisWeek() {
  const today = getToday();
  return {
    startOfWeek: today.clone().startOf("week"),
    endOfWeek: today.clone().endOf("week"),
  };
}

function getRangeThisMonth() {
  const today = getToday();
  return {
    startOfMonth: today.clone().startOf("month"),
    endOfMonth: today.clone().endOf("month"),
  };
}

function getRangeThisQuarter() {
  const today = getToday();
  return {
    startOfQuarter: today.clone().startOf("quarter"),
    endOfQuarter: today.clone().endOf("quarter"),
  };
}

function formatDate(fecha, format = "DD/MM/YYYY HH:mm") {
  return moment(fecha).format(format);
}

function add(date, unit, duration) {
  return moment(date).add(duration, unit);
}

export const dateUtils = {
  getToday,
  getRangeOfToday,
  getRangeThisWeek,
  getRangeThisMonth,
  getRangeThisQuarter,
  formatDate,
  add,
};
