import { defineStore } from "pinia";
import { batchFileCustomersService } from "@/_services";

export const customerBatchDetailStore = defineStore("customerBatchDetailStore", {
  state: () => ({ customerDetail: null, error: null, fileDownload: null }),
  actions: {
    getCustomer(id) {
      batchFileCustomersService
        .getBatchFile(id)
        .then((results) => (this.customerDetail = results))
        .catch((reason) => (this.error = reason));
    },
    downloadFile(id) {
      batchFileCustomersService
        .downloadFile(id)
        .then((results) => (this.fileDownload = results))
        .catch((reason) => (this.error = reason));
    },
  },
});
