<script setup>
defineProps({
  item: {
    type: String,
    default: "",
  },
});
function camelToNormal(text) {
  return text.replace("_", " ");
}
import ArgonBadge from "./../ArgonBadge";
</script>
<template>
  <argon-badge :color="'secondary'" class="size">{{
    camelToNormal(item)
  }}</argon-badge>
</template>

<style scoped>
.size {
  min-width: 120px;
}
</style>