<script setup>
import { onMounted, ref } from "vue";
import { dateUtils } from "@/utils";
import TiposImportaciones from "/src/maestros/TiposImportaciones.json";
import ArgonInput from "@/components/ArgonInput.vue";
import TypeBadge from "@/components/badges/BatchType.vue";
// import { userStore } from "@/_store/userStore";

// const userStores = userStore();


// const isAgency = computed(() => userStores.userSigned?.portalType==='AGENCY');

const props = defineProps({
  info: {
    type: Object,
    default() {
      return {
        customer:{
          contact:{
            name:''
          }
        }
      };
    },
  },
});
let created = ref("");
let customer= ref(
    {
      contact:{
        name:''
      }
    });
onMounted(() => {
  created.value = dateUtils.formatDate(
    props.info.created_at,
    "YYYY-MM-DDTHH:mm"
  );
  customer.value= props.info.customer;
});
</script>
<template>
<div class="row">
  <!-- <div v-if="isAgency" class="col-6">
    <div class="card" >
  <div v-if="isAgency" class="col-6">
    <div class="card" v-if="customer">
      <div class="card-header">Cliente</div>
      <div class="card-body">
        <div class="row">
          <div class="col-6">
            <div class="row">
              <div class="col-2">
                <label class="col-form-label">Nombre</label>
              </div>
              <div class="col-10">
                <argon-input
                  id="customer"
                  v-model="customer.contact.name"
                  placeholder=""
                  :disabled="true"
                  class="ms-4"
                />
              </div>
            </div>
          </div>
          <div class="col-6">
            <div class="row">
              <div class="col-2">
                <label for="inputCode" class="col-form-label">Código</label>
              </div>
              <div class="col-10">
                <argon-input
                  id="customer"
                  v-model="customer.code"
                  placeholder=""
                  :disabled="true"
                  class="ms-4"
                />
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
  </div> -->

  <div>
    <div class="card"> 
      <div class="card-body">
        <div class="row">
          <div class="col-2">
            <div class="row">
              <div class="col-2">
                <label class="col-form-label">Creado</label>
              </div>
              <div class="col-10">
                <argon-input
                  id="created"
                  v-model="created"
                  :is-required="true"
                  type="datetime-local"
                  placeholder=""
                  :disabled="true"
                  class="ms-4"
                />
              </div>
            </div>
          </div>
          <div class="col-2">
            <div class="row">
              <div class="col-2">
                <label for="inputPassword6" class="col-form-label">Tipo</label>
              </div>
              <div class="col-10">
                <type-badge
                  :item="TiposImportaciones[info.type][info.sub_type]"
                  class="ms-4"
                ></type-badge>
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
  </div>
</div>
</template>
<style scoped>
.required:after {
  content: " *";
  color: rgb(207, 22, 22);
}

.table-label {
  vertical-align: top;
}
</style>