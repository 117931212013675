<script setup>
import { computed, ref, onMounted } from "vue";
import ArgonSearcher from "@/components/searcher/ArgonSearcher.vue";
import ArgonButton from "@/components/ArgonButton.vue";
import ArgonTableSettings from "@/components/table/ArgonTableSettings.vue";
import { useRoute } from "vue-router";
import { fileViewStore } from "./fileViewStore";

const emit = defineEmits(["filterChanged", "newItem", "onReload", "newDoc"]);
const route = useRoute();
const fileInput = ref(null);
const fileStore = fileViewStore();
const triggerFileInput = () => {
  fileInput.value.click();
};
const onInputChange = (e) => {
  const file = e.target.files[0];
  if (file) {
    sendFile(file);
  }
  e.target.value = null;
};

const sendFile = (file) => {
  const data = {
    name: file.name,
    type: props.type,
    description: "",
    reference: "",
    origin: route.query.locator
  };
  fileStore.uploadFile(`${url.value}`, file, data);
};
const props = defineProps({
  fieldFilters: {
    type: Array,
    default() {
      return [];
    },
  },
  defaultFilters: {
    type: Array,
    default() {
      return [];
    },
  },
  showFavorites: {
    type: Boolean,
    default: true,
  },
  showConfigOption: {
    type: Boolean,
    default: false,
  },
  importOptions: {
    type: Object,
    default() {
      return {
        title: "Importar Fichero",
        options: [],
        url: "",
      };
    },
  },
  moreActions: {
    type: Array,
    default() {
      return [];
    },
  },
  showImportar: {
    type: Boolean,
    default: true,
  },
  idEntity: { type: String, default: "" },
  container: { type: String, default: "general" },
  entity: { type: String, default: "general" },
  type: { type: String, default: "" },
});

onMounted(() => {
});
function filterChanged(changes) {
  emit("filterChanged", changes);
}
function onEventAction(action) {
  emit('eventAction', action)
}
function onReload() {
  emit("onReload");
}
const url = computed(() => `documents/${props.container}/entity/${props.entity}/folder/${props.idEntity}/document`);
</script>
<template>
  <div class="row m-0 pt-1">
    <div class="col-md-auto d-flex ps-3 align-items-center">
      <argon-button class="me-2 btn-sm" color="primary" @click="triggerFileInput">
        <i class="fa fa-plus me-sm-2"></i>
        Nuevo
      </argon-button>
      <input ref="fileInput" type="file" style="display: none" @change="onInputChange" />
      <argon-table-settings :show-config-option="props.showConfigOption" :show-importar="false"
        @event-action="onEventAction" :moreActions="moreActions"></argon-table-settings>
    </div>
    <div class="col d-flex justify-content-center">
      <argon-searcher size="sm" icon="fa fa-search" class="argon-searcher w-50" placeholder="Buscar..."
        :field-filters="fieldFilters" :default-filters="defaultFilters" @filter-changed="filterChanged"
        :show-favorites="showFavorites"></argon-searcher>
      <div class="mt-1 ms-3">
        <nav aria-label="paginacion" class="d-flex align-items-left">
          <ul class="pagination m-0">
            <li class="page-item">
              <a v-tooltip data-bs-title="Refrescar" class="page-link" @click="onReload">&#8634;</a>
            </li>
          </ul>
        </nav>
      </div>
    </div>
    <!-- <div class="col-md-auto">
      <nav aria-label="paginacion" class="d-flex align-items-left">
        <ul class="pagination m-0">
          <li class="page-item">
            <a v-tooltip data-bs-title="Refrescar" class="page-link" @click="onReload">&#8634;</a>
          </li>
        </ul>
      </nav>
    </div> -->
  </div>
</template>

<style scoped>
.argon-searcher {
  width: 100%;
  max-width: 1140px;
  min-width: 200px;
}
</style>
