<script setup>
import { ref, onMounted, onBeforeUnmount} from "vue";
import { useStore } from "vuex";
const props=defineProps({
  width: {
    type: String,
    default: "",
  }
})
const store = useStore();
const emit=defineEmits(["hide"])
const bootstrap = store.state.bootstrap;
const modalRef = ref(null);

onMounted(() => {
  const modalElement = modalRef.value;
  if (modalElement) {
    modalElement.addEventListener("hidden.bs.modal", onModalHide);
  }
});

onBeforeUnmount(() => {
  const modalElement = modalRef.value;
  if (modalElement) {
    modalElement.removeEventListener("hidden.bs.modal", onModalHide);
  }
});

function closeModal(){
  bootstrap.Modal.getInstance(modalRef.value)?.hide();
}
function onModalHide() {
  emit("hide");
}
function getStyle(){
  if(props.width==='') return "";
  return `min-width: ${props.width} !important;`;
}
defineExpose({
  closeModal,
});
</script>
<template>
  <div ref="modalRef" class="modal fade card-detail-layout" tabindex="-1" aria-hidden="true">
    <div class="modal-dialog mt-lg-10" :style="getStyle()">
      <div class="modal-content" :style="getStyle()">
        <div class="modal-header">
          <slot name="header">
            <h5 class="modal-title">Sin titulo</h5>
          </slot>
          <button type="button" class="btn-close btn-sm" size="sm" data-bs-dismiss="modal" aria-label="Close" @click="closeModal"></button>
        </div>
        <div class="modal-body">
          <slot name="body">
            <p>Sin contenido</p>
          </slot>
        </div>
        <div class="modal-footer">
          <button
            type="button"
            class="btn btn-outline-primary btn-sm"
            data-bs-dismiss="modal"
            @click="closeModal()"
          >
            Cerrar
          </button>
          <slot name="footer">
          </slot>
        </div>
      </div>
    </div>
    <slot></slot>
  </div>
</template>


