<script setup>
import ArgonTable from "@/components/table/ArgonTable.vue";
import moment from "moment";
import MarketplaceAvatar from "@/components/MarketplaceAvatar.vue";
import SynchronizationStatus from "@/components/badges/SynchronizationStatus.vue";
import { onMounted, watch, ref } from "vue";
import { syncListStore } from "@/_store/syncStore";
import { userStore } from "@/_store/userStore";
import { argonTableStore } from "@/_store/argonTableStore";
import ArgonTd from "@/components/table/ArgonTd.vue";
import { estados } from "@/maestros/marketplaceStatus";
import ArgonSnackbar from "@/components/ArgonSnackbar.vue";

const snackbar = ref(null);
const closeSnackbar = () => {
  snackbar.value = null;
};
const syncStore = syncListStore();
const userStores = userStore();
const tableStore = argonTableStore();
const fieldFilters = [
  {
    field: "marketplace",
    description: "Marketplace",
  },
];
const columns = [
  {
    header: "Fecha inicio",
    field: "ini_date",
    sort: false,
    width: "w-15",
  },
  {
    header: "Fecha fin",
    field: "end_date",
    sort: false,
    width: "w-15",
  },
  {
    header: "Marketplace",
    field: "marketplace",
    position: "center",
    sort: false,
    width: "w-15",
  },
  {
    header: "Estado",
    field: "status",
    position: "center",
    sort: false,
    width: "w-5",
  },
];

const defaultFilters = [
  {
    field: "status",
    label: "Estado",
    multi: true,
    options: estados,
  },
];

onMounted(() => {
  tableStore.clear();
  if (userStores.filtersPage.synchronizations)
    tableStore.changeFilters(userStores.filtersPage.synchronizations);
  else tableStore.changeFilters([]);
  reloadPage();
});
function reloadPage() {
  userStores.filtersPage.synchronizations = tableStore.filters;
  syncStore.getAllSynchronizations(
    tableStore.pagination,
    tableStore.query,
    tableStore.sort
  );
}
function getPosition(field) {
  const column = columns.find((col) => col.field === field);
  return column && column.position ? column.position : "start";
}

function syncShop(shopType) {
  syncStore.syncShop(shopType);
}

watch(
  () => syncStore.error,
  (value) => {
    if (value) {
      snackbar.value = {
        title: "Error",
        message: value.message,
        type: "danger",
        color: "white",
      };
    }
  }
);

watch(
  () => syncStore.sync,
  (value) => {
    if (value) {
      snackbar.value = {
        title: "Actualizado",
        message: `<ul>Sincronización iniciada</ul>`,
        type: "info",
        color: "white",
      };
      setTimeout(() => {
        reloadPage();
      }, 1000);
    }
  }
);
</script>
<template>
  <div class="card">
    <div class="card-body">
      <argon-table
        v-if="syncStore.syncs"
        :show-sync-button="false"
        :show-new-button="false"
        :show-config-option="false"
        :columns="columns"
        :field-filters="fieldFilters"
        :show-check-input="false"
        :items="syncStore.syncs"
        :table-hover="false"
        :default-filters="defaultFilters"
        @page-changed="reloadPage"
        @on-reload="reloadPage"
        @filter-changed="reloadPage"
        @on-sync="syncShop"
      >
        <template #default="{ item }">
          <argon-td
            :position="getPosition('ini_date')"
            :text-primary="moment(item.ini_date).format('DD/MM/YYYY HH:mm')"
            :text-secondary="moment(item.ini_date).fromNow()"
          ></argon-td>
          <argon-td
            :position="getPosition('end_date')"
            :text-primary="item.end_date ? moment(item.end_date).format('DD/MM/YYYY HH:mm') : ''"
            :text-secondary="moment(item.ini_date).fromNow()"
          ></argon-td>
          <argon-td :position="getPosition('marketplace')">
            <marketplace-avatar :item="item.marketplace"></marketplace-avatar>
          </argon-td>
          <argon-td :position="getPosition('status')">
            <SynchronizationStatus
              v-if="item.status"
              class="ms-4 mb-2"
              :item="item.status"
              :tooltip="item.error"
            ></SynchronizationStatus>
          </argon-td>
        </template>
      </argon-table>
    </div>
  </div>
  <div class="position-fixed top-1 end-1 z-index-2">
    <argon-snackbar
      v-if="snackbar"
      :title="snackbar.title"
      :description="snackbar.message"
      :icon="{ component: 'ni ni-notification-70', color: snackbar.type }"
      :color="snackbar.color"
      :close-handler="closeSnackbar"
    />
  </div>
</template>