<script setup>
const emit = defineEmits(["click"]);

defineProps({
  actions: {
    type: Array,
    required: true,
  },
  divider: {
    type: Boolean,
    default: false,
  },
});

function click(value) {
  emit("click", value);
}
</script>

<template>
  <li v-for="(action, key) of actions" :key="key">
    <a class="dropdown-item border-radius-md" @click="click(action)">
      {{ action.label }}
    </a>
  </li>
  <li v-if="divider">
    <hr class="dropdown-divider" />
  </li>
</template>