<script setup>

import { computed } from "vue";

import { dashboardStore } from "@/_store";
import DoughnutChart from "../../pages/components/DoughnutChart.vue";


const store = dashboardStore();

const labels = computed({
  // getter
  get() {
    let records = [...store.cities]

    if (records.length == 0) return false

    const labels = records.map((record) => record["name"])

    let datasets = {
      data: [],
      hoverOffset: 4
    }

    records.forEach((record) => {
      datasets["data"] = [...datasets["data"], record["quantity"]]
    })

    return {
      labels: labels,
      datasets: [datasets]
    }
  },
})


</script>
<template>
  <doughnut-chart
    v-if="labels"
    id="provinces-chart"
    title="Top 10 de ubicaciones de entrega"
    description=""
    :chart="labels"
  />
  <doughnut-chart
    v-else
    id="provinces-chart"
    title="Top 10 de ubicaciones de entrega"
    description=""
    :chart="{
      labels: [],
      datasets: [],
    }"
  />
</template>
