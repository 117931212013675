<script setup>
import { onMounted, ref, watch } from "vue";
const emit = defineEmits(["update:modelValue"]);

let localValue = ref(false);

const props = defineProps({
  name: {
    type: String,
    required: true,
  },
  id: {
    type: String,
    required: true,
  },
  modelValue: {
    type: Boolean,
    default: false,
  },
  labelClass: {
    type: String,
    default: "",
  },
  inputClass: {
    type: String,
    default: "",
  },
  label: {
    type: String,
    default: "",
  },
  isRequired: {
    type: Boolean,
    default: false,
  },
  leftLabel: {
    type: String,
    default: "",
  },
  disabled: {
    type: Boolean,
    default: false,
  },
});

onMounted(() => {
  localValue.value = props.modelValue;
});

watch(
  () => localValue.value,
  (value) => {
    emit("update:modelValue", value);
  }
);

</script>
<template>
  <div class="form-group">
    <p
      v-if="label != ''"
      class="fs-6 fw-bold"
      :class="{ required: isRequired }"
    >
      {{ label }}
    </p>
    <div class="form-check form-switch ps-0">
      <label
        v-if="leftLabel != ''"
        class="form-check-label"
        :class="labelClass"
        >{{ leftLabel }}</label
      >
      <input
        :id="id"
        v-model="localValue"
        class="form-check-input ms-1"
        :class="`${inputClass}`"
        type="checkbox"
        :name="name"
        :disabled="disabled"
      />
      <label class="form-check-label" :class="labelClass" :for="id">
        <slot />
      </label>
    </div>
  </div>
</template>

