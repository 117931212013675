<script setup>
import { onBeforeMount, onBeforeUnmount, ref,watch } from "vue";
import { useRouter } from "vue-router";
import { useStore } from "vuex";

import { useUserStore } from "@/_store/userStore.js";
//const swal = inject("$swal");
const userStore = useUserStore();
const router = useRouter();

// import Navbar from "@/examples/PageLayout/Navbar.vue";
import ArgonInput from "@/components/ArgonInput.vue";
import { ArgonLoadingButton } from "@/components";
import ArgonSnackbar from "@/components/ArgonSnackbar.vue";


import { useVuelidate } from "@vuelidate/core";
import { required, email, helpers } from "@vuelidate/validators";

const body = document.getElementsByTagName("body")[0];

const store = useStore();
const toggleDefaultLayout = () => store.commit("toggleDefaultLayout");

const rules = {
  password: { required: helpers.withMessage("Password  es requerido", required) },
  email: { required: helpers.withMessage("Email es requerido", required), email: helpers.withMessage("Ingrese un email valido", email) },
};
const loading = ref(false);

const user = ref({
  email: "",
  password: ""
})
const v$ = ref(useVuelidate(rules, user));

onBeforeMount(() => {
  store.state.hideConfigButton = true;
  toggleDefaultLayout();
  body.classList.remove("bg-gray-100");
});
onBeforeUnmount(() => {
  store.state.hideConfigButton = false;
  toggleDefaultLayout();
  body.classList.add("bg-gray-100");
});

function submit() {
  const resValidation = validate()
  resValidation.then((values) => {
    let message= "";
    for (const value of values) {
      message = `${message}<li>${value.$message}</li>`;
    }
    if (message != "") {
      snackbar.value = {
        title: "Error",
        message: `<ul>${message}</ul>`,
        type: "danger",
        color: "white",
      };
    } else {
        loading.value = true;
        userStore.authenticate(user.value);
        router.push({ name: "/" });
    }
  });
}
watch(
  () => userStore.user,
  (value) => {
    if (value) {
      loading.value = false;
      router.push({
        name: "/",
        query: { fromLogin: true }
      });
    }
  }
);
watch(
  () => userStore.error,
  (value) => {
    if (value) {
      loading.value = false;
      snackbar.value = {
        title: "Error",
        message: value.message,
        type: "danger",
        color: "white",
      };
    }
  }
);
async function validate() {
  await v$.value.$validate();
  return v$.value.$errors;
}
const snackbar = ref(null);
const closeSnackbar = () => {
  snackbar.value = null;
};
</script>
<template>
  <main class="mt-0 main-content card-detail-layout">
    <section>
      <div class="page-header min-vh-100"
      :style="{
        backgroundImage: 'url(' + '/img/logos/public-back-ground.png' + ')',
        backgroundSize: 'cover',
        backgroundPosition: 'center',
      }">
        <span class="mask opacity-3"></span>
        <div class="container text-center">
          <div
            class="row justify-content-md-center mb-6"
          >
            <div class="w-50">
              <img
                class="img-fluid"
                src="/img/logos/BeePaketLogo.png"
                alt="logo"
              />
            </div>
          </div>
          <div
            class="row justify-content-md-center"
          >
          <div
              class="mx-auto col-xl-4 col-lg-5 col-md-7 d-flex flex-column mx-lg-0"
            >
              <div class="card">
                <div class="card-header text-center">
                  <h3 class="font-weight-bolder">Inicio de sesión</h3>
                  <p class="">
                    Introduce tu correo y el password para acceder
                  </p>
                </div>
                <div class="card-body">
                  <form role="form" @submit.prevent="submit">
                    <argon-input
                      id="email"
                      v-model="user.email"
                      :max-length="75"
                      type="text"
                      placeholder="Email"
                      name="email"
                      size="lg"
                      :error="v$.email.$error"
                    />
                    <argon-input
                      id="password"
                      v-model="user.password"
                      type="password"
                      placeholder="Password"
                      name="password"
                      size="lg"
                      :error="v$.password.$error"
                    />
                    <argon-loading-button style="margin-bottom: 0px !important;" class="mt-4" color="default" variant="gradient"
            :label="loading ? 'Accediendo...' : 'Acceder'" :loading="loading" full-width size="lg"></argon-loading-button>
                    <!-- <argon-button
                      class="mt-4"
                      variant="gradient"
                      color="default"
                      full-width
                      size="lg"
                      >Acceder</argon-button
                    > -->
                    <div class="text-center mt-4 mb-4">
                      <router-link
                        :to="{ name: 'Reset Password' }"
                      >
                      ¿Olvidaste tu contraseña?
                    </router-link>
                    </div>
                  </form>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
    </section>
  </main>
  <div class="position-fixed top-1 end-1 z-index-2">
    <argon-snackbar
      v-if="snackbar"
      :title="snackbar.title"
      :description="snackbar.message"
      :icon="{ component: 'ni ni-notification-70', color: snackbar.type }"
      :color="snackbar.color"
      :close-handler="closeSnackbar"
    />
  </div>
</template>
<style scoped>
.mask{
  background-color: #ced4da  !important;
}
</style>
