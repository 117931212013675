import { defineStore } from 'pinia';
import { expediteService } from "@/_services";

export const expediteStore = defineStore('expedite', {
state: () => ({
    label: null,
    error: null,
}),
actions: {
  getlabel(mac) {
      expediteService.getLabelFromMac(mac)
      .then((results) => this.label = results)
      .catch((reason) => {
          this.label= null;
          this.error = reason
      });
  },
},
})