<script setup>
import { computed } from "vue";
import { argonTableStore } from "@/_store/argonTableStore";

const emit = defineEmits(["changeSort"]);

const tableStore = argonTableStore();
var clickCount=0;


const props = defineProps({
  headers: {
    type: Array,
    default() {
      return [];
    },
  },
});


function clickHead(item) {
  if(!item.sort) return;

  var field=Object.keys(orderStore.value)[0]
  if(field!=item.field)
    clickCount=0;

  clickCount++;
  var sortOrder=1;
  switch (clickCount) {
    case 1:
      sortOrder = 1;
      break;
    case 2:
      sortOrder = -1;
      break;
    default:
      sortOrder = null;
      clickCount = 0;
  }

  var order={};
  if(sortOrder){
    order[item.field]=sortOrder
  }else{
    order={
      "_id":-1
    }
  }
  tableStore.sort=order;
  emit("changeSort")
}
function isFocus(item,order){
  var field=Object.keys(orderStore.value)[0]
  if(item.field==field && order==orderStore.value[field]){
    return true;
  }else return false;
}
function getClass(item){
  var position=item.position?item.position:"start";
  return `${item.width} text-${position}`;
}

const orderStore = computed(() => tableStore.sort);

const headers = computed({
  get() {
    return props.headers
  }
});
</script>
<template>
  <th v-for="(item,key) in headers" :key="key" @click="clickHead(item)" class="pointer text-uppercase text-secondary text-xxs font-weight-bolder opacity-7" :class="getClass(item)">
    {{ item.header }}
    <div class="icon-container" v-if="item.sort">
      <i class="fa fa-sort-asc icon icon-asc" :class="{'icon-selected': isFocus(item,1)}"></i>
      <i class="fa fa-sort-desc icon icon-desc" :class="{'icon-selected': isFocus(item,-1)}"></i>
    </div>
  </th>
</template>
<style lang="scss" scoped>
  .table thead th {
    padding: 0.5rem 0.5rem;
  }
  .pointer{
    cursor: pointer;
  }
  .icon-container {
    position: relative;
    display: inline-block;
    width: 20px;
    height: 12px;
    margin-bottom: -2px;
  }
  .icon-container .icon {
    position: absolute;
    left: 0;
    right: 0;
    margin: auto;
  }
  .icon-desc {
    color: #a7b2c3;
    bottom: 0;
  }
  .icon-asc {
    color: #a7b2c3;
    top: 0;
  }
  .icon-selected{
    color: #636262;
  }
</style>