<script setup>
import { ref, computed, watch } from "vue";
import { ArgonLoadingButton } from "@/components";
import ArgonInput from "@/components/ArgonInput.vue";
import ArgonSnackbar from "@/components/ArgonSnackbar.vue";

import { useVuelidate } from "@vuelidate/core";
import { required, helpers, sameAs, not } from "@vuelidate/validators";
import { useUserStore } from "@/_store/userStore.js";
import TemplateFile from "./TemplateFile.vue";
import UploadLogo from "./UploadLogo.vue";
import { userStore as storeUser } from "@/_store/userStore";
const userStores = storeUser();
const userStore = useUserStore();
const credentials = ref({
  password: "",
  newPassword: "",
  confirmPassword: ""
});
const loading = ref(false);
const newPass = computed(() => credentials.value.newPassword)
const currentPass = computed(() => credentials.value.password)
const fileName = ref("");

const rules = {
  password: { required: helpers.withMessage("Contraseña  es requerido", required) },
  newPassword: {
    required: helpers.withMessage("Nueva contraseña es requerido", required),
    different: helpers.withMessage("La Nueva contraseña tiene que ser diferente a la actual", not(sameAs(currentPass))),
    passwordPattern: helpers.withMessage(
      () => ` Mínimo 8 carácteres, con alguna minúscula, mayúscula y número para Nueva contraseña`,
      (value) => /^(?=.*\d)(?=.*[a-z])(?=.*[A-Z])[a-zA-Z0-9!@#$%^&*)(+=._-]{8,}$/.test(value)
    ),
  },
  confirmPassword: {
    required: helpers.withMessage("Confirmar Contraseña es requerido", required),
    sameAsNewPassword: helpers.withMessage("Nueva contraseña y Confirmar contraseña deben coincidir", sameAs(newPass))
  }
};
const v$ = ref(useVuelidate(rules, credentials));

const optionsPlanilla = ref(
  [
    { label: 'Envíos', filename: 'plantilla_envios' },
    { label: 'Contactos', filename: 'plantilla_contactos' },
    { label: 'Clientes', filename: 'plantilla_clientes' },
    { label: 'Pesos Sorter', filename: 'plantilla_sorter', options: [{label: "Ctt Express", value: "xlsx", extra_name:'_ctt'}, {label: "Correos Express", value: "xlsx", extra_name:'_cex'},] },
    { label: 'Tarifas y suplentos', filename: 'plantilla_valoracion',options: [{label: "Suplementos", value: "xlsx", extra_name:'_conceptos'}, {label: "Tarifas Precios", value: "xlsx", extra_name:'_precios_tarifas'},] },
  ]
)


async function validate() {
  await v$.value.$validate();
  return v$.value.$errors;
}
const snackbar = ref(null);
function change() {
  const resValidation = validate()
  resValidation.then((values) => {
    let message = "";
    for (const value of values) {
      message = `${message}<li>${value.$message}</li>`;
    }
    if (message != "") {
      snackbar.value = {
        title: "Error",
        message: `<ul>${message}</ul>`,
        type: "danger",
        color: "white",
      };
    } else {
      loading.value = true;
      let contract = credentials.value
      contract.user = userStore.userSigned.email
      userStore.changePassword(contract);
    }
  });
}
function onDownloadFile(file) {
  fileName.value = file;
  userStore.onDownloadFile(fileName.value);
}
  function download(blob,fileName) {
    const link = document.createElement("a");
    link.setAttribute("download", fileName);
    link.href = URL.createObjectURL(blob);
    link.click();
    URL.revokeObjectURL(link.href);
  }
watch(
  () => userStore.error,
  (value) => {
    if (value) {
      snackbar.value = {
        title: "Error",
        message: value.message,
        type: "danger",
        color: "white",
      };
    }
  }
);
watch(
  () => userStore.changedPassword,
  (value) => {
    if (value) {
      snackbar.value = {
        title: "Guardado",
        message: "Su contraseña ha sido actualizada.",
        type: "info",
        color: "white",
      };
      credentials.value.password = "";
      credentials.value.newPassword = "";
      credentials.value.confirmPassword = "";
      loading.value = false;
      v$.value.$reset();
    }
  }
);

watch(
  () => userStore.templateFile,
  (value) => {
    if (value) {
      download(value,fileName.value);
    }
  }
);
const isAgency = computed(() => userStores.userSigned?.portalType === "AGENCY");
const plantillasList = computed(() => {
  const planillaOptions = [...optionsPlanilla.value];
  if (!isAgency.value) {
    planillaOptions.splice(2, 4);
  }
  return planillaOptions;
});

const closeSnackbar = () => {
  snackbar.value = null;
};

</script>

<template>
  <div class="row">
    <div class="col">
      <upload-logo />
    </div>   
    <div class="col">
      <div class="card h-100">
        <div class="card-header">
          Cambiar contraseña
        </div>
        <div class="card-body">
          <div class="row">
            <div class="col-3">
              <label for="col-form-label" class="col-form-label">Actual</label>
            </div>
            <div class="col-9">
              <argon-input id="password" v-model="credentials.password" type="password" placeholder=""
                :error="v$.password.$error" />
            </div>
          </div>
          <div class="row">
            <div class="col-3">
              <label for="col-form-label" class="col-form-label">Nueva</label>
            </div>
            <div class="col-9">
              <argon-input id="new-password" v-model="credentials.newPassword" type="password" placeholder=""
                :error="v$.newPassword.$error" />
            </div>
          </div>
          <div class="row">
            <div class="col-3">
              <label for="col-form-label" class="col-form-label">Confirmar</label>
            </div>
            <div class="col-9">
              <argon-input id="confirm-password" v-model="credentials.confirmPassword" type="password" placeholder=""
                :error="v$.confirmPassword.$error" />
            </div>
          </div>
        </div>
        <div class="card-footer">
          <div class="position-absolute bottom-5 end-2">
            <argon-loading-button style="margin-bottom: 0px !important;" class="btn btn-sm bg-gradient-primary" color="primary" variant="gradient"
            :label="loading ? 'Guardando...' : 'Cambiar contraseña'" :loading="loading" @click.prevent="change"></argon-loading-button>            
          </div>
        </div>
      </div>
    </div>
    <div class="col">
      <div class="card h-100">
        <div class="card-header">Plantillas ficheros masivos</div>
        <div class="card-body">
          <template-file v-for="item in plantillasList" :key="item.filename" :label="item.label"
            :file-name="item.filename" :options="item.options" @on-download="onDownloadFile" />
        </div>
      </div>
    </div>
  </div>
  <div class="position-fixed top-1 end-1 z-index-2">
    <argon-snackbar v-if="snackbar" :title="snackbar.title" :description="snackbar.message"
      :icon="{ component: 'ni ni-notification-70', color: snackbar.type }" :color="snackbar.color"
      :close-handler="closeSnackbar" />
  </div>
</template>
