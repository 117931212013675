<script setup>
import { computed } from "vue";

const props = defineProps({
  disableButton: {
    type: Boolean,
    default: false,
  },
  icon: {
    type: String,
    default: "",
  },
  label: {
    type: String,
    default: "",
  },
  color: {
    type: String,
    default: "success",
  },
  size: {
    type: String,
    default: "md",
  },
  variant: {
    type: String,
    default: "fill",
  },
  fullWidth: {
    type: Boolean,
    default: false,
  },
  active: {
    type: Boolean,
    default: false,
  },
  loading: {
    type: Boolean,
    default: false,
  }
})
const getClasses = (variant, color, size, fullWidth, active) => {
  let colorValue, sizeValue, fullWidthValue, activeValue;

  // Setting the button variant and color
  if (variant === "gradient") {
    colorValue = `bg-gradient-${color}`;
  } else if (variant === "outline") {
    colorValue = `btn-outline-${color}`;
  } else {
    colorValue = `btn-${color}`;
  }

  sizeValue = size ? `btn-${size}` : null;

  fullWidthValue = fullWidth ? `w-100` : null;

  activeValue = active ? `active` : null;

  return `${colorValue} ${sizeValue} ${fullWidthValue} ${activeValue}`;
}

const disable = computed({
  get() {
    return props.disableButton
  },
})

</script>
<template>
  <button class="btn" :class="getClasses(variant, color, size, fullWidth, active)" :disabled="disable">
    <div v-if="loading" class="spinner-border spinner-size me-sm-2" role="status">
      <span class="visually-hidden fs-1">Loading...</span>
    </div>
    <i v-if="!loading && icon != ''" :class="`${icon} me-sm-2`"></i>
    {{ label }}
  </button>
</template>
<style lang="scss">
.spinner-size {
  width: 16px !important;
  height: 16px !important;
}


</style>

