import createAxios from "./axiosHttp";

function sendInfluenceArea(postal_codes) {
  const axios = createAxios();
  const user = JSON.parse(localStorage.getItem('user'));
  let urlPath = `account/${user.extraData.accountId}/influence_area`;
  return axios.put(urlPath, postal_codes);
}

export const influenceAreaService = {
  sendInfluenceArea
}