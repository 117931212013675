<script setup>
const emit = defineEmits(["removeFilter"]);

const props = defineProps({
  field: {
    type: String,
    default: "",
  },
  description: {
    type: String,
    default: "",
  },
  text: {
    type: [String, Array],
    default: "",
  },
});

function removeFilter() {
  emit("removeFilter", { field: props.field, text: props.text });
}

function textClean(text) {
  if (Array.isArray(text)) {
    return text.join(" o ");
  }
  return text;
}
</script>

<template>
  <div class="col-auto d-flex m-1 filter-lighten p-0">
    <a class="filter-search field-search badge-primary">{{ description }}</a
    ><span class="filter-search text-search badge-secondary"
      >{{ textClean(text)
      }}<a class="ms-1" @click="removeFilter()"><i class="fas fa-times"></i></a
    ></span>
  </div>
</template>

<style lang="scss" scoped>
  .badge-secondary{
    max-width: 100;
    overflow: hidden;
    white-space: normal;
    overflow: visible;
  }
</style>