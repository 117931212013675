<script setup>
import { ref, watch } from 'vue';

const emit = defineEmits(['update:modelValue']);
const props = defineProps({
  modelValue: {
    type: String,
    default: '',
  },
  tabs: {
    type: Array,
    default: () => [],
  },
  styleNav:{
    type: String,
    default:"nav-tabs"
  }
});

const localValue = ref(props.modelValue);

function setActiveTab(tab) {
  localValue.value = tab;
  emit('update:modelValue', tab);
}

watch(
  () => props.modelValue,
  (newVal) => {
    localValue.value = newVal;
  }
);
</script>

<template>
  <ul class="nav mb-2" :class="styleNav">
    <li v-for="(tab, index) in tabs" :key="index" class="nav-item">
      <a
        href="#"
        class="nav-link"
        :class="{ active: localValue === tab.value }"
        @click.prevent="setActiveTab(tab.value)"
      >
        {{ tab.label }}
      </a>
    </li>
  </ul>
</template>

