<script setup>
import ArgonButton from "@/components/ArgonButton.vue";
const emit = defineEmits(["onDownload"]);
defineProps({
  label: {
    type: String,
    default: "",
  },
  fileName: {
    type: String,
    required: true,
  },
  options:{
    type: Array, 
    default() {
      return [
        {label: "Excel", value: "xlsx", extra_name:''},
        {label: "CSV", value: "csv",extra_name: ''}
      ];
    },
  }
});

function downloadFile(filename, extension) {
  emit("onDownload", `${filename}.${extension}`);
}
</script>

<template>
    <div class="row">
      <div class="col-6">
        <label :for="fileName" class="col-form-label">{{ label }}</label>
      </div>
      <div class="col-6 d-flex mb-2 justify-content-end">
        <div class="dropdown">
          <argon-button
            id="navbarDropdownPrinter"
            color="primary"
            class="dropdown-toggle btn-sm"
            data-bs-toggle="dropdown"
            aria-expanded="false"
          >
            <i class="fa fa-download me-sm-2"></i>Descargar
          </argon-button>
          <ul
            class="dropdown-menu z-index-1030 dropdown-menu-lg-start px-2 py-3"
            aria-labelledby="navbarDropdownPrinter"
          >
            <li v-for="(option, key) in options" :key="key" >
              <a
                class="dropdown-item border-radius-md"
                href="javascript:;"
                @click="downloadFile(`${fileName}${option.extra_name}`,option.value)"
              >
              {{ option.label }}
              </a>
            </li>
          </ul>
        </div>
      </div>
  </div>
</template>

<style scoped>
.download-content {
  width: 100%;
}
</style>
