import { defineStore } from "pinia";
import { shipmentService, serviceService, contactService, valuationService } from "@/_services";

export const shipmentDetailStore = defineStore("shipmentDetail", {
  state: () => ({
    error: null,
    shipment: null,
    updatedShipment: null,
    isNew: null,
    createdShipment: null,
    services: null,
    justificante: null,
    canceledShipment: null,
    shipmentLabel: null,
    defaultContact: null,
    valuation: null,
    shipmentStatusChange: null
  }),
  actions: {
    getShipmentById(idShipment) {
      shipmentService
        .getShipmentById(idShipment)
        .then((results) => (this.shipment = results))
        .catch((reason) => (this.error = reason));
    },
    updateShipment(id, body) {
      this.error = null
      shipmentService
        .updateShipment(id, body)
        .then((result) => (this.updatedShipment = result))
        .catch((reason) => (this.error = reason));
    },
    createShipment(body) {
      this.error = null
      shipmentService
        .createShipment(body)
        .then((results) => (this.createdShipment = results))
        .catch((reason) => {
          this.error = reason
        });
    },
    getServices() {
      serviceService
        .getAllServices()
        .then((result) => (this.services = result.data))
        .catch((reason) => (this.error = reason));
    },
    getJustificante(idShipment) {
      shipmentService
        .getJustificante(idShipment)
        .then((results) => (this.justificante = results))
        .catch((reason) => (this.error = reason));
    },
    getContactByDefault( customerId) {
      contactService.getContactByDefault(customerId)
      .then((result) => (this.defaultContact= result))
      .catch((reason) => (this.error = reason));
    },

    cancelShipment(shipmentId) {
      this.error = null
      shipmentService
        .cancelShipment(shipmentId)
        .then((results) => (this.canceledShipment = results))
        .catch((reason) => (this.error = reason));
    },
    getShipmentLabel(listId) {
      shipmentService
        .getShipmentLabel(listId)
        .then((results) => (this.shipmentLabel = results))
        .catch((reason) => (this.error = reason));
    },
    calculateValuation(body) {
      this.error = null
      valuationService
        .calculateValuation(body)
        .then((result) => (this.valuation = result
        ))
        .catch((reason) => {
          this.error = reason
        });
    },
    changeStatusShipment(shipmentId, contract){
      this.shipmentStatusChange= null;
      shipmentService
        .updateShipmentStatus(shipmentId,contract)
        .then((results) => (this.shipmentStatusChange = results))
        .catch((reason) => {
          this.shipmentStatusChange = null
          this.error = reason
        });
    }
  },
});
