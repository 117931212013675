<script setup>
import ArgonSnackbar from "@/components/ArgonSnackbar.vue";
import { ArgonLoadingButton } from "@/components";
import ModalBase from "@/components/modal/ModalBase.vue";
import { pricingConceptsListStore } from "@/_store/pricingConceptsStore";
import { useStore } from "vuex";
import { argonTableStore } from "@/_store/argonTableStore";
import { ref, onMounted, watch } from "vue";

const emits = defineEmits(["closeDelete", "deleteConceptsSuccess"]);
const tableStore = argonTableStore();
const deleteConceptModal = ref(null);
const pricingStore = pricingConceptsListStore();
let loading = ref(false);
const store = useStore();
const bootstrap = store.state.bootstrap;
onMounted(() => {
  let myModal = new bootstrap.Modal(
    document.getElementById("modalDeleteConcepts"),
    {
      keyboard: true,
    }
  );
  myModal.show();
});

function closeModal() {
  emits("closeDelete");
}
function deleteConcept() {
  loading.value = true;
  pricingStore.deletePricingConcepts(tableStore.checkedList);
}
watch(
  () => pricingStore.deleteConcepts,
  (value) => {
    if (value) {
      setTimeout(() => {
        tableStore.checkedList = [];
        deleteConceptModal.value.closeModal(true);
        emits("deleteConceptsSuccess");
      }, 600);
      snackbar.value = {
        title: "Actualizado",
        message: "Ha sido eliminado.",
        type: "info",
        color: "white",
      };
    }
  }
);
watch(
  () => pricingStore.error,
  (value) => {
    if (value) {
      snackbar.value = {
        title: "Error",
        message: value.message,
        type: "danger",
        color: "white",
      };
      loading.value = false;
    }
  }
);
const snackbar = ref(null);
const closeSnackbar = () => {
  snackbar.value = null;
};
</script>
<template>
  <modal-base id="modalDeleteConcepts" ref="deleteConceptModal" @hide="closeModal">
    <template #header>
      <h6 id="ModalLabel" class="modal-title">Eliminar suplemento</h6>
    </template>
    <template #body>
      <p>¿Está seguro que desea realizar esta acción?</p>
    </template>
    <template #footer>
      <argon-loading-button class="btn bg-gradient-primary btn-sm" color="primary" variant="gradient"
        :label="loading ? 'Eliminando...' : 'Aceptar'" :loading="loading" :disable-button="loading"
        @click="deleteConcept" />
    </template>
  </modal-base>
  <div class="position-fixed top-0 end-0 z-index-2 p-3">
    <argon-snackbar v-if="snackbar" :title="snackbar.title" :description="snackbar.message"
      :icon="{ component: 'ni ni-notification-70', color: snackbar.type }" :color="snackbar.color"
      :close-handler="closeSnackbar" />
  </div>
</template>