import { defineStore } from 'pinia';
import { accountService } from '@/_services';


export const accountListStore = defineStore('accountList', {
  state: () => ({
    account: null,
  }),
  actions: {
    getAccountsById() {
      accountService.getAccountsById()
        .then((results) => this.account = results)
        .catch((reason) => this.error = reason);
    },
  },
})
