import createAxios from "./axiosHttp";

function getPoints(pagination, query, sort) {
  const axios = createAxios();
  let urlPath = `point/ql`;
  const ql = {
    query,
    pagination,
    sort,
  };
  return axios.post(urlPath, ql);
}


export const redPointService = {
  getPoints
};
