<script setup>
import { onBeforeMount, onBeforeUnmount,ref,computed,onMounted } from "vue";
import { useStore } from "vuex";
import ArgonButton from "@/components/ArgonButton.vue";
import ArgonInput from "@/components/ArgonInput.vue";
import ArgonSnackbar from "@/components/ArgonSnackbar.vue";
import { useRoute } from "vue-router";

import { useVuelidate } from "@vuelidate/core";
import { required,helpers, sameAs} from "@vuelidate/validators";
import { useUserStore } from "@/_store/userStore.js";
const store = useStore();
const toggleDefaultLayout = () => store.commit("toggleDefaultLayout");

const body = document.getElementsByTagName("body")[0];
const route = useRoute();

const userStore = useUserStore();
let email = ref(false);

const credentials = ref({
  newPassword: "",
  confirmPassword:""
})
const newPass=computed(()=> credentials.value.newPassword)
onBeforeMount(() => {
  store.state.hideConfigButton = true;
  toggleDefaultLayout();
  body.classList.remove("bg-gray-100");
});
onBeforeUnmount(() => {
  store.state.hideConfigButton = false;
  toggleDefaultLayout();
  body.classList.add("bg-gray-100");
});
onMounted(() => {
  email.value = route.query.u;
});

const rules = {
  newPassword: {
    required: helpers.withMessage("Nueva contraseña es requerido", required),
    passwordPattern:  helpers.withMessage(
          () => ` Mínimo 8 carácteres, con alguna minúscula, mayúscula y número para Nueva contraseña`, 
          (value) => /^(?=.*\d)(?=.*[a-z])(?=.*[A-Z])[a-zA-Z0-9!@#$%^&*)(+=._-]{8,}$/.test(value)
        ),
  },
  confirmPassword:{
    required: helpers.withMessage("Confirmar Contraseña es requerido", required),
    sameAsNewPassword:   helpers.withMessage("Nueva contraseña y Confirmar contraseña deben coincidir",sameAs(newPass))
  }
};
const v$ = ref(useVuelidate(rules, credentials));

async function validate() {
  await v$.value.$validate();
  return v$.value.$errors;
}
const snackbar = ref(null);
function change() {
  const resValidation = validate()
  resValidation.then((values) => {
    let message= "";
    for (const value of values) {
      message = `${message}<li>${value.$message}</li>`;
    }
    if (message != "") {
      snackbar.value = {
        title: "Error",
        message: `<ul>${message}</ul>`,
        type: "danger",
        color: "white",
      };
    } else {
      let contract=credentials.value
      contract.user= email.value
      userStore.resetPassword({body:contract,token: route.query.ost});
    }
  });
}
const closeSnackbar = () => {
  snackbar.value = null;
};
</script>

<template>
  <main class="mt-0 main-content">
    <section>
      <div class="page-header min-vh-100">
        <div class="container">
          <div class="row">
            <div class="mx-auto col-xl-4 col-lg-5 col-md-7 d-flex flex-column mx-lg-0">
              <div class="card card-plain">
                <div class="pb-0 card-header text-start">
                  <h3 class="font-weight-bolder">Restaurando Contraseña</h3>
                </div>
                <div class="card-body">
                  <form id="resto-password-form">
                    <div class="mb-3">
                      <argon-input 
                      id="new-password" v-model="credentials.newPassword" type="password" size="lg"
                        placeholder="Nueva contraseña"  :error="v$.newPassword.$error" />
                    </div>
                    <div class="mb-3">
                      <argon-input 
                      id="confirm-password" v-model="credentials.confirmPassword" size='lg' type="password"
                        placeholder="Confirmar nueva contraseña"  :error="v$.confirmPassword.$error" />
                    </div>
                    <div class="text-center">
                      <argon-button 
                        class="mt-4"
                        variant="gradient"
                        color="primary"
                        full-width
                        size="lg"
                        @click.prevent="change">Restablecer</argon-button>
                    </div>
                  </form>
                </div>
              </div>
            </div>
            <div
              class="col-6 d-lg-flex d-none h-100 my-auto pe-0 position-absolute top-0 end-0 text-center justify-content-center flex-column">
              <div
                class="position-relative h-100 m-3 px-7 border-radius-lg d-flex flex-column justify-content-center overflow-hidden"
                :style="{
                  backgroundImage: 'url(' + '/img/logos/back-ground.png' + ')',
                  backgroundSize: 'cover',
                  backgroundPosition: 'center',
                }">
                <span class="mask bg-gradient-primary opacity-5"></span>

                <h2 class="mt-5 text-white font-weight-bolder position-relative">
                  <img class="w-10 me-3 mb-0" src="../../../assets/img/logos/logo-192.png" alt="logo" />
                  BeePaket
                </h2>
                <!-- <p class="text-white position-relative">Be Packet My Friend</p> -->
              </div>
            </div>
          </div>
        </div>
      </div>
    </section>
  </main>
  <div class="position-fixed top-1 end-1 z-index-2">
    <argon-snackbar 
    v-if="snackbar" :title="snackbar.title" :description="snackbar.message"
      :icon="{ component: 'ni ni-notification-70', color: snackbar.type }" :color="snackbar.color"
      :close-handler="closeSnackbar" />
  </div>
</template>
