import { defineStore } from "pinia";
import { accountConfigService } from "@/_services";

export const accountConfList = defineStore("accountListConfigurationStore", {
  state: () => ({ sendConf: null, getConf: null, putConf: null, error: null }),
  actions: {
    sendAccountStopConfig(data) {
      accountConfigService
        .sendAccountStopConfig(data)
        .then((results) => (this.sendConf = results))
        .catch((reason) => (this.error = reason));
    },
    getAccountStopConfig() {
      accountConfigService
        .getAccountStopConfig()
        .then((results) => (this.getConf = results))
        .catch((reason) => (this.error = reason));
    },
    putAccountStopConfig(idConfig, data) {
      accountConfigService
        .putAccountStopConfig(idConfig, data)
        .then((results) => (this.putConf = results))
        .catch((reason) => (this.error = reason));
    },
  },
});
