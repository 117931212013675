<script setup>
import { ref, watch } from "vue";
import { argonTableStore } from "@/_store/argonTableStore";

const emit = defineEmits(["onCheck", "onClick"]);
var checked = ref(false);

const tableStore = argonTableStore();
watch(
  () => tableStore.selectedPage,
  (newSelection) => {
    if (checked.value == newSelection) {
      return;
    }
    checked.value = newSelection;
    if (checked.value) {
      tableStore.addSelectedRow(props.rowId);
    } else {
      tableStore.removeSelectedRow(props.rowId);
    }
  }
);

watch(
  () => tableStore.hasSelectedItems,
  (newSelection) => {
    if (!newSelection) {
      checked.value = false;
    }
  }
);

const props = defineProps({
  rowId: {
    type: String,
    default: "",
  },
  showCheckInput: {
    type: Boolean,
    default: true
  },
  tableHover: {
    type: Boolean,
    default: true,
  },
});

function toogle(target) {
  if (target.checked) {
    tableStore.addSelectedRow(props.rowId);
  } else {
    tableStore.removeSelectedRow(props.rowId);
  }
  emit("onCheck", { id: props.rowId, checked: target.checked });
}

function clickRow(target) {
  if (target.type) return;
  if (target.id === "firstColumn") {
    if(!props.showCheckInput)
      return
    checked.value = !checked.value;
    toogle({ checked: checked.value });
    return;
  }
  emit("onClick", props.rowId);
}
</script>
<template>
  <tr :role="props.tableHover ? 'button' : null" @click="clickRow($event.target)">
    <td class="ps-3">
      <div class="my-auto form-check" :class="!showCheckInput?'disabled':''" id="firstColumn">
        <input :disabled="!showCheckInput" v-model="checked" class="form-check-input" type="checkbox" @click="toogle($event.target)" />
      </div>
    </td>
    <slot></slot>
  </tr>
</template>
