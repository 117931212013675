<script setup>
import { onMounted, ref } from "vue";
import Horario from "@/components/forms/Horario.vue";

let model = ref({
  first_frequency: {
    initial: "",
    final: "",
  },
  second_frequency: {
    initial: "",
    final: "",
  },
});

let props = defineProps({
  modelValue: {
    type: Object,
    default: () => ({
      first_frequency: {
        initial: "",
        final: "",
      },
      second_frequency: {
        initial: "",
        final: "",
      },
    }),
  },
});

let key = ref(1);

onMounted(() => {
  model.value = props.modelValue;
  key.value += 1;

  document
    .querySelector("#fulfillmentModal")
    .addEventListener("show.bs.modal", () => {
      model.value = props.modelValue;
    });
});
</script>
<template>
  <div class="row">
    <div class="col-2">
      <label class="col-form-label">Horario</label>
    </div>
    <div class="col-10">
      <Horario ref="horarioForm" :key="key" v-model="model" />
    </div>
  </div>
</template>
