<script setup>
defineProps({
  item: {
    type: String,
    default: "",
  },
  tooltipMessage: {
    type: String,
    default: "",
  },
});

const colors = {
  PENDIENTE_PREPARAR: "primary",
  PREPARADO_PARCIAL: "info",
  PREPARADO: "success",
  ANULADO: "danger-1",
  ERROR: "danger"
};

function camelToNormal(text) {
  return text.replace("_", " ");
}

import ArgonBadge from "./../ArgonBadge";
</script>
<template>
  <argon-badge v-tooltip :data-bs-title="tooltipMessage!=''?tooltipMessage:null" :color="colors[item]" variant="gradient" class="size">{{
    camelToNormal(item)
  }}</argon-badge>
</template>
<style scoped>
.size {
  min-width: 150px;
}
</style>