import { defineStore } from "pinia";
import { fulfillmentService } from "@/_services";

export const fulfillmentStore = defineStore("fulfillmentStore", {
  state: () => ({
    barcode: null,
    fulfillOrderState: null,
    error: null,
    changeService:0,
    fulfillmentByOrder:null
  }),
  actions: {
    generateBarcode(contract) {
      this.error = null
      fulfillmentService
        .generateBarcode(contract)
        .then((results) => (this.barcode = results))
        .catch((reason) => (this.error = reason));
    },
    fulfillOrder(body, orderId) {
      this.error = null
      fulfillmentService
        .fulfillOrder(body, orderId)
        .then((result) => (this.fulfillOrderState = result))
        .catch((reason) => (this.error = reason));
    },
    getByOrder(orderId){
      fulfillmentService
        .getByOrder(orderId)
        .then((result) => (this.fulfillmentByOrder = result))
        .catch((reason) => (this.error = reason));
    },
  },
});
