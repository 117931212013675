<!-- eslint-disable no-unused-vars -->
<script setup>
import { computed, ref } from 'vue';
import { useStore } from 'vuex';
import ProductItem from '../../ProductItem.vue';
import ArgonButton from "@/components/ArgonButton.vue";

const emit = defineEmits(["addProduct", "addAllProduct", "addSingleAllProduct"]);
const store = useStore();
const isRTL = computed(() => store.state.isRTL)

const props = defineProps({
  products: {
    type: Array,
    default() {
      return [];
    },
  },
  orderStatus:{
    type: String,
    default: "",
  }
});

function addProduct(product) {
  const {
    id,
    product_name,
    variant_sku,
    variant_ean,
    variant_id,
    quantity_pending,
    image
  } = product

  emit("addProduct", {
    id: id,
    product_name: product_name,
    variant_sku: variant_sku,
    variant_ean: variant_ean,
    variant_id: variant_id,
    quantity: quantity_pending,
    image: image
  });
}

function addSingleAllProduct(product) {
  const {
    id,
    product_name,
    variant_sku,
    variant_ean,
    variant_id,
    quantity_pending,
    image
  } = product

  emit("addSingleAllProduct", {
    id: id,
    product_name: product_name,
    variant_sku: variant_sku,
    variant_ean: variant_ean,
    variant_id: variant_id,
    quantity: quantity_pending,
    image: image
  });
}

function addAll() {
  emit("addAllProduct");
}

const isAllowAddAll = computed({
  get() {
    return props.products.some((product) => product.quantity_pending > 0)
  }
});
const showButtons = computed({
  get() {
    return props.orderStatus!='PREPARADO' && props.orderStatus!='ANULADO' && props.orderStatus!='ERROR';
  }
});
</script>
<template>
  <div class="card h-100 card-detail-layout">
    <div class="card-header">Productos</div>
    <div class="card-body body-height">
      <ul :class="`product-to-select-list list-group ${isRTL ? 'pe-0' : ''}`">
        <li
          v-for="(
            product, index
          ) of products" :key="index" :class="`mb-4 border-0 list-group-item d-flex justify-content-between border-radius-lg p-0 w-100`">
          <product-item :product="product" :show-buttons="showButtons" @add-product="addProduct" @addAllProduct="addSingleAllProduct"/>
        </li>
      </ul>
    </div>
    <div class="card-footer ps-1 pe-1 pb-0 text-center">
      <argon-button
        v-if="showButtons"
        size="sm"
        class='me-1'
        variant="outline"
        color="primary"
        :disabled="!isAllowAddAll"
        @click="addAll()"
      >
        Preparar todos
      </argon-button>
    </div>
  </div>
</template>
<style lang="scss" scoped>
.product-to-select-list {
  overflow-y: auto
}
.body-height{
  max-height: 410px;
  overflow-y: auto;
}
</style>
