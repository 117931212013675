<script setup>
import { onMounted, watch, ref } from "vue";
import { contactImportListStore } from "@/_store/contactsImportsStore";
import { argonTableStore } from "@/_store/argonTableStore";
import SimpleTable from "@/components/table/SimpleTable.vue";
import StatusBadge from "@/components/badges/ContactStatus.vue";
import ArgonTd from "@/components/table/ArgonTd.vue";
import TypeBadge from "@/components/badges/ContactType.vue";
import moment from "moment";

const contactsStore = contactImportListStore();
const tableStore = argonTableStore();
const listTable = ref([]);
const props = defineProps({
  idFile: {
    type: String,
    default: "",
  },
});

const headers = [
  {
    header: "Creado",
    field: "created_at",
    width: "w-10",
    sort: false,
  },
  {
    header: "Tipo",
    field: "type",
    sort: false,
    position: "center",
  },
  {
    header: "Nombre",
    field: "name",
    sort: false,
  },
  {
    header: "Dirección",
    field: "address",
    sort: false,
    position: "start",
  },
  {
    header: "Estado",
    field: "status",
    position: "center",
    width: "w-5",
    sort: false,
  },
];
onMounted(() => {
  reloadContactBatch();
});
function getPosition(field) {
  const column = headers.find((col) => col.field === field);
  return column && column.position ? column.position : "start";
}
function reloadContactBatch(){
  contactsStore.getListBatchContacts(props.idFile, tableStore.pagination);
}

watch(
  () => contactsStore.batchesListContacts,
  (newVal) => {
    if (newVal) {
      listTable.value = newVal;
    }
  }
);
</script>
<template>
  <simple-table
    v-if="listTable"
    :headers="headers"
    :items="listTable"
    @filter-changed="reloadContactBatch"
    @on-reload="reloadContactBatch"
    @page-changed="reloadContactBatch"
    :show-pagination="true"
  >
    <template #default="{ item }">
      <argon-td
        :position="getPosition('created_at')"
        :text-primary="moment(item.created_at).format('DD/MM/YYYY')"
        :text-secondary="moment(item.created_at).fromNow()"
      ></argon-td>
      <argon-td :position="getPosition('type')">
        <type-badge class="badge badge-md" :item="item.type"></type-badge>
      </argon-td> 
      <argon-td :text-secondary="item.name" :position="getPosition('name')" />
      <argon-td
        :position="getPosition('address')"
        :text-secondary="item.address"
        max-width="130px"
      />
      <argon-td :position="getPosition('status')">
        <status-badge :item="item.status"></status-badge>
      </argon-td>
    </template>
  </simple-table>
</template>