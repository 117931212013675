import createAxios from "./axiosHttp";

function getAllPricing(pagination, query, sort) {
  const axios = createAxios();
  let urlPath = `pricing/fare/ql`;
  const ql = {
    query,
    pagination,
    sort
  };
  return axios.post(urlPath, ql);
}

function getListPricings() {
  const axios = createAxios();
  let urlPath = `pricing/fare`;
  return axios.get(urlPath);
}

function getPricingPrice(pagination, query, sort) {
  const axios = createAxios();
  let urlPath = `pricing/fare/price/ql`;
  const ql = {
    query,
    pagination,
    sort
  };
  return axios.post(urlPath, ql);
}

function getPricingConcepts(pagination, query, sort) {
  const axios = createAxios();
  let urlPath = `pricing/concept/ql`;
  const ql = {
    query,
    pagination,
    sort
  };
  return axios.post(urlPath, ql);
}

function getPricingPriceById(id) {
  const axios = createAxios();
  let urlPath = `pricing/fare/price/${id}`;
  return axios.get(urlPath);
}

function getPricingConceptsAsExcel(query, sort) {
  const axios = createAxios();
  let urlPath = `/pricing/concept/report/ql`;
  const ql = {
    query,
    sort,
  };
  return axios.post(urlPath, ql, {
    responseType: "blob",
  }).catch(async (error) => {
    const data = await error.text();
    throw JSON.parse(data);
  });
}

function getPricingPriceAsExcel(query, sort) {
  const axios = createAxios();
  let urlPath = `/pricing/fare/price/report/ql`;
  const ql = {
    query,
    sort,
  };
  return axios.post(urlPath, ql, {
    responseType: "blob",
  }).catch(async (error) => {
    const data = await error.text();
    throw JSON.parse(data);
  });
}

function getPricingsAsExcel(query, sort) {
  const axios = createAxios();
  let urlPath = `/pricing/fare/report/ql`;
  const ql = {
    query,
    sort,
  };
  return axios.post(urlPath, ql, {
    responseType: "blob",
  }).catch(async (error) => {
    const data = await error.text();
    throw JSON.parse(data);
  });
}

function deletePricingConcepts(body) {
  const axios = createAxios();
  let urlPath = `pricing/concept/deletion`;
  return axios.post(urlPath,  { ids:body });
}
function getConceptList() {
  const axios = createAxios();
  let urlPath = `pricing/concept`;
  return axios.get(urlPath);
}

function createConcept(body) {
  const axios = createAxios();
  let urlPath = `pricing/concept`;
  return axios.post(urlPath, body);
}

export const pricingService = {
  getAllPricing,
  getPricingPrice,
  getPricingConcepts,
  getPricingPriceById,
  getListPricings,
  getPricingConceptsAsExcel,
  getPricingPriceAsExcel,
  getPricingsAsExcel,
  deletePricingConcepts,
  getConceptList,
  createConcept
}