<script setup>
import GalleryFilesContainer from "@/components/gallery-files/GalleryFilesContainer.vue";
import { useRoute } from "vue-router";

const route = useRoute();
const listType = [
  { label: "Recogida", value: "RECOGIDA" },
  { label: "Entrega", value: "ENTREGA" },
  { label: "Aduanas", value: "ADUANAS" },
  { label: "Otros", value: "OTROS" },
];
</script>
<template>
  <div class="card">
    <div class="card-body">
      <gallery-files-container :id-entity="route.params.id" container="documents"
        entity="shipments" :list-type="listType" :origin="route.query.locator" />
    </div>
  </div>
</template>