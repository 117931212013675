<script setup>
defineProps({
  item: {
    type: String,
    default: "",
  },
  tooltip: {
    type: [String, Array],
    default: "",
  },
});

const colors = {
  ERROR: "danger",
  REGISTRADO: "secondary",
  ENTREGADO: "success",
  RECOGIDA_PENDIENTE: "primary-1",
  TRANSMITIENDO: "primary-1",
  RECOGIDO: "primary",
  EN_TRANSITO: "info-1",
  EN_REPARTO: "info",
  INCIDENCIA: "danger-3",
  ANULADO: "danger-1",
  ENTREGA_CANCELADA: "danger-1",
};

function camelToNormal(text) {
  return text.replace("_", " ");
}

import ArgonBadge from "./../ArgonBadge";
</script>
<template>
  <argon-badge
    v-tooltip
    :data-bs-title="tooltip != '' ? tooltip : null"
    :color="colors[item]"
    variant="gradient"
    class="size"
    >{{ camelToNormal(item) }}</argon-badge
  >
</template>
<style scoped>
.size {
  min-width: 160px;
}
</style>