<script setup>
import { onBeforeMount, onBeforeUnmount } from "vue";
import { useStore } from "vuex";
import { useRouter } from "vue-router";

import AppFooter from "@/examples/PageLayout/Footer.vue";
import ArgonButton from "@/components/ArgonButton.vue";

const store = useStore();
const toggleDefaultLayout = () => store.commit("toggleDefaultLayout");
const router = useRouter();

onBeforeMount(() => {
  store.state.hideConfigButton = true;
  toggleDefaultLayout();
});
onBeforeUnmount(() => {
  store.state.hideConfigButton = false;
  toggleDefaultLayout();
});
</script>
<template>
  <div class="container position-sticky z-index-sticky top-0">
    <navbar
      btn-background="bg-gradient-warning"
      :dark-mode="true"
      is-blur="blur border-radius-lg my-3 py-2 start-0 end-0 mx-4 shadow"
    />
  </div>
  <main class="main-content mt-0">
    <div
      class="page-header min-vh-100"
      style="
        background-image: url(&quot;/img/shapes/beepaket-textura-gold.jpg&quot;);
      "
    > 
      <span class="mask bg-gradient-warning opacity-4"></span>
      <div class="container">
        <div class="row justify-content-center">
          <div class="col-lg-6 col-md-7 mx-auto text-center">
            <h1
              class="display-1 text-bolder text-white fadeIn1 fadeInBottom mt-5"
            >
              Error 403
            </h1>
            <h2 class="fadeIn3 fadeInBottom mt-3 text-white">
            You are not allow to access the requested page
            </h2>
            <p class="lead fadeIn2 fadeInBottom text-white">
              We suggest you to go to back to homepage.
            </p>
            <argon-button
              color="warning"
              variant="gradient"
              class="mt-4 fadeIn2 fadeInBottom"
              @click="router.push({ name: 'Dashboard' })"
              >Go to Homepage</argon-button
            >
          </div>
        </div>
      </div>
    </div>
  </main>
  <app-footer />
</template>
