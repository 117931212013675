  <script setup>
import { onMounted, ref, watch, defineProps } from "vue";
import { quillEditor } from "vue3-quill";
const emit = defineEmits(["update:modelValue"]);
const editorOptions = ref({});
const props = defineProps({
  modelValue: {
    type: String,
    default: "",
  },
  options: {
    type: Array,
    default: () => [],
  },
  placeholder: {
    type: String,
    default: "",
  },
  theme: {
    type: String,
    default: "snow",
  },
  maxLength: {
    type: Number,
    default: 500,
  },
});
const model = ref(props.modelValue);
const key = ref(0);

onMounted(() => {
  if (props.options.length > 0) {
    editorOptions.value = {
      placeholder: props.placeholder,
      theme: props.theme,
      modules: {
        toolbar: props.options,
      },
    };
  } else {
    editorOptions.value = {
      placeholder: props.placeholder,
      theme: props.theme,
      modules: {
        toolbar: [
          ["bold", "italic", "underline", "strike"],
          ["blockquote", "code-block"],
          [{ list: "ordered" }, { list: "bullet" }, { list: "check" }],
          [{ indent: "-1" }, { indent: "+1" }],
          [{ color: [] }, { background: [] }],
          [{ align: [] }],
          ["clean"],
        ],
      },
    };
  }
  key.value++;
});
watch(
  () => model.value,
  (value) => {
    emit("update:modelValue", value);
  },
  { deep: true }
);
watch(
  () => props.modelValue,
  (value) => {
    model.value=value;
  }
);
const onEditorChange = ({ quill }) => {
  const delta = quill.getContents();
  const lengthWithoutNewlines = delta.ops.reduce((length, op) => {
    if (op.insert && typeof op.insert === 'string') {
      return length + op.insert.replace(/\n/g, '').length;
    }
    return length;
  }, 0);

  if (lengthWithoutNewlines > props.maxLength) {
    let excess = lengthWithoutNewlines - props.maxLength;
    let index = quill.getLength() - 1;
    while (excess > 0 && index >= 0) {
      if (quill.getText(index, 1) !== '\n') {
        quill.deleteText(index, 1);
        excess--;
      }
      index--;
    }
  }

  model.value = quill.root.innerHTML;
};
</script>
  <template>
  <quill-editor
  style="height: 70px !important;"
    :key="key"
    :options="editorOptions"
    v-model:value="model"
    class="mb-2"
    @change="onEditorChange"
  />
</template>