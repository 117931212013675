<script setup>
import { ref, watch, defineProps } from "vue";
import { onMounted, onUnmounted } from "vue";
import BadgeTagProducts from "./badges/BadgeTagProducts.vue";
/* import MarketplaceTag from "@/components/badges/MarketplaceTag.vue";
import ArgonAvatar from "@/components/ArgonAvatar.vue";
import NotFound from "@/assets/img/image.png"; */
//import StatusBadge from "@/components/badges/BatchFileStatus.vue";
import ArgonInput from "@/components/ArgonInput.vue";
//import ArgonDetailLayout from "@/components/detail/ArgonDetailLayout.vue";
import PhotoSwipeLightbox from "photoswipe/lightbox";
import NotFound from "@/assets/img/image.png";
import "photoswipe/style.css";

const props = defineProps({
  info: {
    type: Object,
    default: () => ({
      image: null,
      name: "",
      description: "",
      variant_ean: "",
      variant_weight: "",
      variant_weight_unit: "",
      variant_sku: "",
      tags: [],
      marketplace: "",
    }),
  },
});

let lightbox;
const localValue = ref({ ...props.info });
const isZoomed = ref(false);
const handleClickOutside = (event) => {
  const gallery = document.getElementById("product-gallery");
  if (isZoomed.value && gallery && !gallery.contains(event.target)) {
    isZoomed.value = false;
  }
};

const toggleZoom = () => {
  isZoomed.value = !isZoomed.value;
};

onMounted(() => {
  if (!lightbox) {
    lightbox = new PhotoSwipeLightbox({
      gallery: "#" + "product-gallery",
      children: "a",
      pswpModule: () => import("photoswipe"),
    });
    lightbox.init();
  }
  
  document.addEventListener("click", handleClickOutside);
});

onUnmounted(() => {
  if (lightbox) {
    lightbox.destroy();
    lightbox = null;
  }
  document.removeEventListener("click", handleClickOutside);
});

watch(
  () => props.info,
  (newValue) => {
    localValue.value = { ...newValue };
  },
  { immediate: true }
);
</script>
<template>
  <div class="row">
    <div class="col-5">
      <div class="card h-100">
        <div class="card-body">
          <div id="product-gallery" class="my-gallery d-flex gap-3 h-100 w-100 justify-content-center">
            <img v-if="localValue.image" :src="localValue.image" alt="" class="shadow zoomable-image"
              @click="toggleZoom" :class="{ 'zoomed-in': isZoomed }" />
            <img v-else :src="NotFound" class="shadow zoomable-image mb-4" />
          </div>
        </div>
      </div>
    </div>
    <div class="col-7">
      <div class="row spacing">
        <div class="col">
          <div class="card">
            <div class="card-header">General</div>
            <div class="card-body">
              <div class="row">
                <div class="col-12">
                  <div class="row">
                    <div class="col-2">
                      <label class="col-form-label">Tipo</label>
                    </div>
                    <div class="col-10">
                      <argon-input id="tipo" v-model="localValue.type" :disabled="true"></argon-input>
                    </div>
                  </div>
                </div>
              </div>
              <div class="row">
                <div class="col-2">
                  <label for="inputPassword6" class="col-form-label">Descripción</label>
                </div>
                <div class="col-10">
                  <span v-html="localValue.description"></span>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
      <div class="row spacing">
        <div class="col">
          <div class="card">
            <div class="card-header">Variantes</div>
            <div class="card-body">
              <div class="row">
                <div class="col-6">
                  <div class="row">
                    <div class="col-4">
                      <label class="col-form-label">Sku</label>
                    </div>
                    <div class="col-8">
                      <argon-input id="tipo" v-model="localValue.variant_sku" :disabled="true"></argon-input>
                    </div>
                  </div>
                </div>
                <div class="col-6">
                  <div class="row">
                    <div class="col-4">
                      <label class="col-form-label">Ean</label>
                    </div>
                    <div class="col-8">
                      <argon-input id="tipo" v-model="localValue.variant_ean" :disabled="true"></argon-input>
                    </div>
                  </div>
                </div>
              </div>
              <div class="row">
                <div class="col-6">
                  <div class="row">
                    <div class="col-4">
                      <label class="col-form-label">Precio (€)</label>
                    </div>
                    <div class="col-8">
                      <argon-input id="tipo" v-model="localValue.variant_price" :disabled="true"></argon-input>
                    </div>
                  </div>
                </div>
                <div class="col-6">
                  <div class="row">
                    <div class="col-4">
                      <label class="col-form-label">Kilos</label>
                    </div>
                    <div class="col-8">
                      <argon-input id="tipo" v-model="localValue.variant_weight" :disabled="true"></argon-input>
                    </div>
                    <!-- <div class="col-7">
                      <argon-input id="variant_weight_unit" v-model="localValue.variant_weight_unit"
                        :disabled="true"></argon-input>
                    </div> -->
                  </div>
                </div>
              </div>
              <div class="row mb-2">
             
                <div class="col-12">
                  <BadgeTagProducts class="ms-1" v-for="data in localValue.variant_options" :key="data" :item="data" />
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
  </div>
</template>

<style scoped>

.avatar-xxxl:hover {
  cursor: zoom-in;
}

.zoomable-image {
  transition: transform 0.3s ease;
  cursor: pointer;
  max-width: 100%;
  max-height: 320px;
  object-fit: contain;
}

.zoomed-in {
  transform: scale(1.4) ;
  z-index: 2000;
}
</style>
