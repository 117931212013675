<script setup>
import { ref, onMounted, watch } from "vue";
import ArgonTable from "@/components/table/ArgonTable.vue";
import { pricingListStore } from "@/_store/pricingStore";
import { argonTableStore } from "@/_store/argonTableStore";
import ArgonTd from "@/components/table/ArgonTd.vue";
import { userStore } from "@/_store/userStore";
import ArgonSnackbar from "@/components/ArgonSnackbar.vue";

const pricingStore = pricingListStore();
const tableStore = argonTableStore();
const userStores = userStore();
const snackbar = ref(null);
const fieldFilters = ref([
  {
    field: "code",
    description: "Código",
  },
  {
    field: "name",
    description: "Nombre",
  },
]);

const defaultFilters = [
  {
    field: "default",
    label: "Defecto",
    multi: true,
    options: [
      { label: "Defecto", value: true },
    ],
  },
];

const columns = ref([
  {
    header: "Código",
    field: "code",
    position: "start",
    width: 'w-10',
    sort: true
  },
  {
    header: "Nombre",
    field: "name",
    position: "start",
    width: 'w-10',
  },
  {
    header: "Defecto",
    field: "default",
    position: "center",
    width: 'w-10',
  },
]);

onMounted(() => {
  tableStore.clear();
  const shipmentFilters = userStores.filtersPage.pricing?.filters || [];
  tableStore.changeFilters(shipmentFilters);
  reloadPage();
});

function reloadPage() {
  userStores.filtersPage.pricing = {
    filters: tableStore.filters,
    filtersFavorite: tableStore.filtersFavorite,
  };
  pricingStore.getAllPricing(
    tableStore.pagination,
    tableStore.query,
    tableStore.sort
  );
};
watch(
  () => pricingStore.error,
  (value) => {
    if (value) {
      snackbar.value = {
        title: "Error",
        message: value.message,
        type: "danger",
        color: "white",
      };
    }
  }
);
function exportData() {
  pricingStore.getPricingsAsExcel(tableStore.query, tableStore.sort);
}
function getPosition(field) {
  const column = columns.value.find((col) => col.field === field);
  return column && column.position ? column.position : "start";
};
function viewPDF(blob) {
  const URI = URL.createObjectURL(blob);
  window.open(URI, "_blank");
}
watch(
  () => pricingStore.pricingsAsExcel,
  (newVal) => {
    if (newVal) {
      viewPDF(newVal);
    }
  }
);
const closeSnackbar = () => {
  snackbar.value = null;
};
</script>
<template>
  <div class="card">
    <div class="card-body">
      <argon-table
        v-if="pricingStore.pricings"
        :columns="columns"
        :items="pricingStore.pricings"
        :field-filters="fieldFilters"
        :default-filters="defaultFilters"
        @filter-changed="reloadPage"
        @on-reload="reloadPage"
        @sort-changed="reloadPage"
        :table-hover="false"
        :show-check-input="false"
        @page-changed="reloadPage"
        @export-data="exportData"
        :show-importar="false"
        :show-new-button="false"
        >
        <template #default="{ item }">
          <argon-td :position="getPosition('code')" :text-secondary="item.code" />
          <argon-td :position="getPosition('name')" :text-secondary="item.name" />
          <argon-td :position="getPosition('default')">
            <i v-if="item.default" class="fa fa-check text-xxs"></i>
          </argon-td>
        </template>
      </argon-table>
    </div>
  </div>
  <div class="position-fixed top-1 end-1 z-index-2">
    <argon-snackbar
      v-if="snackbar"
      :title="snackbar.title"
      :description="snackbar.message"
      :icon="{ component: 'ni ni-notification-70', color: snackbar.type }"
      :color="snackbar.color"
      :close-handler="closeSnackbar"
    />
  </div>
</template>