import { defineStore } from "pinia";
import { batchFileShipmentsService, batchFileService } from "@/_services";

export const shipmentBatchDetailStore = defineStore(
  "shipmentBatchDetailStore",
  {
    state: () => ({
      batchFileShipment: null,
      error: null,
      fileDownload: null,
      batchShipmentLabel: null,
      canceledBatch: null,
    }),
    actions: {
      getBatchShipment(id) {
        batchFileShipmentsService
          .getBatchFile(id)
          .then((results) => (this.batchFileShipment = results))
          .catch((reason) => (this.error = reason));
      },
      downloadFile(id) {
        batchFileService
          .downloadFile(id)
          .then((results) => (this.fileDownload = results))
          .catch((reason) => (this.error = reason));
      },
      getBatchShipmentLabel(id) {
        batchFileShipmentsService
          .getBatchShipmentLabel(id)
          .then((results) => (this.batchShipmentLabel = results))
          .catch((reason) => (this.error = reason));
      },
      cancelShipmentsByBatch(batchId) {
        batchFileShipmentsService
          .cancelShipmentsByBatch(batchId)
          .then((results) => (this.canceledBatch = results))
          .catch((reason) => (this.error = reason));
      },
    },
  }
);
