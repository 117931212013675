import { defineStore } from "pinia";
import { marketplaceService, serviceService } from "@/_services";

export const marketplaceStore = defineStore("marketplaceStore", {
  state: () => ({
    shop: null,
    error: null,
    services: null,
    pingShopState: null,
    createShopState: null,
    updateShopState: null,
    deactivateShopState: null,
  }),
  actions: {
    getShop(id) {
      marketplaceService
        .getShop(id)
        .then((result) => (this.shop = result))
        .catch((reason) => (this.error = reason));
    },
    createShop(body, shopType) {
      marketplaceService
        .createShop(body, shopType)
        .then((result) => (this.createShopState = result))
        .catch((reason) => (this.error = reason));
    },
    updateShop(id, body) {
      this.error = null
      marketplaceService
        .updateShop(id, body)
        .then((result) => (this.updateShopState = result))
        .catch((reason) => (this.error = reason));
    },
    deactivateShop(id) {
      marketplaceService
        .deactivateShop(id)
        .then((result) => (this.deactivateShopState = result))
        .catch((reason) => (this.error = reason));
    },
    getServices() {
      serviceService
        .getAllServices()
        .then((result) => (this.services = result.data))
        .catch((reason) => (this.error = reason));
    },
    pingShop(body, shopType) {
      marketplaceService
        .pingShop(body, shopType)
        .then((result) => (this.pingShopState = result))
        .catch((reason) => (this.error = reason));
    },
  },
});
