import { defineStore } from "pinia";
import { batchFileCustomersService } from "@/_services";

export const customersImportListStore = defineStore("customersImportList", {
  state: () => ({ batchesFiles: null, error: null, batchesListCustomers: null }),
  actions: {
    getBatchesFiles(pagination, filters, sort) {
      batchFileCustomersService
        .getBatchesFilesCustomer(pagination, filters, sort)
        .then((results) => (this.batchesFiles = results))
        .catch((reason) => (this.error = reason));
    },
    getListBatchCustomers(batchId, pagination) {
      batchFileCustomersService
        .getListBatchCustomers(batchId, pagination)
        .then((results) => (this.batchesListCustomers = results))
        .catch((reason) => (this.error = reason));
    },
    clearListBatchCustomers(){
      this.batchesListCustomers=null;
    }
  },
});
