<script setup>
import { onMounted, watch, ref } from "vue";
import SimpleTable from "@/components/table/SimpleTable.vue";
import StatusBadge from "@/components/badges/ShipmentStatus.vue";
import ArgonTd from "@/components/table/ArgonTd.vue";
import { stopListStore } from "@/_store";
import { argonTableStore } from "@/_store/argonTableStore";

const stopStore = stopListStore();
const tableStore = argonTableStore();
const props = defineProps({
  idStop: {
    type: String,
    required: true,
  }
});
const listTable=ref([])

const headers = [  
  {
    header: "Localizador",
    field: "locator",
    width: "w-15",
    sort: false
  },
  {
    header: "Referencia",
    field: "reference",
    sort: false,
  },
  {
    header: "Estado",
    field: "status",
    sort: false,
    width: "w-5",
    position: "center",
  },
];
onMounted(() => {
  reloadStopShipments();
});
function getPosition(field) {
  const column = headers.find((col) => col.field === field);
  return column && column.position ? column.position : "start";
}
function reloadStopShipments(){
  stopStore.getListStopShipments(props.idStop, tableStore.pagination);
}

watch(
  () => stopStore.stopListShipment,
  (newVal) => {
    if (newVal) {
      listTable.value = newVal;
    }
  }
);
</script>
<template>
  <simple-table
    v-if="listTable"
    :headers="headers"
    :items="listTable"
    @on-reload="reloadStopShipments"
    @page-changed="reloadStopShipments"
    :show-pagination="true"
  >
    <template #default="{ item }">
      <argon-td
        :text-primary="item.locator"
        :position="getPosition('locator')"
      />
      <argon-td
        :text-secondary="item.reference"
        :position="getPosition('reference')"
      />
      <argon-td :position="getPosition('status')">
        <status-badge :item="item.status"></status-badge>
      </argon-td>
    </template>
  </simple-table>
</template>