import { defineStore } from "pinia";
import { pricingService } from "@/_services";

export const pricingListStore = defineStore("pricingList", {
  state: () => ({
    pricings: null,
    pricingsList: null,
    error: null,
    pricingPrices: null,
    pricingConcepts: null,
    pricingsAsExcel: null
  }),
  actions: {
    getAllPricing(pagination, query, sort) {
      pricingService
        .getAllPricing(pagination, query, sort)
        .then((results) => (this.pricings = results))
        .catch((reason) => (this.error = reason));
    },
    getListPricings() {
      pricingService
        .getListPricings()
        .then((results) => (this.pricingsList = results))
        .catch((reason) => (this.error = reason));
    },
    getPricingsAsExcel(query, sort){
      pricingService.getPricingsAsExcel(query, sort)
      .then((results) => this.pricingsAsExcel = results)
      .catch((reason) => this.error = reason);
    }
  },
});
