import createAxios from "./axiosHttp";

function getAllSynchronizations(pagination, query, sort) {
    const axios = createAxios();
    let urlPath = `marketplace/sync/ql`;
    const ql = {
      query,
      pagination,
      sort,
    };
    return axios.post(urlPath, ql);
}

export const syncService = {
    getAllSynchronizations,
}