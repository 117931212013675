import createAxios from "./axiosHttp";

function getAllServices() {
  const axios = createAxios();
  let urlPath = `service/ql`;
  const body = {
    query:{},
    pagination: {
      limit: 500,
      page: 1,
    },
    sort: {  
      priority: 1
    },
  };
  return axios.post(urlPath, body);
}

// function make_query(filters) {
//   if (!filters.length > 0) return {};
//   let query = { $or: [] };
//   for (const filter of filters) {
//     query.$or.push({ [filter.field]: { $regex: filter.text } });
//   }
//   return query;
// }

export const serviceService = {
  getAllServices,
};
