<script setup>
import { useRoute } from "vue-router";
import { onMounted, watch, ref } from "vue";
import { valuationStore as valuationStoreRequest } from "@/_store/valuationStore";
import ValuationsSubTable from "@/components/ValuationsSubTable.vue";
import ArgonSnackbar from "@/components/ArgonSnackbar.vue";
import ArgonDetailLayout from "@/components/detail/ArgonDetailLayout";
import moment from "moment/moment";
import ArgonInput from "@/components/ArgonInput.vue";
import StatusBadge from "@/components/badges/ValuationsStatus.vue";

const route = useRoute();
const valuationStore = valuationStoreRequest();
const valuation = ref(null);
const dateValuation = ref("");
const nameValuation = ref("");
onMounted(() => {
  getValuations(route.params.id);
});

function getValuations(id) {
  valuationStore.getValuationById(id);
}

watch(
  () => valuationStore.error,
  (value) => {
    if (value) {
      snackbar.value = {
        title: "Error",
        message: value.message,
        type: "danger",
        color: "white",
      };
    }
  }
);
watch(
  () => valuationStore.valuation,
  (value) => {
    if (value) {
      valuation.value = value;
      dateValuation.value = moment(valuation.value.shipment.date).format('DD-MM-YYYY');
      nameValuation.value = valuation.value.customer.code +" - "+ valuation.value.customer.contact.name;
    }
  }
);
const snackbar = ref(null);
const closeSnackbar = () => {
  snackbar.value = null;
};
</script>
<template>
  <div class="card">
    <div class="card-body">
      <argon-detail-layout>
        <template #state>
          <status-badge v-if="valuation" :tooltip-message="valuation.errors?valuation.errors[0]:''" :item="valuation.status"></status-badge>
        </template>
        <template #title>
          <div v-if="valuation">
            <argon-input id="name" placeholder="Nombre" v-model="valuation.shipment.locator"
            :disabled="true"
            type="text"
            size="lg"
            font-style="h5 fw-bold" />
          </div>
        </template>
        <template #body>
          <div class="card">
            <div class="card-body text-sm" v-if="valuation">
              <div class="row">
                <div class="col-1">
                  <label class="col-form-label">Fecha</label>
                </div>
                <div class="col-9">
                  <argon-input
                    id="date"
                    v-model="dateValuation"
                    :disabled="true"
                  />
                </div>
              </div>
              <div class="row">
                <div class="col-1">
                  <label class="col-form-label">Cliente</label>
                </div>
                <div class="col-9">
                  <argon-input
                    id="date"
                    v-model="nameValuation"
                    :disabled="true"
                  />
                </div>
              </div>
            </div>
          </div>
        </template>
        <template #tabs>
          <div class="card mt-3">
            <div class="card-body">
              <valuations-sub-table v-if="valuation" v-model="valuation" />
            </div>
          </div>
        </template>
      </argon-detail-layout>
    </div>
  </div>
  <div class="position-fixed top-1 end-1 z-index-2">
    <argon-snackbar
      v-if="snackbar"
      :title="snackbar.title"
      :description="snackbar.message"
      :icon="{ component: 'ni ni-notification-70', color: snackbar.type }"
      :color="snackbar.color"
      :close-handler="closeSnackbar"
    />
  </div>
</template>