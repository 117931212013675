<script setup>
import { ref, watch, defineProps } from 'vue';
import MarketplaceTag from "@/components/badges/MarketplaceTag.vue";
import ArgonAvatar from "@/components/ArgonAvatar.vue";
import NotFound from "@/assets/img/image.png";
import ArgonInput from "@/components/ArgonInput.vue";
import ArgonDetailLayout from "@/components/detail/ArgonDetailLayout.vue";

const props = defineProps({
  info: {
    type: Object,
    default: () => ({
      image: null,
      wahrehouse_name: '',
      product_name: '',
      sku: '',
      ean: '',
      marketplace: '',
      units: 0
    })
  }
});

const localValue = ref({ ...props.info });
const stock = ref(localValue.value.stock);
const isZoomed = ref(false);

const toggleZoom = () => {
  isZoomed.value = !isZoomed.value;
};

watch(
  () => props.info,
  (newValue) => {
    localValue.value = { ...newValue };
    stock.value = localValue.value.stock;
  },
  { immediate: true }
);
</script>

<template>
 <div>
  <argon-detail-layout class="w-100">
    <template #title>
      <argon-input placeholder="Almacén" font-style="h4 fw-bold" v-if="localValue.wahrehouse_name" v-model="localValue.wahrehouse_name" :disabled="true" />
    </template>
    <template #body>
      <div class="d-flex flex-column-reverse flex-sm-row w-100">
        <div>
          <argon-avatar 
          :image="localValue.image ? localValue.image : NotFound" 
            :size="localValue.image ? 'xxxl' : '404'" 
            alt="Avatar"
            :class="{ 'zoomed-in': isZoomed }" 
            @click="toggleZoom"
            shadow="sm"
          />
          <p class="small-font text-left">{{ localValue.image }}</p>
        </div>
        <div class="row w-100 mt-2 ms-4">
          <div class="col-xl-6 col-lg-6 col-md-6 col-sm-12">
            <table class="w-100">
              <tr>
                <td width="1px" class="text-nowrap table-label">
                  <p>Producto</p>
                </td>
                <td>
                  <p class="fs-6 text-start ms-4">{{ localValue.product_name }}</p>
                </td>
              </tr>
              <tr>
                <td width="1px" class="text-nowrap table-label">
                  <p>Sku</p>
                </td>
                <td>
                  <p class="fs-6 text-start ms-4">{{ localValue.sku }}</p>
                </td>
              </tr>
            </table>
          </div>
          <div class="col-xl-6 col-lg-6 col-md-6 col-sm-12 mt-4 mt-sm-0">
            <table class="w-100">
              <tr>
                <td width="1px" class="text-nowrap table-label">
                  <p>Ean</p>
                </td>
                <td>
                  <p class="fs-6 text-start ms-4">{{ localValue.ean }}</p>
                </td>
              </tr>
              <tr>
                <td width="1px" class="text-nowrap table-label">
                  <p>Marketplace</p>
                </td>
                <td>
                  <MarketplaceTag class="ms-4 mb-2" :item="localValue.marketplace"></MarketplaceTag>
                </td>
              </tr>
              <tr>
                <td width="1px" class="text-nowrap table-label">
                  <p>Unidades</p>
                </td>
                <td>
                  <p class="fs-6 text-start ms-4">{{ localValue.units }}</p>
                </td>
              </tr>
            </table>
          </div>
        </div>
      </div>
    </template>
  </argon-detail-layout>
</div>

</template>


  
  
<style scoped>
 
.small-font{
    font-size: 12px;
    max-width: 70%;
}
.table-label {
    vertical-align: top;
}

.avatar-xxxl:hover {
    cursor: zoom-in;
}

.zoomed-in {
    transform: scale(1.3);
    cursor: zoom-out;
    transition: transform 0.3s ease;
}
</style>
