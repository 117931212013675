import createAxios from "./axiosHttp";

function getAllCustomers(pagination, query, sort) {
  const axios = createAxios();
  let urlPath = `customer/ql`;
  const ql = {
    query,
    pagination,
    sort,
  };
  return axios.post(urlPath, ql);
}

function getCustomerById(idCustomer) {
  const axios = createAxios();
  let urlPath = `customer/${idCustomer}`;
  return axios.get(urlPath);
}

function updateCustomer(id, body) {
  const axios = createAxios();
  let urlPath = `customer/${id}`;
  return axios.put(urlPath, body);
}

function getCustomersAsExcel(query, sort) {
  const axios = createAxios();
  let urlPath = `/customer/report/ql`;
  const ql = {
    query,
    sort,
  };
  return axios.post(urlPath, ql, {
    responseType: "blob",
  }).catch(async (error) => {
    const data = await error.text();
    throw JSON.parse(data);
  });
}

function insertCustomer(body) {
  const axios = createAxios();
  let urlPath = `customer`;
  return axios.post(urlPath, body);
}

export const customerService = {
  getAllCustomers,
  getCustomerById,
  updateCustomer,
  getCustomersAsExcel,
  insertCustomer
};
