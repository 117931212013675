import { defineStore } from "pinia";
import { pricingService } from "@/_services";

export const pricingPricesListStore = defineStore("pricingPricesList", {
  state: () => ({
    error: null,
    pricingPrice: null,
    pricingPricesTable: null,
    pricingPricesAsExcel: null
  }),
  actions: {
    getPricingPrice(pagination, query, sort) {
      pricingService
        .getPricingPrice(pagination, query, sort)
        .then((results) => (this.pricingPricesTable = results))
        .catch((reason) => (this.error = reason));
    },
    getPricingPriceById(id) {
      pricingService
        .getPricingPriceById(id)
        .then((results) => (this.pricingPrice = results))
        .catch((reason) => (this.error = reason));
    },
    getPricingPriceAsExcel(query, sort){
      pricingService.getPricingPriceAsExcel(query, sort)
      .then((results) => this.pricingPricesAsExcel = results)
      .catch((reason) => this.error = reason);
    }
  },
});
